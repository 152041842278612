import { Button, Dialog, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { IoLogoLinkedin, IoLogoTwitter, IoLogoYoutube } from 'react-icons/io';

const styles = theme => ({
  root: {
    maxWidth: 800,
    [theme.breakpoints.up('md')]: {
      width: 800,
    },
  },
  leftPanel: {
    padding: theme.spacing(4, 4),
    backgroundColor: '#000',
    color: '#fff',
  },
  name: {
    marginBottom: theme.spacing(4),
  },
  address: {
    marginBottom: theme.spacing(8),
  },
  email: {
    color: 'rgba(255, 255, 255, 0.7)',
    marginBottom: theme.spacing(8),
  },
  socialLinks: {
    '& > a + a': {
      marginLeft: theme.spacing(3),
    },
  },
  socialIcon: {
    width: 24,
    height: 24,
  },
  rightPanel: {
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
  },
  description: {
    marginBottom: theme.spacing(8),
  },
});

function SubmitSuccessDialog({ classes, open }) {
  return (
    <Dialog
      scroll="paper"
      aria-describedby="submit-success-description"
      open={open}
      maxWidth={false}
      onClose={() => {}}
    >
      <Grid container className={classes.root} direction="row-reverse">
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.rightPanel}
          container
          direction="column"
          wrap="nowrap"
          alignItems="center"
          justify="center"
        >
          <Typography
            id="submit-success-description"
            component="p"
            variant="body1"
            align="center"
            className={classes.description}
          >
            Thank you for submitting, we will get back to you shortly
          </Typography>
          <Button variant="contained" color="secondary" component="a" href="https://omnixlabs.com">
            Back to home
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.leftPanel}>
          <Typography component="p" variant="h5" color="inherit" className={classes.name}>
            omniX labs
          </Typography>
          <Typography component="p" variant="subtitle1" color="inherit" className={classes.address}>
            New York, NY USA
          </Typography>
          <Typography
            component="a"
            href="mailto:info@omnixlabs.com?subject=Getting%20in%20Touch"
            variant="subtitle1"
            color="inherit"
            className={classes.email}
          >
            info@omnixlabs.com
          </Typography>
          <div className={classes.socialLinks}>
            <a aria-label="Twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/omniX_labs">
              <IoLogoTwitter className={classes.socialIcon} />
            </a>
            <a
              aria-label="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/omnixlabs/"
            >
              <IoLogoLinkedin className={classes.socialIcon} />
            </a>
            <a
              aria-label="YouTube"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCrlquejpqyZUfljbOyDbvhw"
            >
              <IoLogoYoutube className={classes.socialIcon} />
            </a>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default withStyles(styles)(SubmitSuccessDialog);

import camerasUptime from 'reducers/dashboard/camera/camerasUptime';
import loyalCustomers from 'reducers/dashboard/camera/loyalCustomers';
import returningCustomers from 'reducers/dashboard/camera/returningCustomers';
import snapshotsDownloader from 'reducers/dashboard/camera/snapshotsDownloader';
import totalCustomers from 'reducers/dashboard/camera/totalCustomers';
import vehicleBodyTypes from 'reducers/dashboard/camera/vehicleBodyTypes';
import vehicleMakes from 'reducers/dashboard/camera/vehicleMakes';
import waitTime from 'reducers/dashboard/camera/waitTime';
import { combineReducers } from 'redux-immutable';

export default combineReducers({
  totalCustomers,
  returningCustomers,
  loyalCustomers,
  waitTime,
  vehicleMakes,
  camerasUptime,
  vehicleBodyTypes,
  snapshotsDownloader,
});

import { all } from 'redux-saga/effects';
import analytics from 'sagas/analytics';
import auth from 'sagas/auth';
import dashboard from 'sagas/dashboard';
import entity from 'sagas/entity';
import insights from 'sagas/insights';
import location from 'sagas/location';
import network from 'sagas/network';
import profile from 'sagas/profile';
import reports from 'sagas/reports';
import user from 'sagas/user';

export default function* rootSaga() {
  yield all([
    auth(),
    analytics(),
    dashboard(),
    reports(),
    insights(),
    profile(),
    network(),
    user(),
    location(),
    entity(),
  ]);
}

export function dataLayerPush(value) {
  const dataLayer = window.dataLayer;
  if (dataLayer && dataLayer.push) {
    dataLayer.push(value);
  }
}

export function setupDataLayer(userContext) {
  const context = userContext?.originalUser ?? userContext;
  const userId = context?.id;
  dataLayerPush({ userId });
  setupInternalTrafficFlag(userContext);
}

export const internalTrafficCompanies = ['f08d8f88-7377-4f4e-9a00-7c94027a79a1'];

export function setupInternalTrafficFlag(userContext) {
  const context = userContext?.originalUser ?? userContext;
  const companyId = context?.company?.id;
  if (internalTrafficCompanies.includes(companyId)) {
    dataLayerPush({ internal: 'true' });
  }
}

import { Tooltip, withStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { UserRolesMap } from './constants';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
});

const roles = {
  [UserRolesMap.COMPANY_ADMIN]: {
    label: 'Company administrator',
    tooltip:
      'Company administrators have access to analytics data and cameras to all locations of their company and also are able to manage users.',
  },
  [UserRolesMap.LOCATION_MANAGER]: {
    label: 'Location manager',
    tooltip: 'Location managers have access to analytics data and cameras for the locations assigned to them.',
  },
  [UserRolesMap.PORTFOLIO_SUPERVISOR]: {
    label: 'Portfolio supervisor',
    tooltip:
      'Portfolio supervisors have access to analytics data and cameras in all locations, also able to view users, and companies in read-only mode.',
  },
  superadmin: {
    label: 'Administrator',
  },
};

function RoleWithTooltip({ classes, value }) {
  const role = roles[value];
  if (!role) {
    return null;
  }

  const { label, tooltip } = role;
  return (
    <span className={classes.root}>
      {label}
      {tooltip && (
        <Tooltip interactive title={tooltip}>
          <HelpIcon className={classes.helpIcon} />
        </Tooltip>
      )}
    </span>
  );
}

export default withStyles(styles)(RoleWithTooltip);

import { createReportSettings, reports, widgetReport } from 'api/report';
import mixpanel from 'components/common/mixpanel';
import { resolveLocationNameById } from 'components/common/utils';
import { actions, types } from 'reducers/reports/rewashes';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* fetchReport(requestDate, timezone, period, location, report) {
  return yield call(widgetReport, report, createReportSettings(requestDate, timezone, period, null, location));
}

function* fetchAllReports({ requestDate, timezone, period, location }) {
  try {
    const [total, details] = yield all([
      fetchReport(requestDate, timezone, period, location, reports.VEHICLE_REWASH_COUNT_TOTAL_BY_DATE),
      fetchReport(requestDate, timezone, period, location, reports.VEHICLE_REWASH_COUNT_BY_LICENSE_PLATE),
    ]);

    yield put(
      actions.reportSuccess(requestDate, timezone, period, location, {
        total: total.data,
        details: details.data,
      })
    );

    mixpanel((mp) =>
      mp.track('Rewashes', { timezone, period, locationId: location, locationName: resolveLocationNameById(location) })
    );
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, location, e));
  }
}

export default function* rewashes() {
  yield takeLatest(types.REPORT_REQUEST, fetchAllReports);
}

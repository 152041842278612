import defaultLogoBlack from 'components/media/logo_black.svg';
import defaultLogoWhite from 'components/media/logo_white.svg';
import washxLogoBlack from 'components/media/washx/logo_black.svg';
import washxLogoWhite from 'components/media/washx/logo_white.svg';

const defaultSettings = {
  productName: 'omniX',
  companyName: 'omniX labs',
  logoBlack: defaultLogoBlack,
  logoWhite: defaultLogoWhite,
  themePrimaryColor: '#1E50FF',
  themeSecondaryColor: '#141464',
  brandedPageSlogan: 'Give vision to your business.',
  brandedPageDetails:
    'Giving intelligent eyes to your business by enabling better experiences from any captured moment anytime and\n' +
    '            anywhere.',
};

export const whitelabels = [
  {
    host: 'washx',
    productName: 'WashX',
    companyName: 'WashX',
    logoBlack: washxLogoBlack,
    logoWhite: washxLogoWhite,
    themePrimaryColor: '#6F798B',
    themeSecondaryColor: '#23b4f0',
    brandedPageSlogan: 'Your intelligent business advisor.',
    brandedPageDetails: 'A savvy, precise way to enforce, understand and grow your EverWash members.',
  },
];

export const isWhiteLabel = whitelabels.some(({ host }) => window.location.hostname.toLowerCase().startsWith(host));
const currentSettings =
  whitelabels.find(({ host }) => window.location.hostname.toLowerCase().startsWith(host)) ?? defaultSettings;

export const { productName } = currentSettings;
export const { companyName } = currentSettings;
export const { logoBlack } = currentSettings;
export const { logoWhite } = currentSettings;
export const { themePrimaryColor } = currentSettings;
export const { themeSecondaryColor } = currentSettings;
export const { brandedPageSlogan } = currentSettings;
export const { brandedPageDetails } = currentSettings;

export function isProdEnvironment() {
  const hostname = window.location.hostname.toLowerCase();
  return hostname === 'portal.omnixlabs.com' || whitelabels.some(({ host }) => hostname === `${host}.omnixlabs.com`);
}

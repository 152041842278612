import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridGap: theme.spacing(2),
    gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
  },
  headerLegend: {
    marginLeft: theme.spacing(1),
  },
  value: {
    lineHeight: 1,
    marginBottom: theme.spacing(1),
  },
  label: {
    lineHeight: 1,
  },
}));

function ThreeValuesWidgetHeader({ left, center, right }) {
  const classes = useStyles();

  return (
    <Grid item container alignItems="center" className={classes.root}>
      <Grid
        container
        alignItems="flex-start"
        wrap="nowrap"
        direction="column"
        style={{ borderLeft: `4px solid ${left.color}`, paddingLeft: 12 }}
      >
        <Typography variant="h4" component="span" className={classes.value}>
          {typeof left.value === 'number' || typeof left.value === 'string' ? left.value : <>&mdash;</>}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" component="span" className={classes.label}>
          {left.name}
        </Typography>
      </Grid>
      {center && (
        <Grid
          container
          alignItems="flex-start"
          wrap="nowrap"
          direction="column"
          style={{ borderLeft: `4px solid ${center.color}`, paddingLeft: 12 }}
        >
          <Typography variant="h4" component="span" className={classes.value}>
            {typeof center.value === 'number' ? center.value : <>&mdash;</>}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" component="span" className={classes.label}>
            {center.name}
          </Typography>
        </Grid>
      )}
      {right && (
        <Grid
          container
          alignItems="flex-start"
          wrap="nowrap"
          direction="column"
          style={{ borderLeft: `4px solid ${right.color}`, paddingLeft: 12 }}
        >
          <Typography variant="h4" component="span" className={classes.value}>
            {typeof right.value === 'number' ? right.value : <>&mdash;</>}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" component="span" className={classes.label}>
            {right.name}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default ThreeValuesWidgetHeader;

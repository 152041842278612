import axios from 'axios';
import { stringify } from 'qs';

export function getUserContext() {
  return axios.get(`/apigw/v1/user/@me/context`);
}

export function login(data) {
  return axios.post('/apigw/auth/login', stringify(data));
}

export function logout() {
  return axios.post('/apigw/auth/logout');
}

import {
  Checkbox,
  Chip,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import toJS from 'components/common/toJS';
import { compact, get } from 'lodash';
import React from 'react';
import { FaTag as TagsIcon } from 'react-icons/fa';
import { connect } from 'react-redux';
import { getLocations } from 'reducers/auth';

const styles = theme => ({
  menuList: {
    paddingRight: '0 !important',
    width: '100% !important',
    maxWidth: '600px',
  },
  selectRoot: {
    width: 'calc(100% - 32px)',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  allTagsIcon: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
});

function TagPicker({
  classes,
  className,
  SelectProps,
  selectClasses,
  color,
  onChange,
  value = [],
  locationId,
  availableLocations = [],
  dispatch,
  ...rest
}) {
  const availableTags = get(availableLocations, `${locationId}.tags.byId`, {});
  const availableIds = get(availableLocations, `${locationId}.tags.allIds`, []);
  const resolvedValue = compact(value.map(tagId => get(availableTags, `${tagId}.id`)));

  function handleChange(event) {
    const val = get(event, 'target.value', []);
    onChange(val.includes('') ? [] : val);
  }

  return (
    <TextField
      onChange={handleChange}
      className={className}
      InputLabelProps={{
        shrink: true,
      }}
      select
      fullWidth
      label="Tags"
      margin="none"
      value={resolvedValue}
      {...rest}
      SelectProps={{
        displayEmpty: true,
        classes: { root: classes.selectRoot, ...selectClasses },
        MenuProps: {
          MenuListProps: {
            className: classes.menuList,
          },
        },
        multiple: true,
        renderValue: selected => {
          if (!selected.length) {
            return (
              <Chip variant="outlined" style={{ borderColor: color }} label={'Select tags'} className={classes.chip} />
            );
          }
          return (
            <div className={classes.chips}>
              {selected.map(tagId => {
                const name = get(availableTags, `${tagId}.name`);
                return (
                  <Chip
                    key={name}
                    label={name}
                    className={classes.chip}
                    style={{ backgroundColor: color }}
                    onDelete={() => onChange([...selected].filter(id => id !== tagId))}
                  />
                );
              })}
            </div>
          );
        },
        ...SelectProps,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" className={classes.icon}>
            <TagsIcon />
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value="" divider>
        <ListItemIcon className={classes.allTagsIcon}>
          <TagsIcon />
        </ListItemIcon>
        <ListItemText>Select tags</ListItemText>
      </MenuItem>
      {availableIds.map(id => (
        <MenuItem key={id} value={id}>
          <Checkbox color="primary" checked={resolvedValue.indexOf(id) > -1} />
          <ListItemText
            primary={
              <Typography variant="inherit" noWrap>
                {availableTags[id].name}
              </Typography>
            }
          />
        </MenuItem>
      ))}
    </TextField>
  );
}

function mapStateToProps(state) {
  return {
    availableLocations: getLocations(state),
  };
}

export default connect(mapStateToProps)(withStyles(styles)(toJS(TagPicker)));

import * as api from 'api/user';
import mixpanel from 'components/common/mixpanel';
import { actions, types } from 'reducers/profile/password';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { call, put, takeLeading } from 'redux-saga/effects';

function* updatePassword({ oldPassword, newPassword }) {
  try {
    yield call(api.updatePassword, oldPassword, newPassword);

    yield put(actions.updateSuccess());
    yield put(snackActions.set('Password successfully updated', snackVariants.success));

    mixpanel((mp) => mp.track('User profile | Update password'));
  } catch (e) {
    yield put(actions.updateFailure(e));
  }
}

export default function* password() {
  yield takeLeading(types.UPDATE_REQUEST, updatePassword);
}

import { composeTimeCategories, getTimeLabelFormatter } from 'components/dashboard/common/periods';
import ThreeValuesWidgetHeader from 'components/dashboard/common/ThreeValuesWidgetHeader';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import COLORS from 'components/style/colors';
import { get } from 'lodash';
import React from 'react';

const newColor = COLORS.LIGHT_BLUE;
const returningColor = COLORS.DARK_YELLOW;
const membersColor = COLORS.GREEN;

function FirstTimeReturningMembersWidget({ period, timezone, isFetching, requestDate, report }) {
  const series = report && [
    report.firstTimeReturningMembers.firstTime,
    report.firstTimeReturningMembers.returning,
    report.firstTimeReturningMembers.members,
  ];
  const categories = composeTimeCategories(requestDate, timezone, period);

  return (
    <Widget title="First time vs return">
      <>
        <ThreeValuesWidgetHeader
          left={{
            name: 'First time',
            value: get(report, 'firstTimeReturningMembers.firstTime.value'),
            color: newColor,
          }}
          center={{
            name: 'Returning',
            value: get(report, 'firstTimeReturningMembers.returning.value'),
            color: returningColor,
          }}
          right={{
            name: 'Members',
            value: get(report, 'firstTimeReturningMembers.members.value'),
            color: membersColor,
          }}
        />
        <ValueOverTimeChart
          isFetching={isFetching}
          type="bar"
          stack="one"
          categories={categories}
          categoriesFormatter={getTimeLabelFormatter(requestDate, timezone, period)}
          series={series}
        />
      </>
    </Widget>
  );
}

export default FirstTimeReturningMembersWidget;

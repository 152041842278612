import { fromJS } from 'immutable';

export const types = {
  SET_ONLINE: 'NETWORK/SET_ONLINE',
  SET_OFFLINE: 'NETWORK/SET_OFFLINE',
  CONNECTION_CHECK_REQUEST: 'NETWORK/CONNECTION_CHECK_REQUEST',
  CONNECTION_CHECK_FINISHED: 'NETWORK/CONNECTION_CHECK_FINISHED',
  REQUEST_FAILURE: 'NETWORK/REQUEST_FAILURE',
};

export const initialState = fromJS({
  isOffline: false,
  connectionCheckIsFetching: false,
});

export default function network(state = initialState, action) {
  switch (action.type) {
    case types.SET_ONLINE:
      return state.withMutations(s => {
        s.set('isOffline', false);
        s.set('connectionCheckIsFetching', false);
      });
    case types.SET_OFFLINE:
      return state.set('isOffline', true);
    case types.CONNECTION_CHECK_REQUEST:
      return state.set('connectionCheckIsFetching', true);
    case types.CONNECTION_CHECK_FINISHED:
      return state.set('connectionCheckIsFetching', false);
    default:
      return state;
  }
}

export const actions = {
  requestFailure: error => ({ type: types.REQUEST_FAILURE, error }),
  setOnline: () => ({ type: types.SET_ONLINE }),
  setOffline: () => ({ type: types.SET_OFFLINE }),
  connectionCheckRequest: () => ({ type: types.CONNECTION_CHECK_REQUEST }),
  connectionCheckFinished: () => ({ type: types.CONNECTION_CHECK_FINISHED }),
};

export const getRoot = state => state.get('network');
export const getIsOffline = state => getRoot(state).get('isOffline');
export const getConnectionCheckIsFetching = state => getRoot(state).get('connectionCheckIsFetching');

import { Chip, Grid, makeStyles, TextField } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import StaticTextField from 'components/common/StaticTextField';
import React, { useState } from 'react';

export const VALID_EMAIL_REGEX = /^\S+@\S+$/;

const useStyles = makeStyles((theme) => ({
  emails: {
    marginBottom: theme.spacing(1),
  },

  errorChip: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.primary.contrastText,
  },
  errorIcon: {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

function parseEmailsFromInput(input) {
  return input
    .split(/[, ]+/)
    .map((s) => s.trim())
    .filter((s) => s);
}

function EmailsEditor({ value, error = false, label, helperText, inputHelperText, onChange, ...rest }) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');

  return (
    <StaticTextField
      error={error}
      label={label}
      helperText={helperText}
      value={
        <>
          <Grid container spacing={1} className={classes.emails}>
            {value.map((email, index) => {
              const error = !VALID_EMAIL_REGEX.test(email);
              return (
                <Grid key={index} item>
                  <Chip
                    clickable={false}
                    classes={
                      error
                        ? {
                            root: classes.errorChip,
                            icon: classes.errorIcon,
                            deleteIcon: classes.errorIcon,
                          }
                        : undefined
                    }
                    onDelete={() => onChange(value.filter((_, i) => i !== index))}
                    icon={error ? undefined : <EmailIcon />}
                    label={email}
                  />
                </Grid>
              );
            })}
          </Grid>
          <TextField
            value={inputValue}
            onKeyDown={(event) => {
              if (['Enter', 'Tab', ',', ' '].includes(event.key)) {
                const emails = parseEmailsFromInput(inputValue);
                if (emails.length) {
                  event.preventDefault();
                  onChange([...value, ...emails]);
                  setInputValue('');
                } else if (event.key !== 'Tab') {
                  event.preventDefault();
                }
              }
            }}
            placeholder="Type or paste email addresses and press 'Enter' or 'Space'"
            onChange={(event) => {
              const emails = parseEmailsFromInput(event.target.value);
              if (emails.length > 1) {
                onChange([...value, ...emails]);
                setInputValue('');
              } else if (event.key !== 'Tab') {
                setInputValue(event.target.value);
              }
            }}
            id="alertEmails"
            autoComplete="off"
            name="alertEmails"
            margin="none"
            fullWidth
            helperText={inputHelperText}
            error={error}
            {...rest}
          />
        </>
      }
    />
  );
}

export default EmailsEditor;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import SetTitle from 'components/common/SetTitle';
import LocationEdit from 'components/location/LocationEdit';
import React from 'react';
import { connect } from 'react-redux';
import {
  actions,
  getEntity,
  getLoadError,
  getUpdateError,
  isLoadFetching,
  isNew,
  isOpen,
  isUpdateFetching,
} from 'reducers/location/edit';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,
    flexGrow: 1,
  },
}));

function LocationEditor({
  open,
  isNew,
  fullScreen,
  loadIsFetching,
  loadError,
  resetLoadError,
  updateIsFetching,
  updateError,
  resetUpdateError,
  entity,
  onSubmit,
  onClose,
}) {
  const classes = useStyles();
  const operation = isNew ? 'Create' : 'Edit';
  return (
    <>
      {open && <SetTitle title={`${operation} location`} />}
      <Dialog
        onClose={onClose}
        disableBackdropClick
        PaperProps={{
          id: 'location-editor-dialog-paper',
          className: classes.paperRoot,
        }}
        open={open}
        fullScreen={fullScreen}
        aria-labelledby="location-editor-dialog-title"
      >
        <DialogTitle id="location-editor-dialog-title" className={classes.title} disableTypography>
          <Typography variant="h6">{operation} location</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loadIsFetching && (
          <DialogContent style={{ paddingBottom: 32 }} className={classes.content}>
            <BrandedLoadingIndicator />
          </DialogContent>
        )}
        {!loadIsFetching && entity && (
          <LocationEdit
            onClose={onClose}
            isNew={isNew}
            initialEntity={entity}
            isFetching={updateIsFetching}
            error={updateError}
            onSubmit={onSubmit}
            resetError={resetUpdateError}
          />
        )}
      </Dialog>
      <Dialog
        open={loadError}
        onClose={resetLoadError}
        aria-labelledby="location-editor-load-dialog-title"
        aria-describedby="location-editor-load-dialog-description"
      >
        <DialogTitle id="location-editor-load-dialog-title">Edit failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="location-editor-load-dialog-description">
            Failed to load location for edit, please try again later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetLoadError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    open: isOpen(state),
    isNew: isNew(state),
    loadError: getLoadError(state),
    loadIsFetching: isLoadFetching(state),
    updateIsFetching: isUpdateFetching(state),
    updateError: getUpdateError(state),
    entity: getEntity(state),
  };
}

export default connect(mapStateToProps, {
  onClose: actions.close,
  resetLoadError: actions.resetLoadError,
  resetUpdateError: actions.resetUpdateError,
  onSubmit: actions.updateRequest,
})(withMobileDialog()(LocationEditor));

import { createAverageVisitsDurationReportSettings, reports, widgetReport } from 'api/report';
import { find } from 'lodash';
import { getOpsDashboardConfig } from 'reducers/auth';
import { actions, types } from 'reducers/dashboard/ops/visitDurationBreakdown';
import { call, put, takeLatest, select } from 'redux-saga/effects';

function* fetchReport({ requestDate, timezone, period, location }) {
  try {
    const config = yield select(getOpsDashboardConfig);
    const locationConfig = find(config.toJS(), ['locationId', location]);
    const response = yield call(
      widgetReport,
      reports.VEHICLE_WAIT_TIME_BY_TAGS,
      createAverageVisitsDurationReportSettings(
        requestDate,
        timezone,
        period,
        location,
        locationConfig.enterTags,
        locationConfig.exitTags
      )
    );

    yield put(actions.reportSuccess(requestDate, timezone, period, location, locationConfig, response.data));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, location, e));
  }
}

export default function* visitDurationBreakdown() {
  yield takeLatest(types.REPORT_REQUEST, fetchReport);
}

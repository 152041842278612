import { Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useClasses = makeStyles((theme) => ({
  tooltipHelpIcon: {
    marginLeft: theme.spacing(0.5),
  },
}));

const CompanyTableTooltipHeaderCell = ({ tooltipText, column, ...rest }) => {
  const classes = useClasses();

  return (
    <TableHeaderRow.Cell column={column} {...rest}>
      {column.title}
      <Tooltip aria-label={tooltipText} title={tooltipText}>
        <HelpOutlineIcon className={classes.tooltipHelpIcon} />
      </Tooltip>
    </TableHeaderRow.Cell>
  );
};

export default CompanyTableTooltipHeaderCell;

import { createReportSettings, reports, widgetReport } from 'api/report';
import { actions, types } from 'reducers/dashboard/ops/summary';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { populateLocIdAndName } from 'sagas/dashboard/location/util';

function* fetchReports({ requestDate, timezone, period, location }) {
  try {
    const [total, rewashes] = yield all([
      call(
        widgetReport,
        reports.VEHICLE_TOTAL_CUSTOMERS_WIDGET,
        createReportSettings(requestDate, timezone, period, null, location)
      ),
      call(
        widgetReport,
        reports.VEHICLE_REWASH_COUNT_WIDGET,
        createReportSettings(requestDate, timezone, period, null, location)
      ),
    ]);

    const report = {
      total: yield populateLocIdAndName(total.data, location),
      rewashes: yield populateLocIdAndName(rewashes.data, location),
      timezone,
    };

    yield put(actions.reportSuccess(requestDate, timezone, period, report));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, e));
  }
}

export default function* summary() {
  yield takeLatest(types.REPORT_REQUEST, fetchReports);
}

import { useTheme } from '@material-ui/core';
import toJS from 'components/common/toJS';
import { prepareLegendData, setItemStyleColor } from 'components/dashboard/common/utils';
import Widget from 'components/dashboard/common/Widget';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import WidgetLegend from 'components/dashboard/common/WidgetLegend';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import VehicleMakeIcon, { icons } from 'components/icons/VehicleMakeIcon';
import colors, { VEHICLE_MAKES_COLORS } from 'components/style/colors';
import { cloneDeep, get, remove, startCase } from 'lodash';
import React from 'react';
import { FaCarSide as CarIcon } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer } from 'react-virtualized';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/location/vehicleMakes';

const specialMakeNames = {
  aro: 'ARO',
  baic: 'BAIC',
  baw: 'BAW',
  bmc: 'bmc',
  bmw: 'BMW',
  cmc: 'CMC',
  'can-am': 'Can-Am',
  'caravans-wohnm': 'Caravans-Wohnm',
  'dr-motor': 'DR Automobiles',
  'ds-automobiles': 'DS Automobiles',
  'gac-gonow': 'Gonow',
  gaz: 'GAZ',
  gmc: 'GMC',
  'harley-davidson': 'Harley-Davidson',
  jac: 'JAC',
  jmc: 'JMC',
  ktm: 'KTM',
  ldv: 'LDV',
  'mercedes-benz': 'Mercedes-Benz',
  missing: 'Unknown',
  mclaren: 'McLaren',
  mg: 'MG',
  'mp-lafer': 'MP Lafer',
  'mv-agusta': 'MV Agusta',
  'rolls-royce': 'Rolls-Royce',
  'trucks-lkw': 'Other Trucks',
  tvr: 'TVR',
  uaz: 'UAZ',
  'N/A': 'N/A',
};

export function carMakeName(make) {
  return specialMakeNames[make] || startCase(make).replace(/-/g, ' ');
}

function prepareData(report) {
  if (!report || !report.rows || !report.rows.length) {
    return undefined;
  }

  const rows = cloneDeep(report.rows);
  const missingMakes = remove(rows, (row) => row.row.make === 'missing');
  const top = rows.slice(0, 5).map((row, index) => ({
    name: carMakeName(row.row.make),
    value: row.row.count,
    color: VEHICLE_MAKES_COLORS[index],
  }));
  const others = [...rows.slice(5), ...missingMakes].map((row) => row.row.count);

  return [top, others];
}

function VehicleMakesWidget({
  location,
  history,
  isFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
  defaultLocationId,
}) {
  useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest, defaultLocationId);
  const theme = useTheme();

  if (!requestDate) {
    return null;
  }

  const rich = {};
  Object.entries(icons).forEach(([k, v]) => {
    rich[k] = { height: 28, align: 'center', backgroundColor: { image: v } };
  });

  let legendData;
  let data;

  if (report) {
    const [top, others] = prepareData(report);
    if (top && top.length) {
      data = setItemStyleColor(top);

      const top1Value = get(top, '[0].value', 0);
      const othersSum = others.reduce((acc, obj) => acc + obj, 0);
      legendData = prepareLegendData(top, (e) => () => <VehicleMakeIcon size={20} name={e.name} />);
      if (othersSum) {
        legendData.push({
          name: 'Others',
          color: colors.LIGHT_GREY,
          value: othersSum,
          percent: othersSum >= top1Value ? 100 : othersSum / (top1Value / 100),
          id: 'others',
          renderIcon: () => <CarIcon />,
        });
      }
    }
  }

  return (
    <Widget title="Top brands">
      <>
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = width / 1.78;
            const options = {
              tooltip: {
                trigger: 'item',
                formatter: '<strong>{b}</strong>: {c} ({d}%)',
                extraCssText: `z-index: ${theme.zIndex.drawer + 1}`,
              },
              legend: {
                show: false,
              },
              toolbox: {
                show: false,
              },
              series: report &&
                data && [
                  {
                    type: 'pie',
                    radius: ['40%', '80%'],
                    center: [width / 2, height / 2],
                    avoidLabelOverlap: false,
                    label: {
                      normal: {
                        show: true,
                        position: 'outside',
                        formatter: (value) => `{${value.name.toLowerCase().replace(/(\s|-)/g, '')}| }`,
                        rich,
                      },
                    },
                    labelLine: {
                      show: true,
                      length: 5,
                      length2: 15,
                    },
                    itemStyle: { borderWidth: 2, borderColor: 'white' },
                    data,
                  },
                ],
            };

            return <WidgetChart width={width} height={height} isFetching={isFetching} options={options} />;
          }}
        </AutoSizer>
        <WidgetLegend data={legendData} />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(VehicleMakesWidget)));

import { SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  Link as MuiLink,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AllCamerasUpIcon from '@material-ui/icons/DoneAll';
import DetailsIcon from '@material-ui/icons/List';
import WarningIcon from '@material-ui/icons/Warning';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import CompanyAndWhitelabeler from 'components/common/CompanyAndWhitelabeler';
import { GridRoot, TableContainer } from 'components/common/Grid';
import InlineLoadingIndicator from 'components/common/InlineLoadingIndicator';
import mixpanel from 'components/common/mixpanel';
import SearchBox from 'components/common/SearchBox';
import SetTitle from 'components/common/SetTitle';
import TableAutoLayout from 'components/common/TableAutoLayout';
import TableToolbar from 'components/common/TableToolbar';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import LocationActionMenu from 'components/location/LocationActionMenu';
import LocationDetails from 'components/location/LocationDetails';
import { locationTypes } from 'components/location/LocationEdit';
import LocationFeatures from 'components/location/LocationFeatures';
import LocationOpeningHours from 'components/location/LocationOpeningHours';
import { ERROR_COLOR, SUCCESS_COLOR } from 'components/style/colors';
import { round } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { isAdmin } from 'reducers/auth';
import { actions as locationEditActions } from 'reducers/location/edit';
import {
  actions,
  getError,
  getIsFetching,
  getIsFetchingToAppend,
  getItems,
  getTotalMatchedCount,
} from 'reducers/location/list';
import { getPastTimeFromNow, isEditAuthorized } from '../common/utils';

const noValue = '—';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 0,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  tableContainer: {
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
    minHeight: 200,
  },
  camerasStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  camerasStatusIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const defaultSorting = [
  { columnName: 'company', direction: 'asc' },
  { columnName: 'createdAt', direction: 'desc' },
];

const tableColumnExtensions = [];
const sortingColumnExtensions = [
  { columnName: 'name', sortingEnabled: false },
  { columnName: 'openingHours', sortingEnabled: false },
  { columnName: 'cameras', sortingEnabled: false },
];

function LocationList({
  location,
  history,
  searchRequest,
  onUnmount,
  onDetailsClick,
  isFetching,
  isFetchingToAppend,
  totalMatched,
  error,
  onResetError,
  value,
}) {
  const classes = useStyles();

  useWillUnmount(onUnmount);

  const [query] = useQueryParam('query', (v) => v || '', location, history);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [sorting, setSorting] = useState(defaultSorting);
  const handleSearch = useCallback((value, sorting) => searchRequest(value, sorting, false), [searchRequest]);
  const isUserAdmin = useSelector(isAdmin);

  useEffect(() => {
    handleSearch(query, sorting);
  }, [handleSearch, query, sorting]);

  const columns = useMemo(
    () => [
      {
        name: 'action',
        title: ' ',
        getCellValue: (row) => (
          <div className={classes.actions}>
            <Tooltip title="Show details">
              <IconButton
                aria-label="Show details"
                aria-haspopup="true"
                onClick={() => {
                  mixpanel((mp) => mp.track('Location details', { ...row }));
                  onDetailsClick(row);
                }}
              >
                <DetailsIcon />
              </IconButton>
            </Tooltip>
            {isEditAuthorized(row) && <LocationActionMenu entity={row} />}
          </div>
        ),
      },
      {
        name: 'name',
        title: 'Name',
        getCellValue: (row) => (
          <>
            {row.name} {row.type && <Chip size="small" label={locationTypes[row.type]} />}
            <LocationFeatures value={row} />
          </>
        ),
      },
      {
        name: 'openingHours',
        title: 'Operating hours',
        getCellValue: (row) => <LocationOpeningHours value={row} />,
      },
      {
        name: 'cameras',
        title: 'Cameras',
        getCellValue: ({ jobs }) => {
          if (jobs.length) {
            const jobsUp = jobs.filter((job) => job.status?.state === 'RUNNING').length;
            return (
              <div className={classes.camerasStatus}>
                {jobsUp === jobs.length ? (
                  <AllCamerasUpIcon className={classes.camerasStatusIcon} />
                ) : (
                  <Tooltip title="Some cameras are down or disabled">
                    <WarningIcon className={classes.camerasStatusIcon} />
                  </Tooltip>
                )}
                {`${jobsUp}/${jobs.length}`}
              </div>
            );
          } else {
            return noValue;
          }
        },
      },
      ...(isUserAdmin
        ? [
            {
              name: 'volume24hChange',
              title: 'Volume change 24h',
              getCellValue: ({ volume24hChange = 0, volume24hPrevious = 0, volume24hCurrent = 0 }) => {
                if (volume24hChange === 0) {
                  return '—';
                }
                const sign = volume24hChange > 0 ? '+' : '';
                return (
                  <>
                    <span style={{ color: volume24hChange > 0 ? SUCCESS_COLOR : ERROR_COLOR }}>
                      {`${sign}${round(volume24hChange, 1)}%`}
                    </span>
                    {(volume24hCurrent > 0 || volume24hPrevious > 0) && (
                      <Typography display="block" color="textSecondary" variant="caption">
                        {volume24hPrevious} ➜ {volume24hCurrent}
                      </Typography>
                    )}
                  </>
                );
              },
            },
            {
              name: 'notificationsSent24h',
              title: 'Notifications sent 24h',
              getCellValue: ({ stats }) => {
                const { notificationsSent24h = 0 } = stats ?? {};
                if (!notificationsSent24h) {
                  return '—';
                }
                return notificationsSent24h;
              },
            },
            {
              name: 'fraudDataSyncAt',
              title: 'Data sync',
              getCellValue: ({ stats }) => {
                const { fraudDataSyncAt } = stats ?? {};
                if (!fraudDataSyncAt) {
                  return '—';
                }
                return (
                  <Tooltip
                    placement="bottom-start"
                    title={`Last data sync at ${moment(fraudDataSyncAt).format('LL, LTS')}`}
                    enterDelay={300}
                  >
                    <span>{getPastTimeFromNow(fraudDataSyncAt)}</span>
                  </Tooltip>
                );
              },
            },
            {
              name: 'rewashCount24h',
              title: 'Rewashes 24h',
              getCellValue: ({ stats }) => {
                const { rewashCount24h = 0 } = stats ?? {};
                if (!rewashCount24h) {
                  return '—';
                }
                return rewashCount24h;
              },
            },
            {
              name: 'membershipSold24h',
              title: 'Membership sold 24h',
              getCellValue: ({ stats }) => {
                const { membershipSold24h = 0 } = stats ?? {};
                if (!membershipSold24h) {
                  return '—';
                }
                return membershipSold24h;
              },
            },
            {
              name: 'camerasUptime24h',
              title: 'Cameras uptime 24h',
              getCellValue: ({ stats }) => {
                const { camerasUptime24h = 0 } = stats ?? {};
                if (!camerasUptime24h) {
                  return '—';
                }
                return `${round(camerasUptime24h, 1)}%`;
              },
            },
          ]
        : []),
      {
        name: 'company',
        title: 'Company',
        getCellValue: (row) => <CompanyAndWhitelabeler company={row?.company} whitelabeler={row?.whitelabeler} />,
      },
      {
        name: 'createdAt',
        title: 'Created',
        getCellValue: ({ createdAt }) => (
          <Tooltip
            placement="bottom-start"
            title={`Created at ${moment(createdAt).format('LL, LTS')}`}
            enterDelay={300}
          >
            <span>{getPastTimeFromNow(createdAt)}</span>
          </Tooltip>
        ),
      },
    ],
    [classes, onDetailsClick, isUserAdmin]
  );

  const displayTable = (isFetching && isFetchingToAppend) || !isFetching;

  return (
    <>
      <SetTitle title="Locations" />
      <LocationDetails />
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        aria-describedby="create-location-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="create-location-dialog-description">
            To create a new location or camera please email us at{' '}
            <MuiLink href="mailto:sales@omnixlabs.com">sales@omnixlabs.com</MuiLink>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateDialogOpen(false)} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={error}
        onClose={onResetError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Load failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Failed to load locations, please try again later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onResetError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.searchBoxContainer}>
        <SearchBox
          location={location}
          history={history}
          placeholder="Search by name, ID, etc."
          onSearch={(query) => handleSearch(query, sorting)}
        />
      </div>
      <Paper className={classes.root}>
        <div className={classes.content}>
          {isFetching && !isFetchingToAppend && <BrandedLoadingIndicator />}
          {displayTable && (
            <>
              <div className={classes.tableContainer}>
                <TableToolbar title={`Locations${typeof totalMatched === 'number' ? `: ${totalMatched}` : ''}`}>
                  <Tooltip title="Create location">
                    <Fab
                      onClick={() => {
                        mixpanel((mp) => mp.track('Location create request'));
                        setCreateDialogOpen(true);
                      }}
                      size="small"
                      color="secondary"
                      aria-label="Create location"
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </TableToolbar>
                <Grid rootComponent={GridRoot} rows={value} columns={columns}>
                  <SortingState
                    sorting={sorting}
                    onSortingChange={setSorting}
                    columnExtensions={sortingColumnExtensions}
                  />
                  <Table
                    columnExtensions={tableColumnExtensions}
                    containerComponent={TableContainer}
                    tableComponent={TableAutoLayout}
                  />
                  <TableHeaderRow showSortingControls />
                </Grid>
              </div>
              {!isFetching && totalMatched > 0 && value && value.length < totalMatched && (
                <Waypoint
                  scrollableAncestor={window}
                  bottomOffset="-500px"
                  onEnter={() => searchRequest(query, sorting, true)}
                />
              )}
              {isFetching && isFetchingToAppend && <InlineLoadingIndicator />}
            </>
          )}
        </div>
      </Paper>
    </>
  );
}

function mapStateToProps(state) {
  return {
    value: getItems(state) || [],
    isFetching: getIsFetching(state),
    isFetchingToAppend: getIsFetchingToAppend(state),
    totalMatched: getTotalMatchedCount(state),
    error: getError(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    onResetError: actions.resetError,
    searchRequest: actions.searchRequest,
    onUnmount: actions.reset,
    onCreateClick: locationEditActions.createRequest,
    onDetailsClick: actions.detailsOpen,
  })(toJS(LocationList))
);

import {
  Checkbox,
  Chip,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import AllIcon from '@material-ui/icons/DoneAll';
import toJS from 'components/common/toJS';
import { VEHICLE_MAKES_COLORS } from 'components/style/colors';
import { clone, compact, get } from 'lodash';
import React from 'react';
import { FaTag as TagsIcon } from 'react-icons/fa';
import { connect } from 'react-redux';
import { getLocations } from 'reducers/auth';

const styles = theme => ({
  menuList: {
    paddingRight: '0 !important',
    width: '100% !important',
    maxWidth: '600px',
  },
  selectRoot: {
    width: 'calc(100% - 32px)',
  },
  adornment: {
    padding: theme.spacing(0.5, 0),
    whiteSpace: 'nowrap',
  },
  allTagsIcon: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
});

export const numberOfSets = 2;
export const setsSeparator = ';';

function TagSetPicker({
  classes,
  className,
  SelectProps,
  selectClasses,
  onDelete,
  onChange,
  value = [],
  locationId,
  availableLocations = [],
  index,
  ...rest
}) {
  const availableTags = get(availableLocations, `${locationId}.tags.byId`, {});
  const availableIds = get(availableLocations, `${locationId}.tags.allIds`, []);

  return (
    <TextField
      onChange={onChange}
      className={className}
      InputLabelProps={{
        shrink: true,
      }}
      select
      fullWidth
      margin="none"
      value={value}
      {...rest}
      SelectProps={{
        displayEmpty: true,
        classes: { root: classes.selectRoot, ...selectClasses },
        MenuProps: {
          MenuListProps: {
            className: classes.menuList,
          },
        },
        multiple: true,
        renderValue: selected => {
          if (!selected.length) {
            return (
              <Chip
                variant="outlined"
                style={{ borderColor: VEHICLE_MAKES_COLORS[index] }}
                label={index === 0 ? 'All tags' : 'Select tags'}
                className={classes.chip}
              />
            );
          }
          return (
            <div className={classes.chips}>
              {selected.map(tagId => {
                const name = get(availableTags, `${tagId}.name`);
                return (
                  <Chip
                    key={name}
                    label={name}
                    className={classes.chip}
                    style={{ backgroundColor: VEHICLE_MAKES_COLORS[index] }}
                    onDelete={() => onDelete(tagId)}
                  />
                );
              })}
            </div>
          );
        },
        ...SelectProps,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" className={classes.adornment}>
            <Typography variant="body1" color="textSecondary">
              Chart #{index + 1}
            </Typography>
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value="" divider>
        <ListItemIcon className={classes.allTagsIcon}>{index === 0 ? <AllIcon /> : <TagsIcon />}</ListItemIcon>
        <ListItemText>{index === 0 ? 'All tags' : 'Select tags'}</ListItemText>
      </MenuItem>
      {availableIds.map(id => (
        <MenuItem key={id} value={id}>
          <Checkbox color="primary" checked={value.indexOf(id) > -1} />
          <ListItemText
            primary={
              <Typography variant="inherit" noWrap>
                {availableTags[id].name}
              </Typography>
            }
          />
        </MenuItem>
      ))}
    </TextField>
  );
}

function TagsPicker({
  classes,
  className,
  SelectProps,
  selectClasses,
  onChange,
  value = [],
  locationId,
  availableLocations = [],
  dispatch,
  ...rest
}) {
  const availableTags = get(availableLocations, `${locationId}.tags.byId`, {});
  const values = [];
  for (let index = 0; index < numberOfSets; index++) {
    values.push([]);
  }
  let currentSet = 0;
  value.forEach(tagId => {
    if (tagId === setsSeparator) {
      currentSet += 1;
    } else if (currentSet < numberOfSets) {
      values[currentSet].push(tagId);
    }
  });

  const items = [];
  for (let index = 0; index < numberOfSets; index++) {
    const resolvedValue = compact(values[index].map(tagId => get(availableTags, `${tagId}.id`)));
    items.push(
      <TagSetPicker
        key={index}
        label={index === 0 ? 'Tags' : undefined}
        classes={classes}
        className={className}
        SelectProps={SelectProps}
        selectClasses={selectClasses}
        locationId={locationId}
        availableLocations={availableLocations}
        {...rest}
        value={resolvedValue}
        onChange={event => {
          const val = get(event, 'target.value', []);
          const totalValues = clone(values);
          totalValues[index] = val.includes('') ? [] : val;
          const encoded = [];
          totalValues.forEach(item => {
            encoded.push(...item);
            encoded.push(setsSeparator);
          });
          onChange(encoded);
        }}
        onDelete={tagId => {
          const totalValues = clone(values);
          totalValues[index] = totalValues[index].filter(id => id !== String(tagId));
          const encoded = [];
          totalValues.forEach(item => {
            encoded.push(...item);
            encoded.push(setsSeparator);
          });
          onChange(encoded);
        }}
        index={index}
      />
    );
  }
  return <>{items}</>;
}

function mapStateToProps(state) {
  return {
    availableLocations: getLocations(state),
  };
}

export default connect(mapStateToProps)(withStyles(styles)(toJS(TagsPicker)));

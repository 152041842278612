import memberFraudMini from 'sagas/dashboard/location/memberFraudMini';
import { all } from 'redux-saga/effects';
import camerasUptime from 'sagas/dashboard/location/camerasUptime';
import camerasUptimeMini from 'sagas/dashboard/location/camerasUptimeMini';
import firstTimeMini from 'sagas/dashboard/location/firstTimeMini';
import lastSeen from 'sagas/dashboard/location/lastSeen';
import loyalCustomers from 'sagas/dashboard/location/loyalCustomers';
import memberVisitsMini from 'sagas/dashboard/location/memberVisitsMini';
import notificationsSent from 'sagas/dashboard/location/notificationsSent';
import returningCustomers from 'sagas/dashboard/location/returningCustomers';
import rewashesMini from 'sagas/dashboard/location/rewashesMini';
import totalCustomers from 'sagas/dashboard/location/totalCustomers';
import totalCustomersMini from 'sagas/dashboard/location/totalCustomersMini';
import vehicleBodyTypes from 'sagas/dashboard/location/vehicleBodyTypes';
import vehicleMakes from 'sagas/dashboard/location/vehicleMakes';
import waitTime from 'sagas/dashboard/location/waitTime';
import weatherMini from 'sagas/dashboard/location/weatherMini';

export default function* location() {
  yield all([
    totalCustomers(),
    totalCustomersMini(),
    firstTimeMini(),
    rewashesMini(),
    camerasUptimeMini(),
    weatherMini(),
    returningCustomers(),
    lastSeen(),
    notificationsSent(),
    loyalCustomers(),
    waitTime(),
    vehicleMakes(),
    vehicleBodyTypes(),
    camerasUptime(),
    memberVisitsMini(),
    memberFraudMini(),
  ]);
}

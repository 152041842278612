import { takeEvery } from '@redux-saga/core/effects';
import * as api from 'api/auth';
import { setupDataLayer } from 'components/common/gtm';
import mixpanel from 'components/common/mixpanel';
import moment from 'moment-timezone';
import { actions, types } from 'reducers/auth';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* refreshCsrfCookie() {
  try {
    yield call(api.getUserContext);
  } catch (e) {
    // ignore
  }
}

function* login(action) {
  try {
    yield refreshCsrfCookie();
    const data = { username: action.email, password: action.password, rememberMe: action.rememberMe };
    yield call(api.login, data);

    const response = yield call(api.getUserContext);
    setupDataLayer(response.data);
    yield put(actions.loginSuccess(response.data));
  } catch (e) {
    yield put(actions.loginFailure(e));
  }
}

function* logout() {
  try {
    yield call(api.logout);
    yield refreshCsrfCookie();

    mixpanel((mp) => mp.track('Logout'));
  } catch (e) {
    // ignore
  }
}

function setupMixpanel({ userContext }) {
  mixpanel((mp) => {
    if (userContext.originalUser) {
      mp.disable();
    } else {
      mp.people.set({
        $email: userContext.email,
        $name: userContext.name,
        $created: userContext.createdAt,
        $timezone: userContext.timezone || moment.tz.guess(),
        id: userContext.id,
        role: userContext.role?.name,
        companyName: userContext.company?.name,
        companyId: userContext.company?.id,
        whitelabelerName: userContext.whitelabeler?.name,
        whitelabelerId: userContext.whitelabeler?.id,
      });
      mp.identify(userContext.id);
      mp.track('Portal load');
    }
  });
}

export default function* auth() {
  yield all([
    takeLatest(types.LOGIN_REQUEST, login),
    takeLatest(types.LOGOUT, logout),
    takeEvery(types.LOGIN_SUCCESS, setupMixpanel),
  ]);
}

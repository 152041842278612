import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    userSelect: 'none',
    '&:hover, &:visited': {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    },
  },
  icon: {
    color: 'inherit',
  },
}));

function LocationFeatureChip({ title, icon, details, href, color, backgroundColor }) {
  const classes = useStyles();

  return (
    <Tooltip title={details}>
      <Chip
        classes={{ icon: classes.icon }}
        icon={icon}
        size="small"
        label={title}
        component={href ? Link : 'span'}
        to={href}
        className={classes.root}
        style={{ color, backgroundColor, cursor: href ? 'pointer' : undefined }}
      />
    </Tooltip>
  );
}

export default LocationFeatureChip;

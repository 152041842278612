import { params, reports, widgetReport } from 'api/report';
import { actions, types } from 'reducers/dashboard/location/weatherMini';
import { call, put, takeLatest } from 'redux-saga/effects';
import { populateLocIdAndName } from 'sagas/dashboard/location/util';

function* fetchReport({ requestDate, location }) {
  try {
    const response = yield call(widgetReport, reports.CURRENT_WEATHER_WIDGET, { [params.LOCATION_ID]: location });

    const report = yield populateLocIdAndName(response.data, location);
    yield put(actions.reportSuccess(requestDate, report));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, e));
  }
}

export default function* weatherMini() {
  yield takeLatest(types.REPORT_REQUEST, fetchReport);
}

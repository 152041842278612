import { observer } from 'mobx-react';
import Widget from 'components/dashboard/common/Widget';
import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ERROR_COLOR, SUCCESS_COLOR } from 'components/style/colors';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { AutoSizer } from 'react-virtualized';

function LocationSummary({ state, ...rest }) {
  const summary = state?.summary ?? {};
  const { above_average: good = '', within_average: normal = '', below_average: bad = '' } = summary;
  if (!good && !bad && !normal) {
    return null;
  }

  return (
    <Widget title="Summary" style={{ minHeight: 'unset' }} {...rest}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List style={{ width, maxWidth: width }}>
            {good && (
              <ListItem>
                <ListItemIcon>
                  <SentimentSatisfiedAltIcon style={{ color: SUCCESS_COLOR }} />
                </ListItemIcon>
                <ListItemText primary={good} />
              </ListItem>
            )}
            {normal && (
              <ListItem>
                <ListItemIcon>
                  <SentimentSatisfiedIcon style={{ color: '#FFDC5E' }} />
                </ListItemIcon>
                <ListItemText primary={normal} />
              </ListItem>
            )}
            {bad && (
              <ListItem>
                <ListItemIcon>
                  <SentimentVeryDissatisfiedIcon style={{ color: ERROR_COLOR }} />
                </ListItemIcon>
                <ListItemText primary={bad} />
              </ListItem>
            )}
          </List>
        )}
      </AutoSizer>
    </Widget>
  );
}

export default observer(LocationSummary);

import { Grid, withStyles, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { reports } from 'api/report';
import MasonryLayout from 'components/common/MasonryLayout';
import mixpanel from 'components/common/mixpanel';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import CameraUptimeWidget from 'components/dashboard/camera/CamerasUptimeWidget';
import LoyalCustomersWidget from 'components/dashboard/camera/LoyalCustomersWidget';
import ReturningCustomersWidget from 'components/dashboard/camera/ReturningCustomersWidget';
import TotalCustomersWidget from 'components/dashboard/camera/TotalCustomersWidget';
import useSettingsChangeActionDispatcher from 'components/dashboard/camera/useSettingsChangeActionDispatcher';
import VehicleBodyTypesWidget from 'components/dashboard/camera/VehicleBodyTypesWidget';
import VehicleMakesWidget from 'components/dashboard/camera/VehicleMakesWidget';
import DashboardControls from 'components/dashboard/common/DashboardControls';
import { get } from 'lodash';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDefaultJobId, getJobs, getUserTimezone } from 'reducers/auth';

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
});

const widgets = [
  { id: reports.VEHICLE_TOTAL_CUSTOMERS, component: TotalCustomersWidget },
  { id: reports.VEHICLE_NEW_RETURNING, component: ReturningCustomersWidget },
  { id: reports.VEHICLE_NEW_LOYAL, component: LoyalCustomersWidget },
  { id: reports.VEHICLE_TOP_MAKES, component: VehicleBodyTypesWidget },
  { id: reports.VEHICLE_TOP_MAKES, component: VehicleMakesWidget },
  { id: reports.CAMERA_UPTIME, component: CameraUptimeWidget },
];

function CameraDashboard({ classes, width, location, history, userTimezone, defaultJobId, availableJobs = {} }) {
  const [jobId] = useQueryParam('job', (v) => v || defaultJobId, location, history);
  const dashboardDisabledWidgets = get(availableJobs, `${jobId}.dashboardDisabledWidgets`, []) || [];
  const mixpanelTrack = useCallback((requestDate, timezone, period, jobId) => {
    mixpanel((mp) => mp.track('Camera dashboard', { timezone, period, jobId }));
  }, []);
  useSettingsChangeActionDispatcher(location, history, userTimezone, mixpanelTrack);

  let columns = 1;
  if (isWidthUp('md', width)) {
    columns = 2;
  }
  if (isWidthUp('lg', width)) {
    columns = 3;
  }

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Camera dashboard" />
      <Grid item>
        <DashboardControls />
      </Grid>
      <Grid item className={classes.grow}>
        <MasonryLayout columns={columns} gap={32}>
          {widgets
            .filter((widget) => !dashboardDisabledWidgets.includes(widget.id))
            .map((widget, index) => {
              const Component = widget.component;
              return <Component key={index} />;
            })}
        </MasonryLayout>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    defaultJobId: getDefaultJobId(state),
    availableJobs: getJobs(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(withWidth()(toJS(CameraDashboard)))));

import * as api from 'api/user';
import mixpanel from 'components/common/mixpanel';
import { actions, getListItems, types } from 'reducers/user/list';
import { call, put, select, takeLatest } from 'redux-saga/effects';

function* searchUsers({ query = '', append }) {
  try {
    const limit = 20;
    const sort = [{ name: 'createdAt', order: 'DESC' }];
    const searchParams = { query, limit, sort };

    const items = yield select((state) => getListItems(state));
    if (append && items && items.size > 0) {
      searchParams.tieBreakerId = items.last().get('id');
      searchParams.searchAfter = [new Date(items.last().get('createdAt')).getTime()];
    }

    const response = yield call(api.searchUsers, searchParams);
    yield put(actions.searchSuccess(response.data.items, response.data.paging, append));

    if (!append) {
      mixpanel((mp) => mp.track('User search', { query }));
    }
  } catch (e) {
    yield put(actions.searchFailure(e));
  }
}

export default function* list() {
  yield takeLatest(types.SEARCH_REQUEST, searchUsers);
}

import COLORS from 'components/style/colors';
import { fromJS } from 'immutable';

export const types = {
  REPORT_REQUEST: 'LOCATION/LOYAL_CUSTOMERS/REPORT_REQUEST',
  REPORT_SUCCESS: 'LOCATION/LOYAL_CUSTOMERS/REPORT_SUCCESS',
  REPORT_FAILURE: 'LOCATION/LOYAL_CUSTOMERS/REPORT_FAILURE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report) {
  const rows = report && report.rows;
  if (!rows || !rows.length) {
    return null;
  }

  const result = {
    new: { id: 'new', color: COLORS.LIGHT_BLUE, name: 'New returning customers', value: 0, data: [] },
    loyal: { id: 'loyal', color: COLORS.DARK_YELLOW, name: 'Loyal returning customers', value: 0, data: [] },
  };
  rows.forEach((row) => {
    result.new.data.push(row.row.newReturning);
    result.new.value += row.row.newReturning;

    result.loyal.data.push(row.row.loyalReturning);
    result.loyal.value += row.row.loyalReturning;
  });
  return result;
}

export default function loyalCustomers(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
  }),
  reportSuccess: (requestDate, timezone, period, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    report,
  }),
  reportFailure: (requestDate, timezone, period, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    error,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'location', 'loyalCustomers']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');

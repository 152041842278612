import toJS from 'components/common/toJS';
import { composeInsightsTimeCategories, getInsightsTimeLabelFormatter } from 'components/dashboard/common/periods';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import WidgetHeaderTrend from 'components/dashboard/common/WidgetHeaderTrend';
import COLORS from 'components/style/colors';
import { ceil, cloneDeep, floor, sum } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { getCustomersReport, getReportTimezone, getRequestDate } from 'reducers/insights';

const highVolumeLocations = [
  // 'd76f798e-4653-4240-bb11-ec1981f1f228',
  // '28295ac1-5384-404d-a4bf-914d7f370125',
  // '84bba9e7-0a8a-4af5-8b23-f1a16844c7bb',
  // '1fdfdb43-648f-4e8f-aada-7ba5a7dd5ebb',
  // '05d76d05-788e-4c23-8d19-c093e90b7ecd',
  // 'b492c7dd-e92d-4ef4-8d35-528059031176',
  // '26554866-e191-4fbd-937f-129aa60b5b9d',
  // '3d5a6232-43b4-4cd8-a423-5f322d05cd85',
  // 'db4146f5-a366-4907-a607-64fc0fff3e30',
  // '8cbeebe2-29aa-428a-87ec-e86d3a904d6c',
  // '32f43a60-ca3f-4bfd-a443-967e2b749da0',
  // '3b5b11ac-9579-41f4-8ce1-1f5c06ace703',
  // '9dc7883a-b1a6-410b-b0e3-ece820dffb28',
  // 'a1981989-aabf-4e52-9925-78e3b93d5d2e',
  // '34ef5f1b-9ebd-467c-a14f-9f522cbbefdf',
  // 'ea9ef92b-1814-4a24-b4b9-ec94a496f301',
  // 'f68bfcb6-24ca-4ec3-a4c1-cd5a31c32b42',
  // '7eae9995-23dd-4f0d-be0c-175979bfdc5b',
  // '741100c2-4a1c-45b5-a44f-c315b63aa30b',
  // '76611e7b-c767-43f3-a490-a9de6520e29d',
  // '32307095-807b-4e72-871e-993119df4db6',
  // '7873a08f-da8c-46a3-baf6-91b4f3b94a36',
  // '588cade0-d030-4040-ae00-0b49bf4d8dae',
  // 'b867a0d5-e5da-4cdd-a47d-6c45ef66f460',
  // 'f5a94960-099b-4b6a-be4a-27ec29d7662c',
  // '8bc1b25b-3e6b-4925-b248-287c85a6f620',
  // '66ef9a1b-5d5d-4479-8f9e-4c2876e9a239',
  // '72cf7004-abef-47c5-9d8e-22d32e22f73b',
  // 'e9f33a48-4ddd-4c70-a473-8520a1a5cb45',
  // '741d03a6-e58a-41cf-9d19-4fbf776a8dc0',
  // '64a2b5ad-54ef-468f-830a-10d5e65cb112',
  // '1ed0deb0-ca20-426b-9942-863d9c08b41f',
  // 'eedca256-bedd-4f50-8a8f-be60a6a2ffbd',
  // 'b8cd5425-05f0-4c7c-88f5-f7dddd284daf',
];

function adjustValues(bucket, coefficient) {
  bucket.data = bucket.data.map((value) => {
    let result = value * coefficient;
    result = coefficient > 1 ? ceil(result) : floor(result);
    if (value > 0 && !result) {
      result = 1;
    }
    return result;
  });
}

function prepareReport(report, locationId) {
  if (!report || !highVolumeLocations.includes(locationId)) {
    return report;
  }
  const result = cloneDeep(report);
  const [from] = result;
  const to = cloneDeep(from);
  from.color = COLORS.GREEN;
  from.name = 'From';
  from.barGap = '-51%';
  adjustValues(from, 0.7);
  to.name = 'Up to';
  to.color = 'rgba(51, 201, 130, 0.7)';
  to.barWidth = 2;
  adjustValues(to, 1.3);
  result.push(to);
  return result;
}

function prepareTotal(report) {
  if (!report || !report.length) {
    return null;
  }

  if (report.length === 1) {
    return sum(report[0].data);
  }
  return `${sum(report[0].data)}-${sum(report[1].data)}`;
}

function InsightsCustomersVolumeWidget({ requestDate, timezone, period, report, locationId }) {
  const preparedReport = prepareReport(report, locationId);
  const categories = preparedReport && composeInsightsTimeCategories(requestDate, timezone, period);
  const total = prepareTotal(preparedReport);
  return (
    <Widget title="Volume prediction">
      <>
        <WidgetHeaderTrend name="Customers" headerValue={total} value={0} period="last week" />
        <ValueOverTimeChart
          isFetching={false}
          type="bar"
          categories={categories}
          categoriesFormatter={getInsightsTimeLabelFormatter()}
          series={preparedReport}
        />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    requestDate: getRequestDate(state),
    report: getCustomersReport(state),
    timezone: getReportTimezone(state),
  };
}

export default connect(mapStateToProps, null)(toJS(InsightsCustomersVolumeWidget));

import { createReportSettings, reports, widgetReport } from 'api/report';
import { resolveTimezone } from 'components/dashboard/common/periods';
import { getLocations } from 'reducers/auth';
import { actions, modes, types } from 'reducers/dashboard/ops/loaderEfficiency';
import { call, put, select, takeLatest } from 'redux-saga/effects';

function* fetchReport({ requestDate, timezone, period, mode, location }) {
  try {
    const availableLocations = yield select(getLocations);
    const locationTimezone = resolveTimezone(location, availableLocations.toJS(), null);
    let reportId;
    switch (mode) {
      case modes.BY_DAY_OF_WEEK:
        reportId = reports.VEHICLE_LOADER_EFFICIENCY_BY_DAY_OF_WEEK;
        break;
      case modes.BY_VOLUME:
        reportId = reports.VEHICLE_LOADER_EFFICIENCY_BY_RANGE;
        break;
      default:
        reportId = reports.VEHICLE_LOADER_EFFICIENCY;
        break;
    }

    const response = yield call(
      widgetReport,
      reportId,
      createReportSettings(requestDate, locationTimezone, period, null, location)
    );

    yield put(actions.reportSuccess(requestDate, timezone, period, mode, response.data));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, mode, e));
  }
}

export default function* returningCustomers() {
  yield takeLatest(types.REPORT_REQUEST, fetchReport);
}

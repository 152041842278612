import { observer } from 'mobx-react';
import Widget from 'components/dashboard/common/Widget';
import React, { useCallback, useState } from 'react';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import { makeAutoObservable } from 'mobx';
import { createReportSettings, reports, widgetReport } from 'api/report';
import { get } from 'lodash';
import { fromPromise } from 'mobx-utils';
import { Typography } from '@material-ui/core';
import PlusLabel from 'components/common/PlusLabel';
import COLORS from 'components/style/colors';

class State {
  requestDate = null;
  timezone = null;
  period = null;
  locationId = null;
  reportResult = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadData(requestDate, timezone, period, locationId) {
    this.requestDate = requestDate;
    this.timezone = timezone;
    this.period = period;
    this.locationId = locationId;
    this.report = null;

    this.reportResult = fromPromise(
      widgetReport(reports.AI_SUMMARY_REPORT, createReportSettings(requestDate, timezone, period, null, locationId))
    );
  }

  get totalCustomersReport() {
    if (this.reportResult === null || this.reportResult.state !== 'fulfilled') {
      return null;
    }
    return this.reportResult.value[0].data;
  }

  get waitTimeReport() {
    if (this.reportResult === null || this.reportResult.state !== 'fulfilled') {
      return null;
    }
    return this.reportResult.value[1].data;
  }

  get loading() {
    return this.reportResult === null || this.reportResult.state === 'pending';
  }

  get data() {
    if (this.reportResult === null || this.reportResult.state !== 'fulfilled') {
      return null;
    }

    const { data } = this.reportResult.value;
    if (get(data, 'empty', true)) {
      return null;
    }

    return data.rows[0].row.summary;
  }
}

function AiSummaryWidget({ location, history, userTimezone, defaultLocationId, ...rest }) {
  const [state] = useState(() => new State());
  const onSettingsChange = useCallback(
    (requestDate, timezone, period, locationId) => {
      state.loadData(requestDate, timezone, period, locationId);
    },
    [state]
  );
  useSettingsChangeActionDispatcher(location, history, userTimezone, onSettingsChange, defaultLocationId);

  if (!state.data) {
    return null;
  }

  return (
    <Widget
      title="AI wrap-up"
      titleActionContainerProps={{ style: { display: 'flex', alignItems: 'center', justifyContent: 'center' } }}
      titleAction={
        <PlusLabel text="Beta" style={{ marginRight: 4, color: '#000', backgroundColor: COLORS.DARK_YELLOW }} />
      }
      {...rest}
    >
      <Typography
        style={{ padding: '0 16px', minHeight: 200 }}
        variant="body1"
        component="div"
        dangerouslySetInnerHTML={{ __html: state.data }}
      />
    </Widget>
  );
}

export default observer(AiSummaryWidget);

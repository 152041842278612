import { all } from 'redux-saga/effects';
import camerasUptime from 'sagas/dashboard/camera/camerasUptime';
import loyalCustomers from 'sagas/dashboard/camera/loyalCustomers';
import returningCustomers from 'sagas/dashboard/camera/returningCustomers';
import snapshotsDownloader from 'sagas/dashboard/camera/snapshotsDownloader';
import totalCustomers from 'sagas/dashboard/camera/totalCustomers';
import vehicleBodyTypes from 'sagas/dashboard/camera/vehicleBodyTypes';
import vehicleMakes from 'sagas/dashboard/camera/vehicleMakes';
import waitTime from 'sagas/dashboard/camera/waitTime';

export default function* camera() {
  yield all([
    totalCustomers(),
    returningCustomers(),
    loyalCustomers(),
    waitTime(),
    vehicleMakes(),
    vehicleBodyTypes(),
    camerasUptime(),
    snapshotsDownloader(),
  ]);
}

import { withTheme } from '@material-ui/core';
import toJS from 'components/common/toJS';
import ThreeValuesWidgetHeader from 'components/dashboard/common/ThreeValuesWidgetHeader';
import Widget from 'components/dashboard/common/Widget';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import COLORS from 'components/style/colors';
import { sumBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer } from 'react-virtualized';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/location/notificationsSent';

function NotificationsSentWidget({
  location,
  history,
  theme,
  isFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
  defaultLocationId,
}) {
  useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest, defaultLocationId);

  if (!requestDate) {
    return null;
  }

  const data = report && [
    ...report
      .filter((row) => row.value > 0)
      .map((row) => ({ name: row.name, value: row.value, itemStyle: { color: row.color } })),
  ];
  const total = report && sumBy(report, 'value');

  return (
    <Widget title="High potential customers">
      <>
        <ThreeValuesWidgetHeader
          left={{
            name: 'Opportunities',
            value: report && total > 0 ? total : undefined,
            color: COLORS.LIGHT_GREY,
          }}
        />
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = width / 1.78;
            const options = {
              legend: {
                show: true,
                orient: 'vertical',
                right: 10,
              },
              tooltip: {
                trigger: 'item',
                formatter: '<strong>{b}</strong>: {c} ({d}%)',
                extraCssText: `z-index: ${theme.zIndex.drawer + 1}`,
              },
              series: report &&
                data &&
                data.length > 0 && [
                  {
                    type: 'pie',
                    radius: ['40%', '80%'],
                    center: [width / 2, height / 2],
                    avoidLabelOverlap: true,
                    label: {
                      normal: {
                        show: true,
                        position: 'outside',
                        formatter: '{c}',
                        color: theme.palette.text.primary,
                        fontSize: 16,
                      },
                    },
                    labelLine: {
                      show: true,
                      length: 5,
                      length2: 15,
                    },
                    itemStyle: { borderWidth: 2, borderColor: 'white' },
                    data,
                  },
                ],
            };

            return <WidgetChart width={width} height={height} isFetching={isFetching} options={options} />;
          }}
        </AutoSizer>
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(withTheme(NotificationsSentWidget)))
);

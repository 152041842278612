import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import SearchBox from 'components/common/SearchBox';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import { carMakeName } from 'components/dashboard/location/VehicleMakesWidget';
import LocationPicker from 'components/pickers/LocationPicker';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDefaultLocationId, getUserTimezone } from 'reducers/auth';
import { actions, getReport, isReportFetching, isReportInitial } from 'reducers/reports/licensePlateSearch';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputRoot: {
    minHeight: 40,
    backgroundColor: 'white',
    paddingLeft: 12,
    borderRadius: 4,
    borderBottom: 'unset !important',
    '&::before': {
      borderBottom: 'unset !important',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  tablePaper: {
    width: '100%',
    overflowX: 'auto',
    minHeight: '100%',
    alignSelf: 'flex-start',
  },
  table: {
    minWidth: 600,
  },
}));

function LicensePlateSearch({
  location,
  history,
  userTimezone,
  defaultLocationId,
  report,
  isFetching,
  isInitial,
  reportRequest,
  onUnmount,
}) {
  const classes = useStyles();
  const [locationId, setLocationId] = useQueryParam('location', (v) => v || defaultLocationId, location, history);
  const [query, setQuery] = useQueryParam('query', (v) => v || '', location, history);
  const timezone = userTimezone || moment.tz.guess();
  useWillUnmount(onUnmount);
  useEffect(() => {
    if (query && query.trim()) {
      reportRequest(moment.tz(timezone).format(), timezone, locationId, query);
    }
  }, [location, query, userTimezone, locationId, timezone, reportRequest]);
  useEffect(() => {
    localStorage.setItem('lastUsedLocationId', locationId);
  }, [locationId]);

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Customer search" />
      <Grid item container spacing={1} justify="space-between" alignItems="flex-end">
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          container
          direction="column"
          justify="flex-end"
          alignItems="flex-end"
          wrap="nowrap"
        >
          <SearchBox
            location={location}
            history={history}
            placeholder="Enter full license plate or any part of it"
            onSearch={(query) => setQuery(query)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-end"
          wrap="nowrap"
        >
          <LocationPicker
            value={locationId}
            onChange={(value) => setLocationId(value)}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.content}>
        {!isFetching && report && (
          <Paper className={classNames(classes.tablePaper, classes.grow)}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">License plate</TableCell>
                  <TableCell align="left" sortDirection="desc">
                    <TableSortLabel disabled active direction="desc">
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Camera</TableCell>
                  <TableCell align="left">Make</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {report.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item.licensePlate}
                    </TableCell>
                    <TableCell align="left">
                      <Typography display="block" variant="body2" noWrap>
                        {moment.tz(item.datetime, timezone).format('MM/DD/YYYY HH:mm')}
                      </Typography>
                      <Typography display="block" variant="caption">
                        {moment.tz(item.datetime, timezone).utc().fromNow()}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{item.camera}</TableCell>
                    <TableCell align="left">{carMakeName(item.make)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
        {isInitial && (
          <Typography variant="h5" component="span" align="center">
            To search a customer please select a location <br />
            and enter a license plate (or part of it) to the input above
          </Typography>
        )}
        {isFetching && <BrandedLoadingIndicator />}
        {!isFetching && !isInitial && !report && (
          <Typography variant="h5" component="span" align="center">
            No records found for this license plate
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
    defaultLocationId: getDefaultLocationId(state),
    report: getReport(state),
    isFetching: isReportFetching(state),
    isInitial: isReportInitial(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest, onUnmount: actions.reset })(toJS(LicensePlateSearch))
);

import { Button, Grid, Link as MuiLink, TextField, Typography } from '@material-ui/core';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import PasswordField, { allRequirementsSatisfied } from 'components/common/PasswordField';
import React, { useEffect, useState } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserEmail } from 'reducers/auth';
import { actions, getIsSuccess, getPasswordError, isUpdateFetching } from 'reducers/profile/password';

function UpdatePassword({ email, onSubmit, onUnmount, initialError, isFetching, isSuccess }) {
  useWillUnmount(onUnmount);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(initialError);
  const [newPasswordError, setNewPasswordError] = useState(null);
  const hasErrors =
    error != null || newPasswordError != null || !allRequirementsSatisfied(newPassword) || oldPassword === '';

  useEffect(() => setError(initialError), [initialError]);
  useEffect(() => {
    if (isSuccess) {
      setError(null);
      setOldPassword('');
      setNewPassword('');
      setNewPasswordError(null);
      onUnmount();
    }
  }, [isSuccess, onUnmount]);

  function handleSubmit(event) {
    event.preventDefault();
    if (!hasErrors && !isFetching) {
      onSubmit(oldPassword, newPassword);
    }
  }

  function validateNewPassword(newP, oldP) {
    if (newP !== '' && oldP !== '' && allRequirementsSatisfied(newP) && oldP === newP) {
      setNewPasswordError("New and old passwords can't be the same");
    } else {
      setNewPasswordError(null);
    }
  }

  return (
    <Grid onSubmit={handleSubmit} component="form" container spacing={3} direction="column" wrap="nowrap">
      <Grid item>
        <Typography variant="h6">Update password</Typography>
      </Grid>
      <TextField
        style={{ display: 'none' }}
        aria-hidden="true"
        value={email}
        autoComplete="email"
        name="email"
        type="email"
      />
      <Grid item>
        <PasswordField
          disabled={isFetching}
          value={oldPassword}
          onChange={event => {
            setOldPassword(event.target.value);
            validateNewPassword(newPassword, event.target.value);
            if (error) {
              setError(null);
            }
          }}
          label="Old password"
          autoComplete="current-password"
          name="password"
          margin="none"
          error={error !== null}
          helperText={
            <>
              {error != null && `${error}. `}
              <MuiLink to={`/forgot-password?email=${email}`} component={Link}>
                Forgot password?
              </MuiLink>
            </>
          }
          fullWidth
        />
      </Grid>
      <Grid item>
        <PasswordField
          disabled={isFetching}
          value={newPassword}
          onChange={event => {
            setNewPassword(event.target.value);
            validateNewPassword(event.target.value, oldPassword);
          }}
          label="New password"
          autoComplete="new-password"
          error={newPasswordError !== null}
          helperText={newPasswordError}
          name="newPassword"
          margin="none"
          fullWidth
          showRequirements
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <Button disabled={hasErrors} color="secondary" type="submit" variant="contained" fullWidth>
          <AsyncActionLabel label="Update password" fetchingLabel="Updating..." isFetching={isFetching} />
        </Button>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    email: getUserEmail(state),
    isFetching: isUpdateFetching(state),
    isSuccess: getIsSuccess(state),
    initialError: getPasswordError(state),
  };
}

export default connect(
  mapStateToProps,
  { onSubmit: actions.updateRequest, onUnmount: actions.reset }
)(UpdatePassword);

import { createReportSettings, csvReport, reports, widgetReport } from 'api/report';
import mixpanel from 'components/common/mixpanel';
import { downloadAjaxResponse, resolveLocationNameById, resolveTagNameById } from 'components/common/utils';
import { setsSeparator } from 'components/pickers/TagsPicker';
import { getLocations } from 'reducers/auth';
import { actions, types } from 'reducers/reports/totalCustomers';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

export function parseSets(tags) {
  const sets = { 0: [] };
  let setIndex = 0;
  tags.forEach((tag) => {
    if (tag === setsSeparator) {
      setIndex += 1;
    } else {
      if (!sets[setIndex]) {
        sets[setIndex] = [];
      }
      sets[setIndex].push(tag);
    }
  });
  return sets;
}

function* fetchReport({ requestDate, timezone, period, location, tags }) {
  try {
    const availableLocations = yield select(getLocations);
    const availableTags = availableLocations.getIn([location, 'tags', 'byId']);
    const sets = parseSets(tags);
    const responses = yield all(
      Object.values(sets).map((tags) =>
        call(
          widgetReport,
          reports.VEHICLE_COUNT_BY_TAGS,
          createReportSettings(requestDate, timezone, period, null, location, tags)
        )
      )
    );

    const result = {};
    Object.keys(sets).forEach((setIndex, index) => (result[setIndex] = responses[index].data));
    yield put(actions.reportSuccess(requestDate, timezone, period, result, availableTags, tags));

    mixpanel((mp) =>
      mp.track('Total customers by tags', {
        timezone,
        period,
        locationId: location,
        locationName: resolveLocationNameById(location),
        tags,
      })
    );
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, e));
  }
}

function* downloadCsv({ requestDate, timezone, period, location, tags }) {
  try {
    const sets = parseSets(tags);
    const locationName = resolveLocationNameById(location);
    const response = yield call(
      csvReport,
      reports.VEHICLE_DIMENSIONS_BY_TAGS_CSV,
      createReportSettings(requestDate, timezone, period, null, location, sets[0])
    );
    const setName = sets[0]?.length
      ? ` - ${sets[0].map((tagId) => resolveTagNameById(location, tagId)).join(', ')}`
      : '';
    downloadAjaxResponse(response, 'CSV', `${locationName}${setName} - Customers.csv`);

    yield put(actions.downloadCsvComplete(requestDate, timezone, period, null));

    mixpanel((mp) =>
      mp.track('Total customers by tags | Download CSV', {
        locationId: location,
        locationName: resolveLocationNameById(location),
        timezone,
        period,
        tags,
      })
    );
  } catch (e) {
    yield put(snackActions.set('Failed to download CSV, please try again later', snackVariants.error));
    yield put(actions.downloadCsvComplete(requestDate, timezone, period, e));
  }
}

export default function* totalCustomers() {
  yield takeLatest(types.REPORT_REQUEST, fetchReport);
  yield takeLatest(types.DOWNLOAD_CSV_REQUEST, downloadCsv);
}

import * as api from 'api/health';
import moment from 'moment';
import { actions, getIsOffline, types } from 'reducers/network';
import { call, delay, put, race, select, take, takeLeading } from 'redux-saga/effects';

function* delayedAutoRetry() {
  yield delay(20000);
  yield put(actions.connectionCheckRequest());
}

function* autoRetryScheduler() {
  while (true) {
    yield race({
      task: call(delayedAutoRetry),
      cancel: take([types.CONNECTION_CHECK_REQUEST, types.SET_ONLINE]),
    });
  }
}

function* startAutoRetry() {
  yield race({
    task: call(autoRetryScheduler),
    cancel: take(types.SET_ONLINE),
  });
}

function* connectionCheck() {
  const start = moment();
  try {
    yield call(api.checkHealth);
  } catch (e) {
    // ignore
  }

  const isOffline = yield select(getIsOffline);
  if (isOffline) {
    const remainingWait = 3000 - moment.duration(moment().diff(start)).asMilliseconds();
    if (remainingWait > 0) {
      yield delay(remainingWait);
    }
  }
  yield put(actions.connectionCheckFinished());
}

export default function* network() {
  yield takeLeading(types.CONNECTION_CHECK_REQUEST, connectionCheck);
  yield takeLeading(types.SET_OFFLINE, startAutoRetry);
}

import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    padding: '0 8px',
    borderRadius: 4,
    color: theme.palette.primary.contrastText,
  },
}));

function PlusLabel({ text = 'Plus', ...rest }) {
  const classes = useStyles();

  return (
    <Typography component="span" variant="caption" className={classes.root} {...rest}>
      {text}
    </Typography>
  );
}

export default PlusLabel;

import toJS from 'components/common/toJS';
import ThreeValuesWidgetHeader from 'components/dashboard/common/ThreeValuesWidgetHeader';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import COLORS from 'components/style/colors';
import { round, sumBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/location/lastSeen';

const returningColor = COLORS.DARK_YELLOW;

function LastSeenWidget({
  location,
  history,
  isFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
  defaultLocationId,
}) {
  useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest, defaultLocationId);

  if (!requestDate) {
    return null;
  }

  const categories = report && report.map((r) => r.name);
  const series = report && [
    {
      name: 'Customers',
      data: [...report.map((row) => ({ value: row.value, itemStyle: { color: row.color } }))],
    },
  ];
  const total = report && sumBy(report, 'value');
  const percentagesCategories = report && total > 0 && report.map((row) => `${round(row.value / (total / 100), 1)}%`);

  return (
    <Widget title="Customers last seen">
      <>
        <ThreeValuesWidgetHeader left={{ name: 'Return', value: total, color: returningColor }} />
        <ValueOverTimeChart
          optionsCustomizer={(options) => {
            options.xAxis.push({
              type: 'category',
              data: percentagesCategories,
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              axisLabel: {
                margin: 10,
              },
            });
          }}
          isFetching={isFetching}
          type="bar"
          categories={categories}
          series={report && total > 0 ? series : undefined}
        />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(LastSeenWidget)));

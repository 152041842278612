import { fromJS } from 'immutable';
import { get, reverse, sortBy } from 'lodash';
import moment from 'moment-timezone';

export const types = {
  REPORT_REQUEST: 'REPORTS_AVERAGE_VISITS_DURATION/REPORT_REQUEST',
  REPORT_SUCCESS: 'REPORTS_AVERAGE_VISITS_DURATION/REPORT_SUCCESS',
  REPORT_FAILURE: 'REPORTS_AVERAGE_VISITS_DURATION/REPORT_FAILURE',
  RESET: 'REPORTS_AVERAGE_VISITS_DURATION/RESET',
};

const initialState = fromJS({
  isInitial: true,
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report, locationId, timezone) {
  if (get(report, 'empty', true)) {
    return null;
  }

  const mapped = report.rows.map(({ row }) => {
    return {
      enterTime: row.enterTime,
      exitTime: row.exitTime,
      licensePlate: row.licensePlate,
      make: row.make,
      color: row.color,
      waitTime: row.waitTime,
    };
  });
  const filtered = mapped.filter((row) => {
    const enterMoment = moment.tz(row.enterTime, 'UTC');
    const offset = moment.tz.zone(timezone).utcOffset(moment.tz(row.enterTime, 'UTC').valueOf());
    const exitMoment = row.exitTime
      ? moment.tz(row.exitTime, 'UTC')
      : moment.tz(Date.now(), 'UTC').subtract(offset, 'minutes');
    const duration = moment.duration(exitMoment.diff(enterMoment));
    const durationMinutes = duration.asMinutes();
    if (locationId === 'd76f798e-4653-4240-bb11-ec1981f1f228') {
      return durationMinutes >= 10 && durationMinutes < 60;
    } else {
      return true;
    }
  });
  return reverse(sortBy(filtered, 'enterTime'));
}

export default function averageVisitsDuration(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
        s.set('isInitial', false);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report, location, timezone } = action;
        const { average, details } = report;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS({ average, details: prepareReport(details, location, timezone) }));
        s.set('isInitial', false);
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
        s.set('isInitial', false);
      });
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location, enterTags, exitTags) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
    enterTags,
    exitTags,
  }),
  reportSuccess: (requestDate, timezone, period, location, enterTags, exitTags, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    location,
    enterTags,
    exitTags,
    report,
  }),
  reportFailure: (requestDate, timezone, period, location, enterTags, exitTags, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    location,
    enterTags,
    exitTags,
    error,
  }),
  reset: () => ({ type: types.RESET }),
};

export const getRoot = (state) => state.get('reports').get('averageVisitsDuration');
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');
export const isReportInitial = (state) => getRoot(state).get('isInitial');

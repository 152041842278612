import { createReportSettings, reports, widgetReport } from 'api/report';
import { actions, types } from 'reducers/dashboard/camera/totalCustomers';
import { call, put, takeLatest } from 'redux-saga/effects';
import { populateJobIdAndName } from 'sagas/dashboard/location/util';

function* fetchReport({ requestDate, timezone, period, job }) {
  try {
    const response = yield call(
      widgetReport,
      reports.VEHICLE_TOTAL_CUSTOMERS_WITH_SESSIONS,
      createReportSettings(requestDate, timezone, period, job)
    );

    const report = yield populateJobIdAndName(response.data, job);
    yield put(actions.reportSuccess(requestDate, timezone, period, report));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, e));
  }
}

export default function* totalCustomers() {
  yield takeLatest(types.REPORT_REQUEST, fetchReport);
}

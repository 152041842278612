export const AclOperationMap = {
  READ: 'READ',
  WRITE: 'WRITE',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

export const UserRolesMap = {
  LOCATION_MANAGER: 'location_manager',
  COMPANY_ADMIN: 'company_admin',
  PORTFOLIO_SUPERVISOR: 'portfolio_supervisor',
};

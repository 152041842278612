import { withTheme } from '@material-ui/core';
import toJS from 'components/common/toJS';
import { formatSeconds } from 'components/dashboard/camera/WaitTimeWidget';
import ThreeValuesWidgetHeader from 'components/dashboard/common/ThreeValuesWidgetHeader';
import Widget from 'components/dashboard/common/Widget';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import COLORS from 'components/style/colors';
import { round } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer } from 'react-virtualized';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/ops/visitDurationBreakdown';

function VisitDurationBreakdownWidget({
  location,
  history,
  theme,
  isFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
  defaultLocationId,
}) {
  useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest, defaultLocationId);

  if (!requestDate) {
    return null;
  }

  const data = report &&
    report.data && [
      ...report.data
        .filter((row) => row.value > 0)
        .map((row) => ({ name: row.name, value: row.value, itemStyle: { color: row.color } })),
    ];

  return (
    <Widget title="Visit duration">
      <>
        <ThreeValuesWidgetHeader
          left={{
            name: 'Avg. duration',
            value: formatSeconds(report?.average),
            color: COLORS.LIGHT_GREY,
          }}
        />
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = width / 1.78 + 120 + 30.5;
            const options = {
              legend: {
                show: true,
                top: 20,
              },
              tooltip: {
                trigger: 'item',
                formatter: '<strong>{b}</strong>: {c} ({d}%)',
                extraCssText: `z-index: ${theme.zIndex.drawer + 1}`,
              },
              series: report &&
                data &&
                data.length > 0 && [
                  {
                    type: 'pie',
                    radius: ['35%', '65%'],
                    center: [width / 2, height / 2 + 50],
                    avoidLabelOverlap: true,
                    label: {
                      normal: {
                        show: true,
                        position: 'outside',
                        formatter: (value) => `${round(value.percent, 1)}%`,
                        color: theme.palette.text.primary,
                        fontSize: 16,
                      },
                    },
                    labelLine: {
                      show: true,
                      length: 5,
                      length2: 15,
                    },
                    itemStyle: { borderWidth: 2, borderColor: 'white' },
                    data,
                  },
                ],
            };

            return <WidgetChart width={width} height={height} isFetching={isFetching} options={options} />;
          }}
        </AutoSizer>
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(withTheme(VisitDurationBreakdownWidget)))
);

import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MemberIdIcon from '@material-ui/icons/Person';
import { visitSnapshotUrl } from 'components/reports/MemberValidationDetails';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { FaCarSide as MemberLicensePlateIcon } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContent: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gridTemplateRows: '1fr',
    gridGap: theme.spacing(1),
    alignItems: 'center',
  },
  titleIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
  },
  snapshot: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));

function MemberValidationSnapshotDetails({ timezone, visit, onClose }) {
  const classes = useStyles();
  const [error, setError] = useState(null);

  if (!visit) {
    return null;
  }

  const { memberId, jobId, mappedDate, mappedLicensePlate } = visit;

  return (
    <Dialog
      onClose={onClose}
      disableBackdropClick
      fullWidth
      PaperProps={{
        id: 'member-validation-details-dialog-paper',
      }}
      open
      fullScreen
      aria-labelledby="member-validation-snapshot-details-dialog-title"
    >
      <DialogTitle id="member-validation-snapshot-details-dialog-title" className={classes.title} disableTypography>
        <div className={classes.titleContent}>
          <MemberIdIcon className={classes.titleIcon} />
          <Typography variant="body1">{memberId}</Typography>
          <MemberLicensePlateIcon className={classes.titleIcon} />
          <div>
            <Typography variant="body1">{mappedLicensePlate}</Typography>
            <Typography component="span" color="textSecondary" variant="caption">
              {moment.tz(mappedDate, 'UTC').format('MM/DD/YYYY HH:mm')}
            </Typography>
          </div>
        </div>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <img
          style={error && { backgroundColor: '#BDBDBD' }}
          onError={() => setError(true)}
          className={classes.snapshot}
          alt=""
          src={visitSnapshotUrl(jobId, mappedDate, timezone)}
        />
      </DialogContent>
    </Dialog>
  );
}

export default MemberValidationSnapshotDetails;

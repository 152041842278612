import { fromJS } from 'immutable';

export const types = {
  SET_READY: 'MAP/SET_READY',
};

const initialState = fromJS({
  isReady: false,
});

export default function map(state = initialState, action) {
  switch (action.type) {
    case types.SET_READY:
      return state.withMutations((s) => {
        s.set('isReady', true);
      });
    default:
      return state;
  }
}

export const actions = {
  setReady: () => ({
    type: types.SET_READY,
  }),
};

export const getIsReady = (state) => state.getIn(['map', 'isReady']);

import { Grid } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';

export function GridRoot(props) {
  return <Grid.Root {...props} style={{ height: '100%' }} />;
}

export function TableContainer(props) {
  return <div {...props} style={{ width: '100%', minHeight: 200, overflowX: 'auto' }} />;
}

import toJS from 'components/common/toJS';
import MiniWidget from 'components/dashboard/common/MiniWidget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/location/rewashesMini';

function RewashesMiniWidget({
  location,
  history,
  isFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
  defaultLocationId,
}) {
  useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest, defaultLocationId);

  if (!requestDate) {
    return null;
  }

  const value = report?.rows?.[0]?.row?.totalRewashCount;
  return <MiniWidget isFetching={isFetching} title="Rewashes" value={value} />;
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(RewashesMiniWidget)));

import useQueryParam from 'components/common/useQueryParam';
import { resolveLocationIdByJobId } from 'components/common/utils';
import { parsePeriod } from 'components/dashboard/common/periods';
import useTimezone from 'components/dashboard/common/useTimezone';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { getDefaultJobId } from 'reducers/auth';
import store from 'reducers/index';

export function defaultJobId() {
  const state = store.getState();
  return getDefaultJobId(state);
}

export default function useSettingsChangeActionDispatcher(location, history, userTimezone, actionDispatcher) {
  let [timezone] = useTimezone(location, history, userTimezone);
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  const [period] = useQueryParam('period', (value) => parsePeriod(value, timezone), location, history);
  let [job] = useQueryParam('job', (v) => v, location, history);
  if (!job) {
    job = defaultJobId();
  }

  useEffect(() => {
    actionDispatcher(moment().format(), timezone, period, job);
  }, [location, timezone, period, job, actionDispatcher]);
  useEffect(() => {
    localStorage.setItem('lastUsedJobId', job);
    const locationId = resolveLocationIdByJobId(job);
    if (locationId) {
      localStorage.setItem('lastUsedLocationId', locationId);
    }
  }, [job]);

  return [period, timezone, job];
}

import { get } from 'lodash';
import { getJobs, getLocations } from 'reducers/auth';
import { select } from 'redux-saga/effects';

export function* populateJobIdAndName(report, job) {
  if (get(report, 'empty', true)) {
    return report;
  }

  const allJobs = yield select(getJobs);
  report.rows.forEach(row => {
    row.row.jobId = job;
    row.row.jobName = getJobName(allJobs, job);
  });
  return report;
}

export function* populateLocIdAndName(report, location) {
  if (get(report, 'empty', true)) {
    return report;
  }

  const allLocations = yield select(getLocations);
  report.rows.forEach(row => {
    row.row.locId = location;
    row.row.locName = getLocationName(allLocations, location);
  });
  return report;
}

const defaultLocationName = 'Location';
function getLocationName(allLocations, locationId) {
  if (!allLocations) {
    return defaultLocationName;
  }

  return allLocations.getIn([locationId, 'name'], defaultLocationName);
}

const defaultJobName = 'Camera';

function getJobName(allJobs, jobId) {
  if (!allJobs) {
    return defaultJobName;
  }

  return allJobs.getIn([jobId, 'name'], defaultJobName);
}

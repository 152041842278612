import useQueryParam from 'components/common/useQueryParam';
import { parsePeriod } from 'components/dashboard/common/periods';
import useTimezone from 'components/dashboard/common/useTimezone';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { getDefaultLocationId } from 'reducers/auth';
import store from 'reducers/index';

export function defaultLocationId() {
  const state = store.getState();
  return getDefaultLocationId(state);
}

export default function useSettingsChangeActionDispatcher(
  location,
  history,
  userTimezone,
  actionDispatcher,
  defaultLocation = null,
  additionalParams = null
) {
  let [timezone] = useTimezone(location, history, userTimezone);
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  const [period] = useQueryParam('period', (value) => parsePeriod(value, timezone), location, history);
  let [locationId] = useQueryParam('location', (v) => v, location, history);
  if (!locationId) {
    locationId = defaultLocation ? defaultLocation : defaultLocationId();
  }

  useEffect(() => {
    if (additionalParams) {
      actionDispatcher(moment().format(), timezone, period, additionalParams, locationId);
    } else {
      actionDispatcher(moment().format(), timezone, period, locationId);
    }
  }, [timezone, period, locationId, actionDispatcher, defaultLocation, additionalParams]);
  useEffect(() => {
    localStorage.setItem('lastUsedLocationId', locationId);
  }, [locationId]);

  return [period, timezone, locationId];
}

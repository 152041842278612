export function prepareLegendData(series, renderIconProvider) {
  const maxValue = Math.max(...series.map(d => d.value), 0);
  return series.map((entry, index) => ({
    name: entry.name,
    value: entry.value,
    color: entry.color,
    percent: maxValue > 0 ? entry.value / (maxValue / 100) : 0,
    id: index,
    renderIcon: entry.renderIcon || (renderIconProvider && renderIconProvider(entry)),
  }));
}

export function setItemStyleColor(series) {
  series.forEach(s => {
    s.itemStyle = { color: s.color };
  });
  return series;
}

import { companyName } from 'components/common/whitelabel';
import React from 'react';
import { Helmet } from 'react-helmet-async';

function SetTitle({ title }) {
  return (
    <Helmet>
      <title>
        {title} | {companyName}
      </title>
    </Helmet>
  );
}

export default React.memo(SetTitle);

import { InputAdornment, ListSubheader, TextField, withStyles } from '@material-ui/core';
import CompanyIcon from '@material-ui/icons/Business';
import LocationIcon from '@material-ui/icons/LocationOn';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import CompanyAndWhitelabeler from 'components/common/CompanyAndWhitelabeler';
import toJS from 'components/common/toJS';
import { find, get, sortBy, uniqBy } from 'lodash';
import matchSorter from 'match-sorter';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { getLocationIds, getLocations } from 'reducers/auth';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  menuList: {
    paddingRight: '0 !important',
    width: '100% !important',
    maxWidth: '600px',
  },
  selectRoot: {
    width: 'calc(100% - 32px)',
  },
  groupLabel: {
    backgroundColor: theme.palette.background.paper,
    top: -8,
    display: 'flex',
    margin: theme.spacing(1.5, 0),
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  groupUl: {
    padding: 0,
  },
  companyContainer: {
    lineHeight: 'normal',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const filterOptions = (options, { inputValue }) => {
  const matched = matchSorter(options, inputValue, { keys: [(item) => item.name] });
  return sortBy(matched, [
    (location) => get(location, 'company.name', '').toLowerCase(),
    (location) => location.name.toLowerCase(),
  ]);
};

function LocationPicker({
  classes,
  className,
  SelectProps,
  selectClasses,
  value,
  onChange,
  availableIds = [],
  availableLocations = {},
  dispatch,
  ...rest
}) {
  const companies = useMemo(() => uniqBy(Object.values(availableLocations), 'company.id'), [availableLocations]);
  const options = useMemo(() => {
    return sortBy(Object.values(availableLocations), [
      (location) => get(location, 'company.name', '').toLowerCase(),
      (location) => location.name.toLowerCase(),
    ]);
  }, [availableLocations]);
  const resolvedValue = find(options, { id: value });

  return (
    <Autocomplete
      filterOptions={filterOptions}
      className={classNames(classes.root, className)}
      id="location-picker"
      options={options}
      groupBy={
        companies.length > 1 ? (option) => `${get(option, 'company.name')}\r\n${get(option, 'company.id')}` : undefined
      }
      getOptionLabel={(option) => option.name}
      value={resolvedValue}
      onChange={(event, newValue) => {
        onChange(newValue.id);
      }}
      blurOnSelect
      openOnFocus
      disableClearable
      noOptionsText="No locations available"
      {...rest}
      renderGroup={(params) => {
        const [, companyId] = params.group.split('\r\n');
        const location = companies.find((c) => c.company.id === companyId);
        return (
          <li key={params.key}>
            <ListSubheader className={classes.groupLabel} component="div">
              <CompanyIcon className={classes.icon} />
              <CompanyAndWhitelabeler
                className={classes.companyContainer}
                company={location.company}
                whitelabeler={location.whitelabeler}
              />
            </ListSubheader>
            <ul className={classes.groupUl}>{params.children}</ul>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          label="Location"
          margin="none"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            className: SelectProps.className,
            startAdornment: (
              <InputAdornment position="start">
                <LocationIcon className={classes.icon} color="inherit" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    availableLocations: getLocations(state),
    availableIds: getLocationIds(state),
  };
}

export const PureLocationPicker = withStyles(styles)(LocationPicker);
export default connect(mapStateToProps)(withStyles(styles)(toJS(LocationPicker)));

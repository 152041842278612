import { Snackbar, withStyles } from '@material-ui/core';
import toJS from 'components/common/toJS';
import { DRAWER_WIDTH } from 'components/menu/PrimaryNavigation';
import SnackbarContentWrapper from 'components/snackbar/SnackbarContentWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { isAuthenticated } from 'reducers/auth';
import { actions, getNotification } from 'reducers/snackbarNotification';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${DRAWER_WIDTH / 2}px)`,
    },
  },
  rootAnonymous: {
    [theme.breakpoints.up('md')]: {
      left: 'calc(50% + 240px)',
    },
  },
});

function SnackbarNotification({ classes, authenticated, notification, onClose }) {
  const { id, message, variant } = notification;

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  }

  return (
    <Snackbar
      key={id}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={message !== null}
      autoHideDuration={6000}
      onClose={handleClose}
      className={authenticated ? classes.root : classes.rootAnonymous}
    >
      {message && <SnackbarContentWrapper onClose={handleClose} variant={variant} message={message} />}
    </Snackbar>
  );
}

SnackbarNotification.propTypes = {
  notification: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    authenticated: isAuthenticated(state),
    notification: getNotification(state),
  };
}

export default connect(
  mapStateToProps,
  { onClose: actions.reset }
)(withStyles(styles)(toJS(SnackbarNotification)));

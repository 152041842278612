import { fromJS } from 'immutable';
import { types as authTypes } from 'reducers/auth';

export const variants = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
};

export const types = {
  SET: 'SNACKBAR_NOTIFICATIONS/SET',
  RESET: 'SNACKBAR_NOTIFICATIONS/RESET',
};

export const initialState = fromJS({
  message: null,
  variant: null,
  id: 0,
});

export default function snackbarNotification(state = initialState, action) {
  switch (action.type) {
    case types.SET:
      return state.withMutations(s =>
        s
          .set('message', action.message)
          .set('variant', action.variant)
          .set('id', s.get('id') + 1)
      );
    case types.RESET:
    case authTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export const actions = {
  set: (message, variant = variants.info) => ({ type: types.SET, message, variant }),
  reset: () => ({ type: types.RESET }),
};

export const getNotification = state => state.get('snackbarNotification');

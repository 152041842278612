import { Grid, Typography, withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { WARN_COLOR } from 'components/style/colors';
import React from 'react';
import { connect } from 'react-redux';
import { isImpersonation } from 'reducers/auth';

const styles = theme => ({
  root: {
    color: 'white',
    backgroundColor: WARN_COLOR,
    height: 56,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
});

function ImpersonationStatus({ classes, impersonation }) {
  if (!impersonation) {
    return null;
  }

  return (
    <Grid container wrap="nowrap" justify="center" alignItems="center" className={classes.root}>
      <WarningIcon className={classes.icon} />
      <Typography color="inherit" component="span" display="inline" variant="subtitle1">
        Impersonation mode
      </Typography>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    impersonation: isImpersonation(state),
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(ImpersonationStatus));

import axios from 'axios';
import moment from 'moment';

export const apiPath = '/apigw/v1/detectionjob';

export function downloadSnapshots(eventType, startDate, endDate, jobId) {
  return axios.get(
    `${apiPath}/${jobId}/snapshot/${eventType}/${moment(startDate).utc().toISOString()}_${moment(endDate)
      .utc()
      .toISOString()}/data.zip`,
    {
      headers: {
        Accept: 'image/jpeg',
      },
      responseType: 'blob',
    }
  );
}

import COLORS from 'components/style/colors';
import { fromJS } from 'immutable';
import { get, round, sumBy } from 'lodash';
import { DRIVE_OFF_NON_CLASSIC_LAYOUT } from 'reducers/auth';

export const types = {
  REPORT_REQUEST: 'DRIVEOFF/SUMMARY/REPORT_REQUEST',
  REPORT_SUCCESS: 'DRIVEOFF/SUMMARY/REPORT_SUCCESS',
  REPORT_FAILURE: 'DRIVEOFF/SUMMARY/REPORT_FAILURE',
};

export const modes = {
  DEFAULT: 'DATE',
  BY_HOUR: 'HOUR',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report, layout) {
  const { bay, exit } = report;
  const bayEmpty = get(bay, 'empty', true);
  const exitEmpty = get(exit, 'empty', true);
  if (bayEmpty && exitEmpty) {
    return null;
  }

  let bayCount = bayEmpty ? null : sumBy(bay.rows, 'row.eventsCount');
  let exitCount = exitEmpty ? null : sumBy(exit.rows, 'row.eventsCount');
  let driveoffCount = !exitEmpty && exitCount > bayCount ? exitCount - bayCount : null;
  let driveoffRate;
  if (layout === DRIVE_OFF_NON_CLASSIC_LAYOUT) {
    driveoffCount = exitCount;
    exitCount = null;
    driveoffRate = null;
  } else {
    driveoffRate = !exitEmpty && driveoffCount > 0 ? round(driveoffCount / (exitCount / 100), 1) : null;
  }

  const summary = {
    bayCount,
    exitCount,
    driveoffCount,
    driveoffRate,
  };

  const bayExitReport = {
    bay: { id: 'bay', color: COLORS.BLUE, name: 'Bay', value: 0, data: [] },
    exit: { id: 'exit', color: COLORS.DARK_YELLOW, name: 'Exit', value: 0, data: [] },
  };
  if (layout === DRIVE_OFF_NON_CLASSIC_LAYOUT) {
    bayExitReport.exit.name = 'Drive-off';
    bayExitReport.exit.color = COLORS.DARK_GREY;
  }

  const loopReport = bayEmpty ? exit : bay;
  loopReport.rows.forEach((_, index) => {
    const bc = bayEmpty ? 0 : bay.rows[index].row.eventsCount;
    bayExitReport.bay.data.push(bc);
    bayExitReport.bay.value += bc;

    const ec = exitEmpty ? 0 : exit.rows[index].row.eventsCount;
    bayExitReport.exit.data.push(ec);
    bayExitReport.exit.value += ec;
  });

  return { bayExitReport, summary };
}

export default function summary(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report, layout } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report, layout)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, mode, location) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    mode,
    location,
  }),
  reportSuccess: (requestDate, timezone, period, layout, mode, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    layout,
    mode,
    report,
  }),
  reportFailure: (requestDate, timezone, period, mode, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    mode,
    error,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'driveoff', 'summary']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');

import { Avatar, Card, CardHeader, CardMedia, Grid, makeStyles, Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import CameraIcon from '@material-ui/icons/Videocam';
import { momentUserTz } from 'components/common/momentUtils';
import COLORS, { CHARTS_PALETTE, ERROR_COLOR, SUCCESS_COLOR } from 'components/style/colors';
import { cloneDeep, remove, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { FaTag as TagIcon } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    gridGap: theme.spacing(4),
  },
  card: {
    width: '100%',
  },
  thumbnail: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundColor: '#757575',
    position: 'relative',
  },
  tagsContainer: {
    position: 'absolute',
    right: 0,
    bottom: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    backgroundSize: 'cover',
    padding: theme.spacing(0.5, 1),
  },
  tagIcon: {
    color: 'inherit',
    width: 15,
  },
}));

function cameraStatusColorAndDescription(job) {
  if (job.status?.state === 'RUNNING') {
    return [SUCCESS_COLOR, 'Camera is up and running'];
  } else if (job.enabled === false) {
    return [COLORS.LIGHT_GREY, 'Camera is disabled'];
  } else {
    return [ERROR_COLOR, 'Camera is down'];
  }
}

function CameraAvatar({ value }) {
  const [backgroundColor, description] = cameraStatusColorAndDescription(value);
  return (
    <Tooltip title={description}>
      <Avatar aria-label="recipe" style={{ backgroundColor }}>
        <CameraIcon />
      </Avatar>
    </Tooltip>
  );
}

const tagColors = remove(cloneDeep(CHARTS_PALETTE), (color) => !Object.values(COLORS).includes(color));

function LocationCameras({ value }) {
  const classes = useStyles();
  const { jobs } = value;
  const uniqTags = uniqBy(
    jobs.flatMap((job) => job.tags),
    'id'
  );

  if (jobs?.length > 0) {
    return (
      <div className={classes.root}>
        {jobs.map((job) => {
          const { id, name, tags, createdAt, status } = job;
          const image = status?.state === 'RUNNING' ? `/apigw/v1/detectionjob/${id}/thumb.jpg` : undefined;

          return (
            <Card key={id} className={classes.card} elevation={0}>
              <CardMedia className={classes.thumbnail} image={image} component={image ? undefined : 'div'} title="">
                {tags?.length > 0 && (
                  <Grid container spacing={1} justify="flex-end" className={classes.tagsContainer}>
                    {tags.map(({ id, name }) => (
                      <Grid item key={id}>
                        <Chip
                          classes={{ icon: classes.tagIcon }}
                          icon={<TagIcon />}
                          label={name}
                          size="small"
                          style={{
                            color: 'white',
                            backgroundColor: tagColors[uniqTags.findIndex((tag) => tag.id === id) % tagColors.length],
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </CardMedia>
              <CardHeader
                avatar={<CameraAvatar value={job} />}
                title={name}
                subheader={`Created ${moment.min(momentUserTz(createdAt), momentUserTz()).fromNow()}`}
              />
            </Card>
          );
        })}
      </div>
    );
  }
  return null;
}

export default LocationCameras;

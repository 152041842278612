import * as api from 'api/insights';
import { createInsightsReportSettings, params, reports, widgetReport } from 'api/report';
import mixpanel from 'components/common/mixpanel';
import { resolveLocationNameById } from 'components/common/utils';
import moment from 'moment-timezone';
import { actions, getReport, getRequestDate, types } from 'reducers/insights';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

function* submitFeedback({ timezone, period, locationId, positive, notes }) {
  try {
    yield put(snackActions.set('Feedback submitted. Thank you!', snackVariants.success));

    const requestDate = yield select(getRequestDate);
    const report = yield select(getReport);
    const settings = createInsightsReportSettings(requestDate, timezone, period, locationId);

    const request = {
      locationId,
      timezone,
      period: `${period}, ${settings[params.START_DATE]} - ${settings[params.END_DATE]}`,
      positive,
      notes,
      state: JSON.stringify(report.toJS(), null, 2),
    };
    yield call(api.submitFeedback, request);

    mixpanel((mp) =>
      mp.track('Insights feedback', {
        locationId,
        locationName: resolveLocationNameById(locationId),
        timezone,
        period: `${period}, ${settings[params.START_DATE]} - ${settings[params.END_DATE]}`,
        positive,
        notes,
      })
    );
  } catch (e) {
    // ignore
  }
}

function* fetchEventsReport(requestDate, timezone, period, location) {
  const start = moment.tz(requestDate, timezone).startOf('day').utc();
  const end = moment.tz(requestDate, timezone).add(30, 'days').endOf('day').utc();
  return yield call(
    widgetReport,
    reports.INSIGHTS_LOCAL_EVENTS,
    createInsightsReportSettings(requestDate, timezone, null, location, [start, end])
  );
}

function* fetchReport(requestDate, timezone, period, location, report) {
  return yield call(widgetReport, report, createInsightsReportSettings(requestDate, timezone, period, location));
}

function* fetchAllReports({ requestDate, timezone, period, location }) {
  try {
    const [customers, events, weather] = yield all([
      fetchReport(requestDate, timezone, period, location, reports.INSIGHTS_CUSTOMERS_PREDICTION),
      fetchEventsReport(requestDate, timezone, period, location),
      fetchReport(requestDate, timezone, period, location, reports.INSIGHTS_WEATHER_PREDICTION),
    ]);

    const report = {
      customers: customers.data,
      events: events.data,
      weather: weather.data,
      timezone,
    };
    yield put(actions.reportSuccess(requestDate, timezone, period, report));

    mixpanel((mp) =>
      mp.track('Insights', { timezone, period, locationId: location, locationName: resolveLocationNameById(location) })
    );
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, e));
  }
}

export default function* insights() {
  yield all([takeLatest(types.REPORT_REQUEST, fetchAllReports), takeEvery(types.SUBMIT_FEEDBACK, submitFeedback)]);
}

import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ThumbnailIcon from '@material-ui/icons/Image';
import LicensePlatesSummaryIcon from '@material-ui/icons/List';
import MemberIdIcon from '@material-ui/icons/Person';
import VisitDateIcon from '@material-ui/icons/Schedule';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import toJS from 'components/common/toJS';
import MemberValidationSnapshotDetails from 'components/reports/MemberValidationSnapshotDetails';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { FaCarSide as MemberLicensePlateIcon } from 'react-icons/fa';
import { connect } from 'react-redux';
import {
  actions,
  getDetailsError,
  getDetailsMemberId,
  getDetailsParentReport,
  getDetailsReport,
  isDetailsFetching,
  isDetailsOpen,
} from 'reducers/reports/memberValidation';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  titleContent: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gridTemplateRows: '1fr',
    gridGap: theme.spacing(1),
    alignItems: 'center',
  },
  titleIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  visitTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
  avatar: {
    borderRadius: '15%',
  },
}));

export function visitSnapshotUrl(jobId, mappedDate, timezone) {
  const snapshotPath = mappedDate && moment.tz(mappedDate.replace('Z', ''), timezone).utc().toISOString();
  return snapshotPath && `/apigw/v1/detectionjob/${jobId}/snapshot/enter/${snapshotPath}/snapshot.jpg`;
}

function MemberValidationsDetails({
  timezone,
  open,
  isFetching,
  fullScreen,
  memberId,
  parentReport,
  report,
  onClose,
  error,
}) {
  const classes = useStyles();
  const { totalVisits } = parentReport ?? {};
  const [snapshotDetailsVisit, setSnapshotDetailsVisit] = useState(null);

  return (
    <>
      <MemberValidationSnapshotDetails
        timezone={timezone}
        visit={snapshotDetailsVisit}
        onClose={() => setSnapshotDetailsVisit(null)}
      />
      <Dialog
        open={error}
        onClose={onClose}
        aria-labelledby="member-validation-details-error-dialog-title"
        aria-describedby="member-validation-details-error-dialog-description"
      >
        <DialogTitle id="member-validation-details-error-dialog-title">Load failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="member-validation-details-error-dialog-description">
            Failed to load member validation details, please try again later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={onClose}
        disableBackdropClick
        scroll="body"
        maxWidth="sm"
        fullWidth
        PaperProps={{
          id: 'member-validation-details-dialog-paper',
        }}
        open={open && !error}
        fullScreen={fullScreen}
        aria-labelledby="member-validation-details-dialog-title"
      >
        <DialogTitle id="member-validation-details-dialog-title" className={classes.title} disableTypography>
          <div className={classes.titleContent}>
            <MemberIdIcon className={classes.titleIcon} />
            <Typography variant="body1">
              {memberId}
              <br />
              {totalVisits && (
                <Typography component="span" color="textSecondary" variant="caption">
                  {pluralize('visit', totalVisits, true)}
                </Typography>
              )}
            </Typography>
            {report?.memberLicensePlate && (
              <>
                <MemberLicensePlateIcon className={classes.titleIcon} />
                <Typography variant="body1">{report.memberLicensePlate.toUpperCase()}</Typography>
              </>
            )}
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {isFetching && (
          <DialogContent style={{ paddingBottom: 32 }} className={classes.content}>
            <BrandedLoadingIndicator />
          </DialogContent>
        )}
        {report && !isFetching && (
          <>
            <DialogContent className={classes.content}>
              {report.similarGroups.length > 0 && (
                <List
                  elevation={3}
                  aria-labelledby="summary-list-subheader"
                  subheader={
                    <ListSubheader className={classes.visitTitle} component="div" id="summary-list-subheader">
                      <LicensePlatesSummaryIcon />
                      <span>License plates summary</span>
                    </ListSubheader>
                  }
                >
                  {report.similarGroups.map((group) => {
                    const { lps, totalVisits: plateTotalVisits, color } = group;
                    const plate = lps[0];

                    return (
                      <ListItem key={plate}>
                        <ListItemAvatar>
                          <Avatar className={classes.avatar} style={{ backgroundColor: color }}>
                            <MemberLicensePlateIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={plate}
                          secondary={`${plateTotalVisits} of ${totalVisits} ${pluralize('visit', totalVisits)}`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
              {report.visits.map(({ date, visits }) => {
                return (
                  <List
                    component={Paper}
                    elevation={3}
                    key={date}
                    aria-labelledby={`${date}-list-subheader`}
                    subheader={
                      <ListSubheader className={classes.visitTitle} component="div" id={`${date}-list-subheader`}>
                        <VisitDateIcon />
                        <span>{moment.tz(date, 'UTC').format('MM/DD/YYYY HH:mm')}</span>
                      </ListSubheader>
                    }
                  >
                    {visits.map((visit, index) => {
                      const { mappedLicensePlate, mappedDate, color, jobId } = visit;
                      const snapshotFullUrl = visitSnapshotUrl(jobId, mappedDate, timezone);

                      return (
                        <ListItem key={`${date}-${index}`}>
                          <ListItemAvatar>
                            <Avatar className={classes.avatar} src={snapshotFullUrl}>
                              <ThumbnailIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <span style={{ borderLeft: `solid 4px ${color}`, paddingLeft: 8 }}>
                                {mappedLicensePlate ?? '—'}
                              </span>
                            }
                            secondary={mappedDate && moment.tz(mappedDate, 'UTC').format('MM/DD/YYYY HH:mm')}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              disabled={mappedDate == null}
                              onClick={() => setSnapshotDetailsVisit(visit)}
                              edge="end"
                              aria-label="Zoom in thumbnail"
                            >
                              <ZoomInIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} variant="contained" color="secondary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    open: isDetailsOpen(state),
    isFetching: isDetailsFetching(state),
    report: getDetailsReport(state),
    memberId: getDetailsMemberId(state),
    parentReport: getDetailsParentReport(state),
    error: getDetailsError(state),
  };
}

export default connect(mapStateToProps, {
  onClose: actions.detailsClose,
})(withMobileDialog()(toJS(MemberValidationsDetails)));

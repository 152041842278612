import { createAverageVisitsDurationReportSettings, reports, widgetReport } from 'api/report';
import mixpanel from 'components/common/mixpanel';
import { resolveLocationNameById } from 'components/common/utils';
import { actions, types } from 'reducers/reports/averageVisitsDuration';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* fetchReport(requestDate, timezone, period, location, enterTags, exitTags, report) {
  return yield call(
    widgetReport,
    report,
    createAverageVisitsDurationReportSettings(requestDate, timezone, period, location, enterTags, exitTags)
  );
}

function* fetchAllReports({ requestDate, timezone, period, location, enterTags, exitTags }) {
  try {
    const [average, details] = yield all([
      fetchReport(requestDate, timezone, period, location, enterTags, exitTags, reports.VEHICLE_AVG_WAIT_TIME_BY_TAGS),
      fetchReport(requestDate, timezone, period, location, enterTags, exitTags, reports.VEHICLE_WAIT_TIME_BY_TAGS),
    ]);

    yield put(
      actions.reportSuccess(requestDate, timezone, period, location, enterTags, exitTags, {
        average: average.data,
        details: details.data,
      })
    );

    mixpanel((mp) =>
      mp.track('Visit duration', {
        timezone,
        period,
        locationId: location,
        locationName: resolveLocationNameById(location),
        enterTags,
        exitTags,
      })
    );
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, location, enterTags, exitTags, e));
  }
}

export default function* averageVisitsDuration() {
  yield takeLatest(types.REPORT_REQUEST, fetchAllReports);
}

import { createLicensePlateSearchReportSettings, reports, widgetReport } from 'api/report';
import mixpanel from 'components/common/mixpanel';
import { resolveLocationNameById } from 'components/common/utils';
import { actions, types } from 'reducers/reports/licensePlateSearch';
import { call, put, takeLatest } from 'redux-saga/effects';

function* fetchReport({ requestDate, timezone, location, query }) {
  try {
    const response = yield call(
      widgetReport,
      reports.VEHICLE_DIMENSIONS_BY_LICENSE_PLATE,
      createLicensePlateSearchReportSettings(requestDate, timezone, location, query)
    );

    yield put(actions.reportSuccess(requestDate, timezone, location, query, response.data));

    mixpanel((mp) =>
      mp.track('Customer search', {
        timezone,
        locationId: location,
        locationName: resolveLocationNameById(location),
        query,
      })
    );
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, location, query, e));
  }
}

export default function* licensePlateSearch() {
  yield takeLatest(types.REPORT_REQUEST, fetchReport);
}

import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import mixpanel from 'components/common/mixpanel';
import SetTitle from 'components/common/SetTitle';
import ProfileDetails from 'components/user/ProfileDetails';
import UpdatePassword from 'components/user/UpdatePassword';
import React, { useEffect } from 'react';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
      display: 'block',
    },
  },
  paper: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
  content: {
    maxWidth: 360,
    '& > div + div': {
      marginTop: theme.spacing(7),
    },
  },
});

function UserProfile({ classes }) {
  useEffect(() => {
    mixpanel((mp) => mp.track('User profile'));
  }, []);

  return (
    <div className={classes.root}>
      <SetTitle title="Profile" />
      <Typography component="h1" variant="h5" color="textSecondary" className={classes.title}>
        Profile
      </Typography>
      <Paper className={classes.paper}>
        <Grid className={classes.content} container direction="column" wrap="nowrap">
          <Grid item>
            <ProfileDetails />
          </Grid>
          <Grid item>
            <UpdatePassword />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(UserProfile);

import {
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DetailsIcon from '@material-ui/icons/List';
import classNames from 'classnames';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import { parseMemberValidationMode } from 'components/common/utils';
import DashboardControls, { memberValidationMode } from 'components/dashboard/common/DashboardControls';
import { parsePeriod } from 'components/dashboard/common/periods';
import useTimezone from 'components/dashboard/common/useTimezone';
import MemberValidationDetails from 'components/reports/MemberValidationDetails';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDefaultLocationIdWithFraud, getUserTimezone } from 'reducers/auth';
import {
  actions,
  getReport,
  getRequestDate,
  isDetailsFetching,
  isReportFetching,
  isReportInitial,
} from 'reducers/reports/memberValidation';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  inputRoot: {
    backgroundColor: 'white',
    paddingLeft: 12,
    borderRadius: 4,
    borderBottom: 'unset !important',
    '&::before': {
      borderBottom: 'unset !important',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  total: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tablePaper: {
    width: '100%',
    overflowX: 'auto',
    minHeight: 200,
    alignSelf: 'flex-start',
  },
  table: {
    minWidth: 600,
  },
  controlsContainer: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

function Fraud({
  location,
  history,
  userTimezone,
  defaultLocationId,
  report,
  isFetching,
  detailsIsFetching,
  isInitial,
  reportRequest,
  detailsRequest,
  onUnmount,
}) {
  const classes = useStyles();
  const [locationId] = useQueryParam('location', (v) => v || defaultLocationId, location, history);
  let [timezone] = useTimezone(location, history, userTimezone);
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  const [period] = useQueryParam('period', (value) => parsePeriod(value, timezone), location, history);
  const [mvMode] = useQueryParam('mvMode', (value) => parseMemberValidationMode(value), location, history);
  useWillUnmount(onUnmount);
  useEffect(() => {
    if (locationId) {
      reportRequest(moment.tz(timezone).format(), timezone, period, locationId, mvMode);
    }
  }, [period, location, locationId, timezone, mvMode, reportRequest]);
  useEffect(() => {
    localStorage.setItem('lastUsedLocationId', locationId);
  }, [locationId]);
  const showInitialBlock = isInitial && !isFetching;

  return (
    <>
      <MemberValidationDetails timezone={timezone} />
      <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
        <SetTitle title="Member validation" />
        <Grid item>
          <DashboardControls mode={memberValidationMode} />
        </Grid>
        <Grid item className={classes.content}>
          {!isFetching && report?.length ? (
            <>
              <Typography
                display="block"
                className={classes.total}
                color="textSecondary"
                variant="subtitle1"
                align="left"
              >
                Total: {report.length}
                {report[0].lastSyncDate && (
                  <Tooltip
                    placement="bottom-start"
                    title={`Last data sync at ${moment.tz(report[0].lastSyncDate, 'UTC').format('LL, LTS')}`}
                    enterDelay={300}
                  >
                    <Typography align="right" component="span" color="textSecondary" variant="caption">
                      Last data sync: {moment.tz(report[0].lastSyncDate.replace('Z', ''), timezone).fromNow()}
                    </Typography>
                  </Tooltip>
                )}
              </Typography>
              <Paper className={classNames(classes.tablePaper, classes.grow)}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" />
                      <TableCell align="left">Member ID</TableCell>
                      <TableCell align="left" sortDirection="desc">
                        <TableSortLabel disabled active direction="desc">
                          Last visit
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.map((item, index) => {
                      const lastVisitTime = moment.tz(item.lastVisitDatetime, 'UTC');
                      const { memberId, totalVisits } = item;

                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <IconButton
                              aria-label="Show details"
                              aria-haspopup="true"
                              disabled={detailsIsFetching}
                              onClick={() => {
                                detailsRequest(moment.tz(timezone).format(), timezone, period, locationId, memberId);
                              }}
                            >
                              <DetailsIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {memberId}
                            <br />
                            <Typography component="span" color="textSecondary" variant="caption">
                              {pluralize('visit', totalVisits, true)}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">{lastVisitTime.format('MM/DD/YYYY HH:mm')}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </>
          ) : null}
          {showInitialBlock && (
            <Typography variant="h5" component="span" align="center">
              To see how many {mvMode} members was detected during a specific period,
              <br />
              please select the location
            </Typography>
          )}
          {isFetching && <BrandedLoadingIndicator />}
          {!isFetching && !isInitial && !report?.length && locationId && (
            <Typography variant="h5" component="span" align="center">
              No {mvMode} members found for this location and period
              {report?.lastSyncDate && (
                <>
                  <br />
                  <br />
                  <Tooltip
                    placement="bottom-start"
                    title={`Last data sync at ${moment.tz(report?.lastSyncDate, 'UTC').format('LL, LTS')}`}
                    enterDelay={300}
                  >
                    <Typography variant="inherit">
                      Last data sync: {moment.tz(report?.lastSyncDate.replace('Z', ''), timezone).fromNow()}
                    </Typography>
                  </Tooltip>
                </>
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
    defaultLocationId: getDefaultLocationIdWithFraud(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    isFetching: isReportFetching(state),
    detailsIsFetching: isDetailsFetching(state),
    isInitial: isReportInitial(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    reportRequest: actions.reportRequest,
    detailsRequest: actions.detailsRequest,
    onUnmount: actions.reset,
  })(toJS(Fraud))
);

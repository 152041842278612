import MomentUtils from '@date-io/moment';
import { createMuiTheme, CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getUserContext } from 'api/auth';
import App from 'components/App';
import { dataLayerPush, setupDataLayer } from 'components/common/gtm';
import SplashScreen from 'components/common/SplashScreen';
import { themePrimaryColor, themeSecondaryColor } from 'components/common/whitelabel';
import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from 'reducers';
import { actions } from 'reducers/auth';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: themePrimaryColor,
    },
    secondary: {
      main: themeSecondaryColor,
    },
  },
  overrides: {
    MuiLink: {
      root: {
        fontWeight: 500,
        color: themeSecondaryColor,
        '&:visited': {
          color: themeSecondaryColor,
        },
        '&:hover': {
          color: themeSecondaryColor,
        },
      },
    },
  },
});

function Root() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function load() {
      try {
        const response = await getUserContext();
        setupDataLayer(response.data);
        dataLayerPush({ event: 'userId' });
        store.dispatch(actions.loginSuccess(response.data));
        setLoaded(true);
      } catch (error) {
        if (error.response || error.request) {
          setLoaded(true);
        } else {
          throw error;
        }
      }
    }
    if (!loaded) {
      load();
    }
  }, [loaded]);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {loaded ? (
              <App />
            ) : (
              <>
                <CssBaseline />
                <SplashScreen />
              </>
            )}
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default Root;

import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import MenuIcon from '@material-ui/icons/MoreVert';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import React, { useCallback, useState } from 'react';

function ReportActionMenu({
  label = 'Download CSV',
  fetchingLabel = 'Downloading...',
  isDownloadFetching,
  onDownload,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleDownload = useCallback(() => {
    handleClose();
    onDownload();
  }, [onDownload, handleClose]);
  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-label="Actions"
        aria-owns={open ? `report-actions-menu` : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="report-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDownload} disabled={isDownloadFetching}>
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <AsyncActionLabel label={label} fetchingLabel={fetchingLabel} isFetching={isDownloadFetching} />
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ReportActionMenu;

import camerasUptime from 'reducers/dashboard/location/camerasUptime';
import camerasUptimeMini from 'reducers/dashboard/location/camerasUptimeMini';
import firstTimeMini from 'reducers/dashboard/location/firstTimeMini';
import lastSeen from 'reducers/dashboard/location/lastSeen';
import loyalCustomers from 'reducers/dashboard/location/loyalCustomers';
import memberFraudMini from 'reducers/dashboard/location/memberFraudMini';
import memberVisitsMini from 'reducers/dashboard/location/memberVisitsMini';
import notificationsSent from 'reducers/dashboard/location/notificationsSent';
import returningCustomers from 'reducers/dashboard/location/returningCustomers';
import rewashesMini from 'reducers/dashboard/location/rewashesMini';
import totalCustomers from 'reducers/dashboard/location/totalCustomers';
import totalCustomersMini from 'reducers/dashboard/location/totalCustomersMini';
import vehicleBodyTypes from 'reducers/dashboard/location/vehicleBodyTypes';
import vehicleMakes from 'reducers/dashboard/location/vehicleMakes';
import waitTime from 'reducers/dashboard/location/waitTime';
import weatherMini from 'reducers/dashboard/location/weatherMini';
import { combineReducers } from 'redux-immutable';

export default combineReducers({
  totalCustomers,
  totalCustomersMini,
  firstTimeMini,
  rewashesMini,
  camerasUptimeMini,
  weatherMini,
  returningCustomers,
  lastSeen,
  notificationsSent,
  loyalCustomers,
  waitTime,
  vehicleMakes,
  vehicleBodyTypes,
  camerasUptime,
  memberVisitsMini,
  memberFraudMini,
});

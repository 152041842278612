import { Grid, withStyles, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { reports } from 'api/report';
import MasonryLayout from 'components/common/MasonryLayout';
import mixpanel from 'components/common/mixpanel';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import { resolveLocationNameById } from 'components/common/utils';
import CamerasUptimeMiniWidget from 'components/dashboard/location/CamerasUptimeMiniWidget';
import FirstTimeMiniWidget from 'components/dashboard/location/FirstTimeMiniWidget';
import LastSeenWidget from 'components/dashboard/location/LastSeenWidget';
import MemberFraudMiniWidget from 'components/dashboard/location/MemberFraudMiniWidget';
import MemberVisitsMiniWidget from 'components/dashboard/location/MemberVisitsMiniWidget';
import NotificationsSentWidget from 'components/dashboard/location/NotificationsSentWidget';
import ReturningCustomersWidget from 'components/dashboard/location/ReturningCustomersWidget';
import RewashesMiniWidget from 'components/dashboard/location/RewashesMiniWidget';
import TotalCustomersMiniWidget from 'components/dashboard/location/TotalCustomersMiniWidget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import WeatherMiniWidget from 'components/dashboard/location/WeatherMiniWidget';
import { get } from 'lodash';
import React, { useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { EW_COMPANY_ID, getDefaultLocationId, getLocations, getUserTimezone, isSuperAdmin } from 'reducers/auth';
import AiSummaryWidget from 'components/dashboard/location/AiSummaryWidget';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  miniWidgetsContainer: {
    marginBottom: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    gridGap: theme.spacing(4),
  },
});

const widgets = [
  { id: reports.VEHICLE_FIRST_TIME_VS_RETURN_WIDGET, component: ReturningCustomersWidget },
  { id: reports.VEHICLE_CUSTOMERS_LAST_SEEN_WIDGET, component: LastSeenWidget },
  { id: reports.VEHICLE_NOTIFICATIONS_WIDGET, component: NotificationsSentWidget },
];

function LocationDashboard({
  classes,
  width,
  location,
  history,
  userTimezone,
  defaultLocationId,
  availableLocations = {},
}) {
  const superAdmin = useSelector(isSuperAdmin);
  const [locationId] = useQueryParam('location', (v) => v || defaultLocationId, location, history);
  const dashboardDisabledWidgets = get(availableLocations, `${locationId}.dashboardDisabledWidgets`, []) || [];
  const locationCompanyId = get(availableLocations, `${locationId}.company.id`);
  const locationWhitelabelerId = get(availableLocations, `${locationId}.whitelabeler.id`);
  const mixpanelTrack = useCallback((requestDate, timezone, period, locationId) => {
    mixpanel((mp) =>
      mp.track('Location dashboard', {
        timezone,
        period,
        locationId,
        locationName: resolveLocationNameById(locationId),
      })
    );
  }, []);
  useSettingsChangeActionDispatcher(location, history, userTimezone, mixpanelTrack, defaultLocationId);

  let columns = 1;
  if (isWidthUp('md', width)) {
    columns = 2;
  }
  if (isWidthUp('lg', width)) {
    columns = 3;
  }

  return (
    <Grid item className={classes.grow}>
      <div className={classes.miniWidgetsContainer}>
        <TotalCustomersMiniWidget defaultLocationId={defaultLocationId} />
        {(locationCompanyId === EW_COMPANY_ID || locationWhitelabelerId === EW_COMPANY_ID) && (
          <>
            <MemberVisitsMiniWidget defaultLocationId={defaultLocationId} />
            {superAdmin && <MemberFraudMiniWidget defaultLocationId={defaultLocationId} />}
          </>
        )}
        <FirstTimeMiniWidget defaultLocationId={defaultLocationId} />
        <RewashesMiniWidget defaultLocationId={defaultLocationId} />
        <CamerasUptimeMiniWidget defaultLocationId={defaultLocationId} />
        <WeatherMiniWidget defaultLocationId={defaultLocationId} />
      </div>
      <MasonryLayout columns={columns} gap={32}>
        {widgets
          .filter((widget) => !dashboardDisabledWidgets.includes(widget.id))
          .map((widget, index) => {
            const Component = widget.component;
            return <Component key={index} defaultLocationId={defaultLocationId} />;
          })
          .concat([
            <AiSummaryWidget
              key="aiSummary"
              history={history}
              location={location}
              userTimezone={userTimezone}
              defaultLocationId={defaultLocationId}
            />,
          ])}
      </MasonryLayout>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    defaultLocationId: getDefaultLocationId(state),
    availableLocations: getLocations(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(withWidth()(toJS(LocationDashboard)))));

import { createReportSettings, reports, widgetReport } from 'api/report';
import { actions, types } from 'reducers/dashboard/location/returningCustomers';
import { call, put, takeLatest } from 'redux-saga/effects';

function* fetchReport({ requestDate, timezone, period, location }) {
  try {
    const response = yield call(
      widgetReport,
      reports.VEHICLE_FIRST_TIME_VS_RETURN_WIDGET,
      createReportSettings(requestDate, timezone, period, null, location)
    );

    yield put(actions.reportSuccess(requestDate, timezone, period, response.data));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, e));
  }
}

export default function* returningCustomers() {
  yield takeLatest(types.REPORT_REQUEST, fetchReport);
}

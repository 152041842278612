import COLORS from 'components/style/colors';
import { fromJS } from 'immutable';
import { capitalize, sortBy } from 'lodash';
import moment from 'moment';

export const modes = {
  BY_HOUR: 'HOUR',
  BY_DAY_OF_WEEK: 'DOW',
  BY_VOLUME: 'VOLUME',
};

export const daysOfWeek = moment().locale('en').localeData().weekdays(true);

export const types = {
  REPORT_REQUEST: 'OPS/LOADER_EFFICIENCY/REPORT_REQUEST',
  REPORT_SUCCESS: 'OPS/LOADER_EFFICIENCY/REPORT_SUCCESS',
  REPORT_FAILURE: 'OPS/LOADER_EFFICIENCY/REPORT_FAILURE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report, mode) {
  const rows = report && report.rows;
  if (!rows || !rows.length) {
    return null;
  }

  let sortedRows = rows;
  if (mode === modes.BY_DAY_OF_WEEK) {
    sortedRows = sortBy(rows, ({ row }) => {
      const { dayOfWeek } = row;
      return daysOfWeek.indexOf(capitalize(dayOfWeek));
    });
  } else if (mode === modes.BY_VOLUME) {
    sortedRows = sortBy(rows, ({ row }) => {
      const { range } = row;
      return parseInt(range);
    });
  }
  const result = {
    queue: { id: 'queue', color: COLORS.DARK_YELLOW, name: 'Queue', value: 0, data: [] },
    noQueue: { id: 'noQueue', color: COLORS.GREEN, name: 'No queue', value: 0, data: [] },
  };
  sortedRows.forEach((row) => {
    result.queue.data.push([...(mode === modes.BY_VOLUME ? [row.row.range] : []), row.row.queue, row.row.queueCount]);
    result.queue.value += row.row.queue;

    result.noQueue.data.push([
      ...(mode === modes.BY_VOLUME ? [row.row.range] : []),
      row.row.noQueue,
      row.row.noQueueCount,
    ]);
    result.noQueue.value += row.row.noQueue;
  });
  return result;
}

export default function loaderEfficiency(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report, mode } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report, mode)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, mode, location) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    mode,
    location,
  }),
  reportSuccess: (requestDate, timezone, period, mode, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    mode,
    report,
  }),
  reportFailure: (requestDate, timezone, period, mode, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    mode,
    error,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'ops', 'loaderEfficiency']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');

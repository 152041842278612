import {
  amber,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
} from '@material-ui/core/colors';

const COLORS = Object.freeze({
  BLUE: '#3c6fff',
  LIGHT_BLUE: '#65c7f6',
  GREEN: '#33c982',
  DARK_YELLOW: '#fcb700',
  DARK_GREY: '#6a798e',
  LIGHT_GREY: '#b3bbc6',
});

const TONE = 500;

export const RACE_COLORS = Object.freeze([
  COLORS.GREEN,
  COLORS.LIGHT_BLUE,
  teal[TONE],
  purple[TONE],
  deepPurple[TONE],
  cyan[TONE],
  lime[TONE],
  orange[TONE],
]);

export const CHARTS_PALETTE = Object.freeze([
  COLORS.BLUE,
  COLORS.GREEN,
  COLORS.DARK_YELLOW,
  COLORS.LIGHT_BLUE,
  COLORS.DARK_GREY,
  COLORS.LIGHT_GREY,
  lime[TONE],
  indigo[TONE],
  blueGrey[TONE],
  orange[TONE],
  brown[TONE],
  teal[TONE],
  purple[TONE],
  pink[TONE],
  deepPurple[TONE],
  cyan[TONE],
  deepOrange[TONE],
]);

export function getPaletteColorByIndex(index) {
  return CHARTS_PALETTE[index % CHARTS_PALETTE.length];
}

export const TOTAL_CUSTOMERS_COLORS = Object.freeze([
  COLORS.BLUE,
  COLORS.GREEN,
  COLORS.DARK_YELLOW,
  COLORS.LIGHT_BLUE,
  COLORS.DARK_GREY,
  COLORS.LIGHT_GREY,
]);

export const VEHICLE_MAKES_COLORS = Object.freeze([
  COLORS.DARK_YELLOW,
  COLORS.GREEN,
  COLORS.BLUE,
  COLORS.LIGHT_BLUE,
  COLORS.DARK_GREY,
]);

export const MEMBER_VALIDATION_PLATE_COLORS = Object.freeze([
  COLORS.DARK_GREY,
  COLORS.LIGHT_GREY,
  COLORS.DARK_YELLOW,
  COLORS.LIGHT_BLUE,
  COLORS.BLUE,
]);

export const SUCCESS_COLOR = green[600];
export const WARN_COLOR = amber[700];
export const ERROR_COLOR = red[700];

export default COLORS;

import { Grid, withStyles, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import MasonryLayout from 'components/common/MasonryLayout';
import mixpanel from 'components/common/mixpanel';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import { resolveLocationNameById } from 'components/common/utils';
import DashboardControls, { opsDashboardMode } from 'components/dashboard/common/DashboardControls';
import MiniWidget from 'components/dashboard/common/MiniWidget';
import CamerasUptimeMiniWidget from 'components/dashboard/location/CamerasUptimeMiniWidget';
import FirstTimeMiniWidget from 'components/dashboard/location/FirstTimeMiniWidget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import WeatherMiniWidget from 'components/dashboard/location/WeatherMiniWidget';
import LoaderEfficiencyWidget from 'components/dashboard/ops/LoaderEfficiencyWidget';
import VehicleBodyTypesWidget from 'components/dashboard/ops/VehicleBodyTypesWidget';
import VisitDurationBreakdownWidget from 'components/dashboard/ops/VisitDurationBreakdownWidget';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getDefaultLocationIdWithOps,
  getLocationsWithOps,
  getOpsDashboardConfig,
  getUserTimezone,
  isSuperAdmin,
} from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/ops/summary';
import ProductivityWidget from 'components/dashboard/ops/ProductivityWidget';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  miniWidgetsContainer: {
    marginBottom: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
    gridGap: theme.spacing(4),
  },
});

function OpsDashboard({
  classes,
  width,
  location,
  history,
  userTimezone,
  requestDate,
  reportRequest,
  isFetching,
  report,
  defaultLocationId,
  opsDashboardConfig,
  isSuperAdmin,
}) {
  const onSettingsChange = useCallback(
    (requestDate, timezone, period, locationId) => {
      mixpanel((mp) =>
        mp.track('Operations dashboard', {
          timezone,
          period,
          locationId,
          locationName: resolveLocationNameById(locationId),
        })
      );
      reportRequest(requestDate, timezone, period, locationId);
    },
    [reportRequest]
  );
  useSettingsChangeActionDispatcher(location, history, userTimezone, onSettingsChange, defaultLocationId);

  if (!requestDate) {
    return null;
  }

  let columns = 1;
  if (isWidthUp('md', width)) {
    columns = 2;
  }
  if (isWidthUp('lg', width)) {
    columns = 3;
  }

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Operations insights" />
      <Grid item>
        <DashboardControls mode={opsDashboardMode} />
      </Grid>
      <Grid item className={classes.grow}>
        <div className={classes.miniWidgetsContainer}>
          <MiniWidget isFetching={isFetching} title="Total" value={report?.summary?.total} />
          <FirstTimeMiniWidget defaultLocationId={defaultLocationId} />
          <MiniWidget
            isFetching={isFetching}
            title="Rewash ratio"
            value={report?.summary?.rewashRatio}
            valueHelperText="%"
          />
          <CamerasUptimeMiniWidget defaultLocationId={defaultLocationId} />
          <WeatherMiniWidget defaultLocationId={defaultLocationId} />
        </div>
        <MasonryLayout columns={columns} gap={32}>
          <VisitDurationBreakdownWidget defaultLocationId={defaultLocationId} />
          <VehicleBodyTypesWidget defaultLocationId={defaultLocationId} />
          <LoaderEfficiencyWidget defaultLocationId={defaultLocationId} />
          {isSuperAdmin && (
            <ProductivityWidget
              location={location}
              history={history}
              userTimezone={userTimezone}
              defaultLocationId={defaultLocationId}
              opsDashboardConfig={opsDashboardConfig}
            />
          )}
        </MasonryLayout>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    report: getReport(state),
    requestDate: getRequestDate(state),
    defaultLocationId: getDefaultLocationIdWithOps(state),
    availableLocations: getLocationsWithOps(state),
    userTimezone: getUserTimezone(state),
    opsDashboardConfig: getOpsDashboardConfig(state),
    isSuperAdmin: isSuperAdmin(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(
    withStyles(styles)(withWidth()(toJS(OpsDashboard)))
  )
);

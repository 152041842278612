import { CssBaseline } from '@material-ui/core';
import Activation from 'components/activation/Activation';
import mixpanel from 'components/common/mixpanel';
import ScrollToTop from 'components/common/ScrollToTop';
import { isWhiteLabel } from 'components/common/whitelabel';
import RequestDemo from 'components/demo/RequestDemo';
import Login from 'components/login/Login';
import Main from 'components/Main';
import SnackbarNotifications from 'components/snackbar/SnackbarNotification';
import SnackbarOfflineStatus from 'components/snackbar/SnackbarOfflineStatus';
import ForgotPassword from 'components/user/ForgotPassword';
import ResetPassword from 'components/user/ResetPassword';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { isAuthenticated } from 'reducers/auth';
import { useDidMount } from 'react-hooks-lib';
import useQueryParam from 'components/common/useQueryParam';
import store from 'reducers';
import { actions } from 'reducers/auth';

function AppContent({ location, history, authenticated }) {
  const [autoAuthEmail] = useQueryParam('autoAuthEmail', (v) => v ?? '', location, history);
  const [autoAuthPassword] = useQueryParam('autoAuthPassword', (v) => v ?? '', location, history);
  useDidMount(() => {
    if (autoAuthEmail?.length > 0 && autoAuthPassword?.length > 0) {
      store.dispatch(actions.loginRequest(autoAuthEmail, autoAuthPassword, false));
    }
  });
  useEffect(() => {
    if (authenticated) {
      mixpanel((mp) =>
        mp.track('Pageview', {
          host: window.location.hostname,
          url: `${location.pathname}${location.search}${location.hash}`,
          title: document.title,
        })
      );
    }
  }, [authenticated, location]);

  return (
    <>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/activate" component={Activation} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        {!isWhiteLabel && <Route exact path="/sign-up" component={RequestDemo} />}

        {authenticated ? <Route component={Main} /> : <Route component={Login} />}
      </Switch>
      <SnackbarNotifications />
      <SnackbarOfflineStatus />
    </>
  );
}

function mapStateToProps(state) {
  return {
    authenticated: isAuthenticated(state),
  };
}

const AppContentContainer = withRouter(connect(mapStateToProps, null)(AppContent));

function App() {
  return (
    <Router>
      <ScrollToTop>
        <CssBaseline />
        <AppContentContainer />
      </ScrollToTop>
    </Router>
  );
}

export default App;

import React, { useCallback, useState } from 'react';
import { find, round, sortBy, uniq } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { COMPANY_DASHBOARD_CONFIG, getUserTimezone } from 'reducers/auth';
import { params, reports, widgetReport } from 'api/report';
import { fromPromise } from 'mobx-utils';
import { observer } from 'mobx-react';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import toJS from 'components/common/toJS';
import moment from 'moment-timezone';
import { resolveLocationNameById } from 'components/common/utils';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import LocationIcon from '@material-ui/icons/LocationOn';
import Widget from 'components/dashboard/common/Widget';
import { colors } from 'reducers/dashboard/company/summary';

const locations = uniq(
  COMPANY_DASHBOARD_CONFIG.flatMap(({ locations }) => locations)
    .flat()
    .map(({ id }) => id)
);

class State {
  reportResult = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadData(requestDate, timezone, _) {
    const requests = [
      widgetReport(reports.MEMBERSHIP_CONVERSION_RATE_BY_LOCATIONS, {
        [params.START_DATE]: moment.tz(requestDate, timezone).subtract(2, 'hours').utc(),
        [params.END_DATE]: moment.tz(requestDate, timezone).subtract(1, 'hour').utc(),
        [params.TIMEZONE]: timezone,
        [params.LOCATION_IDS]: locations,
      }),
    ];
    this.reportResult = fromPromise(Promise.all(requests));
  }

  get loading() {
    return this.reportResult === null || this.reportResult.state === 'pending';
  }

  get data() {
    if (this.reportResult === null || this.reportResult.state !== 'fulfilled') {
      return null;
    }
    const [currentMonthResponse] = this.reportResult.value;
    const rows = sortBy(
      currentMonthResponse.data.rows.map((row) => ({
        ...row.row,
        conversionRate: round(row.row.conversionRate, 1),
        color: colors[row.row.locationId],
        locationName: resolveLocationNameById(row.row.locationId),
      })),
      'nonMemberVisits'
    ).reverse();
    locations
      .filter((locationId) => !find(rows, { locationId }))
      .forEach((locationId) => {
        rows.push({ locationId, color: colors[locationId], locationName: resolveLocationNameById(locationId) });
      });
    return rows;
  }
}

function NonMembersLeaderboard({ location, history, userTimezone }) {
  const [state] = useState(() => new State());
  const onSettingsChange = useCallback(
    (requestDate, timezone, period) => {
      state.loadData(requestDate, timezone, period);
    },
    [state]
  );
  useSettingsChangeActionDispatcher(location, history, userTimezone, onSettingsChange);

  return (
    <Widget title="Last hour at bats">
      {state.loading || !state.data ? (
        <ValueOverTimeChart isFetching={state.loading} type="bar" stack="one" categories={null} series={null} />
      ) : (
        <div>
          <List>
            {state.data.map(({ locationId, locationName, nonMemberVisits, color }) => (
              <ListItem key={locationId}>
                <ListItemAvatar>
                  <Avatar style={{ borderRadius: '15%', backgroundColor: color }}>
                    <LocationIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={locationName} />
                <ListItemSecondaryAction>
                  <Typography variant="body1" component="div">
                    {nonMemberVisits ?? '—'}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(connect(mapStateToProps)(toJS(observer(NonMembersLeaderboard))));

import { createReportSettings, params, reports, widgetReport } from 'api/report';
import mixpanel from 'components/common/mixpanel';
import { MEMBER_VALIDATION_MODE_INVALID, resolveLocationNameById } from 'components/common/utils';
import { actions, types } from 'reducers/reports/memberValidation';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* fetchOverallReport({ requestDate, timezone, period, location, mode }) {
  try {
    const [mvResponse, dataSyncReport] = yield all([
      call(
        widgetReport,
        mode === MEMBER_VALIDATION_MODE_INVALID ? reports.MEMBER_VALIDATION_FRAUD_REPORT : reports.GREEN_FRAUD_REPORT,
        createReportSettings(requestDate, timezone, period, null, location)
      ),
      call(
        widgetReport,
        reports.DATA_SYNC_FRAUD_REPORT,
        createReportSettings(requestDate, timezone, period, null, location)
      ),
    ]);

    yield put(
      actions.reportSuccess(requestDate, timezone, period, location, mode, mvResponse.data, dataSyncReport.data)
    );

    mixpanel((mp) =>
      mp.track('Member validation', {
        timezone,
        period,
        locationId: location,
        locationName: resolveLocationNameById(location),
        mode,
      })
    );
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, location, mode, e));
  }
}

function* fetchDetailsReport({ requestDate, timezone, period, location, memberId }) {
  try {
    const response = yield call(widgetReport, reports.DETAILED_FRAUD_TABLE, {
      ...createReportSettings(requestDate, timezone, period, null, location),
      [params.MEMBER_ID]: memberId,
    });

    yield put(actions.detailsSuccess(requestDate, timezone, period, location, memberId, response.data));

    mixpanel((mp) =>
      mp.track('Member validation | Details', {
        timezone,
        period,
        locationId: location,
        locationName: resolveLocationNameById(location),
        memberId,
      })
    );
  } catch (e) {
    yield put(actions.detailsFailure(requestDate, timezone, period, location, memberId, e));
  }
}

export default function* memberValidation() {
  yield all([
    takeLatest(types.REPORT_REQUEST, fetchOverallReport),
    takeLatest(types.DETAILS_REQUEST, fetchDetailsReport),
  ]);
}

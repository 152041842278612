import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam, { clearQueryParams } from 'components/common/useQueryParam';
import { carMakeName } from 'components/dashboard/location/VehicleMakesWidget';
import { PureLocationPicker } from 'components/pickers/LocationPicker';
import TagPicker from 'components/pickers/TagPicker';
import COLORS, { ERROR_COLOR, SUCCESS_COLOR, WARN_COLOR } from 'components/style/colors';
import { cloneDeep, get, upperFirst } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDidUpdate, useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getDefaultLocationIdWithActiveVisits,
  getLocationIdsWithActiveVisits,
  getLocationsWithActiveVisits,
  getUserTimezone,
  RT_COMPANY_ID,
} from 'reducers/auth';
import { actions, getReport, isReportFetching, isReportInitial } from 'reducers/reports/visitsDuration';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  inputRoot: {
    minHeight: 40,
    backgroundColor: 'white',
    paddingLeft: 12,
    borderRadius: 4,
    borderBottom: 'unset !important',
    '&::before': {
      borderBottom: 'unset !important',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  total: {
    width: '100%',
  },
  tablePaper: {
    width: '100%',
    overflowX: 'auto',
    minHeight: '100%',
    alignSelf: 'flex-start',
  },
  table: {
    minWidth: 600,
  },
  controlsContainer: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

function getDurationColor(duration) {
  const minutes = duration.asMinutes();
  if (minutes < 18) {
    return SUCCESS_COLOR;
  } else if (minutes > 18 && minutes < 20) {
    return WARN_COLOR;
  } else {
    return ERROR_COLOR;
  }
}

const defaultTags = [];

function VisitsDuration({
  location,
  history,
  userTimezone,
  defaultLocationId,
  report,
  isFetching,
  isInitial,
  reportRequest,
  onUnmount,
  availableLocations,
  availableIds,
}) {
  const classes = useStyles();
  const [currentTime, setCurrentTime] = useState(null);
  const [locationId, setLocationId] = useQueryParam('location', (v) => v || defaultLocationId, location, history);
  const locationCompanyId = get(availableLocations, `${locationId}.company.id`);
  let [enterTags, setEnterTags] = useQueryParam('enter', (v) => v || defaultTags, location, history);
  let [exitTags, setExitTags] = useQueryParam('exit', (v) => v || defaultTags, location, history);
  const timezone = userTimezone || moment.tz.guess();
  useWillUnmount(onUnmount);
  useDidUpdate(() => {
    enterTags = defaultTags;
    exitTags = defaultTags;
    clearQueryParams(['enter', 'exit'], location, history);
  }, [locationId]);
  useEffect(() => {
    localStorage.setItem('lastUsedLocationId', locationId);
  }, [locationId]);
  const enterTagsJson = JSON.stringify(enterTags);
  const exitTagsJson = JSON.stringify(exitTags);
  useEffect(() => {
    const enterTagsParsed = JSON.parse(enterTagsJson);
    const exitTagsParsed = JSON.parse(exitTagsJson);
    if (locationId && enterTagsParsed.length && exitTagsParsed.length) {
      reportRequest(moment.tz(timezone).format(), timezone, locationId, enterTagsParsed, exitTagsParsed);
    }
  }, [location, locationId, timezone, enterTagsJson, exitTagsJson, reportRequest]);
  useEffect(() => {
    let id = null;
    if (report) {
      id = setInterval(() => setCurrentTime(Date.now()), 1000);
    }
    return () => id && clearInterval(id);
  }, [report]);
  const showInitialBlock = (isInitial || !enterTags.length || !exitTags.length) && !isFetching;
  const preparedReport =
    report &&
    cloneDeep(report).filter((item) => {
      const enterMoment = moment.tz(item.enterTime, 'UTC');
      const offset = moment.tz.zone(timezone).utcOffset(moment.tz(item.enterTime, 'UTC').valueOf());
      const exitMoment = item.exitTime
        ? moment.tz(item.exitTime, 'UTC')
        : moment.tz(currentTime || Date.now(), 'UTC').subtract(offset, 'minutes');
      const duration = moment.duration(exitMoment.diff(enterMoment));

      item.enterMoment = enterMoment;
      item.duration = duration;

      const durationMinutes = duration.asMinutes();
      if (locationId === 'd76f798e-4653-4240-bb11-ec1981f1f228') {
        return durationMinutes < 60;
      } else {
        return (locationCompanyId !== RT_COMPANY_ID || durationMinutes > 12) && durationMinutes < 60;
      }
    });

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Active visits" />
      <Grid item container justify="flex-end" alignItems="flex-end">
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-end"
          wrap="nowrap"
          className={classes.controlsContainer}
        >
          <PureLocationPicker
            availableLocations={availableLocations}
            availableIds={availableIds}
            value={locationId}
            onChange={(locId) => setLocationId(locId)}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
          <TagPicker
            label="Enter tags"
            onChange={(tags) => setEnterTags(tags)}
            value={enterTags}
            locationId={locationId}
            color={COLORS.DARK_YELLOW}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
          <TagPicker
            label="Exit tags"
            onChange={(tags) => setExitTags(tags)}
            value={exitTags}
            locationId={locationId}
            color={COLORS.GREEN}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.content}>
        {!isFetching && report && preparedReport.length && enterTags.length && exitTags.length ? (
          <>
            <Typography
              display="block"
              className={classes.total}
              color="textSecondary"
              variant="subtitle1"
              align="left"
            >
              Total: {preparedReport.length}
            </Typography>
            <Paper className={classNames(classes.tablePaper, classes.grow)}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">License plate</TableCell>
                    <TableCell align="left">Duration</TableCell>
                    <TableCell align="left" sortDirection="asc">
                      <TableSortLabel disabled active direction="asc">
                        Entry time
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">Make</TableCell>
                    <TableCell align="left">Color</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {preparedReport.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {item.licensePlate}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ color: item.exitTime ? undefined : getDurationColor(item.duration) }}
                        >
                          {moment.utc(item.duration.asMilliseconds()).format('HH:mm:ss')}
                        </TableCell>
                        <TableCell align="left">{item.enterMoment.format('MM/DD/YYYY HH:mm')}</TableCell>
                        <TableCell align="left">{carMakeName(item.make)}</TableCell>
                        <TableCell align="left">{upperFirst(item.color)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </>
        ) : null}
        {showInitialBlock && (
          <Typography variant="h5" component="span" align="center">
            To determine what point you want to measure the duration
            <br />
            please select enter and exit tags
          </Typography>
        )}
        {isFetching && <BrandedLoadingIndicator />}
        {!isFetching && !isInitial && (!report || !preparedReport.length) && enterTags.length && exitTags.length && (
          <Typography variant="h5" component="span" align="center">
            No active visits found for these tags
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
    defaultLocationId: getDefaultLocationIdWithActiveVisits(state),
    report: getReport(state),
    isFetching: isReportFetching(state),
    isInitial: isReportInitial(state),
    availableLocations: getLocationsWithActiveVisits(state),
    availableIds: getLocationIdsWithActiveVisits(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest, onUnmount: actions.reset })(toJS(VisitsDuration))
);

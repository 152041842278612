import { Hidden, withStyles } from '@material-ui/core';
import EntityDeleter from 'components/common/EntityDeleter';
import CameraDashboard from 'components/dashboard/camera/CameraDashboard';
import CompanyDashboard from 'components/dashboard/company/CompanyDashboard';
import DriveoffDashboard from 'components/dashboard/driveoff/DriveoffDashboard';
import OpsDashboard from 'components/dashboard/ops/OpsDashboard';
import SalesDashboard from 'components/dashboard/sales/SalesDashboard';
import Footer from 'components/footer/Footer';
import Insights from 'components/insights/Insights';
import LocationEditor from 'components/location/LocationEditor';
import LocationList from 'components/location/LocationList';
import PrimaryNavigation from 'components/menu/PrimaryNavigation';
import NotFound from 'components/pages/NotFound';
import AverageVisitsDuration from 'components/reports/AverageVisitsDuration';
import Fraud from 'components/reports/Fraud';
import LicensePlateSearch from 'components/reports/LicensePlateSearch';
import MemberValidation from 'components/reports/MemberValidation';
import Reports from 'components/reports/Reports';
import Rewashes from 'components/reports/Rewashes';
import VisitsDuration from 'components/reports/VisitsDuration';
import StreamList from 'components/stream/StreamList';
import UserEditor from 'components/user/UserEditor';
import UserList from 'components/user/UserList';
import UserProfile from 'components/user/UserProfile';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import {
  isActiveVisitsAvailable,
  isCompaniesAvailable,
  isCompanyDashboardAvailable,
  isDriveoffDashboardAvailable,
  isDriveoffDashboardOnly,
  isFraudDebugReportAvailable,
  isFraudReportAvailable,
  isInsightsAvailable,
  isLocationIntelligenceAvailable,
  isLocationIntelligenceOnly,
  isLocationsAvailable,
  isOilAndLocationIntelligenceOnly,
  isOilChangersLocationIntelligenceAvailable,
  isOpsDashboardAvailable,
  isRewashesAvailable,
  isUsersAvailable,
  isVisitDurationAvailable,
} from 'reducers/auth';
import LocationIntelligence from 'components/dashboard/location-intelligence/LocationIntelligence';
import OilChangersLocationIntelligence from 'components/dashboard/location-intelligence/OilChangersLocationIntelligence';
import IframeSalesLeaderboard from 'components/dashboard/company/IframeSalesLeaderboard';
import CompanyList from 'components/company/company-list/CompanyList';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 0,
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
    '@media print': {
      padding: theme.spacing(2, 2),
    },
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    overflowX: 'hidden',
    backgroundColor: '#f1f2f7',
  },
});

function Main({ classes }) {
  const insightsAvailable = useSelector(isInsightsAvailable);
  const activeVisitsAvailable = useSelector(isActiveVisitsAvailable);
  const visitDurationAvailable = useSelector(isVisitDurationAvailable);
  const rewashesAvailable = useSelector(isRewashesAvailable);
  const usersAvailable = useSelector(isUsersAvailable);
  const companiesAvailable = useSelector(isCompaniesAvailable);
  const locationsAvailable = useSelector(isLocationsAvailable);
  const fraudReportAvailable = useSelector(isFraudReportAvailable);
  const fraudDebugReportAvailable = useSelector(isFraudDebugReportAvailable);
  const opsDashboardAvailable = useSelector(isOpsDashboardAvailable);
  const companyDashboardAvailable = useSelector(isCompanyDashboardAvailable);
  const locationIntelligenceAvailable = useSelector(isLocationIntelligenceAvailable);
  const oilChangersLocationIntelligenceAvailable = useSelector(isOilChangersLocationIntelligenceAvailable);
  const driveoffDashboardAvailable = useSelector(isDriveoffDashboardAvailable);
  const driveoffDashboardOnly = useSelector(isDriveoffDashboardOnly);
  const locationIntelligenceOnly = useSelector(isLocationIntelligenceOnly);
  const oilAndLocationIntelligenceOnly = useSelector(isOilAndLocationIntelligenceOnly);

  return (
    <div className={classes.root}>
      <PrimaryNavigation />
      <main className={classes.main}>
        <Hidden smUp implementation="css">
          <div className={classes.toolbar} />
        </Hidden>
        <div className={classes.content}>
          <Switch>
            {driveoffDashboardAvailable && <Route exact path="/dashboard/driveoff" component={DriveoffDashboard} />}

            {driveoffDashboardOnly && driveoffDashboardAvailable ? (
              <Route exact path="/" render={() => <Redirect to="/dashboard/driveoff" />} />
            ) : oilAndLocationIntelligenceOnly || (locationIntelligenceOnly && locationIntelligenceAvailable) ? (
              <Route exact path="/" render={() => <Redirect to="/locationintelligence" />} />
            ) : (
              <Route exact path="/" render={() => <Redirect to="/dashboard/sales" />} />
            )}

            {!locationIntelligenceOnly && !driveoffDashboardOnly && insightsAvailable && (
              <Route exact path="/insights" component={Insights} />
            )}
            {((!driveoffDashboardOnly && locationIntelligenceAvailable) || oilAndLocationIntelligenceOnly) && (
              <Route exact path="/locationintelligence" component={LocationIntelligence} />
            )}
            {((!driveoffDashboardOnly && !locationIntelligenceOnly && oilChangersLocationIntelligenceAvailable) ||
              oilAndLocationIntelligenceOnly) && (
              <Route exact path="/oclocationintelligence" component={OilChangersLocationIntelligence} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && (
              <Route exact path="/dashboard/sales" component={SalesDashboard} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && opsDashboardAvailable && (
              <Route exact path="/dashboard/ops" component={OpsDashboard} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && companyDashboardAvailable && (
              <Route exact path="/dashboard/company" component={CompanyDashboard} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && companyDashboardAvailable && (
              <Route exact path="/dashboard/iframesalesleaderboard" component={IframeSalesLeaderboard} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && (
              <Route exact path="/dashboard/camera" component={CameraDashboard} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && (
              <Route exact path="/reports/customersbytags" component={Reports} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && (
              <Route exact path="/reports/lpsearch" component={LicensePlateSearch} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && activeVisitsAvailable && (
              <Route exact path="/reports/activevisits" component={VisitsDuration} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && visitDurationAvailable && (
              <Route exact path="/reports/visitsduration" component={AverageVisitsDuration} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && rewashesAvailable && (
              <Route exact path="/reports/rewashes" component={Rewashes} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && fraudReportAvailable && (
              <Route exact path="/reports/fraud" component={MemberValidation} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && fraudDebugReportAvailable && (
              <Route exact path="/reports/frauddebug" component={Fraud} />
            )}
            {!locationIntelligenceOnly && !driveoffDashboardOnly && locationsAvailable && (
              <Route exact path="/locations" component={LocationList} />
            )}
            {!driveoffDashboardOnly && usersAvailable && <Route exact path="/users" component={UserList} />}
            {companiesAvailable && <Route exact path="/companies" component={CompanyList} />}

            <Route exact path="/streams" component={StreamList} />
            <Route exact path="/profile" component={UserProfile} />
            <Route component={NotFound} />
          </Switch>
        </div>
        <UserEditor />
        <LocationEditor />
        <EntityDeleter />
        <Footer />
      </main>
    </div>
  );
}

export default withRouter(withStyles(styles, { withTheme: true })(Main));

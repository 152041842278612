import averageVisitsDuration from 'reducers/reports/averageVisitsDuration';
import fraud from 'reducers/reports/fraud';
import licensePlateSearch from 'reducers/reports/licensePlateSearch';
import memberValidation from 'reducers/reports/memberValidation';
import rewashes from 'reducers/reports/rewashes';
import totalCustomers from 'reducers/reports/totalCustomers';
import visitsDuration from 'reducers/reports/visitsDuration';
import { combineReducers } from 'redux-immutable';

export default combineReducers({
  totalCustomers,
  licensePlateSearch,
  visitsDuration,
  averageVisitsDuration,
  rewashes,
  fraud,
  memberValidation,
});

import { makeStyles } from '@material-ui/core';
import PinIcon from '@material-ui/icons/LocationOn';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'reducers';
import { actions, getIsReady } from 'reducers/map';

const pinIconSize = 32;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 0,
    paddingTop: '75%',
    position: 'relative',
  },
  pinIcon: {
    position: 'absolute',
    userSelect: 'none',
    top: `calc(50% - ${pinIconSize}px)`,
    left: `calc(50% - ${pinIconSize / 2}px)`,
    width: pinIconSize,
    height: pinIconSize,
    zIndex: 999999,
    color: '#EA4335',
  },
}));

function onBingMapReady() {
  store.dispatch(actions.setReady());
}
const callbackFunctionName = 'omnixBingMapCallback';
window[callbackFunctionName] = onBingMapReady;

const apiKey = 'AvAc6e3ZMdhSEu1dwV5Hz6kKirImzkjhRMYgFbFq8OUZcyV-Jd34RvSiAeFJLgd_';
const scriptSrc = `https://www.bing.com/api/maps/mapcontrol?callback=${callbackFunctionName}&key=${apiKey}`;

let scriptAppended = false;

function Map({ latitude, longitude }) {
  const classes = useStyles();
  const ref = useRef(null);
  const ready = useSelector(getIsReady);
  const [error, setError] = useState(false);
  const validCoords = typeof latitude === 'number' && typeof longitude === 'number';
  useEffect(() => {
    if (!scriptAppended) {
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      scriptAppended = true;
    }
  }, []);
  useEffect(() => {
    if (ready && validCoords) {
      try {
        new window.Microsoft.Maps.Map(ref.current, {
          center: new window.Microsoft.Maps.Location(latitude, longitude),
          mapTypeId: window.Microsoft.Maps.MapTypeId.road,
          zoom: 17,
          showLocateMeButton: false,
          showMapTypeSelector: false,
          disablePanning: true,
          enableClickableLogo: false,
          disableZooming: true,
        });
        setError(false);
      } catch (e) {
        setError(true);
      }
    }
  }, [ready, latitude, longitude, validCoords]);

  return validCoords ? (
    <div ref={ref} className={classes.root}>
      {ready && !error && <PinIcon className={classes.pinIcon} />}
    </div>
  ) : null;
}

export default Map;

import { InputAdornment, ListItemText, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import TimezoneIcon from '@material-ui/icons/AccessTime';
import timezones from 'components/pickers/timezones';
import moment from 'moment-timezone';
import React from 'react';

const styles = () => ({
  menuList: {
    paddingRight: '0 !important',
    width: '100% !important',
    maxWidth: '600px',
  },
  selectRoot: {
    width: 'calc(100% - 32px)',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

function formatTimezoneName(timezone) {
  const offset = `(GMT${moment.tz(timezone).format('Z')})`;
  return `${offset} ${timezone.replace(/_/g, ' ')}`;
}

function TimezonePicker({ classes, SelectProps, selectClasses, value, compact, ...rest }) {
  const autoTz = formatTimezoneName(moment.tz.guess());

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      select
      fullWidth
      label="Timezone"
      margin="none"
      value={value || ''}
      {...rest}
      SelectProps={{
        displayEmpty: true,
        classes: { root: classes.selectRoot, ...selectClasses },
        MenuProps: {
          MenuListProps: {
            className: classes.menuList,
          },
        },
        ...SelectProps,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <TimezoneIcon className={classes.icon} color="inherit" />
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value="" divider>
        <ListItemText
          primary={
            <Typography variant="inherit" noWrap>
              {compact ? `${autoTz} (Auto)` : 'Detect automatically'}
            </Typography>
          }
          secondary={
            !compact && (
              <Typography variant="body2" color="textSecondary" noWrap>
                {autoTz}
              </Typography>
            )
          }
        />
      </MenuItem>
      {timezones.map(({ label, tzCode }) => (
        <MenuItem key={tzCode} value={tzCode}>
          <Typography variant="inherit" noWrap>
            {label}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  );
}

export default withStyles(styles)(TimezonePicker);

import { createReportSettings, reports, widgetReport } from 'api/report';
import { actions, types } from 'reducers/dashboard/location/camerasUptimeMini';
import { call, put, takeLatest } from 'redux-saga/effects';
import { populateLocIdAndName } from 'sagas/dashboard/location/util';

function* fetchReport({ requestDate, timezone, period, location }) {
  try {
    const response = yield call(
      widgetReport,
      reports.CAMERA_STATUS_WIDGET,
      createReportSettings(requestDate, timezone, period, null, location)
    );

    const report = yield populateLocIdAndName(response.data, location);
    yield put(actions.reportSuccess(requestDate, timezone, period, report));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, e));
  }
}

export default function* camerasUptimeMini() {
  yield takeLatest(types.REPORT_REQUEST, fetchReport);
}

import { Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import React from 'react';

const styles = (theme) => ({
  root: {
    minHeight: 48,
  },
  label: {
    cursor: 'default',
    minHeight: 16,
    transform: 'scale(0.75)',
    transformOrigin: 'top left',
  },
  labelTypography: {
    lineHeight: 1,
    fontSize: '1rem',
  },
  value: {
    minHeight: 32,
  },
  valueTypography: {
    width: '100%',
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
});

function StaticTextField({ classes, label, helperText, error = false, value }) {
  const labelComponent = (
    <Typography
      variant="caption"
      color={error ? 'error' : 'textSecondary'}
      component="div"
      className={classes.labelTypography}
    >
      {label}
    </Typography>
  );

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.root}>
      <Grid item container alignItems="center" justify="flex-start" className={classes.label}>
        {helperText ? (
          <Tooltip placement="bottom-start" title={helperText}>
            <Grid container wrap="nowrap" alignItems="center">
              {labelComponent}
              <HelpIcon className={classes.helpIcon} />
            </Grid>
          </Tooltip>
        ) : (
          labelComponent
        )}
      </Grid>
      <Grid item container className={classes.value}>
        <Typography variant="body1" color="textPrimary" component="div" className={classes.valueTypography}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(StaticTextField);

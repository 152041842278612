import { Checkbox, Chip, ListItemText, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';

const styles = theme => ({
  menuList: {
    paddingRight: '0 !important',
    width: '100% !important',
    maxWidth: '600px',
  },
  selectRoot: {
    width: 'calc(100% - 32px)',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
});

function LocationsPicker({
  classes,
  className,
  SelectProps,
  selectClasses,
  color,
  onChange,
  value = [],
  availableLocations,
  ...rest
}) {
  function handleChange(event) {
    const val = get(event, 'target.value', []);
    onChange(val.includes('') ? [] : val);
  }

  return (
    <TextField
      onChange={handleChange}
      className={className}
      select
      fullWidth
      label="Locations"
      margin="none"
      value={value}
      {...rest}
      SelectProps={{
        displayEmpty: true,
        multiple: true,
        renderValue: selected => {
          if (!selected.length) {
            return '';
          }
          return (
            <div className={classes.chips}>
              {selected.map(locationId => {
                const name = get(availableLocations.byId, `${locationId}.name`);
                return <Chip key={name} label={name} className={classes.chip} />;
              })}
            </div>
          );
        },
        ...SelectProps,
      }}
    >
      {availableLocations.allIds.map(locationId => (
        <MenuItem key={locationId} value={locationId}>
          <Checkbox color="primary" checked={value.indexOf(locationId) > -1} />
          <ListItemText
            primary={
              <Typography variant="inherit" noWrap>
                {get(availableLocations.byId, `${locationId}.name`)}
              </Typography>
            }
          />
        </MenuItem>
      ))}
    </TextField>
  );
}

export default withStyles(styles)(LocationsPicker);

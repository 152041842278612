import axios from 'axios';

export const apiPath = '/apigw/v1/user';

export function getActivationStatus(email, token) {
  return axios.get(`/apigw/v1/user/activation/status?email=${email}&token=${token}`);
}

export function activate(email, token, password) {
  return axios.post('/apigw/v1/user/actions/activate/invoke', { email, token, password });
}

export function updateProfile(name, timezone) {
  return axios.post('/apigw/v1/user/@me', { name, timezone });
}

export function updatePassword(oldPassword, newPassword) {
  return axios.post('/apigw/v1/user/@me/password', { oldPassword, newPassword });
}

export function requestPasswordReset(email) {
  return axios.post('/apigw/v1/user/actions/requestPasswordReset/invoke', { email });
}

export function getPasswordResetStatus(email, token) {
  return axios.get(`/apigw/v1/user/passwordReset/status?email=${email}&token=${token}`);
}

export function passwordReset(email, token, password) {
  return axios.post('/apigw/v1/user/actions/passwordReset/invoke', { email, token, password });
}

export function signUp(params) {
  return axios.post('/apigw/v1/user/actions/signup/invoke', params);
}

export function searchUsers(searchParams) {
  return axios.post('/apigw/v1/user/search', searchParams);
}

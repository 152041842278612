import CameraIcon from '@material-ui/icons/Videocam';
import toJS from 'components/common/toJS';
import useSettingsChangeActionDispatcher from 'components/dashboard/camera/useSettingsChangeActionDispatcher';
import { composeTimeCategories, getTimeLabelFormatter } from 'components/dashboard/common/periods';
import { prepareLegendData } from 'components/dashboard/common/utils';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import WidgetHeaderTrend from 'components/dashboard/common/WidgetHeaderTrend';
import WidgetLegend from 'components/dashboard/common/WidgetLegend';
import { cloneDeep, meanBy, round } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/camera/camerasUptime';

function prepareReport(report) {
  if (!report) {
    return report;
  }
  const result = cloneDeep(report);
  result.forEach((s) => {
    s.name = 'Uptime';
  });
  return result;
}

function CamerasUptimeWidget({ location, history, isFetching, requestDate, userTimezone, report, reportRequest }) {
  const [period, timezone] = useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest);

  if (!requestDate) {
    return null;
  }

  const series =
    report &&
    report.map((s) => ({
      ...s,
      lineStyle: { width: 3 },
      smooth: true,
      showSymbol: false,
    }));
  const categories = composeTimeCategories(requestDate, timezone, period);
  const legendData = report && prepareLegendData(report);
  const total = report && `${round(meanBy(report, 'value'), 1)}%`;

  return (
    <Widget icon={CameraIcon} title="Cameras uptime">
      <>
        <WidgetHeaderTrend name="Avg. uptime" headerValue={total} value={0} period="last week" />
        <ValueOverTimeChart
          isFetching={isFetching}
          type="line"
          categories={categories}
          categoriesFormatter={getTimeLabelFormatter(requestDate, timezone, period)}
          series={prepareReport(series)}
        />
        <WidgetLegend data={legendData} icon={<CameraIcon />} />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(CamerasUptimeWidget))
);

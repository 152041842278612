import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  left: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  right: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function TableToolbar({ title, children, ...rest }) {
  const classes = useStyles();

  return (
    <div className={classes.root} {...rest}>
      <div className={classes.left}>
        <Typography color="textSecondary" variant="subtitle1">
          {title}
        </Typography>
      </div>
      <div className={classes.right}>{children}</div>
    </div>
  );
}

export default TableToolbar;

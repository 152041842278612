import WidgetChart from 'components/dashboard/common/WidgetChart';
import React from 'react';
import { observer } from 'mobx-react';
import { AutoSizer } from 'react-virtualized';
import COLORS from 'components/style/colors';
import ThreeValuesWidgetHeader from 'components/dashboard/common/ThreeValuesWidgetHeader';

function ProductivityScoreGauge({ state }) {
  const showContent = typeof state?.productivityScore == 'number';
  if (!showContent) {
    return null;
  }

  return (
    <>
      <ThreeValuesWidgetHeader
        left={{
          name: 'Wash score',
          value: `${state?.productivityScore} of 5`,
          color: COLORS.GREEN,
        }}
      />
      <div>
        <AutoSizer disableHeight style={{ minHeight: 270 }}>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = width / 1.78;
            const options = {
              series: [
                {
                  type: 'gauge',
                  center: [width / 2, '43%'],
                  startAngle: 180,
                  endAngle: 0,
                  min: 0,
                  max: 1,
                  splitNumber: 8,
                  axisLine: {
                    lineStyle: {
                      width: 50,
                      color: [
                        [0.2, '#e60002'],
                        [0.4, '#e47435'],
                        [0.6, '#f5c408'],
                        [0.8, '#95dd55'],
                        [1, '#0fc652'],
                      ],
                    },
                  },
                  pointer: {
                    itemStyle: {
                      color: 'auto',
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    show: false,
                  },
                  axisLabel: {
                    show: false,
                  },
                  title: {
                    show: false,
                  },
                  detail: {
                    show: false,
                  },
                  data: [
                    {
                      value: (state.productivityScore ?? 0) / 5,
                    },
                  ],
                },
              ],
            };

            return (
              <WidgetChart
                width={width}
                height={height}
                isFetching={false}
                options={options}
                style={{ width: '200%', height: '200%' }}
              />
            );
          }}
        </AutoSizer>
      </div>
    </>
  );
}

export default observer(ProductivityScoreGauge);

import { IconButton, ListItemIcon, makeStyles, Menu, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/MoreVert';
import { TYPE_LOCATION } from 'components/common/entity';
import { WARN_COLOR } from 'components/style/colors';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { actions as deleteActions } from 'reducers/entity/delete';
import { actions as editActions } from 'reducers/location/edit';

const useStyles = makeStyles(() => ({
  notesBadge: {
    backgroundColor: WARN_COLOR,
  },
  menuPaper: {
    minWidth: 200,
  },
  notesDialogPaper: {
    minWidth: 300,
  },
  notesDialogContentText: {
    overflow: 'auto',
    whiteSpace: 'pre-line',
  },
}));

function LocationActionMenu({ entity, onEdit, onDelete, superAdmin, ...rest }) {
  const classes = useStyles();
  const { id } = entity;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleEdit = useCallback(() => {
    setAnchorEl(null);
    onEdit(entity.id, entity.name);
  }, [entity, onEdit]);
  const handleDelete = useCallback(() => {
    setAnchorEl(null);
    onDelete(TYPE_LOCATION, entity);
  }, [entity, onDelete]);
  const open = Boolean(anchorEl);

  return (
    <div {...rest}>
      <IconButton
        aria-label="Actions"
        aria-owns={open ? `${id}-actions-menu` : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        disableAutoFocusItem
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id={`${id}-actions-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>
        {entity.authorizedOperations?.includes('DELETE') && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default connect(null, { onEdit: editActions.loadRequest, onDelete: deleteActions.openDialog })(
  LocationActionMenu
);

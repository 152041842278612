import COLORS from 'components/style/colors';
import { fromJS } from 'immutable';
import { get, round, sumBy } from 'lodash';

export const types = {
  REPORT_REQUEST: 'SALES/SUMMARY/REPORT_REQUEST',
  REPORT_SUCCESS: 'SALES/SUMMARY/REPORT_SUCCESS',
  REPORT_FAILURE: 'SALES/SUMMARY/REPORT_FAILURE',
  MEMBERS_DOWNLOAD_REQUEST: 'SALES/SUMMARY/MEMBERS_DOWNLOAD_REQUEST',
  MEMBERS_DOWNLOAD_COMPLETE: 'SALES/SUMMARY/MEMBERS_DOWNLOAD_COMPLETE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
  membersDownloadIsFetching: false,
});

function prepareReport(report) {
  const { totalReturningNonMembers, membershipSold } = report;
  if (get(totalReturningNonMembers, 'empty', true)) {
    return null;
  }
  const members = sumBy(totalReturningNonMembers.rows, 'row.memberVisits');
  const firstTime = sumBy(totalReturningNonMembers.rows, 'row.newCustomers');
  const returningNonMembers = sumBy(totalReturningNonMembers.rows, 'row.returningNonMembers');

  const summary = {
    total: firstTime + returningNonMembers + members,
    firstTime,
    returningNonMembers,
    members: members,
  };

  if (!get(membershipSold, 'empty', true)) {
    const membershipSoldCount = sumBy(membershipSold.rows, 'row.membershipSoldCount');
    summary.membershipSold = membershipSoldCount;
    const potential = summary.firstTime + summary.returningNonMembers;
    if (membershipSoldCount > 0 && potential > 0) {
      let conversionRate;
      if (membershipSoldCount > potential) {
        conversionRate = 100;
      } else {
        conversionRate = round(membershipSoldCount / (potential / 100), 1);
      }
      summary.conversionRate = conversionRate;
    }
  }

  const firstTimeReturningMembers = {
    firstTime: { id: 'firstTime', color: COLORS.LIGHT_BLUE, name: 'First time', value: 0, data: [] },
    returning: { id: 'returning', color: COLORS.DARK_YELLOW, name: 'Returning', value: 0, data: [] },
    members: { id: 'members', color: COLORS.GREEN, name: 'Members', value: 0, data: [] },
  };
  totalReturningNonMembers.rows.forEach((row) => {
    firstTimeReturningMembers.firstTime.data.push(row.row.newCustomers);
    firstTimeReturningMembers.firstTime.value += row.row.newCustomers;

    const returning = row.row.returningNonMembers;
    firstTimeReturningMembers.returning.data.push(returning);
    firstTimeReturningMembers.returning.value += returning;

    const members = row.row.memberVisits;
    firstTimeReturningMembers.members.data.push(members);
    firstTimeReturningMembers.members.value += members;
  });

  return { firstTimeReturningMembers, summary };
}

export default function summary(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    case types.MEMBERS_DOWNLOAD_REQUEST:
      return state.withMutations((s) => {
        s.set('membersDownloadIsFetching', true);
      });
    case types.MEMBERS_DOWNLOAD_COMPLETE:
      return state.withMutations((s) => {
        s.set('membersDownloadIsFetching', false);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
  }),
  reportSuccess: (requestDate, timezone, period, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    report,
  }),
  reportFailure: (requestDate, timezone, period, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    error,
  }),
  membersDownloadRequest: (requestDate, timezone, period, location) => ({
    type: types.MEMBERS_DOWNLOAD_REQUEST,
    requestDate,
    timezone,
    period,
    location,
  }),
  membersDownloadComplete: (requestDate, timezone, period, error) => ({
    type: types.MEMBERS_DOWNLOAD_COMPLETE,
    requestDate,
    timezone,
    period,
    error,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'sales', 'summary']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');
export const isMembersDownloadFetching = (state) => getRoot(state).get('membersDownloadIsFetching');

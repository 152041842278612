import { createReportSettings, params, reports, widgetReport } from 'api/report';
import mixpanel from 'components/common/mixpanel';
import { resolveLocationNameById } from 'components/common/utils';
import { actions, types } from 'reducers/reports/fraud';
import { call, put, takeLatest } from 'redux-saga/effects';

function* fetchAllReports({ requestDate, timezone, period, location, query }) {
  try {
    const settings = createReportSettings(requestDate, timezone, period, null, location);
    if (query.trim()) {
      settings[params.MEMBER_ID] = query.trim();
    }
    const response = yield call(widgetReport, reports.DETAILED_FRAUD_TABLE, settings);

    yield put(actions.reportSuccess(requestDate, timezone, period, location, query, response.data));

    mixpanel((mp) =>
      mp.track('Fraud debug', {
        timezone,
        period,
        locationId: location,
        locationName: resolveLocationNameById(location),
        query,
      })
    );
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, location, query, e));
  }
}

export default function* fraud() {
  yield takeLatest(types.REPORT_REQUEST, fetchAllReports);
}

import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import toJS from 'components/common/toJS';
import { getDescriptionByCode } from 'components/common/weather';
import { composeInsightsTimeCategories, getInsightsTimeLabelFormatter } from 'components/dashboard/common/periods';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import WeatherCodeIcon from 'components/icons/WeatherCodeIcon';
import React from 'react';
import { connect } from 'react-redux';
import { getReportTimezone, getRequestDate, getWeatherReport } from 'reducers/insights';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr',
      gridGap: theme.spacing(1),
      alignItems: 'center',
    },
  },
  temp: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

function InsightsWeatherWidget({ requestDate, timezone, period, report }) {
  const theme = useTheme();
  const isLargeWidth = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();

  const categories = report && composeInsightsTimeCategories(requestDate, timezone, period);
  return (
    <Widget title="Weather">
      <>
        {report && (
          <>
            <div className={classes.header}>
              <div className={classes.temp}>
                <WeatherCodeIcon code={report.total.code} size={92} />
                <div>
                  <Typography variant="h4" component="div" align="right">
                    {report.total.maxTemp}&#8457;
                  </Typography>
                  <Typography variant="h4" color="textSecondary" component="div" align="right">
                    {report.total.minTemp}&#8457;
                  </Typography>
                </div>
              </div>
              <Typography variant={isLargeWidth ? 'h4' : 'h6'} align={isLargeWidth ? 'right' : 'left'} component="div">
                {getDescriptionByCode(report.total.code)}
              </Typography>
            </div>
            <Typography variant="subtitle1" color="textSecondary" align="center">
              Temperature and precipitation
            </Typography>
          </>
        )}
        <ValueOverTimeChart
          optionsCustomizer={(options) => {
            options.tooltip.formatter = (params) =>
              `${params[0].axisValue} <br /> ${params
                .map((p) => `${p.marker} ${p.seriesName}: ${p.value}${p.seriesName === 'Temperature' ? '℉' : '%'}`)
                .join('<br />')}`;

            options.yAxis = [
              {
                type: 'value',
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  formatter: '{value}℉',
                  axisLabel: {
                    margin: 10,
                  },
                },
              },
              {
                min: 0,
                max: 100,
                axisLabel: {
                  formatter: '{value}%',
                  margin: 10,
                },
                type: 'value',
                axisTick: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                splitLine: {
                  show: true,
                },
              },
            ];
          }}
          isFetching={false}
          categories={categories}
          categoriesFormatter={getInsightsTimeLabelFormatter()}
          series={report && report.hourly}
        />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    requestDate: getRequestDate(state),
    report: getWeatherReport(state),
    timezone: getReportTimezone(state),
  };
}

export default connect(mapStateToProps, null)(toJS(InsightsWeatherWidget));

import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam, { clearQueryParams } from 'components/common/useQueryParam';
import DashboardControls, { averageVisitsDurationMode } from 'components/dashboard/common/DashboardControls';
import { parsePeriod } from 'components/dashboard/common/periods';
import { carMakeName } from 'components/dashboard/location/VehicleMakesWidget';
import AverageVisitDurationWidget from 'components/reports/AverageVisitDurationWidget';
import { ERROR_COLOR, SUCCESS_COLOR, WARN_COLOR } from 'components/style/colors';
import { upperFirst } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useDidUpdate, useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDefaultLocationIdWithVisitDuration, getUserTimezone } from 'reducers/auth';
import {
  actions,
  getReport,
  getRequestDate,
  isReportFetching,
  isReportInitial,
} from 'reducers/reports/averageVisitsDuration';
import MedianVisitDurationWidget from 'components/reports/MedianVisitDurationWidget';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  inputRoot: {
    backgroundColor: 'white',
    paddingLeft: 12,
    borderRadius: 4,
    borderBottom: 'unset !important',
    '&::before': {
      borderBottom: 'unset !important',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  averageContainer: {
    width: '100%',
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  total: {
    width: '100%',
  },
  tablePaper: {
    width: '100%',
    overflowX: 'auto',
    minHeight: 200,
    alignSelf: 'flex-start',
  },
  table: {
    minWidth: 600,
  },
  controlsContainer: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

function getDurationColor(duration) {
  const minutes = duration.asMinutes();
  if (minutes < 18) {
    return SUCCESS_COLOR;
  } else if (minutes > 18 && minutes < 20) {
    return WARN_COLOR;
  } else {
    return ERROR_COLOR;
  }
}

const defaultTags = [];

function VisitsDuration({
  location,
  history,
  requestDate,
  userTimezone,
  defaultLocationId,
  report,
  isFetching,
  isInitial,
  reportRequest,
  onUnmount,
}) {
  const classes = useStyles();
  const [locationId] = useQueryParam('location', (v) => v || defaultLocationId, location, history);
  let [enterTags] = useQueryParam('enter', (v) => v || defaultTags, location, history);
  let [exitTags] = useQueryParam('exit', (v) => v || defaultTags, location, history);
  const timezone = userTimezone || moment.tz.guess();
  const [period] = useQueryParam('period', (value) => parsePeriod(value, timezone), location, history);
  useWillUnmount(onUnmount);
  useDidUpdate(() => {
    enterTags = defaultTags;
    exitTags = defaultTags;
    clearQueryParams(['enter', 'exit'], location, history);
  }, [locationId]);
  useEffect(() => {
    localStorage.setItem('lastUsedLocationId', locationId);
  }, [locationId]);
  const enterTagsJson = JSON.stringify(enterTags);
  const exitTagsJson = JSON.stringify(exitTags);
  useEffect(() => {
    const enterTagsParsed = JSON.parse(enterTagsJson);
    const exitTagsParsed = JSON.parse(exitTagsJson);
    if (locationId && enterTagsParsed.length && exitTagsParsed.length) {
      reportRequest(moment.tz(timezone).format(), timezone, period, locationId, enterTagsParsed, exitTagsParsed);
    }
  }, [period, location, locationId, timezone, enterTagsJson, exitTagsJson, reportRequest]);
  const showInitialBlock = (isInitial || !enterTags.length || !exitTags.length) && !isFetching;

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Visit duration" />
      <Grid item>
        <DashboardControls mode={averageVisitsDurationMode} />
      </Grid>
      <Grid item className={classes.content}>
        {!isFetching && report && report.details && enterTags.length && exitTags.length ? (
          <>
            <div className={classes.averageContainer}>
              <MedianVisitDurationWidget
                requestDate={requestDate}
                timezone={timezone}
                period={period}
                report={report.details}
              />
              <AverageVisitDurationWidget
                requestDate={requestDate}
                timezone={timezone}
                period={period}
                report={report.average}
              />
            </div>
            <Typography
              display="block"
              className={classes.total}
              color="textSecondary"
              variant="subtitle1"
              align="left"
            >
              Total: {report.details.length}
            </Typography>
            <Paper className={classNames(classes.tablePaper, classes.grow)}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">License plate</TableCell>
                    <TableCell align="left">Duration</TableCell>
                    <TableCell align="left" sortDirection="desc">
                      <TableSortLabel disabled active direction="desc">
                        Entry time
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">Exit time</TableCell>
                    <TableCell align="left">Make</TableCell>
                    <TableCell align="left">Color</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.details.map((item, index) => {
                    const enterMoment = moment.tz(item.enterTime, 'UTC');
                    const offset = moment.tz.zone(timezone).utcOffset(moment.tz(item.enterTime, 'UTC').valueOf());
                    const exitMoment = item.exitTime
                      ? moment.tz(item.exitTime, 'UTC')
                      : moment.tz(Date.now(), 'UTC').subtract(offset, 'minutes');
                    const duration = moment.duration(exitMoment.diff(enterMoment));
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {item.licensePlate}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ color: item.exitTime ? getDurationColor(duration) : undefined }}
                        >
                          {item.exitTime ? moment.utc(duration.asMilliseconds()).format('HH:mm:ss') : <>&mdash;</>}
                        </TableCell>
                        <TableCell align="left">{enterMoment.format('MM/DD/YYYY HH:mm')}</TableCell>
                        <TableCell align="left">
                          {item.exitTime ? exitMoment.format('MM/DD/YYYY HH:mm') : <>&mdash;</>}
                        </TableCell>
                        <TableCell align="left">{carMakeName(item.make)}</TableCell>
                        <TableCell align="left">{upperFirst(item.color)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </>
        ) : null}
        {showInitialBlock && (
          <Typography variant="h5" component="span" align="center">
            To determine what point you want to measure the duration
            <br />
            please select enter and exit tags
          </Typography>
        )}
        {isFetching && <BrandedLoadingIndicator />}
        {!isFetching && !isInitial && (!report || !report.details) && enterTags.length && exitTags.length && (
          <Typography variant="h5" component="span" align="center">
            No visits found for these tags
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
    defaultLocationId: getDefaultLocationIdWithVisitDuration(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    isFetching: isReportFetching(state),
    isInitial: isReportInitial(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest, onUnmount: actions.reset })(toJS(VisitsDuration))
);

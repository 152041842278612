import { createReportSettings, reports, widgetReport } from 'api/report';
import mixpanel from 'components/common/mixpanel';
import { downloadData, resolveLocationNameById } from 'components/common/utils';
import { get } from 'lodash';
import { actions, types } from 'reducers/dashboard/sales/summary';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { populateLocIdAndName } from 'sagas/dashboard/location/util';
import Papa from 'papaparse';
import moment from 'moment-timezone';

function* fetchReports({ requestDate, timezone, period, location }) {
  try {
    const [totalReturningNonMembers, membershipSold] = yield all([
      call(
        widgetReport,
        reports.VEHICLE_RETURNING_NON_MEMBERS_WIDGET,
        createReportSettings(requestDate, timezone, period, null, location)
      ),
      call(
        widgetReport,
        reports.MEMBERSHIP_SOLD_TOTAL_WIDGET,
        createReportSettings(requestDate, timezone, period, null, location)
      ),
    ]);

    const report = {
      totalReturningNonMembers: yield populateLocIdAndName(totalReturningNonMembers.data, location),
      membershipSold: yield populateLocIdAndName(membershipSold.data, location),
      timezone,
    };

    yield put(actions.reportSuccess(requestDate, timezone, period, report));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, e));
  }
}

function* downloadMembers({ requestDate, timezone, period, location }) {
  try {
    const response = yield call(
      widgetReport,
      reports.VEHICLE_RETURNING_NON_MEMBERS_WIDGET,
      createReportSettings(requestDate, timezone, period, null, location)
    );
    const report = response.data;
    if (!get(report, 'empty', true)) {
      const locationName = resolveLocationNameById(location);
      const fields = ['Date', 'First time', 'Returning', 'Members'];
      const data = report.rows.map(({ row }) => {
        const { scaledDate, newCustomers, returningNonMembers, memberVisits } = row;
        return [
          moment.tz(scaledDate, 'UTC').format('MM/DD/YYYY HH:mm'),
          newCustomers,
          returningNonMembers,
          memberVisits,
        ];
      });
      const csv = Papa.unparse({
        fields,
        data,
      });

      downloadData(csv, 'text/csv', `${locationName} - Total.csv`);
    }

    yield put(actions.membersDownloadComplete(requestDate, timezone, period, null));

    mixpanel((mp) =>
      mp.track('Sales dashboard | Members download CSV', {
        locationId: location,
        locationName: resolveLocationNameById(location),
        timezone,
        period,
      })
    );
  } catch (e) {
    yield put(snackActions.set('Failed to download CSV, please try again later', snackVariants.error));
    yield put(actions.membersDownloadComplete(requestDate, timezone, period, e));
  }
}

export default function* summary() {
  yield takeLatest(types.REPORT_REQUEST, fetchReports);
  yield takeLatest(types.MEMBERS_DOWNLOAD_REQUEST, downloadMembers);
}

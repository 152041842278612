import WidgetChart from 'components/dashboard/common/WidgetChart';
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { useTheme } from '@material-ui/core';

function ValueOverTimeChart({
  isFetching,
  type,
  series,
  stack,
  categories,
  categoriesFormatter,
  optionsCustomizer,
  disableHeight = true,
  additionalHeight = 0,
}) {
  const theme = useTheme();
  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      extraCssText: `z-index: ${theme.zIndex.drawer + 1}`,
    },
    grid: {
      left: 0,
      top: 20,
      right: 10,
      bottom: 0,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: categories,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: categoriesFormatter,
          margin: 10,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          margin: 10,
        },
      },
    ],
  };

  if (optionsCustomizer) {
    optionsCustomizer(options);
  }

  return (
    <AutoSizer disableHeight={disableHeight}>
      {({ width, height }) => {
        if (width === 0) {
          return null;
        }

        const resultHeight = disableHeight ? width / 1.78 + additionalHeight : height;
        const preparedSeries =
          series &&
          series.map((s) => ({
            type,
            stack,
            ...s,
            itemStyle: { color: s.color, ...s.itemStyle },
            data: s.data,
            barMaxWidth: type === 'bar' ? width / 6 : undefined,
            barMaxHeight: type === 'bar' ? resultHeight - 20 : undefined,
          }));
        const preparedOptions = { ...options, series: preparedSeries };

        return <WidgetChart width={width} height={resultHeight} isFetching={isFetching} options={preparedOptions} />;
      }}
    </AutoSizer>
  );
}

export default ValueOverTimeChart;

import { fromJS } from 'immutable';
import { get, reverse, sortBy } from 'lodash';

export const types = {
  REPORT_REQUEST: 'REPORTS_LP_SEARCH/REPORT_REQUEST',
  REPORT_SUCCESS: 'REPORTS_LP_SEARCH/REPORT_SUCCESS',
  REPORT_FAILURE: 'REPORTS_LP_SEARCH/REPORT_FAILURE',
  RESET: 'REPORTS_LP_SEARCH/RESET',
};

const initialState = fromJS({
  isInitial: true,
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report) {
  if (get(report, 'empty', true)) {
    return null;
  }

  const mapped = report.rows.map(({ row }) => {
    return {
      datetime: row.datetime,
      camera: row.jobName,
      licensePlate: row.licensePlate,
      make: row.make,
    };
  });
  return reverse(sortBy(mapped, 'datetime'));
}

export default function licensePlateSearch(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations(s => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
        s.set('isInitial', false);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations(s => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
        s.set('isInitial', false);
      });
    case types.REPORT_FAILURE:
      return state.withMutations(s => {
        s.set('isFetching', false);
        s.set('report', null);
        s.set('isInitial', false);
      });
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, location, query) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    location,
    query,
  }),
  reportSuccess: (requestDate, timezone, location, query, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    location,
    query,
    report,
  }),
  reportFailure: (requestDate, timezone, location, query, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    location,
    query,
    error,
  }),
  reset: () => ({ type: types.RESET }),
};

export const getRoot = state => state.get('reports').get('licensePlateSearch');
export const getRequestDate = state => getRoot(state).get('requestDate');
export const getReport = state => getRoot(state).get('report');
export const isReportFetching = state => getRoot(state).get('isFetching');
export const isReportInitial = state => getRoot(state).get('isInitial');

import React, { useCallback, useState } from 'react';
import { find, round, sortBy, uniq } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { COMPANY_DASHBOARD_CONFIG, getUserTimezone } from 'reducers/auth';
import { params, reports, widgetReport } from 'api/report';
import { fromPromise } from 'mobx-utils';
import { observer } from 'mobx-react';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { endOfMinuteDateUtc } from 'components/dashboard/common/periods';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import toJS from 'components/common/toJS';
import moment from 'moment-timezone';
import { resolveLocationNameById } from 'components/common/utils';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import LocationIcon from '@material-ui/icons/LocationOn';
import Widget from 'components/dashboard/common/Widget';
import { colors } from 'reducers/dashboard/company/summary';

const locations = uniq(
  COMPANY_DASHBOARD_CONFIG.flatMap(({ locations }) => locations)
    .flat()
    .map(({ id }) => id)
);

class State {
  reportResult = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadData(requestDate, timezone, _) {
    const requests = [
      widgetReport(reports.MEMBERSHIP_CONVERSION_RATE_BY_LOCATIONS, {
        [params.START_DATE]: moment.tz(requestDate, timezone).startOf('month').utc(),
        [params.END_DATE]: endOfMinuteDateUtc(requestDate, timezone),
        [params.TIMEZONE]: timezone,
        [params.LOCATION_IDS]: locations,
      }),
      widgetReport(reports.MEMBERSHIP_CONVERSION_RATE_BY_LOCATIONS, {
        [params.START_DATE]: moment.tz(requestDate, timezone).startOf('day').utc(),
        [params.END_DATE]: endOfMinuteDateUtc(requestDate, timezone),
        [params.TIMEZONE]: timezone,
        [params.LOCATION_IDS]: locations,
      }),
    ];
    this.reportResult = fromPromise(Promise.all(requests));
  }

  get loading() {
    return this.reportResult === null || this.reportResult.state === 'pending';
  }

  get data() {
    if (this.reportResult === null || this.reportResult.state !== 'fulfilled') {
      return null;
    }
    const [currentMonthResponse, todayResponse] = this.reportResult.value;
    const rows = sortBy(
      currentMonthResponse.data.rows.map((row) => ({
        ...row.row,
        conversionRate: round(row.row.conversionRate, 1),
        color: colors[row.row.locationId],
        todayMembershipSoldCount: find(
          todayResponse.data.rows,
          (todayRow) => todayRow.row.locationId === row.row.locationId
        )?.row?.membershipSoldCount,
        locationName: resolveLocationNameById(row.row.locationId),
      })),
      'conversionRate'
    ).reverse();
    locations
      .filter((locationId) => !find(rows, { locationId }))
      .forEach((locationId) => {
        rows.push({ locationId, color: colors[locationId], locationName: resolveLocationNameById(locationId) });
      });
    return rows;
  }
}

function StoreLeaderboard({ location, history, userTimezone }) {
  const [state] = useState(() => new State());
  const onSettingsChange = useCallback(
    (requestDate, timezone, period) => {
      state.loadData(requestDate, timezone, period);
    },
    [state]
  );
  useSettingsChangeActionDispatcher(location, history, userTimezone, onSettingsChange);

  return (
    <Widget title="Store leaderboard">
      {state.loading || !state.data ? (
        <ValueOverTimeChart isFetching={state.loading} type="bar" stack="one" categories={null} series={null} />
      ) : (
        <div>
          <List dense>
            {state.data.map(
              ({ locationId, locationName, membershipSoldCount, todayMembershipSoldCount, conversionRate, color }) => (
                <ListItem style={{ paddginTop: 8, paddingBottom: 8 }} key={locationId}>
                  <ListItemAvatar>
                    <Avatar style={{ borderRadius: '15%', backgroundColor: color }}>
                      <LocationIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={locationName}
                    secondary={
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ minWidth: 90, display: 'flex', alignItems: 'center' }}>
                          <span>
                            <strong>{membershipSoldCount ?? '—'}</strong>
                            <br />
                            <Typography component="span" variant="caption" color="textSecondary">
                              this month
                            </Typography>
                          </span>
                        </span>
                        <span style={{ marginLeft: 16 }}>
                          <strong>{todayMembershipSoldCount ?? '—'}</strong>
                          <br />
                          <Typography component="span" variant="caption" color="textSecondary">
                            today
                          </Typography>
                        </span>
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Typography variant="body1" component="div">
                      {conversionRate ? `${conversionRate}%` : '—'}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            )}
          </List>
        </div>
      )}
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(connect(mapStateToProps)(toJS(observer(StoreLeaderboard))));

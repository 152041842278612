import { fromJS } from 'immutable';

export const types = {
  UPDATE_REQUEST: 'PROFILE_DETAILS/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PROFILE_DETAILS/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PROFILE_DETAILS/UPDATE_FAILURE',
};

export const initialState = fromJS({
  isFetching: false,
});

export default function details(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_REQUEST:
      return state.withMutations(s => s.set('isFetching', true));
    case types.UPDATE_SUCCESS:
      return state.withMutations(s => s.set('isFetching', false));
    case types.UPDATE_FAILURE:
      return state.withMutations(s => s.set('isFetching', false));
    default:
      return state;
  }
}

export const actions = {
  updateRequest: (name, timezone) => ({ type: types.UPDATE_REQUEST, name, timezone }),
  updateSuccess: userContext => ({ type: types.UPDATE_SUCCESS, userContext }),
  updateFailure: error => ({ type: types.UPDATE_FAILURE, error }),
};

export const getDetails = state => state.get('profile').get('details');
export const isUpdateFetching = state => getDetails(state).get('isFetching');

import { all } from 'redux-saga/effects';
import averageVisitsDuration from 'sagas/reports/averageVisitsDuration';
import fraud from 'sagas/reports/fraud';
import licensePlateSearch from 'sagas/reports/licensePlateSearch';
import memberValidation from 'sagas/reports/memberValidation';
import rewashes from 'sagas/reports/rewashes';
import totalCustomers from 'sagas/reports/totalCustomers';
import visitsDuration from 'sagas/reports/visitsDuration';

export default function* reports() {
  yield all([
    totalCustomers(),
    licensePlateSearch(),
    visitsDuration(),
    averageVisitsDuration(),
    rewashes(),
    fraud(),
    memberValidation(),
  ]);
}

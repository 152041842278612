import { withTheme } from '@material-ui/core';
import ThreeValuesWidgetHeader from 'components/dashboard/common/ThreeValuesWidgetHeader';
import Widget from 'components/dashboard/common/Widget';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import COLORS from 'components/style/colors';
import { sumBy } from 'lodash';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

function HighPotentialCustomers({ theme, isFetching, requestDate, report }) {
  if (!requestDate) {
    return null;
  }

  const initialData = report && [
    {
      name: 'First time',
      value: report.summary.firstTime,
      color: COLORS.LIGHT_BLUE,
    },
    {
      name: 'Returning',
      value: report.summary.returningNonMembers,
      color: COLORS.DARK_YELLOW,
    },
  ];
  const data = initialData && [
    ...initialData
      .filter((row) => row.value > 0)
      .map((row) => ({ name: row.name, value: row.value, itemStyle: { color: row.color } })),
  ];
  const total = initialData && sumBy(initialData, 'value');

  return (
    <Widget title="High potential customers">
      <>
        <ThreeValuesWidgetHeader
          left={{
            name: 'Opportunities',
            value: initialData && total > 0 ? total : undefined,
            color: COLORS.LIGHT_GREY,
          }}
        />
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = width / 1.78;
            const options = {
              legend: {
                show: true,
                orient: 'vertical',
                right: 10,
              },
              tooltip: {
                trigger: 'item',
                formatter: '<strong>{b}</strong>: {c} ({d}%)',
                extraCssText: `z-index: ${theme.zIndex.drawer + 1}`,
              },
              series: initialData &&
                data &&
                data.length > 0 && [
                  {
                    type: 'pie',
                    radius: ['40%', '80%'],
                    center: [width / 2, height / 2],
                    avoidLabelOverlap: true,
                    label: {
                      normal: {
                        show: true,
                        position: 'outside',
                        formatter: '{c}',
                        color: theme.palette.text.primary,
                        fontSize: 16,
                      },
                    },
                    labelLine: {
                      show: true,
                      length: 5,
                      length2: 15,
                    },
                    itemStyle: { borderWidth: 2, borderColor: 'white' },
                    data,
                  },
                ],
            };

            return <WidgetChart width={width} height={height} isFetching={isFetching} options={options} />;
          }}
        </AutoSizer>
      </>
    </Widget>
  );
}

export default withTheme(HighPotentialCustomers);

import { Avatar, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Typography } from '@material-ui/core';
import MusicIcon from '@material-ui/icons/MusicNote';
import SportIcon from '@material-ui/icons/Sports';
import GenericEventIcon from '@material-ui/icons/Today';
import toJS from 'components/common/toJS';
import COLORS from 'components/style/colors';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { getLocationsWithInsights } from 'reducers/auth';
import { getEventsReport } from 'reducers/insights';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  time: {
    marginTop: 6,
    marginLeft: theme.spacing(2),
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  avatar: {
    borderRadius: '15%',
  },
  emptyEvents: {
    height: 250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const avatarStyles = {
  Music: {
    icon: MusicIcon,
    color: COLORS.BLUE,
  },
  Sport: {
    icon: SportIcon,
    color: COLORS.GREEN,
  },
  default: {
    icon: GenericEventIcon,
    color: COLORS.DARK_GREY,
  },
};

function renderEventTime(date) {
  const time = moment.tz(date, 'UTC').format('HH:mm');
  return time === '00:00' ? 'All day' : time;
}

function InsightsEventsWidget({ report }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h4" component="div">
        Local events
      </Typography>
      {report ? (
        <List>
          {report.map(({ date, name, location, type }, index) => {
            const avatarStyle = avatarStyles[type] || avatarStyles['default'];
            const Icon = avatarStyle.icon;

            return (
              <React.Fragment key={index}>
                <ListItem disableGutters alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: avatarStyle.color }}>
                      <Icon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={name} secondary={location} />
                  <div className={classes.time}>
                    {moment.tz(date, 'UTC').format('MMM D')}
                    <br />
                    {renderEventTime(date)}
                  </div>
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      ) : (
        <Typography variant="h5" component="div" className={classes.emptyEvents} align="center">
          No events found
        </Typography>
      )}
    </Paper>
  );
}

function mapStateToProps(state) {
  return {
    report: getEventsReport(state),
    availableLocations: getLocationsWithInsights(state),
  };
}

export default connect(mapStateToProps, null)(toJS(InsightsEventsWidget));

import { Grid, Typography } from '@material-ui/core';
import LoadingIndicator from 'components/common/LoadingIndicator';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/chart/gauge';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/markLine';
import 'echarts/lib/component/tooltip';
import echarts from 'echarts/lib/echarts';
import React, { useEffect, useRef } from 'react';

function WidgetChart({ isFetching, width, height, options, style, ...rest }) {
  const chartRef = useRef(null);
  const series = options && options.series;

  useEffect(() => {
    if (chartRef && chartRef.current != null) {
      chartRef.current.getEchartsInstance().setOption(options);
    }
  }, [width, height, options, series]);

  if (width === 0 || height === 0) {
    return null;
  }

  return (
    <Grid item>
      <div style={{ width, height, position: 'static', pointerEvents: (isFetching || !series) && 'none' }}>
        {(isFetching || !series) && (
          <Grid style={{ width, height, position: 'absolute' }} container justify="center" alignItems="center">
            {isFetching && (
              <LoadingIndicator>
                <Typography variant="h5" component="span">
                  Loading data...
                </Typography>
              </LoadingIndicator>
            )}
            {!isFetching && !series && (
              <Typography variant="h5" component="span" align="center">
                No data to display
              </Typography>
            )}
          </Grid>
        )}
        {series && !isFetching && (
          <ReactEchartsCore
            ref={chartRef}
            notMerge
            echarts={echarts}
            style={{ width: '100%', height: '100%', ...style }}
            option={options}
            {...rest}
          />
        )}
      </div>
    </Grid>
  );
}

export default WidgetChart;

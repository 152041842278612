import { Typography } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/Videocam';
import toJS from 'components/common/toJS';
import SnapshotsDownloader from 'components/dashboard/camera/SnapshotsDownloader';
import useSettingsChangeActionDispatcher from 'components/dashboard/camera/useSettingsChangeActionDispatcher';
import { composeTimeCategories, getTimeLabelFormatter, isCustomPeriod } from 'components/dashboard/common/periods';
import { prepareLegendData } from 'components/dashboard/common/utils';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import WidgetHeaderTrend from 'components/dashboard/common/WidgetHeaderTrend';
import WidgetLegend from 'components/dashboard/common/WidgetLegend';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/camera/totalCustomers';

function prepareReport(report) {
  if (!report) {
    return report;
  }
  const result = cloneDeep(report);
  result.forEach((s) => {
    s.name = 'Customers';
  });
  return result;
}

function TotalCustomersWidget({ location, history, isFetching, requestDate, userTimezone, report, reportRequest }) {
  const [period, timezone, jobId] = useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest);

  if (!requestDate) {
    return null;
  }

  const categories = composeTimeCategories(requestDate, timezone, period);
  const legendData = report && prepareLegendData(report);
  const total = report && report.reduce((acc, obj) => acc + obj.value, 0);
  return (
    <Widget
      title="Total customers"
      titleAction={
        !isCustomPeriod(period) && (
          <Typography color="textSecondary" variant="subtitle1" noWrap>
            Today, {moment.tz(requestDate, timezone).format('HH:mm')}
          </Typography>
        )
      }
    >
      <>
        <WidgetHeaderTrend
          name="Customers"
          headerValue={total}
          value={<SnapshotsDownloader jobId={jobId} timezone={timezone} />}
          period="last week"
        />
        <ValueOverTimeChart
          isFetching={isFetching}
          type="bar"
          stack="one"
          categories={categories}
          categoriesFormatter={getTimeLabelFormatter(requestDate, timezone, period)}
          series={prepareReport(report)}
        />
        <WidgetLegend data={legendData} icon={<CameraIcon />} />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(TotalCustomersWidget))
);

import COLORS, { ERROR_COLOR, MEMBER_VALIDATION_PLATE_COLORS } from 'components/style/colors';
import { fromJS, List } from 'immutable';
import { compact, get, groupBy, intersection, isEqual, reverse, sortBy, uniq } from 'lodash';

export const types = {
  REPORT_REQUEST: 'REPORTS/MEMBER_VALIDATION/REPORT_REQUEST',
  REPORT_SUCCESS: 'REPORTS/MEMBER_VALIDATION/REPORT_SUCCESS',
  REPORT_FAILURE: 'REPORTS/MEMBER_VALIDATION/REPORT_FAILURE',
  DETAILS_REQUEST: 'REPORTS/MEMBER_VALIDATION//DETAILS_REQUEST',
  DETAILS_SUCCESS: 'REPORTS/MEMBER_VALIDATION/DETAILS_SUCCESS',
  DETAILS_FAILURE: 'REPORTS/MEMBER_VALIDATION/DETAILS_FAILURE',
  DETAILS_CLOSE: 'REPORTS/MEMBER_VALIDATION/DETAILS_CLOSE',
  RESET: 'REPORTS/MEMBER_VALIDATION/RESET',
};

const initialState = fromJS({
  isInitial: true,
  isFetching: false,
  requestDate: null,
  report: null,
  detailsOpen: false,
  detailsMemberId: null,
  detailsReport: null,
  detailsIsFetching: false,
  detailsError: false,
});

function prepareReport(mvReport, dataSyncReport) {
  const lastSyncDate = !get(dataSyncReport, 'empty', true) && dataSyncReport.rows[0].row.lastSyncDate;
  if (get(mvReport, 'empty', true)) {
    return { lastSyncDate };
  }

  const mapped = mvReport.rows.map(({ row }) => {
    return {
      memberId: row.memberId,
      lastVisitDatetime: row.lastVisitDatetime,
      totalVisits: row.totalVisits,
      similarGroups: row.similarGroups,
      lastSyncDate,
    };
  });
  return reverse(sortBy(mapped, 'lastVisitDatetime'));
}

function prepareDetailsReport(memberId, report, state) {
  const rootReport = state.get('report');
  const memberReport = rootReport?.find((item) => item.get('memberId') === memberId).toJS();
  if (get(report, 'empty', true) || !memberReport) {
    return null;
  }

  const mapped = reverse(
    sortBy(
      report.rows.map(({ row }) => {
        return {
          memberId: row.memberId,
          date: row.date,
          mappedDate: row.mappedDate,
          licensePlate: row.licensePlate,
          mappedLicensePlate: row.mappedLicensePlate,
          similarMatch: row.similarMatch,
          jobId: row.jobId,
        };
      }),
      'date'
    )
  );
  const visits = reverse(
    sortBy(
      Object.entries(groupBy(mapped, 'date')).map(([date, visits]) => ({
        date,
        visits,
      })),
      'date'
    )
  );

  const memberLicensePlate = mapped[0]?.licensePlate;
  const matchedPlates = uniq(mapped.filter((row) => row.similarMatch).map((row) => row.mappedLicensePlate));
  const { similarGroups } = memberReport;
  const greenGroup = matchedPlates.length
    ? similarGroups.find(({ lps }) => intersection(lps, matchedPlates).length)
    : undefined;
  if (greenGroup) {
    greenGroup.color = COLORS.GREEN;
    const memberNormalizedPlate = memberLicensePlate.toUpperCase().replace(/\s/g, '');
    if (greenGroup.lps.find((lp) => lp.toUpperCase() === memberNormalizedPlate)) {
      greenGroup.lps[0] = memberNormalizedPlate;
    }
  }
  const otherGroups = reverse(
    sortBy(
      similarGroups.filter((group) => !isEqual(group, greenGroup)),
      'totalVisits'
    )
  ).slice(0, MEMBER_VALIDATION_PLATE_COLORS.length);
  otherGroups.forEach((group, index) => (group.color = MEMBER_VALIDATION_PLATE_COLORS[index]));
  const resultSimilarGroups = compact([greenGroup, ...otherGroups]);
  visits.forEach((visit) => {
    visit.visits.forEach((row) => {
      const visitGroup = resultSimilarGroups.find(({ lps }) => lps.includes(row.mappedLicensePlate));
      if (row.similarMatch) {
        row.color = COLORS.GREEN;
      } else {
        row.color = visitGroup ? visitGroup.color : ERROR_COLOR;
      }
    });
    visit.visits = reverse(sortBy(visit.visits, 'mappedDate'));
  });

  return { memberId, memberLicensePlate, visits, similarGroups: resultSimilarGroups };
}

export default function memberValidation(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
        s.set('isInitial', false);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, mvReport, dataSyncReport } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(mvReport, dataSyncReport)));
        s.set('isInitial', false);
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
        s.set('isInitial', false);
      });
    case types.DETAILS_REQUEST:
      return state.withMutations((s) => {
        s.set('detailsReport', null);
        s.set('detailsOpen', true);
        s.set('detailsIsFetching', true);
        s.set('detailsMemberId', action.memberId);
      });
    case types.DETAILS_SUCCESS:
      return state.withMutations((s) => {
        const { memberId, report } = action;
        s.set('detailsIsFetching', false);
        s.set('detailsReport', fromJS(prepareDetailsReport(memberId, report, state)));
        s.set('detailsError', false);
      });
    case types.DETAILS_FAILURE:
      return state.withMutations((s) => {
        s.set('detailsIsFetching', false);
        s.set('detailsReport', null);
        s.set('detailsError', true);
      });
    case types.DETAILS_CLOSE:
      return state.withMutations((s) => {
        s.set('detailsIsFetching', false);
        s.set('detailsError', false);
        s.set('detailsOpen', false);
      });
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location, mode) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
    mode,
  }),
  reportSuccess: (requestDate, timezone, period, location, mode, mvReport, dataSyncReport) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    location,
    mode,
    mvReport,
    dataSyncReport,
  }),
  reportFailure: (requestDate, timezone, period, location, mode, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    location,
    mode,
    error,
  }),
  detailsRequest: (requestDate, timezone, period, location, memberId) => ({
    type: types.DETAILS_REQUEST,
    requestDate,
    timezone,
    period,
    location,
    memberId,
  }),
  detailsSuccess: (requestDate, timezone, period, location, memberId, report) => ({
    type: types.DETAILS_SUCCESS,
    requestDate,
    timezone,
    period,
    location,
    memberId,
    report,
  }),
  detailsFailure: (requestDate, timezone, period, location, memberId, error) => ({
    type: types.DETAILS_FAILURE,
    requestDate,
    timezone,
    period,
    location,
    memberId,
    error,
  }),
  detailsClose: () => ({
    type: types.DETAILS_CLOSE,
  }),
  reset: () => ({ type: types.RESET }),
};

export const getRoot = (state) => state.get('reports').get('memberValidation');
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');
export const isReportInitial = (state) => getRoot(state).get('isInitial');
export const isDetailsFetching = (state) => getRoot(state).get('detailsIsFetching');
export const isDetailsOpen = (state) => getRoot(state).get('detailsOpen');
export const getDetailsReport = (state) => getRoot(state).get('detailsReport');
export const getDetailsMemberId = (state) => getRoot(state).get('detailsMemberId');
export const getDetailsParentReport = (state) => {
  const memberId = getDetailsMemberId(state);
  const report = getReport(state);
  if (!report || !memberId || !List.isList(report)) {
    return null;
  }
  return report.find((item) => item.get('memberId') === memberId);
};
export const getDetailsError = (state) => getRoot(state).get('detailsError');

import toJS from 'components/common/toJS';
import MiniWidget from 'components/dashboard/common/MiniWidget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import ReportActionMenu from 'components/reports/ReportActionMenu';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/location/totalCustomersMini';
import { actions as totalCustomersActions, isDownloadFetching } from 'reducers/reports/totalCustomers';

function TotalCustomersMiniWidget({
  location,
  history,
  isFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
  defaultLocationId,
  downloadIsFetching,
  downloadCsvRequest,
}) {
  const [period, timezone, locationId] = useSettingsChangeActionDispatcher(
    location,
    history,
    userTimezone,
    reportRequest,
    defaultLocationId
  );

  if (!requestDate) {
    return null;
  }

  const value = report?.rows?.[0]?.row?.eventsCount;
  const action = value ? (
    <ReportActionMenu
      isDownloadFetching={downloadIsFetching}
      onDownload={() => downloadCsvRequest(requestDate, timezone, period, locationId, [])}
    />
  ) : null;
  return <MiniWidget isFetching={isFetching} title="Total" value={value} action={action} />;
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
    downloadIsFetching: isDownloadFetching(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    reportRequest: actions.reportRequest,
    downloadCsvRequest: totalCustomersActions.downloadCsvRequest,
  })(toJS(TotalCustomersMiniWidget))
);

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import RoleWithTooltip from 'components/common/RoleWithTooltip';
import toJS from 'components/common/toJS';
import LocationsPicker from 'components/pickers/LocationsPicker';
import { cloneDeep, get } from 'lodash';
import React, { useState } from 'react';
import { useDidUpdate } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { getCompanies, getCompanyIds, isSuperAdmin } from 'reducers/auth';
import { UserRolesMap } from '../common/constants';

const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  primaryButton: {
    minWidth: 170,
  },
});

function getLocationsError(locationIds, role) {
  if (role === UserRolesMap.LOCATION_MANAGER && !locationIds.length) {
    return 'Locations are required';
  }
  return null;
}

function UserEdit({
  classes,
  onClose,
  isSuperAdmin,
  initialEntity,
  availableCompanies,
  availableCompanyIds,
  isFetching,
  isNew,
  error,
  resetError,
  onSubmit,
}) {
  const availableCompaniesPrepared = cloneDeep(availableCompanies);
  const availableCompanyIdsPrepared = cloneDeep(availableCompanyIds);
  const initialCompany = initialEntity?.company;
  if (initialCompany && !availableCompanyIdsPrepared.includes(initialCompany.id)) {
    availableCompanyIdsPrepared.push(initialCompany.id);
    availableCompaniesPrepared[initialCompany.id] = initialCompany;
  }

  const initialCompanyId = availableCompanyIdsPrepared.length === 1 ? availableCompanyIdsPrepared[0] : '';
  const [name, setName] = useState(get(initialEntity, 'name', ''));
  const [email, setEmail] = useState(get(initialEntity, 'email', ''));
  const initialSuperAdmin = get(initialEntity, 'superAdmin', false);
  const defaultRole = initialSuperAdmin ? 'superadmin' : UserRolesMap.LOCATION_MANAGER;
  const initialRole = get(initialEntity, 'role', UserRolesMap.LOCATION_MANAGER);
  const [role, setRole] = useState(initialRole ? initialRole : defaultRole);
  const [companyId, setCompanyId] = useState(get(initialEntity, 'company.id', initialCompanyId));
  const [locationIds, setLocationIds] = useState(get(initialEntity, 'locations', []).map((location) => location.id));
  const [wasSubmit, setWasSubmit] = useState(false);
  const superAdmin = role === 'superadmin';
  const nameError = !name.trim() ? 'Name is required' : null;
  const emailError = !email.trim() ? 'Email is required' : null;
  const companyError = !companyId ? 'Company is required' : null;
  const locationsError = getLocationsError(locationIds, role);
  useDidUpdate(() => setLocationIds([]), [companyId]);

  function handleSubmit(event) {
    event.preventDefault();
    setWasSubmit(true);
    if (!nameError && !emailError && !companyError && !locationsError) {
      doSubmit();
    }
  }

  function doSubmit() {
    const resolvedRole = superAdmin ? null : role;
    const resolvedLocations = resolvedRole !== UserRolesMap.LOCATION_MANAGER ? [] : locationIds;
    const entity = {
      ...initialEntity,
      name,
      email,
      superAdmin,
      role: resolvedRole,
      companyId,
      locationIds: resolvedLocations,
    };

    let entityId = initialEntity.id;
    onSubmit(entityId, entity, isNew);
  }

  return (
    <>
      <Dialog
        transitionDuration={0}
        open={!!error}
        onClose={resetError}
        aria-labelledby="user-edit-error-dialog-title"
        aria-describedby="user-edit-error-dialog-description"
      >
        <DialogTitle id="user-edit-wizard-error-dialog-title">Save failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="user-edit-error-dialog-description">{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <DialogContent className={classes.content}>
        <TextField
          value={name}
          onChange={(event) => setName(event.target.value)}
          id="name"
          label="Full name"
          autoComplete="name"
          name="name"
          margin="none"
          error={wasSubmit && nameError != null}
          helperText={wasSubmit && nameError}
          inputProps={{ required: true }}
          fullWidth
        />
        <TextField
          value={email}
          disabled={!isNew}
          onChange={(event) => setEmail(event.target.value)}
          id="email"
          label="Email"
          autoComplete="email"
          type="email"
          name="email"
          margin="none"
          error={wasSubmit && emailError != null}
          helperText={wasSubmit && emailError}
          inputProps={{ required: true }}
          fullWidth
        />
        <TextField
          select
          disabled={!isNew}
          label="Company"
          id="company"
          name="company"
          margin="none"
          error={wasSubmit && companyError != null}
          helperText={wasSubmit && companyError}
          fullWidth
          value={companyId}
          onChange={(event) => setCompanyId(event.target.value)}
        >
          {availableCompanyIdsPrepared.map((companyId) => (
            <MenuItem key={companyId} value={companyId}>
              {availableCompaniesPrepared[companyId].name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Role"
          id="role"
          name="role"
          margin="none"
          fullWidth
          value={role}
          onChange={(event) => setRole(event.target.value)}
        >
          <MenuItem value={UserRolesMap.LOCATION_MANAGER}>
            <RoleWithTooltip value={UserRolesMap.LOCATION_MANAGER} />
          </MenuItem>
          <MenuItem divider value={UserRolesMap.COMPANY_ADMIN}>
            <RoleWithTooltip value={UserRolesMap.COMPANY_ADMIN} />
          </MenuItem>
          {isSuperAdmin
            ? [
                <MenuItem divider key={UserRolesMap.PORTFOLIO_SUPERVISOR} value={UserRolesMap.PORTFOLIO_SUPERVISOR}>
                  <RoleWithTooltip value={UserRolesMap.PORTFOLIO_SUPERVISOR} />
                </MenuItem>,
                <MenuItem key="superadmin" value="superadmin">
                  <RoleWithTooltip value="superadmin" />
                </MenuItem>,
              ]
            : null}
        </TextField>
        {role === UserRolesMap.LOCATION_MANAGER && (
          <LocationsPicker
            id="locationIds"
            name="locationIds"
            margin="none"
            error={wasSubmit && locationsError != null}
            helperText={wasSubmit && locationsError}
            fullWidth
            value={locationIds}
            onChange={(value) => setLocationIds(value)}
            availableLocations={availableCompaniesPrepared[companyId]?.locations ?? { allIds: [], byId: {} }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isFetching} variant="text" color="default">
          Cancel
        </Button>
        <Button
          className={classes.primaryButton}
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="secondary"
        >
          <AsyncActionLabel label="Save" fetchingLabel="Saving..." isFetching={isFetching} />
        </Button>
      </DialogActions>
    </>
  );
}

function mapStateToProps(state) {
  return {
    availableCompanies: getCompanies(state),
    availableCompanyIds: getCompanyIds(state),
    isSuperAdmin: isSuperAdmin(state),
  };
}

export default connect(mapStateToProps)(withStyles(styles)(toJS(UserEdit)));

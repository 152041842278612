import { VEHICLE_MAKES_COLORS } from 'components/style/colors';
import { fromJS } from 'immutable';
import { get } from 'lodash';
import { parseSets } from 'sagas/reports/totalCustomers';

export const types = {
  REPORT_REQUEST: 'REPORTS_TOTAL_CUSTOMERS/REPORT_REQUEST',
  REPORT_SUCCESS: 'REPORTS_TOTAL_CUSTOMERS/REPORT_SUCCESS',
  REPORT_FAILURE: 'REPORTS_TOTAL_CUSTOMERS/REPORT_FAILURE',
  DOWNLOAD_CSV_REQUEST: 'REPORTS_TOTAL_CUSTOMERS/DOWNLOAD_CSV_REQUEST',
  DOWNLOAD_CSV_COMPLETE: 'REPORTS_TOTAL_CUSTOMERS/DOWNLOAD_CSV_COMPLETE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
  downloadIsFetching: false,
});

function resolveSetName(setIndex, availableTags, allTags) {
  const sets = parseSets(allTags);
  const tags = sets[setIndex];
  if (!tags.length) {
    return 'All tags';
  }
  return tags.map(id => availableTags.getIn([id, 'name'])).join(', ');
}

function prepareReport(report, availableTags, tags) {
  if (Object.values(report).every(data => get(data, 'empty', true) === true)) {
    return null;
  }

  const result = [];
  Object.entries(report).forEach(([setIndex, data]) => {
    const set = {
      color: VEHICLE_MAKES_COLORS[setIndex],
      name: resolveSetName(setIndex, availableTags, tags),
      value: 0,
      data: [],
      lineStyle: { width: 3 },
      smooth: true,
      showSymbol: false,
    };
    data.rows.forEach(row => {
      set.data.push(row.row.eventsCount);
      set.value += row.row.eventsCount;
    });
    result.push(set);
  });
  return result;
}

export default function totalCustomers(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations(s => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations(s => {
        const { requestDate, report, availableTags, tags } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report, availableTags, tags)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations(s => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    case types.DOWNLOAD_CSV_REQUEST:
      return state.withMutations(s => {
        s.set('downloadIsFetching', true);
      });
    case types.DOWNLOAD_CSV_COMPLETE:
      return state.withMutations(s => {
        s.set('downloadIsFetching', false);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location, tags) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
    tags,
  }),
  reportSuccess: (requestDate, timezone, period, report, availableTags, tags) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    report,
    availableTags,
    tags,
  }),
  reportFailure: (requestDate, timezone, period, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    error,
  }),
  downloadCsvRequest: (requestDate, timezone, period, location, tags) => ({
    type: types.DOWNLOAD_CSV_REQUEST,
    requestDate,
    timezone,
    period,
    location,
    tags,
  }),
  downloadCsvComplete: (requestDate, timezone, period, error) => ({
    type: types.DOWNLOAD_CSV_COMPLETE,
    requestDate,
    timezone,
    period,
    error,
  }),
};

export const getRoot = state => state.get('reports').get('totalCustomers');
export const getRequestDate = state => getRoot(state).get('requestDate');
export const getReport = state => getRoot(state).get('report');
export const isReportFetching = state => getRoot(state).get('isFetching');
export const isDownloadFetching = state => getRoot(state).get('downloadIsFetching');

import { fromJS } from 'immutable';
import { get } from 'lodash';

export const types = {
  REPORT_REQUEST: 'LOCATION/WEATHER_MINI/REPORT_REQUEST',
  REPORT_SUCCESS: 'LOCATION/WEATHER_MINI/REPORT_SUCCESS',
  REPORT_FAILURE: 'LOCATION/WEATHER_MINI/REPORT_FAILURE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report) {
  if (get(report, 'empty', true)) {
    return null;
  }

  return report;
}

export default function weatherMini(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
  }),
  reportSuccess: (requestDate, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    report,
  }),
  reportFailure: (requestDate, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    error,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'location', 'weatherMini']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');

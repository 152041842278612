import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Paper, IconButton, Tooltip, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/styles';
import { useHistory, useLocation } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { SortingState } from '@devexpress/dx-react-grid';
import { Grid as DxGrid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { useSelector } from 'react-redux';

import SetTitle from 'components/common/SetTitle';
import PlatesIgnoreEditDialog from 'components/company/PlatesIgnoreEditDialog';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import { getPastTimeFromNow, isEditAuthorized } from 'components/common/utils';
import TableToolbar from 'components/common/TableToolbar';
import SearchBox from 'components/common/SearchBox';
import useQueryParam from 'components/common/useQueryParam';
import InlineLoadingIndicator from 'components/common/InlineLoadingIndicator';
import { GridRoot, TableContainer } from 'components/common/Grid';
import TableAutoLayout from 'components/common/TableAutoLayout';
import { isSuperAdmin } from 'reducers/auth';
import CompanyTableTooltipHeaderCell from './CompanyTableTooltipHeaderCell';
import CompanyListState from './CompanyListState';

const getPlatesCountText = (platesAmount = 0) => {
  const platesWorldForm = platesAmount > 1 ? 'plates' : 'plate';

  return platesAmount > 0 ? `${platesAmount} ${platesWorldForm}` : '—';
};

const useStyles = makeStyles((theme) => ({
  searchBoxWrapper: {
    marginBottom: theme.spacing(3.5),
  },
  toolbar: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(4),
  },
  tableContainer: {
    height: '100%',
  },
  tooltipHelpIcon: {
    marginLeft: theme.spacing(0.5),
  },
}));

const sortingColumnExtensions = [
  { columnName: 'name', sortingEnabled: false },
  { columnName: 'platesIgnoreList', sortingEnabled: false },
];

const CompanyList = () => {
  const history = useHistory();
  const location = useLocation();
  const [query] = useQueryParam('query', (v) => v || '', location, history);
  const classes = useStyles();

  const isUserSuperAdmin = useSelector(isSuperAdmin);

  const [state] = useState(() => new CompanyListState());

  const { resetCompanyEdit, savePlatesIgnoreList, searchCompanies, sortingChange } = state;

  const isPlatesEditOpen = Boolean(state.companyEdit);
  const isBaseLoading = state.isLoading;
  const isLoadingMore = state.companies.length && state.isLoadingMore;

  const TableTitle = useCallback(({ children, column, ...rest }) => {
    const headerCellMap = {
      platesIgnoreList: (
        <CompanyTableTooltipHeaderCell
          column={column}
          tooltipText="These license plates will be excluded from omniX Analytics"
          {...rest}
        />
      ),
    };
    const HeaderCell = headerCellMap[column.name];

    return (
      HeaderCell ?? (
        <TableHeaderRow.Cell column={column} {...rest}>
          {children}
        </TableHeaderRow.Cell>
      )
    );
  }, []);

  const columns = useMemo(() => {
    const superAdminColumn = isUserSuperAdmin
      ? [
          {
            name: 'carWashApiRemainingCount',
            title: 'Car Wash Remaining Predictions',
            getCellValue: ({ carWashApiRemainingCount }) => carWashApiRemainingCount ?? '—',
          },
          {
            name: 'oilChangeApiRemainingCount',
            title: 'Oil Change Remaining Predictions',
            getCellValue: ({ oilChangeApiRemainingCount }) => oilChangeApiRemainingCount ?? '—',
          },
        ]
      : [];

    return [
      {
        name: 'name',
        title: 'Name',
        getCellValue: ({ name }) => name,
      },
      {
        name: 'createdAt',
        title: 'Created',
        getCellValue: ({ createdAt }) => getPastTimeFromNow(createdAt),
      },
      ...superAdminColumn,
      {
        name: 'platesIgnoreList',
        title: 'Excluded license plates',
        showColumnWhenGrouped: TableTitle,
        getCellValue: (company) => {
          const isEditable = isEditAuthorized(company);
          const isPlatesListEmpty = company.platesIgnoreList.length === 0;

          return (
            <>
              {(isEditable || !isPlatesListEmpty) && (
                <Tooltip title={`${isEditable ? 'Modify' : 'Show'} excluded license plates`}>
                  <IconButton
                    aria-haspopup
                    aria-label={`${isEditable ? 'Modify' : 'Show'} excluded license plates`}
                    onClick={() => state.setCompanyEdit(company)}
                  >
                    {isEditable ? <EditIcon /> : <VisibilityIcon />}
                  </IconButton>
                </Tooltip>
              )}
              {getPlatesCountText(company.platesIgnoreList.length)}
            </>
          );
        },
      },
    ];
  }, [TableTitle, state, isUserSuperAdmin]);

  const handleSearch = useCallback((query, sorting, isAppend = false) => searchCompanies(query, sorting, isAppend), [
    searchCompanies,
  ]);

  const handleSearchChange = useCallback((query) => handleSearch(query, state.sorting), [handleSearch, state.sorting]);

  const handleSearchMore = useCallback(() => handleSearch(query, state.sorting, true), [
    handleSearch,
    query,
    state.sorting,
  ]);

  useEffect(() => {
    handleSearch(query, state.sorting);
  }, [handleSearch, query, state.sorting]);

  return (
    <>
      <SetTitle title="Companies" />
      <Grid item container className={classes.searchBoxWrapper} justify="center">
        <SearchBox
          location={location}
          history={history}
          placeholder="Search by name, ID, etc."
          onSearch={handleSearchChange}
        />
      </Grid>
      <Paper className={classes.root}>
        {isBaseLoading && !isLoadingMore ? (
          <BrandedLoadingIndicator />
        ) : (
          <>
            <TableToolbar className={classes.toolbar} title={`Companies: ${state.companiesAmount}`} />
            <DxGrid rootComponent={GridRoot} rows={state.companies} columns={columns}>
              <SortingState
                sorting={state.sorting}
                onSortingChange={sortingChange}
                columnExtensions={sortingColumnExtensions}
              />
              <Table containerComponent={TableContainer} tableComponent={TableAutoLayout} />
              <TableHeaderRow showSortingControls cellComponent={TableTitle} />
            </DxGrid>
            <Waypoint bottomOffset="-500px" scrollableAncestor={window} onEnter={handleSearchMore} />
            {isLoadingMore && <InlineLoadingIndicator />}
          </>
        )}
      </Paper>
      {isPlatesEditOpen && (
        <PlatesIgnoreEditDialog
          company={state.companyEdit}
          isOpen={isPlatesEditOpen}
          isReadonly={!isEditAuthorized(state.companyEdit)}
          onClose={resetCompanyEdit}
          onSave={savePlatesIgnoreList}
        />
      )}
    </>
  );
};

export default observer(CompanyList);

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link as MuiLink,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import * as api from 'api/user';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import LoadingIndicator from 'components/common/LoadingIndicator';
import PasswordField, { allRequirementsSatisfied } from 'components/common/PasswordField';
import SetTitle from 'components/common/SetTitle';
import StaticTextField from 'components/common/StaticTextField';
import { productName } from 'components/common/whitelabel';
import BrandedFormPage, { BLOCKS_SPACING } from 'components/pages/BrandedFormPage';
import MobileLogo from 'components/pages/MobileLogo';
import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { actions, getAuth } from 'reducers/auth';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 272,
    maxWidth: 480,
  },
  form: {
    marginTop: theme.spacing(BLOCKS_SPACING),
  },
});

function Activation({ classes, location, login, authIsFetching }) {
  const { email = '', token = '' } = parse(location.search, { ignoreQueryPrefix: true });

  const [success, setSuccess] = useState(false);
  const [status, setStatus] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);

  useEffect(() => {
    async function getStatus() {
      try {
        const response = await api.getActivationStatus(email, token);
        setStatus(response.data);
      } catch (e) {
        // TODO: implement global error handling strategy and apply it here
      }
    }

    getStatus();
  }, [email, token]);

  const inputsDisabled = !status || status !== 'VALID' || isFetching || authIsFetching;

  async function activate() {
    try {
      setIsFetching(true);

      await api.activate(email, token, password);
      login(email, password, rememberMe);

      setIsFetching(false);
      setSuccess(true);
    } catch (e) {
      setIsFetching(false);
    }
  }

  function hasErrors() {
    return !allRequirementsSatisfied(password);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!isFetching && !authIsFetching) {
      if (!hasErrors()) {
        activate();
      }
    }
  }

  if (success && !authIsFetching) {
    return <Redirect to="/" />;
  }

  const statusLoading = !status && (
    <div>
      <LoadingIndicator>
        <Typography variant="subtitle2" component="span">
          Verifying your activation link...
        </Typography>
      </LoadingIndicator>
    </div>
  );

  const activationStatus = status && (
    <div>
      {status === 'VALID' && (
        <Typography component="p" variant="subtitle1">
          To finish activation and sign in, please set a password for your account
        </Typography>
      )}
      {(status === 'INVALID' || status === 'EXPIRED') && (
        <>
          <Typography component="p" variant="subtitle1" color="error">
            Your activation link is incorrect or expired.
          </Typography>
          <Typography variant="subtitle1">
            Please <MuiLink href="mailto:help@omnixlabs.com">contact support</MuiLink> for additional help.
          </Typography>
          <br />
          <Typography component="p" variant="subtitle1" color="textPrimary">
            Return to the{' '}
            <MuiLink component={Link} to="/">
              home page
            </MuiLink>
            .
          </Typography>
        </>
      )}
      {status === 'ALREADY_ACTIVE' && (
        <Typography component="p" variant="subtitle1">
          <strong>{email}</strong> account has been already activated. You can{' '}
          <MuiLink component={Link} to={`/login?email=${email}`}>
            Sign in
          </MuiLink>{' '}
          using this email and your password.
          <br />
          <br />
          Forgot the password? You can{' '}
          <MuiLink component={Link} to={`/forgot-password?email=${email}`}>
            reset it
          </MuiLink>{' '}
          here.
        </Typography>
      )}
    </div>
  );

  return (
    <BrandedFormPage>
      <div className={classes.root}>
        <SetTitle title="Account activation" />
        <MobileLogo />
        <Typography component="h1" variant="h6" gutterBottom>
          Account activation | {productName}
        </Typography>
        {statusLoading}
        {activationStatus}
        {(!status || status === 'VALID') && (
          <>
            <Grid
              component="form"
              container
              spacing={BLOCKS_SPACING}
              direction="column"
              wrap="nowrap"
              onSubmit={handleSubmit}
              className={classes.form}
            >
              <Grid item>
                <StaticTextField value={email} label="Email" />
              </Grid>
              <TextField
                style={{ display: 'none' }}
                aria-hidden="true"
                value={email}
                autoComplete="email"
                name="email"
                type="email"
                id="email"
              />
              <Grid item>
                <PasswordField
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  disabled={inputsDisabled}
                  label="New password"
                  id="newPassword"
                  name="newPassword"
                  autoComplete="new-password"
                  margin="none"
                  variant="outlined"
                  fullWidth
                  showRequirements
                />
              </Grid>
              <Grid item container direction="column" spacing={1}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={inputsDisabled}
                        checked={rememberMe}
                        onChange={(event, checked) => setRememberMe(checked)}
                        value="remember"
                        color="secondary"
                      />
                    }
                    label="Keep me signed in"
                  />
                </Grid>
                <Grid item container>
                  <Grid item sm={7} xs={12}>
                    <Button
                      disabled={!status || status !== 'VALID' || hasErrors()}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                    >
                      <AsyncActionLabel
                        label="Activate & Sign in"
                        fetchingLabel="Activating..."
                        isFetching={isFetching || authIsFetching}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </BrandedFormPage>
  );
}

function mapStateToProps(state) {
  return {
    authIsFetching: getAuth(state).get('isFetching'),
  };
}

export default withRouter(connect(mapStateToProps, { login: actions.loginRequest })(withStyles(styles)(Activation)));

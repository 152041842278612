import React, { useCallback, useState } from 'react';
import { chunk, sortBy, uniq } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { COMPANY_DASHBOARD_CONFIG, getUserTimezone } from 'reducers/auth';
import { params, reports, widgetReport } from 'api/report';
import { fromPromise } from 'mobx-utils';
import { observer } from 'mobx-react';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import { List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { endOfMinuteDateUtc } from 'components/dashboard/common/periods';
import SetTitle from 'components/common/SetTitle';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import toJS from 'components/common/toJS';
import moment from 'moment-timezone';
import { resolveLocationNameById } from 'components/common/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 864,
    maxHeight: 864,
    overflowY: 'hidden',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(4),
  },
  leftContainer: {},
  rightContainer: {
    display: 'grid',
    gridTemplateColumns: '66% 4% 30%',
  },
  title: {
    textTransform: 'uppercase',
  },
  list: {},
  listItem: {},
}));

const locations = uniq(
  COMPANY_DASHBOARD_CONFIG.flatMap(({ locations }) => locations)
    .flat()
    .map(({ id }) => id)
);

class State {
  reportResult = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadData(requestDate, timezone, _) {
    const requests = [
      widgetReport(reports.EMPLOYEE_SALES_LEADER_BOARD_BY_LOCATIONS, {
        [params.START_DATE]: moment.tz(requestDate, timezone).subtract(1, 'month').startOf('month').utc(),
        [params.END_DATE]: moment.tz(requestDate, timezone).subtract(1, 'month').endOf('month').utc(),
        [params.TIMEZONE]: timezone,
        [params.LOCATION_IDS]: locations,
      }),
      widgetReport(reports.EMPLOYEE_SALES_LEADER_BOARD_BY_LOCATIONS, {
        [params.START_DATE]: moment.tz(requestDate, timezone).startOf('isoWeek').utc(),
        [params.END_DATE]: endOfMinuteDateUtc(requestDate, timezone),
        [params.TIMEZONE]: timezone,
        [params.LOCATION_IDS]: locations,
      }),
      widgetReport(reports.EMPLOYEE_SALES_LEADER_BOARD_BY_LOCATIONS, {
        [params.START_DATE]: moment.tz(requestDate, timezone).startOf('day').utc(),
        [params.END_DATE]: endOfMinuteDateUtc(requestDate, timezone),
        [params.TIMEZONE]: timezone,
        [params.LOCATION_IDS]: locations,
      }),
    ];
    this.reportResult = fromPromise(Promise.all(requests));
  }

  get loading() {
    return this.reportResult === null || this.reportResult.state === 'pending';
  }

  get data() {
    if (this.reportResult === null || this.reportResult.state !== 'fulfilled') {
      return {};
    }
    const [lastMonthResponse, currentWeekResponse, todayResponse] = this.reportResult.value;
    return {
      lastMonth: sortBy(
        lastMonthResponse.data.rows.map((row) => ({
          ...row.row,
          locationName: resolveLocationNameById(row.row.locationId),
        })),
        'salesCount'
      )
        .reverse()
        .slice(0, 10),
      currentWeek: sortBy(
        currentWeekResponse.data.rows.map((row) => ({
          ...row.row,
          locationName: resolveLocationNameById(row.row.locationId),
        })),
        'salesCount'
      ).reverse(),
      today: sortBy(
        todayResponse.data.rows.map((row) => ({
          ...row.row,
          locationName: resolveLocationNameById(row.row.locationId),
        })),
        'salesCount'
      ).reverse(),
    };
  }
}

function IframeSalesLeaderboard({ location, history, userTimezone }) {
  const classes = useStyles();
  const [state] = useState(() => new State());
  const onSettingsChange = useCallback(
    (requestDate, timezone, period) => {
      state.loadData(requestDate, timezone, period);
    },
    [state]
  );
  useSettingsChangeActionDispatcher(location, history, userTimezone, onSettingsChange);

  if (state.loading || state.data === {}) {
    return null;
  }

  return (
    <>
      <SetTitle title="Sales leaderboard" />
      <div className={classes.root}>
        <div className={classes.leftContainer}>
          <Typography
            style={{ marginLeft: 16 }}
            display="block"
            color="textSecondary"
            variant="subtitle1"
            className={classes.title}
          >
            Today's leaderboard
          </Typography>
          <div style={{ display: 'grid', gridGap: 24, gridTemplateColumns: '1fr 1fr 1fr' }}>
            {chunk(state.data.today, 13)
              .slice(0, 3)
              .map((chunk, index) => (
                <List key={index} dense className={classes.list}>
                  {chunk.map((row) => (
                    <ListItem key={row.employeeId + row.locationId} className={classes.listItem}>
                      <ListItemText primary={row.employeeName} secondary={row.locationName} />
                      <ListItemSecondaryAction>
                        <Typography variant="h6">{row.salesCount}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ))}
          </div>
        </div>
        <div className={classes.rightContainer} style={{ paddingLeft: 16, borderLeft: '1px solid black' }}>
          <div>
            <Typography display="block" color="textSecondary" variant="subtitle1" className={classes.title}>
              Week to date leaderboard
            </Typography>
            <div style={{ display: 'grid', gridGap: 24, gridTemplateColumns: '1fr 1fr' }}>
              {chunk(state.data.currentWeek, 13)
                .slice(0, 2)
                .map((chunk, index) => (
                  <List key={index} dense className={classes.list}>
                    {chunk.map((row) => (
                      <ListItem key={row.employeeId + row.locationId} disableGutters>
                        <ListItemText primary={row.employeeName} secondary={row.locationName} />
                        <ListItemSecondaryAction>
                          <Typography variant="h6">{row.salesCount}</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                ))}
            </div>
          </div>
          <div />
          <div style={{ paddingLeft: 16, borderLeft: '1px solid black' }}>
            <Typography display="block" color="textSecondary" variant="subtitle1" className={classes.title}>
              Last month top 10 sellers
            </Typography>
            <List dense className={classes.list}>
              {state.data.lastMonth.map((row) => (
                <ListItem key={row.employeeId + row.locationId} disableGutters>
                  <ListItemText primary={row.employeeName} secondary={row.locationName} />
                  <ListItemSecondaryAction>
                    <Typography variant="h6">{row.salesCount}</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(connect(mapStateToProps)(toJS(observer(IframeSalesLeaderboard))));

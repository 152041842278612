import toJS from 'components/common/toJS';
import useSettingsChangeActionDispatcher from 'components/dashboard/camera/useSettingsChangeActionDispatcher';
import { composeTimeCategories, getTimeLabelFormatter } from 'components/dashboard/common/periods';
import ThreeValuesWidgetHeader from 'components/dashboard/common/ThreeValuesWidgetHeader';
import { prepareLegendData } from 'components/dashboard/common/utils';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import WidgetLegend from 'components/dashboard/common/WidgetLegend';
import COLORS from 'components/style/colors';
import { get } from 'lodash';
import React from 'react';
import { FaCarSide as CarIcon } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/camera/loyalCustomers';

const newColor = COLORS.LIGHT_BLUE;
const loyalColor = COLORS.DARK_YELLOW;

function LoyalCustomersWidget({ location, history, isFetching, requestDate, userTimezone, report, reportRequest }) {
  const [period, timezone] = useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest);

  if (!requestDate) {
    return null;
  }

  const series =
    report &&
    [report.new, report.loyal].map((s) => ({
      ...s,
      lineStyle: { width: 3 },
      smooth: true,
      showSymbol: false,
    }));
  const categories = composeTimeCategories(requestDate, timezone, period);
  const legendData = report && prepareLegendData(series).sort((a, b) => b.value - a.value);

  return (
    <Widget title="Loyalty">
      <>
        <ThreeValuesWidgetHeader
          left={{ name: 'New', value: get(report, 'new.value'), color: newColor }}
          right={{ name: 'Loyal', value: get(report, 'loyal.value'), color: loyalColor }}
        />
        <ValueOverTimeChart
          isFetching={isFetching}
          type="line"
          categories={categories}
          categoriesFormatter={getTimeLabelFormatter(requestDate, timezone, period)}
          series={series}
        />
        <WidgetLegend data={legendData} icon={<CarIcon />} />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(LoyalCustomersWidget))
);

import { makeStyles, Paper, Typography } from '@material-ui/core';
import toJS from 'components/common/toJS';
import { getInsightsDay } from 'components/dashboard/common/periods';
import { capitalize, get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { getLocationsWithInsights } from 'reducers/auth';
import { getCustomersReport, getReportTimezone, getRequestDate } from 'reducers/insights';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  header: {
    paddingLeft: theme.spacing(1),
    borderLeft: '4px solid',
    borderColor: theme.palette.secondary.main,
  },
  headerDow: {
    textTransform: 'uppercase',
  },
}));

function InsightsOverviewWidget({ requestDate, timezone, period, report, locationId, availableLocations }) {
  const classes = useStyles();

  const periodTitle = capitalize(period);
  const locationName = get(availableLocations, `${locationId}.name`, '');
  const day = getInsightsDay(requestDate, timezone);
  const total =
    report &&
    report[0].data.reduce(
      (acc, obj, index) => {
        acc[index < 12 ? 0 : 1] += obj === null ? 0 : obj;
        return acc;
      },
      [0, 0]
    );

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography variant="subtitle1" component="div" color="textSecondary" className={classes.headerDow}>
          {day.format('dddd')}
        </Typography>
        <Typography variant="h4" component="div">
          {day.format('MMMM D')}
        </Typography>
      </div>
      <div>
        <Typography variant="h6" component="div" gutterBottom>
          {`${periodTitle}'s`} prediction
        </Typography>
        {report ? (
          <Typography variant="body1">
            Hi {locationName} team. {periodTitle} expect about {total[0]} customers in the morning and about {total[1]}{' '}
            customers in the afternoon.
          </Typography>
        ) : (
          <Typography variant="body1">
            Sorry, prediction data isn't available at the moment. Please try again later.
          </Typography>
        )}
      </div>
      <div>
        <Typography variant="subtitle1" component="div" color="textSecondary" align="right">
          Time in location (GMT{moment.tz(requestDate, timezone).format('Z')})
        </Typography>
        <Typography variant="body1" component="div" align="right">
          {moment.tz(requestDate, timezone).format('ddd HH:mm')}
        </Typography>
      </div>
    </Paper>
  );
}

function mapStateToProps(state) {
  return {
    requestDate: getRequestDate(state),
    report: getCustomersReport(state),
    timezone: getReportTimezone(state),
    availableLocations: getLocationsWithInsights(state),
  };
}

export default connect(mapStateToProps, null)(toJS(InsightsOverviewWidget));

import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import DashboardControls, { rewashesMode } from 'components/dashboard/common/DashboardControls';
import { parsePeriod } from 'components/dashboard/common/periods';
import { carMakeName } from 'components/dashboard/location/VehicleMakesWidget';
import RewashesCountWidget from 'components/reports/RewashesCountWidget';
import { upperFirst } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDefaultLocationIdWithRewashes, getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching, isReportInitial } from 'reducers/reports/rewashes';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  inputRoot: {
    backgroundColor: 'white',
    paddingLeft: 12,
    borderRadius: 4,
    borderBottom: 'unset !important',
    '&::before': {
      borderBottom: 'unset !important',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  averageContainer: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
  total: {
    width: '100%',
  },
  tablePaper: {
    width: '100%',
    overflowX: 'auto',
    minHeight: 200,
    alignSelf: 'flex-start',
  },
  table: {
    minWidth: 600,
  },
  controlsContainer: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));

function Rewashes({
  location,
  history,
  requestDate,
  userTimezone,
  defaultLocationId,
  report,
  isFetching,
  isInitial,
  reportRequest,
  onUnmount,
}) {
  const classes = useStyles();
  const [locationId] = useQueryParam('location', v => v || defaultLocationId, location, history);
  const timezone = userTimezone || moment.tz.guess();
  const [period] = useQueryParam('period', value => parsePeriod(value, timezone), location, history);
  useWillUnmount(onUnmount);
  useEffect(() => {
    localStorage.setItem('lastUsedLocationId', locationId);
  }, [locationId]);
  useEffect(() => {
    if (locationId) {
      reportRequest(moment.tz(timezone).format(), timezone, period, locationId);
    }
  }, [period, location, locationId, timezone, reportRequest]);
  const showInitialBlock = isInitial && !isFetching;

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Rewashes" />
      <Grid item>
        <DashboardControls mode={rewashesMode} />
      </Grid>
      <Grid item className={classes.content}>
        {!isFetching && report && report.details ? (
          <>
            <div className={classes.averageContainer}>
              <RewashesCountWidget
                requestDate={requestDate}
                timezone={timezone}
                period={period}
                report={report.total}
              />
            </div>
            <Paper className={classNames(classes.tablePaper, classes.grow)}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">License plate</TableCell>
                    <TableCell align="left">Rewashes</TableCell>
                    <TableCell align="left" sortDirection="desc">
                      <TableSortLabel disabled active direction="desc">
                        First wash at
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">Last wash at</TableCell>
                    <TableCell align="left">Make</TableCell>
                    <TableCell align="left">Color</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.details.map((item, index) => {
                    const firstMoment = moment.tz(item.firstTime, 'UTC');
                    const lastMoment = moment.tz(item.lastTime, 'UTC');

                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {item.licensePlate}
                        </TableCell>
                        <TableCell align="left">{item.rewashCount}</TableCell>
                        <TableCell align="left">{firstMoment.format('MM/DD/YYYY HH:mm')}</TableCell>
                        <TableCell align="left">{lastMoment.format('MM/DD/YYYY HH:mm')}</TableCell>
                        <TableCell align="left">{carMakeName(item.make)}</TableCell>
                        <TableCell align="left">{upperFirst(item.color)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </>
        ) : null}
        {showInitialBlock && (
          <Typography variant="h5" component="span" align="center">
            To see how many rewashes was made during a specific period,
            <br />
            please select the location
          </Typography>
        )}
        {isFetching && <BrandedLoadingIndicator />}
        {!isFetching && !isInitial && (!report || !report.details) && locationId && (
          <Typography variant="h5" component="span" align="center">
            No rewashes found for this location and period
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
    defaultLocationId: getDefaultLocationIdWithRewashes(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    isFetching: isReportFetching(state),
    isInitial: isReportInitial(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest, onUnmount: actions.reset })(toJS(Rewashes))
);

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { DatePicker } from '@material-ui/pickers';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import toJS from 'components/common/toJS';
import { dateFormat, minDate } from 'components/dashboard/common/periods';
import ReportActionMenu from 'components/reports/ReportActionMenu';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useDidUpdate } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { isSuperAdmin } from 'reducers/auth';
import { actions, isDownloadFetching } from 'reducers/dashboard/camera/snapshotsDownloader';

const useStyles = makeStyles((theme) => ({
  downloadIcon: {
    marginRight: theme.spacing(1),
  },
  datePicker: {
    width: '100%',
  },
  inputRoot: {
    width: '100%',
    minHeight: 40,
    backgroundColor: '#F0F2F7',
    paddingLeft: 12,
    borderRadius: 4,
    borderBottom: 'unset !important',
    '&::before': {
      borderBottom: 'unset !important',
    },
  },
}));

function SnapshotsDownloader({ jobId, superAdmin, timezone, isFetching, downloadRequest }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [day, setDay] = useState(moment.tz(timezone).format(dateFormat));
  useDidUpdate(() => {
    if (!isFetching) {
      setOpen(false);
    }
  }, [isFetching]);

  if (!superAdmin) {
    return null;
  }

  return (
    <>
      <ReportActionMenu label="Download snapshots" isDownloadFetching={isFetching} onDownload={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="snapshots-downloader-dialog-title"
        aria-describedby="snapshots-downloader-dialog-description"
      >
        <DialogTitle id="snapshots-downloader-dialog-title">Download cameras snapshots</DialogTitle>
        <DialogContent id="snapshots-downloader-dialog-description">
          <DatePicker
            className={classes.datePicker}
            autoOk
            showTodayButton
            label="Day"
            format={dateFormat}
            value={moment.tz(day, dateFormat, timezone)}
            minDate={moment.tz(minDate, dateFormat, timezone)}
            maxDate={moment.tz(timezone)}
            InputProps={{ className: classes.inputRoot }}
            onChange={(value) => setDay(value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} disabled={isFetching} variant="text" color="default">
            Cancel
          </Button>
          <Button
            onClick={() => {
              const start = moment.tz(day, dateFormat, timezone).startOf('day').utc().toISOString();
              const end = moment.tz(day, dateFormat, timezone).endOf('day').utc().toISOString();
              downloadRequest(start, end, jobId);
            }}
            variant="contained"
            color="secondary"
          >
            {!isFetching && <DownloadIcon className={classes.downloadIcon} />}
            <AsyncActionLabel isFetching={isFetching} fetchingLabel="Downloading..." label="Download" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    superAdmin: isSuperAdmin(state),
    isFetching: isDownloadFetching(state),
  };
}

export default connect(mapStateToProps, {
  downloadRequest: actions.downloadRequest,
})(toJS(SnapshotsDownloader));

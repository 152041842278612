import { createAverageVisitsDurationReportSettings, reports, widgetReport } from 'api/report';
import { resolveLocationNameById } from 'components/common/utils';
import { cloneDeep } from 'lodash';
import { COMPANY_DASHBOARD_CONFIG } from 'reducers/auth';
import { actions, types } from 'reducers/dashboard/company/summary';
import { all, call, put, takeLatest } from 'redux-saga/effects';

function* fetchReports({ requestDate, timezone, period }) {
  try {
    const config = cloneDeep(COMPANY_DASHBOARD_CONFIG);
    const requests = [];
    config.slice(0, 1).forEach(({ locations }) => {
      locations.forEach(({ id, enterTags, exitTags }) => {
        requests.push(
          call(
            widgetReport,
            reports.VEHICLE_WAIT_TIME_BY_TAGS,
            createAverageVisitsDurationReportSettings(requestDate, timezone, period, id, enterTags, exitTags)
          )
        );
      });
    });
    const responses = yield all(requests);
    let responseIndex = 0;
    const report = config.slice(0, 1).map((entry) => {
      return {
        ...entry,
        locations: entry.locations.map((location) => {
          const response = responses[responseIndex].data;
          responseIndex++;
          return {
            ...location,
            name: resolveLocationNameById(location.id),
            report: response,
          };
        }),
      };
    });
    yield put(actions.reportSuccess(requestDate, timezone, period, report));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, e));
  }
}

export default function* summary() {
  yield takeLatest(types.REPORT_REQUEST, fetchReports);
}

import { LinearProgress, withStyles } from '@material-ui/core';
import Logo from 'components/common/Logo';
import React, { useEffect, useState } from 'react';

const styles = () => ({
  root: {
    background: `radial-gradient(circle, #00004B 0%, #1F2018 75%)`,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  logo: {
    width: 280,
  },
  progress: {
    width: 280,
    backgroundColor: 'white',
  },
});

function SplashScreen({ classes }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setVisible(true), 1000);
    return () => clearTimeout(id);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Logo white className={classes.logo} />
      <LinearProgress color="primary" className={classes.progress} />
    </div>
  );
}

export default withStyles(styles)(React.memo(SplashScreen));

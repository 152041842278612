import * as api from 'api/location';
import mixpanel from 'components/common/mixpanel';
import { actions, getItems, types } from 'reducers/location/list';
import { call, put, select, takeLatest } from 'redux-saga/effects';

function* searchLocations({ query = '', sorting, append }) {
  try {
    const limit = 20;
    const sort = sorting.map(({ columnName, direction }) => ({
      name: columnName === 'company' ? 'company.raw' : columnName,
      order: direction.toUpperCase(),
    }));
    const searchParams = { query, limit, sort };

    const items = yield select((state) => getItems(state));
    if (append && items && items.size > 0) {
      searchParams.tieBreakerId = items.last().get('id');
      searchParams.searchAfter = sorting.map(({ columnName }) => {
        const item = items.last();
        if (columnName === 'company') {
          return item.get('company').get('name');
        } else if (columnName === 'createdAt') {
          return new Date(item.get('createdAt')).getTime();
        } else if (columnName === 'fraudDataSyncAt') {
          const fraudSync = item.getIn(['stats', 'fraudDataSyncAt']);
          return fraudSync ? new Date(fraudSync).getTime() : 0;
        } else if (columnName === 'notificationsSent24h') {
          const notifications = item.getIn(['stats', 'notificationsSent24h']);
          return notifications ? notifications : 0;
        } else if (columnName === 'rewashCount24h') {
          const rewashes = item.getIn(['stats', 'rewashCount24h']);
          return rewashes ? rewashes : 0;
        } else if (columnName === 'membershipSold24h') {
          const membershipSold = item.getIn(['stats', 'membershipSold24h']);
          return membershipSold ? membershipSold : 0;
        } else if (columnName === 'camerasUptime24h') {
          const camerasUptime24h = item.getIn(['stats', 'camerasUptime24h']);
          return camerasUptime24h ? camerasUptime24h : 0;
        } else {
          return item.get(columnName);
        }
      });
    }

    const response = yield call(api.searchLocations, searchParams);
    yield put(actions.searchSuccess(response.data.items, response.data.paging, append));

    if (!append) {
      mixpanel((mp) => mp.track('Location search', { query }));
    }
  } catch (e) {
    yield put(actions.searchFailure(e));
  }
}

export default function* list() {
  yield takeLatest(types.SEARCH_REQUEST, searchLocations);
}

import { parse, stringify } from 'qs';

export function clearQueryParams(names, location, history) {
  const parseResult = parse(location.search, { ignoreQueryPrefix: true });
  names.forEach(name => (parseResult[name] = undefined));
  const search = stringify({
    ...parseResult,
  });
  history.push({
    pathname: location.pathname,
    search,
  });
}

export default function useQueryParam(name, mapper, location, history) {
  const parseResult = parse(location.search, { ignoreQueryPrefix: true });
  const param = mapper(parseResult[name]);

  function setParam(value) {
    const search = stringify({
      ...parse(location.search, { ignoreQueryPrefix: true }),
      [name]: value,
    });
    history.push({
      pathname: location.pathname,
      search,
    });
  }

  return [param, setParam];
}

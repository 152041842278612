import { Fab, makeStyles, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import NotificationEditor from 'components/location/NotificationEditor';
import { cloneDeep, pullAt } from 'lodash';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  empty: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  fabContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const newEntry = {
  enabled: false,
  name: '',
  messageSubject: '',
  messageTemplate: null,
  range: 1,
  rangeUnit: 'DAYS',
  thresholdMin: 1,
  thresholdMax: 2,
  senderTypes: [],
  emails: [],
  telegramSettings: '{"chatId": null}',
  lightboxSettings: '{"apiSettings": [{"jobIds": [], "url": ""}]}',
};

function NotificationsEditor({ value, onChange, wasSubmit }) {
  const classes = useStyles();

  if (!value?.length) {
    return (
      <div className={classes.empty}>
        <Typography variant="body1" align="center">
          Notifications are not configured for this location
          <br />
          Add one to start!
        </Typography>
        <Tooltip title="Add notification">
          <Fab
            onClick={() => {
              const newValue = cloneDeep(value);
              onChange([cloneDeep(newEntry), ...newValue]);
            }}
            size="small"
            color="secondary"
            aria-label="Create location"
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
    );
  }

  return (
    <>
      <div className={classes.fabContainer}>
        <Tooltip title="Add notification">
          <Fab
            onClick={() => {
              const newValue = cloneDeep(value);
              onChange([cloneDeep(newEntry), ...newValue]);
            }}
            size="small"
            color="secondary"
            aria-label="Create location"
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      {value.map((entry, index) => {
        const id = entry.id || index;
        return (
          <NotificationEditor
            key={id}
            id={id}
            onDelete={() => {
              const newValue = cloneDeep(value);
              pullAt(newValue, [index]);
              onChange(newValue);
            }}
            onChange={(field, v) => {
              const newValue = cloneDeep(value);
              newValue[index][field] = v;
              onChange(newValue);
            }}
            value={entry}
            wasSubmit={wasSubmit}
          />
        );
      })}
    </>
  );
}

export default NotificationsEditor;

import { observer } from 'mobx-react';
import useQueryParam from 'components/common/useQueryParam';
import { withRouter } from 'react-router';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  withWidth,
} from '@material-ui/core';
import SetTitle from 'components/common/SetTitle';
import React, { useEffect, useState } from 'react';
import { makeAutoObservable, observable } from 'mobx';
import { isWidthUp } from '@material-ui/core/withWidth';
import Widget from 'components/dashboard/common/Widget';
import MasonryLayout from 'components/common/MasonryLayout';
import WidgetHeaderTrend from 'components/dashboard/common/WidgetHeaderTrend';
import { AutoSizer } from 'react-virtualized';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import HouseIcon from '@material-ui/icons/House';
import IncomeIcon from '@material-ui/icons/AccountBalance';
import HouseValueIcon from '@material-ui/icons/LocalOffer';
import PersonIcon from '@material-ui/icons/Person';
import PopulationIcon from '@material-ui/icons/Group';
import GasStationIcon from '@material-ui/icons/LocalGasStation';
import SupermarketIcon from '@material-ui/icons/ShoppingCart';
import ShoppingMallIcon from '@material-ui/icons/LocalMall';
import CompetitorsIcon from '@material-ui/icons/Business';
import MajorCompetitorsIcon from '@material-ui/icons/EmojiTransportation';
import TrafficIcon from '@material-ui/icons/DirectionsCar';
import { parse, stringify } from 'qs';
import StaticTextField from 'components/common/StaticTextField';
import { Link } from 'react-router-dom';
import MapContainer from 'components/dashboard/location-intelligence/MapContainer';
import axios from 'axios';
import pluralize from 'pluralize';
import BackIcon from '@material-ui/icons/ChevronLeft';
import NearbyMap from 'components/dashboard/location-intelligence/NearbyMap';
import mixpanel from 'components/common/mixpanel';
import OilChangersSitePicker from 'components/common/OilChangersSitePicker';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import COLORS from 'components/style/colors';
import { useWillUnmount } from 'react-hooks-lib';
import { isNumber, random } from 'lodash';
import LocationIntelligenceSplashScreen from 'components/dashboard/location-intelligence/LocationIntelligenceSplashScreen';
import LocationSummary from 'components/dashboard/location-intelligence/LocationSummary';
import { gaugeSizeInPercentages } from 'components/dashboard/location-intelligence/LocationIntelligence';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { downloadAjaxResponse } from 'components/common/utils';
import store from 'reducers';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import GenderDistributionChart from 'components/dashboard/location-intelligence/GenderDistributionChart';
import RaceDistributionChart from 'components/dashboard/location-intelligence/RaceDistributionChart';
import BusinessIcon from '@material-ui/icons/Business';
import EmployeeIcon from '@material-ui/icons/AccountBox';
import PayrollIcon from '@material-ui/icons/LocalAtm';

const MajorCompetitorsList = ['Take 5', 'Jiffy Lube', 'Valvoline'];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  masonryContainer: {
    flexGrow: 1,
    flexShrink: 0,
    alignSelf: 'flex-start',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  gaugeContainer: {
    minHeight: 270,
  },
  mapContainer: {
    '& > div': {
      width: '100%',
      height: '100%',
    },
  },
}));

class State {
  lat = 0;
  long = 0;
  size = 0;
  numberOfBays = 0;
  accessPoints = 0;
  existingSite = false;
  loading = false;
  loadingDelay = 30000;
  loadingStep = -1;
  data = undefined;
  pdfLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  dispose() {
    this.clearLoadingInterval();
  }

  clearLoadingInterval() {
    if (this.loadingTickInterval) {
      clearInterval(this.loadingTickInterval);
      this.loadingTickInterval = undefined;
    }
  }

  async setParams(lat, long, size, numberOfBays, accessPoints, existingSite) {
    this.lat = lat ?? 0;
    this.long = long ?? 0;
    this.size = size > 0 ? size : 0;
    this.numberOfBays = numberOfBays > 0 ? numberOfBays : 0;
    this.accessPoints = accessPoints > 0 ? accessPoints : 0;
    this.existingSite = existingSite ?? false;
    if (this.lat !== 0 && this.long !== 0 && this.size > 0) {
      try {
        this.loading = true;
        this.loadingStep = 0;
        this.loadingDelay = random(28000, 34000);
        this.clearLoadingInterval();
        this.loadingTickInterval = setInterval(() => {
          if (this.loadingStep < 8) {
            this.loadingStep++;
          } else {
            this.clearLoadingInterval();
          }
        }, this.loadingDelay / 8);
        const response = await axios.get(
          `/proxy/oilchange-newsite-prediction/api/?la=${this.lat}&lo=${this.long}&lot_size=${this.size}&existing_site=${this.existingSite}&access_points=${this.accessPoints}&number_of_bays=${this.numberOfBays}`
        );
        this.data = observable(response.data);
        mixpanel((mp) =>
          mp.track('Lube location intelligence', {
            lat: this.lat,
            long: this.long,
            lotSize: this.size,
            numberOfBays: this.numberOfBays,
            accessPoints: this.accessPoints,
            existingSite: this.existingSite,
          })
        );
      } catch (e) {
        this.data = undefined;
        this.clearLoadingInterval();
        this.loadingStep = -1;
      } finally {
        this.loading = false;
      }
    }
  }

  downloadPdf = async () => {
    if (this.pdfLoading) {
      return;
    }

    try {
      this.pdfLoading = true;
      const data = this.data;
      data.lot = `${this.size} ft², ${this.accessPoints} access ${this.accessPoints > 1 ? 'points' : 'point'}`;
      const response = await axios.post('/apigw/v1/pdf/oilchange-newsite-prediction/report.pdf', data, {
        responseType: 'arraybuffer',
      });
      downloadAjaxResponse(response, 'PDF', 'omniX Site Predictor Report.pdf');
    } catch {
      store.dispatch(snackActions.set('Failed to download PDF, please try again later', snackVariants.error));
    } finally {
      this.pdfLoading = false;
    }
  };

  get welcomeState() {
    return this.lat === 0 || this.long === 0 || this.size <= 0;
  }

  get showSplashScreen() {
    return !this.welcomeState && (this.loading || (this.loadingStep >= 0 && this.loadingStep < 8));
  }

  get majorCompetitorsNames() {
    const competitorCountMap = this.data?.major_competitors.reduce((acc, competitor) => {
      const competitorNameLower = competitor.name.toLowerCase();
      const knownCompetitorName = MajorCompetitorsList.find((name) => {
        const nameRegex = new RegExp('\\b' + name.toLowerCase() + '\\b');

        return nameRegex.test(competitorNameLower);
      });

      if (knownCompetitorName) {
        const currentCompetitorCount = acc[knownCompetitorName]?.count;

        acc[knownCompetitorName] = {
          name: knownCompetitorName,
          count: (currentCompetitorCount ?? 0) + 1,
        };
      }

      return acc;
    }, {});

    return Object.values(competitorCountMap ?? {})
      .map(({ name, count }) => {
        const competitorPluralName = pluralize(name, count);

        return `${competitorPluralName} (${count})`;
      })
      .join(', ');
  }
}

function OilChangersLocationIntelligence({ location, history, width }) {
  const classes = useStyles();
  const [state] = useState(() => new State());
  const [lat] = useQueryParam('lat', (v) => v, location, history);
  const [long] = useQueryParam('long', (v) => v, location, history);
  const [size] = useQueryParam('size', (v) => v, location, history);
  const [numberOfBays] = useQueryParam('numberOfBays', (v) => v, location, history);
  const [accessPoints] = useQueryParam('accessPoints', (v) => v, location, history);
  const [existingSite] = useQueryParam('existingSite', (v) => v, location, history);
  useEffect(() => {
    state.setParams(lat, long, size, numberOfBays, accessPoints, existingSite);
  }, [state, lat, long, size, numberOfBays, accessPoints, existingSite]);
  useWillUnmount(() => state.dispose());

  let columns = 1;
  let mapXs = 12;
  if (isWidthUp('md', width)) {
    mapXs = 6;
    columns = 2;
  }
  if (isWidthUp('lg', width)) {
    mapXs = 8;
    columns = 3;
  }

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Lube site predictor" />
      {state.welcomeState && (
        <Grid item>
          <OilChangersSitePicker
            onSubmit={(lat, long, size, numberOfBays, accessPoints, existingSite) => {
              const search = stringify({
                ...parse(location.search, { ignoreQueryPrefix: true }),
                lat,
                long,
                size,
                numberOfBays,
                accessPoints,
                existingSite,
              });
              history.push({
                pathname: location.pathname,
                search,
              });
            }}
          />
        </Grid>
      )}
      <Grid item className={classes.content}>
        {state.showSplashScreen && (
          <LocationIntelligenceSplashScreen step={state.loadingStep} totalDuration={state.loadingDelay} />
        )}
        {!state.welcomeState && !state.showSplashScreen && (
          <div className={classes.masonryContainer} style={{ position: 'relative' }}>
            <Button
              disabled={state.pdfLoading}
              onClick={state.downloadPdf}
              style={{ position: 'absolute', top: 0, right: 0 }}
              variant="outlined"
            >
              <DownloadIcon style={{ marginRight: 8 }} />
              Download PDF
            </Button>
            <MasonryLayout columns={columns} gap={32} style={{ marginTop: 56 }}>
              <Widget
                title="Location"
                titleAction={
                  <Button
                    style={{ whiteSpace: 'nowrap' }}
                    component={Link}
                    to="/oclocationintelligence"
                    fullWidth
                    color="secondary"
                    variant="text"
                    size="small"
                  >
                    <BackIcon style={{ marginRight: 8 }} />
                    Analyze another
                  </Button>
                }
              >
                <div
                  style={{
                    padding: 16,
                  }}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <StaticTextField value={state?.data?.address || `${lat}, ${long}`} label="Address" />
                    </Grid>
                    <Grid item>
                      <StaticTextField
                        value={`${size} ft², ${numberOfBays} ${
                          numberOfBays > 1 ? 'bays' : 'bay'
                        }, ${accessPoints} access ${accessPoints > 1 ? 'points' : 'point'}`}
                        label="Lot"
                      />
                    </Grid>
                    <Grid item>
                      <AutoSizer disableHeight>
                        {({ width }) => {
                          if (width === 0) {
                            return null;
                          }

                          const height = width / 1.78;

                          return (
                            <div
                              style={{
                                width,
                                height,
                              }}
                              className={classes.mapContainer}
                            >
                              <MapContainer lat={lat} long={long} />
                            </div>
                          );
                        }}
                      </AutoSizer>
                    </Grid>
                  </Grid>
                </div>
              </Widget>
              <Widget title="Customer volume prediction" PaperProps={{ elevation: 10 }}>
                <WidgetHeaderTrend
                  name="Expected mature site monthly customers"
                  headerValue={state.data?.predicted_monthly_volume?.third_year_average_monthly_volume?.toLocaleString()}
                  value={0}
                  period="last week"
                />
                <AutoSizer disableHeight className={classes.gaugeContainer}>
                  {({ width }) => {
                    if (width === 0) {
                      return null;
                    }

                    const height = width / 1.78;
                    const gaugeSizePercentages = gaugeSizeInPercentages(width);
                    const options = {
                      series: [
                        {
                          type: 'gauge',
                          center: [width / 2, '43%'],
                          startAngle: 180,
                          endAngle: 0,
                          min: 0,
                          max: 1,
                          splitNumber: 8,
                          axisLine: {
                            lineStyle: {
                              width: 50,
                              color: [
                                [0.16666666666, '#ae0001'],
                                [0.33333333332, '#e60002'],
                                [0.49999999998, '#e47435'],
                                [0.66666666664, '#f5c408'],
                                [0.8333333333, '#95dd55'],
                                [1, '#0fc652'],
                              ],
                            },
                          },
                          pointer: {
                            itemStyle: {
                              color: 'auto',
                            },
                          },
                          axisTick: {
                            show: false,
                          },
                          splitLine: {
                            show: false,
                          },
                          axisLabel: {
                            show: false,
                          },
                          title: {
                            show: false,
                          },
                          detail: {
                            show: true,
                            offsetCenter: [0, '30%'],
                            formatter: () => `{header|${state.data?.score ?? 0} of 100}\n{title|location rating}`,
                            rich: {
                              header: {
                                fontFamily: 'Roboto',
                                fontSize: 32,
                                fontWeight: 400,
                                color: 'rgba(0, 0, 0, 0.87)',
                                letterSpacing: 0.2499,
                              },
                              title: {
                                fontFamily: 'Roboto',
                                fontSize: 16,
                                color: 'rgba(0, 0, 0, 0.54)',
                              },
                            },
                          },
                          data: [
                            {
                              value: (state.data?.score ?? 0) / 100,
                            },
                          ],
                        },
                      ],
                    };

                    return (
                      <WidgetChart
                        width={width}
                        height={height}
                        isFetching={false}
                        options={options}
                        style={{ width: `${gaugeSizePercentages}%`, height: `${gaugeSizePercentages}%` }}
                      />
                    );
                  }}
                </AutoSizer>
              </Widget>
              <Widget title="Key stats" style={{ minHeight: 'unset' }}>
                <div>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <TrafficIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.traffic_count?.toLocaleString() ?? '—'}</strong> annual average daily
                            traffic
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <HouseIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.households_per_zipcode?.toLocaleString() ?? '—'}</strong> households
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <IncomeIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>${state.data?.income_per_household?.toLocaleString() ?? '—'}</strong> average
                            household income
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <HouseValueIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>${state.data?.average_house_value?.toLocaleString() ?? '—'}</strong> average house
                            value
                          </>
                        }
                      />
                    </ListItem>
                  </List>
                </div>
              </Widget>
              <Widget title="Population" style={{ minHeight: 'unset' }}>
                <div>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <PopulationIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.population?.toLocaleString() ?? '—'}</strong> people live nearby
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.median_age?.toLocaleString() ?? '—'}</strong> median age
                          </>
                        }
                        secondary={
                          isNumber(state.data?.median_age_male) && isNumber(state.data?.median_age_female) ? (
                            <>
                              <strong>{state.data?.median_age_male?.toLocaleString()}</strong> male,{' '}
                              <strong>{state.data?.median_age_female?.toLocaleString()}</strong> female
                            </>
                          ) : null
                        }
                      />
                    </ListItem>
                  </List>
                  <GenderDistributionChart data={state.data} />
                  <RaceDistributionChart data={state.data} />
                </div>
              </Widget>
              <div />
              <Widget title="Business climate" style={{ minHeight: 'unset' }}>
                <div>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.number_of_businesses?.toLocaleString() ?? '—'}</strong> businesses
                            nearby
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <EmployeeIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.number_of_employees?.toLocaleString() ?? '—'}</strong> employees
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <PayrollIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>${state.data?.business_annual_payroll?.toLocaleString() ?? '—'}</strong> business
                            annual payroll
                          </>
                        }
                      />
                    </ListItem>
                  </List>
                </div>
              </Widget>
              <div />
              <Widget title="Growth trajectory" style={{ minHeight: 'unset' }}>
                <ValueOverTimeChart
                  isFetching={false}
                  type="line"
                  categories={['1st year', '2nd year', '3rd year']}
                  optionsCustomizer={(options) => {
                    options.tooltip.formatter = (params) =>
                      `${params[0].axisValue}<br /> <table>${params.map((p) => {
                        const value = p.data;
                        return `<tr><td>${p.marker} ${
                          p.seriesName
                        }</td><td style="text-align: right;padding-left: 8px"><b>${value.toLocaleString()}</b></td></tr><tr><td>${
                          p.marker
                        } Annual customers</td><td style="text-align: right;padding-left: 8px"><b>${(
                          value * 12
                        ).toLocaleString()}</b></td></tr>`;
                      })}
                        </table>`;
                  }}
                  series={[
                    {
                      name: 'Monthly customers',
                      itemStyle: { color: COLORS.DARK_YELLOW },
                      smooth: true,
                      data: [
                        state.data?.predicted_monthly_volume?.first_year_average_monthly_volume,
                        state.data?.predicted_monthly_volume?.second_year_average_monthly_volume,
                        state.data?.predicted_monthly_volume?.third_year_average_monthly_volume,
                      ],
                    },
                  ]}
                />
              </Widget>
              <Widget title="Places nearby" style={{ minHeight: 'unset' }}>
                <div>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <MajorCompetitorsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.major_competitors?.length ?? '—'}</strong> major{' '}
                            {pluralize('competitor', state.data?.major_competitors?.length || 0, false)}
                            <br />
                            <Typography component="span" variant="caption" color="textSecondary">
                              {state.majorCompetitorsNames}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CompetitorsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.competitors?.length ?? '—'}</strong>{' '}
                            {pluralize('competitor', state.data?.competitors?.length || 0, false)}
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <GasStationIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.near_by_gas_station?.toLocaleString() ?? '—'}</strong> gas{' '}
                            {pluralize('station', state.data?.near_by_gas_station || 0, false)}
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <SupermarketIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.near_by_supermarket?.toLocaleString() ?? '—'}</strong>{' '}
                            {pluralize('supermarket', state.data?.near_by_supermarket || 0, false)}
                          </>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ShoppingMallIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <strong>{state.data?.near_by_shopping_mall_plaza?.toLocaleString() ?? '—'}</strong> shopping{' '}
                            {pluralize('mall', state.data?.near_by_shopping_mall_plaza || 0, false)}/
                            {pluralize('plaza', state.data?.near_by_shopping_mall_plaza || 0, false)}
                          </>
                        }
                      />
                    </ListItem>
                  </List>
                </div>
              </Widget>
              <LocationSummary state={state.data} />
            </MasonryLayout>
            <Grid container spacing={4}>
              <Grid item xs={mapXs}>
                <NearbyMap
                  lat={lat}
                  long={long}
                  data={state.data}
                  blocks={[
                    {
                      name: 'Competitors',
                      dataPath: 'competitors',
                    },
                    {
                      name: 'Major competitors',
                      dataPath: 'major_competitors',
                    },
                    {
                      name: 'Retailers',
                      dataPath: 'attractions.select_retailers',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default withRouter(withWidth()(observer(OilChangersLocationIntelligence)));

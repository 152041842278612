import toJS from 'components/common/toJS';
import MiniWidget from 'components/dashboard/common/MiniWidget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import { round } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/location/memberFraudMini';

function MemberFraudMiniWidget({
  location,
  history,
  isFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
  defaultLocationId,
}) {
  useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest, defaultLocationId);

  if (!requestDate) {
    return null;
  }

  const { mismatch, ratio } = report ?? {};
  const helperText = ratio > 0 ? `(${round(ratio, 1)}%)` : null;
  return <MiniWidget isFetching={isFetching} title="Mismatches" value={mismatch} valueHelperText={helperText} />;
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(MemberFraudMiniWidget))
);

import moment from 'moment';
import React from 'react';

function LocationOpeningHours({ value }) {
  const { openTime, closeTime } = value;
  if (openTime && closeTime) {
    return (
      <>
        {moment(openTime, 'HH:mm:ss').format('HH:mm')} — {moment(closeTime, 'HH:mm:ss').format('HH:mm')}
      </>
    );
  }
  return '24h';
}

export default LocationOpeningHours;

import { makeAutoObservable, runInAction } from 'mobx';
import { last } from 'lodash';
import { searchCompanies, updateCompany } from 'api/company';

const COMPANIES_LIMIT = 20;

class CompanyListState {
  _companies = [];
  _companyEdit = null;
  _isLoading = false;
  _isLoadingMore = false;
  _totalAmount = 0;
  _sorting = [{ columnName: 'createdAt', direction: 'desc' }];

  constructor() {
    makeAutoObservable(this);
  }

  get companies() {
    return this._companies;
  }

  get companiesAmount() {
    return this._totalAmount;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isLoadingMore() {
    return this._isLoadingMore;
  }

  get companyEdit() {
    return this._companyEdit;
  }

  get sorting() {
    return this._sorting;
  }

  searchCompanies = async (query = '', sorting, isAppend) => {
    const limit = COMPANIES_LIMIT;
    const sort = sorting.map(({ columnName, direction }) => ({ name: columnName, order: direction.toUpperCase() }));
    const searchParams = { query, limit, sort };

    if (isAppend) {
      if (this._companies.length === this._totalAmount) {
        return;
      }
      this._isLoadingMore = true;

      const { id, createdAt, carWashApiRemainingCount, oilChangeApiRemainingCount } = last(this._companies);

      searchParams.tieBreakerId = id;
      searchParams.searchAfter = sort.map(({ name }) => {
        switch (name) {
          case 'createdAt':
            return new Date(createdAt).getTime();
          case 'carWashApiRemainingCount':
            return carWashApiRemainingCount || 0;
          case 'oilChangeApiRemainingCount':
            return oilChangeApiRemainingCount || 0;
          default:
            return new Date(createdAt).getTime();
        }
      });
    }

    this._isLoading = true;

    const { items, paging } = await searchCompanies(searchParams);

    runInAction(() => {
      this._companies = isAppend ? [...this._companies, ...items] : items;
      this._totalAmount = paging.count;
      this._isLoading = false;
      this._isLoadingMore = false;
    });
  };

  setCompanyEdit = (company) => {
    this._companyEdit = company;
  };

  resetCompanyEdit = () => {
    this._companyEdit = null;
  };

  sortingChange = (sort) => {
    this._sorting = sort;
  };

  savePlatesIgnoreList = async (platesIgnoreList) => {
    const { id, ...rest } = this._companyEdit;

    this.resetCompanyEdit();

    const deduplicatedPlatesList = Array.from(new Set(platesIgnoreList));
    const cleanPlatesList = deduplicatedPlatesList
      .filter(Boolean)
      .map((plate) => plate.toUpperCase().replace(/[^A-Z0-9]/g, ''));

    await updateCompany(id, { ...rest, id, platesIgnoreList: cleanPlatesList });

    runInAction(() => {
      this._companies = this._companies.map((company) => ({
        ...company,
        id: company.id,
        platesIgnoreList: company.id === id ? cleanPlatesList : company.platesIgnoreList,
      }));
    });
  };
}

export default CompanyListState;

import COLORS, { CHARTS_PALETTE } from 'components/style/colors';
import { fromJS } from 'immutable';
import { cloneDeep, meanBy, round, sortBy, uniq } from 'lodash';
import { COMPANY_DASHBOARD_CONFIG, SALE_TIME_COMPANY_DASHBOARD_GROUP_NAME } from 'reducers/auth';
import { median } from 'components/common/utils';

export const types = {
  REPORT_REQUEST: 'COMPANY/SUMMARY/REPORT_REQUEST',
  REPORT_SUCCESS: 'COMPANY/SUMMARY/REPORT_SUCCESS',
  REPORT_FAILURE: 'COMPANY/SUMMARY/REPORT_FAILURE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

export const colors = {};
const palette = CHARTS_PALETTE.filter((v) => v !== COLORS.LIGHT_GREY);
uniq(
  COMPANY_DASHBOARD_CONFIG.flatMap(({ locations }) => locations)
    .flat()
    .map(({ id }) => id)
).forEach((id, index) => {
  colors[id] = palette[index % palette.length];
});

function calculateMedianTime(locationReport) {
  return round(median(locationReport.rows.map(({ row }) => row.waitTime)));
}

function calculateAverageTime(locationReport) {
  return round(meanBy(locationReport.rows, ({ row }) => row.waitTime));
}

// function topSlowest(locationReport) {
//   return reverse(sortBy(locationReport.rows, ({ row }) => row.waitTime)).slice(0, 3);
// }

function prepareReport(report) {
  if (!report) {
    return null;
  }
  const result = cloneDeep(report);
  result.forEach((entry) => {
    const { locations, name } = entry;
    locations.forEach((location) => {
      location.averageTime = calculateAverageTime(location.report);
      location.medianTime = calculateMedianTime(location.report);
      location.color = colors[location.id];
    });
    const sortField = name === SALE_TIME_COMPANY_DASHBOARD_GROUP_NAME ? 'averageTime' : 'medianTime';
    entry.locations = sortBy(locations, sortField);
  });
  return result;
}

export default function summary(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
  }),
  reportSuccess: (requestDate, timezone, period, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    report,
  }),
  reportFailure: (requestDate, timezone, period, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    error,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'company', 'summary']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');

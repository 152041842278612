import { parse, stringify } from 'qs';

export default function useTimezone(location, history, userTimezone) {
  const { timezone = userTimezone } = parse(location.search, { ignoreQueryPrefix: true });

  function setTimezone(value) {
    const search = stringify({
      ...parse(location.search, { ignoreQueryPrefix: true }),
      timezone: value,
    });
    history.push({
      pathname: location.pathname,
      search,
    });
  }

  return [timezone, setTimezone];
}

import { Grid, withStyles } from '@material-ui/core';
import SetTitle from 'components/common/SetTitle';
import DashboardControls, { reportsMode } from 'components/dashboard/common/DashboardControls';
import TotalCustomersByTagsWidget from 'components/reports/TotalCustomersByTagsWidget';
import React from 'react';

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
});

function Reports({ classes }) {
  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Analytics" />
      <Grid item>
        <DashboardControls mode={reportsMode} />
      </Grid>
      <Grid item className={classes.grow}>
        <TotalCustomersByTagsWidget key="total" />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Reports);

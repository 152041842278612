import { apiPath } from 'api/location';
import axios from 'axios';
import mixpanel from 'components/common/mixpanel';
import { actions, types } from 'reducers/location/edit';
import { actions as locationListActions } from 'reducers/location/list';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { call, delay, put, takeLatest } from 'redux-saga/effects';

function* loadEntity({ entityId }) {
  try {
    const response = yield call(axios.get, `${apiPath}/${entityId}`);
    yield put(actions.loadSuccess(entityId, response.data));
  } catch (e) {
    yield put(actions.loadFailure(e));
  }
}

function* updateEntity({ entityId, entity, isNew }) {
  try {
    const response = isNew
      ? yield call(axios.post, apiPath, entity)
      : yield call(axios.put, `${apiPath}/${entityId}`, entity);
    if (isNew) {
      yield delay(1000);
      yield put(locationListActions.searchRequest());
    }
    yield put(actions.updateSuccess(entityId, response.data));

    const message = isNew ? 'Location created' : 'Location updated';
    yield put(snackActions.set(message, snackVariants.success));

    mixpanel((mp) => mp.track(isNew ? 'Location create' : 'Location update', { ...entity }));
  } catch (e) {
    yield put(actions.updateFailure(e));
  }
}

export default function* edit() {
  yield takeLatest(types.LOAD_REQUEST, loadEntity);
  yield takeLatest(types.UPDATE_REQUEST, updateEntity);
}

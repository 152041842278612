import { Typography, useTheme } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/Videocam';
import toJS from 'components/common/toJS';
import useSettingsChangeActionDispatcher from 'components/dashboard/camera/useSettingsChangeActionDispatcher';
import { composeTimeCategories, getTimeLabelFormatter } from 'components/dashboard/common/periods';
import { prepareLegendData } from 'components/dashboard/common/utils';
import Widget from 'components/dashboard/common/Widget';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import WidgetHeaderTrend from 'components/dashboard/common/WidgetHeaderTrend';
import WidgetLegend from 'components/dashboard/common/WidgetLegend';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import { find, round, sum as sumArray } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer } from 'react-virtualized';
import { getUserTimezone } from 'reducers/auth';
import {
  getReport as getTotalCustomersReport,
  isReportFetching as isTotalCustomersReportFetching,
} from 'reducers/dashboard/camera/totalCustomers';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/camera/waitTime';

function pad(num) {
  return num < 10 ? `0${num}` : num;
}

export function formatSeconds(seconds) {
  if (!seconds || seconds < 1) {
    return '\u2014';
  }
  const minutes = Math.floor(seconds / 60);
  const secs = Math.ceil(seconds % 60);
  return `${pad(minutes)}:${pad(secs)}`;
}

function prepareData(report, totalCustomersReport) {
  if (!report || !totalCustomersReport || report.length === 0 || totalCustomersReport.length === 0) {
    return [undefined, undefined, undefined, undefined];
  }

  const series = [];
  totalCustomersReport.forEach((entry) => {
    const matched = find(report, ['id', entry.id]);
    if (matched) {
      matched.value = entry.value;
      matched.color = entry.color;
      series.push(matched);
    }
  });
  if (series.length === 0) {
    return [undefined, undefined, undefined, undefined];
  }

  const preparedSeries = series.map((s) => ({ ...s, type: 'scatter', itemStyle: { color: s.color } }));

  const parts = series[0].data.length;
  const averages = [];
  const nonZeroAverages = [];
  for (let i = 0; i < parts; i += 1) {
    let sum = 0;
    let nonZeroCount = 0;

    for (let j = 0; j < series.length; j += 1) {
      const value = series[j].data[i][2];
      if (value > 0) {
        sum += value;
        nonZeroCount += 1;
      }
    }
    if (nonZeroCount > 0) {
      const average = round(sum / nonZeroCount, 1);
      averages.push(average);
      nonZeroAverages.push(average);
    } else {
      averages.push(0);
    }
  }
  const totalAverages =
    nonZeroAverages.length > 0 ? round(sumArray(nonZeroAverages) / nonZeroAverages.length, 1) : undefined;

  const yAxisData = [];
  for (let i = 0; i < series.length; i += 1) {
    yAxisData.push(i);
  }

  let maxValue = 0;
  series.forEach((s) => {
    s.data.forEach((d) => {
      const value = d[2];
      if (value > maxValue) {
        maxValue = value;
      }
    });
  });

  return [preparedSeries, averages, totalAverages, yAxisData, maxValue];
}

function WaitTimeWidget({
  location,
  history,
  isFetching,
  requestDate,
  userTimezone,
  report,
  totalCustomersReport,
  reportRequest,
}) {
  const [period, timezone] = useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest);
  const theme = useTheme();

  if (!requestDate) {
    return null;
  }

  const categories = composeTimeCategories(requestDate, timezone, period);
  const [series, averages, totalAverage, yAxisData, maxValue] = prepareData(report, totalCustomersReport);
  const legendData = series && prepareLegendData(series);

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) =>
        `${params[0].axisValue} <br /> ${params
          .map((p) => `${p.marker} Wait time: <b>${formatSeconds(p.data[2])}</b> mm:ss`)
          .join('<br />')}`,
      extraCssText: `z-index: ${theme.zIndex.drawer + 1}`,
    },
    grid: {
      left: 10,
      top: 20,
      right: 10,
      bottom: 0,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        position: 'bottom',
        data: categories,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: getTimeLabelFormatter(requestDate, timezone, period),
          margin: 4,
        },
      },
      {
        type: 'category',
        position: 'top',
        data: averages,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value) => formatSeconds(value),
          margin: 4,
        },
      },
    ],
    yAxis: [
      {
        type: 'category',
        data: yAxisData,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
          margin: 4,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series,
  };

  return (
    <Widget title="Wait time">
      <>
        <WidgetHeaderTrend
          name="Avg. wait time"
          headerValue={
            totalAverage && (
              <>
                {formatSeconds(totalAverage)}
                <Typography noWrap display="inline" variant="h6" component="span">
                  mm:ss
                </Typography>
              </>
            )
          }
          value={0}
          period="yesterday"
          aimDownward
        />
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = width / 1.78;

            let preparedOptions = options;
            if (series && series.length > 0) {
              const maxSize = Math.min(width / series[0].data.length - 1, height / series.length - 21, height / 5 - 10);
              const oneP = maxSize / 100;
              preparedOptions = {
                ...options,
                series: options.series.map((s) => ({ ...s, symbolSize: (val) => (val[2] / (maxValue / 100)) * oneP })),
              };
            }

            return <WidgetChart width={width} height={height} isFetching={isFetching} options={preparedOptions} />;
          }}
        </AutoSizer>
        <WidgetLegend data={legendData} icon={<CameraIcon />} />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state) || isTotalCustomersReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    totalCustomersReport: getTotalCustomersReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(WaitTimeWidget)));

import { observer } from 'mobx-react';
import { isNumber } from 'lodash';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import React from 'react';
import { indigo, pink } from '@material-ui/core/colors';
import { AutoSizer } from 'react-virtualized';

function GenderDistributionChart({ data, ...rest }) {
  if (!isNumber(data?.gender_percentage?.male) || !isNumber(data?.gender_percentage?.female)) {
    return null;
  }

  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        if (width === 0) {
          return null;
        }

        return (
          <WidgetChart
            width={width}
            height="60px"
            options={{
              grid: {
                top: 25,
                bottom: 10,
                left: 16,
                right: 16,
              },
              legend: [
                {
                  data: ['Male'],
                  left: 10,
                  orient: 'vertical',
                  selectedMode: false,
                },
                {
                  data: ['Female'],
                  right: 10,
                  orient: 'vertical',
                  selectedMode: false,
                },
              ],
              xAxis: {
                type: 'value',
                max: 100,
                splitLine: { show: false },
                axisTick: { show: false },
                axisLabel: { show: false },
                axisLine: { show: false },
              },
              yAxis: {
                type: 'category',
                axisTick: { show: false },
                axisLine: { show: false },
                axisLabel: { show: false },
              },
              series: [
                {
                  name: 'Male',
                  type: 'bar',
                  stack: 'gender',
                  data: [data?.gender_percentage?.male],
                  label: {
                    show: true,
                    position: 'insideRight',
                    formatter: '{c}%',
                    color: '#fff',
                  },
                  itemStyle: {
                    color: indigo[500],
                  },
                  silent: true,
                },
                {
                  name: 'Female',
                  type: 'bar',
                  stack: 'gender',
                  data: [data?.gender_percentage?.female],
                  label: {
                    show: true,
                    position: 'insideLeft',
                    formatter: '{c}%',
                    color: '#fff',
                  },
                  itemStyle: {
                    color: pink[500],
                  },
                  silent: true,
                },
              ],
            }}
            {...rest}
          />
        );
      }}
    </AutoSizer>
  );
}

export default observer(GenderDistributionChart);

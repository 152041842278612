import { Grid, Link as MuiLink, Paper, Typography, withStyles } from '@material-ui/core';
import SetTitle from 'components/common/SetTitle';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
  },
});

function NotFound({ classes }) {
  return (
    <div className={classes.root}>
      <SetTitle title="Page not found" />
      <Paper className={classes.paper}>
        <Grid className={classes.content} container direction="column" wrap="nowrap" alignItems="center">
          <Typography component="h1" variant="h3" gutterBottom>
            <strong>404.</strong> Page not found
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            The requested page was not found on this server. That&apos;s all we know.
          </Typography>
          <Typography variant="subtitle1">
            You can return to the{' '}
            <MuiLink component={Link} to="/">
              home page
            </MuiLink>
            .
          </Typography>
        </Grid>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(NotFound);

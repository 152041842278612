import { AppBar, Hidden, IconButton, Popover, Toolbar, Tooltip, Typography, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import WarningIcon from '@material-ui/icons/Warning';
import { companyName } from 'components/common/whitelabel';
import { WARN_COLOR } from 'components/style/colors';
import UserAvatar from 'components/user/UserAvatar';
import UserInfoMenu from 'components/user/UserInfoMenu';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserEmail, isImpersonation } from 'reducers/auth';

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    color: '#1F2018',
  },
  rootImpersonation: {
    backgroundColor: WARN_COLOR,
    color: 'white',
  },
  menuButton: {
    position: 'relative',
    left: -12,
  },
  avatarContainer: {
    width: 48,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  warningIcon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  avatarButton: {
    padding: 0,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
});

const titles = {
  '/insights': 'Predictive insights',
  '/dashboard/sales': 'Sales insights',
  '/dashboard/ops': 'Operations insights',
  '/dashboard/camera': 'Camera dashboard',
  '/dashboard/driveoff': 'Drive-off dashboard',
  '/dashboard/company': 'Portfolio leaderboards',
  '/reports/customersbytags': 'Total customers',
  '/reports/lpsearch': 'Customer search',
  '/reports/activevisits': 'Active visits',
  '/reports/visitsduration': 'Visit duration',
  '/reports/rewashes': 'Rewashes',
  '/reports/fraud': 'Member validation',
  '/reports/frauddebug': 'Member validation debug',
  '/streams': 'Streams',
  '/lpsearch': 'Vehicle search',
  '/profile': 'Profile',
  '/users': 'Users',
  '/locations': 'Locations',
  '/locationintelligence': 'Site predictor',
  '/oclocationintelligence': 'Site predictor',
};

function MenuAppBar({ classes, location, onMenuClick, email, impersonation }) {
  const { pathname } = location;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleUserMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  useEffect(() => handleClose(), [location]);

  return (
    <Hidden smUp implementation="css">
      <AppBar className={impersonation ? classes.rootImpersonation : classes.root} position="fixed" component="header">
        <Toolbar className={classes.toolbar}>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer" onClick={onMenuClick}>
            <MenuIcon />
          </IconButton>
          <div>
            <Typography component="h1" variant="h6" align="center" color="inherit">
              {titles[pathname]}
            </Typography>
          </div>
          <div className={classes.avatarContainer}>
            {impersonation && (
              <Tooltip title="You are in impersonation mode">
                <WarningIcon className={classes.warningIcon} />
              </Tooltip>
            )}
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              aria-label={`${companyName} account: ${email}`}
              onClick={handleUserMenu}
              color="inherit"
              className={classes.avatarButton}
            >
              <UserAvatar size={40} />
            </IconButton>
          </div>
          <Popover
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <UserInfoMenu />
          </Popover>
        </Toolbar>
      </AppBar>
    </Hidden>
  );
}

function mapStateToProps(state) {
  return {
    email: getUserEmail(state),
    impersonation: isImpersonation(state),
  };
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(MenuAppBar)));

import { observer } from 'mobx-react';
import { LinearProgress, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MapIcon from '@material-ui/icons/Map';
import PublicIcon from '@material-ui/icons/Public';
import CompetitorsIcon from '@material-ui/icons/LocalCarWash';
import ShoppingMallIcon from '@material-ui/icons/LocalMall';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import ViewListIcon from '@material-ui/icons/ViewList';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
    maxWidth: 900,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    color: theme.palette.secondary.main,
    width: 80,
    height: 80,
    marginBottom: theme.spacing(3.5),
  },
  progress: {
    marginTop: theme.spacing(7.5),
    width: '100%',
    height: 12,
  },
}));

const steps = [
  { icon: MapIcon, text: 'Analyzing address coordinates' },
  { icon: PublicIcon, text: 'Retrieving geospatial data' },
  { icon: CompetitorsIcon, text: 'Searching and assessing competitors' },
  { icon: ShoppingMallIcon, text: 'Extrapolating local retail attractions' },
  { icon: TrendingUpIcon, text: 'Integrating omniX predictors and datasets' },
  { icon: DesktopMacIcon, text: 'Running selected machine learning models' },
  { icon: ViewListIcon, text: 'Generating projection data' },
  { icon: DescriptionIcon, text: 'Compiling final report' },
];

function LocationIntelligenceSplashScreen({ step = 0, totalDuration = 30000, ...rest }) {
  const classes = useStyles();
  const { icon: Icon, text } = steps[step] ?? steps[steps.length - 1];
  const [currentProgress, setCurrentProgress] = useState(0);
  useEffect(() => {
    setCurrentProgress(0);

    const timer = setInterval(() => {
      setCurrentProgress((oldProgress) => {
        return oldProgress < 99 ? oldProgress + 1 : oldProgress;
      });
    }, totalDuration / 100);

    return () => {
      clearInterval(timer);
    };
  }, [totalDuration, setCurrentProgress]);

  return (
    <div className={classes.root} {...rest}>
      <div className={classes.content}>
        <Icon className={classes.icon} />
        <Typography align="center" className={classes.text} color="secondary" variant="h4">
          {text}...
        </Typography>
        <LinearProgress className={classes.progress} variant="determinate" color="secondary" value={currentProgress} />
      </div>
    </div>
  );
}

export default observer(LocationIntelligenceSplashScreen);

import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import SearchBox from 'components/common/SearchBox';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import DashboardControls, { fraudMode } from 'components/dashboard/common/DashboardControls';
import { parsePeriod } from 'components/dashboard/common/periods';
import useTimezone from 'components/dashboard/common/useTimezone';
import { ERROR_COLOR, SUCCESS_COLOR } from 'components/style/colors';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getDefaultLocationIdWithFraud, getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching, isReportInitial } from 'reducers/reports/fraud';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  inputRoot: {
    backgroundColor: 'white',
    paddingLeft: 12,
    borderRadius: 4,
    borderBottom: 'unset !important',
    '&::before': {
      borderBottom: 'unset !important',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  total: {
    width: '100%',
  },
  tablePaper: {
    width: '100%',
    overflowX: 'auto',
    minHeight: 200,
    alignSelf: 'flex-start',
  },
  table: {
    minWidth: 600,
  },
  controlsContainer: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  darkerRow: {
    backgroundColor: theme.palette.action.hover,
  },
}));

function Fraud({
  location,
  history,
  userTimezone,
  defaultLocationId,
  report,
  isFetching,
  isInitial,
  reportRequest,
  onUnmount,
}) {
  const classes = useStyles();
  const [locationId] = useQueryParam('location', (v) => v || defaultLocationId, location, history);
  let [timezone] = useTimezone(location, history, userTimezone);
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  const [period] = useQueryParam('period', (value) => parsePeriod(value, timezone), location, history);
  const [query, setQuery] = useQueryParam('query', (v) => v || '', location, history);
  useWillUnmount(onUnmount);
  useEffect(() => {
    if (locationId) {
      reportRequest(moment.tz(timezone).format(), timezone, period, locationId, query);
    }
  }, [period, location, locationId, timezone, query, reportRequest]);
  useEffect(() => {
    localStorage.setItem('lastUsedLocationId', locationId);
  }, [locationId]);
  const showInitialBlock = isInitial && !isFetching;
  let lastMemberId = null;
  let lastDate = null;
  let darkerRowBackground = true;
  let showVisitTime = true;

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Member validation debug" />
      <Grid item>
        <DashboardControls mode={fraudMode} />
      </Grid>
      <Grid item container justify="center">
        <SearchBox
          location={location}
          history={history}
          placeholder="Enter member ID to filter results"
          onSearch={(query) => setQuery(query)}
        />
      </Grid>
      <Grid item className={classes.content}>
        {!isFetching && report ? (
          <>
            <Typography
              display="block"
              className={classes.total}
              color="textSecondary"
              variant="subtitle1"
              align="left"
            >
              Total: {report.length}
            </Typography>
            <Paper className={classNames(classes.tablePaper, classes.grow)}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <TableSortLabel disabled active direction="desc">
                        Member ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left" sortDirection="desc">
                      <TableSortLabel disabled active direction="desc">
                        Visit time
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">omniX visit time</TableCell>
                    <TableCell align="left">License plate</TableCell>
                    <TableCell align="left">omniX license plate</TableCell>
                    <TableCell align="left">Camera</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {report.map((item, index) => {
                    const visitTime = moment.tz(item.date, 'UTC');
                    const omnixVisitTime = moment.tz(item.mappedDate, 'UTC');
                    const { memberId, similarMatch, date } = item;
                    let color = ERROR_COLOR;
                    if (similarMatch) {
                      color = SUCCESS_COLOR;
                    }
                    if (lastMemberId !== memberId) {
                      darkerRowBackground = !darkerRowBackground;
                    }
                    showVisitTime = !(lastMemberId === memberId && lastDate === date);
                    lastMemberId = memberId;
                    lastDate = date;

                    return (
                      <TableRow key={index} className={darkerRowBackground ? classes.darkerRow : undefined}>
                        <TableCell component="th" scope="row">
                          {memberId}
                        </TableCell>
                        <TableCell align="left">{showVisitTime && visitTime.format('MM/DD/YYYY HH:mm:ss')}</TableCell>
                        <TableCell align="left">
                          {item.mappedDate ? omnixVisitTime.format('MM/DD/YYYY HH:mm:ss') : '—'}
                        </TableCell>
                        <TableCell align="left">{item.licensePlate}</TableCell>
                        <TableCell align="left">
                          {item.mappedLicensePlate ? (
                            <span style={{ borderLeft: `solid 4px ${color}`, paddingLeft: 8 }}>
                              {item.mappedLicensePlate}
                            </span>
                          ) : (
                            '—'
                          )}
                        </TableCell>
                        <TableCell align="left">{item.jobName || '—'}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </>
        ) : null}
        {showInitialBlock && (
          <Typography variant="h5" component="span" align="center">
            To see how many invalid members was detected during a specific period,
            <br />
            please select the location
          </Typography>
        )}
        {isFetching && <BrandedLoadingIndicator />}
        {!isFetching && !isInitial && !report && locationId && (
          <Typography variant="h5" component="span" align="center">
            No invalid members found for this location and period
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
    defaultLocationId: getDefaultLocationIdWithFraud(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    isFetching: isReportFetching(state),
    isInitial: isReportInitial(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest, onUnmount: actions.reset })(toJS(Fraud))
);

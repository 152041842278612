import { fromJS } from 'immutable';

export const types = {
  DOWNLOAD_REQUEST: 'CAMERA/SNAPSHOTS_DOWNLOADER/DOWNLOAD_REQUEST',
  DOWNLOAD_COMPLETE: 'CAMERA/SNAPSHOTS_DOWNLOADER/DOWNLOAD_COMPLETE',
};

const initialState = fromJS({
  isFetching: false,
});

export default function snapshotsDownloader(state = initialState, action) {
  switch (action.type) {
    case types.DOWNLOAD_REQUEST:
      return state.withMutations((s) => {
        s.set('isFetching', true);
      });
    case types.DOWNLOAD_COMPLETE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
      });
    default:
      return state;
  }
}

export const actions = {
  downloadRequest: (startDate, endDate, jobId) => ({
    type: types.DOWNLOAD_REQUEST,
    startDate,
    endDate,
    jobId,
  }),
  downloadComplete: (startDate, endDate, jobId) => ({
    type: types.DOWNLOAD_COMPLETE,
    startDate,
    endDate,
    jobId,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'camera', 'snapshotsDownloader']);
export const isDownloadFetching = (state) => getRoot(state).get('isFetching');

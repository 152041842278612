import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';
import { SUCCESS_COLOR, WARN_COLOR } from 'components/style/colors';
import { every } from 'lodash';
import React, { useState } from 'react';

const styles = theme => ({
  requirements: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  itemIcon: {
    marginRight: 0,
  },
  satisfied: {
    color: SUCCESS_COLOR,
  },
  error: {
    color: theme.palette.error.dark,
  },
  warn: {
    color: WARN_COLOR,
  },
});

const requirements = [
  { text: 'At least 8 characters long', checker: value => value.length >= 8 },
  { text: 'Contains lowercase letter', checker: value => value.toUpperCase() !== value },
  { text: 'Contains uppercase letter', checker: value => value.toLowerCase() !== value },
  { text: 'Contains number', checker: value => /\d/.test(value) },
];

export function allRequirementsSatisfied(value) {
  return every(requirements, ({ checker }) => checker(value));
}

function PasswordField({
  classes,
  showRequirements = false,
  showRequirementsAsErrors = false,
  value,
  disabled = false,
  ...rest
}) {
  const [show, setShow] = useState(false);

  const label = show ? 'Hide password' : 'Show password';
  const visibilityButton = (
    <IconButton disabled={disabled} aria-label={label} onClick={() => setShow(!show)}>
      {show ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  );

  return (
    <>
      <TextField
        disabled={disabled}
        type={show ? 'text' : 'password'}
        value={value}
        {...rest}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {disabled ? (
                visibilityButton
              ) : (
                <Tooltip title={label} placement="bottom">
                  {visibilityButton}
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
      {showRequirements && (
        <List disablePadding className={classes.requirements}>
          {requirements.map(({ text, checker }) => {
            const satisfied = value && value !== '' && checker(value);
            return (
              <ListItem key={text} disabled={!satisfied && !showRequirementsAsErrors} disableGutters>
                <ListItemIcon
                  className={classNames(
                    classes.itemIcon,
                    satisfied && classes.satisfied,
                    showRequirementsAsErrors && !satisfied && classes.error
                  )}
                >
                  {satisfied ? <SuccessIcon /> : <ErrorIcon />}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    className: showRequirementsAsErrors && !satisfied ? classes.error : undefined,
                  }}
                  primary={text}
                />
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
}

export default withStyles(styles)(PasswordField);

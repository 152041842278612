import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Switch,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import StreamHealthyIcon from '@material-ui/icons/Done';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import StreamUnhealthyIcon from '@material-ui/icons/Warning';
import SetTitle from 'components/common/SetTitle';
import React from 'react';

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const styles = theme => ({
  cardGrid: {
    paddingBottom: theme.spacing(4),
    marginTop: 0,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardContentHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  streamHealthyIcon: {
    color: green[500],
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    border: 'none',
    height: 44,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)',
    '&:hover': {
      boxShadow: '0 3px 8px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.08)',
    },
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
  searchIcon: {
    cursor: 'pointer',
    width: theme.spacing(9),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    flexGrow: 1,
  },
  inputInput: {
    height: 36,
    lineHeight: 36,
    padding: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
});

function StreamList({ classes }) {
  return (
    <div>
      <SetTitle title="Video streams" />
      <Typography component="h1" variant="h2" align="center" gutterBottom>
        Video streams
      </Typography>
      <section className={classes.search}>
        <div className={classes.searchIcon} role="button" aria-label="Search">
          <SearchIcon />
        </div>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          placeholder="Search…"
        />
      </section>
      <div className={classes.fab}>
        <Fab variant="extended" color="secondary" aria-label="Add stream">
          <AddIcon className={classes.extendedIcon} />
          Add stream
        </Fab>
      </div>
      <Grid component="section" justify="center" container spacing={5} className={classes.cardGrid}>
        {cards.map(card => (
          <Grid item key={card} sm={6} md={4} lg={3}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22288%22%20height%3D%22225%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20288%20225%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_164edaf95ee%20text%20%7B%20fill%3A%23eceeef%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A14pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_164edaf95ee%22%3E%3Crect%20width%3D%22288%22%20height%3D%22225%22%20fill%3D%22%2355595c%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2296.32500076293945%22%20y%3D%22118.8%22%3EThumbnail%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                title="Stream thumbnail"
              />
              <CardContent className={classes.cardContent}>
                <header className={classes.cardContentHeader}>
                  <Typography variant="h5" component="header">
                    Stream name
                  </Typography>
                  {card % 2 ? (
                    <Tooltip title="The stream is healthy" aria-label="The stream is healthy">
                      <StreamHealthyIcon className={classes.streamHealthyIcon} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="The stream is not healthy" aria-label="The stream is not healthy">
                      <StreamUnhealthyIcon color="error" />
                    </Tooltip>
                  )}
                </header>
                <Typography>This is a media card. You can use this section to describe the content.</Typography>
              </CardContent>
              <CardActions className={classes.cardActions} disableActionSpacing>
                <FormControlLabel control={<Switch checked />} label="Active" labelPlacement="start" />
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(StreamList);

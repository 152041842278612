import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import StaticTextField from 'components/common/StaticTextField';
import EmailsEditor from 'components/location/EmailsEditor';
import { without } from 'lodash';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputsContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  formLabel: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
}));

function NotificationEditor({ id, value, onChange, onDelete, wasSubmit = false }) {
  const classes = useStyles();

  const enabled = value.enabled;
  const name = value.name;
  const messageSubject = value.messageSubject;
  const messageTemplate = value.messageTemplate;
  const range = value.range;
  const rangeUnit = value.rangeUnit;
  const thresholdMin = value.thresholdMin;
  const thresholdMax = value.thresholdMax;
  const senderTypes = value.senderTypes;
  const emails = value.emails;
  const telegramSettings = value.telegramSettings;
  const lightboxSettings = value.lightboxSettings;
  const nameError = !name.trim() ? 'Name is required' : null;
  const messageSubjectError = !messageSubject.trim() ? 'Message subject is required' : null;
  const emailsError = senderTypes.includes('email') && !emails?.length ? 'At least one email is required' : null;
  const telegramSettingsError =
    senderTypes.includes('telegram') && !telegramSettings?.trim() ? 'Telegram settings are required' : null;
  const lightboxSettingsError =
    senderTypes.includes('lightbox') && !lightboxSettings?.trim() ? 'Lightbox settings are required' : null;
  const senderTypesError = !senderTypes?.length ? 'Select at least one option' : null;

  const messagePreview = messageTemplate?.length
    ? messageTemplate.replace('$make', 'BMW').replace('$color', 'blue').replace('$licensePlate', '6TRJ244')
    : null;
  return (
    <Paper elevation={4} className={classes.root}>
      <div className={classes.toolbar}>
        <Switch checked={enabled} onChange={(e) => onChange('enabled', e.target.checked)} name={`${id}-enabled`} />
        <IconButton onClick={onDelete} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </div>
      <TextField
        value={name}
        onChange={(event) => onChange('name', event.target.value)}
        id={`${id}-name`}
        label="Name"
        autoComplete="off"
        name={`${id}-name`}
        margin="none"
        error={wasSubmit && nameError != null}
        helperText={wasSubmit && nameError}
        inputProps={{ required: true }}
        fullWidth
      />
      <StaticTextField
        label="Date period"
        helperText="For this period we'll analyze data to prepare the notification. E.g. the period can be used to specify that we want to notify about customers who visited a location within the past 2 months."
        value={
          <div className={classes.inputsContainer}>
            <TextField
              value={range}
              onChange={(event) => onChange('range', event.target.value)}
              id={`${id}-range`}
              name={`${id}-range`}
              margin="none"
              error={range <= 0}
              inputProps={{ min: 1, max: Math.pow(2, 31) - 1 }}
              type="number"
              style={{ width: 60 }}
            />
            <Select
              id={`${id}-rangeUnit`}
              style={{ width: 150 }}
              value={rangeUnit}
              onChange={(e) => onChange('rangeUnit', e.target.value)}
            >
              <MenuItem value="DAYS">Days</MenuItem>
              <MenuItem value="MONTHS">Months</MenuItem>
              <MenuItem value="YEARS">Years</MenuItem>
            </Select>
          </div>
        }
      />
      <StaticTextField
        label="Visits threshold"
        helperText="Send notification if visit count more or equal minimum threshold and less than maximum threshold"
        value={
          <div className={classes.inputsContainer}>
            <TextField
              value={thresholdMin}
              onChange={(event) => onChange('thresholdMin', event.target.value)}
              id={`${id}-thresholdMin`}
              name={`${id}-thresholdMin`}
              margin="none"
              error={!thresholdMin || parseInt(thresholdMin) <= 0}
              inputProps={{ min: 1, max: Math.pow(2, 31) - 1 }}
              type="number"
              style={{ width: 60 }}
            />
            <span>&mdash;</span>
            <TextField
              value={thresholdMax}
              onChange={(event) => onChange('thresholdMax', event.target.value)}
              id={`${id}-thresholdMax`}
              name={`${id}-thresholdMax`}
              margin="none"
              error={
                !thresholdMax ||
                parseInt(thresholdMax) <= 1 ||
                parseInt(thresholdMax) <= parseInt(thresholdMin ? thresholdMin : 0)
              }
              inputProps={{ min: 2, max: Math.pow(2, 31) - 1 }}
              type="number"
              style={{ width: 60 }}
            />
          </div>
        }
      />
      <FormControl error={wasSubmit && senderTypesError != null} component="fieldset" className={classes.formControl}>
        <FormLabel className={classes.formLabel} component="legend">
          Notify via
        </FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={senderTypes.includes('email')}
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    onChange('senderTypes', [...value.senderTypes, 'email']);
                  } else {
                    onChange('senderTypes', without([...value.senderTypes], 'email'));
                  }
                }}
                name={`${id}-sendToEmails`}
              />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={senderTypes.includes('telegram')}
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    onChange('senderTypes', [...value.senderTypes, 'telegram']);
                  } else {
                    onChange('senderTypes', without([...value.senderTypes], 'telegram'));
                  }
                }}
                name={`${id}-sendToTelegram`}
              />
            }
            label="Telegram"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={senderTypes.includes('lightbox')}
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    onChange('senderTypes', [...value.senderTypes, 'lightbox']);
                  } else {
                    onChange('senderTypes', without([...value.senderTypes], 'lightbox'));
                  }
                }}
                name={`${id}-sendToLightbox`}
              />
            }
            label="Lightbox"
          />
        </FormGroup>
        {wasSubmit && senderTypesError && <FormHelperText error>{senderTypesError}</FormHelperText>}
      </FormControl>
      {senderTypes.includes('email') && (
        <EmailsEditor
          value={emails}
          label="Emails"
          helperText="We'll send notifications to these emails"
          onChange={(value) => onChange('emails', value)}
          error={wasSubmit && emailsError != null}
          inputHelperText={wasSubmit && emailsError}
        />
      )}
      {senderTypes.includes('telegram') && (
        <TextField
          value={telegramSettings}
          onChange={(event) => onChange('telegramSettings', event.target.value)}
          id={`${id}-telegramSettings`}
          label="Telegram settings"
          autoComplete="off"
          name={`${id}-telegramSettings`}
          margin="none"
          error={wasSubmit && telegramSettingsError != null}
          helperText={wasSubmit && telegramSettingsError}
          fullWidth
        />
      )}
      {senderTypes.includes('lightbox') && (
        <TextField
          value={lightboxSettings}
          onChange={(event) => onChange('lightboxSettings', event.target.value)}
          id={`${id}-lightboxSettings`}
          label="Lightbox settings"
          autoComplete="off"
          name={`${id}-lightboxSettings`}
          margin="none"
          error={wasSubmit && lightboxSettingsError != null}
          helperText={wasSubmit && lightboxSettingsError}
          fullWidth
        />
      )}
      <TextField
        value={messageSubject}
        onChange={(event) => onChange('messageSubject', event.target.value)}
        id={`${id}-messageSubject`}
        label="Message subject"
        autoComplete="off"
        name={`${id}-messageSubject`}
        margin="none"
        error={wasSubmit && messageSubjectError != null}
        helperText={wasSubmit && messageSubjectError}
        inputProps={{ required: true }}
        fullWidth
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={messageTemplate !== null}
            onChange={(event) => {
              const checked = event.target.checked;
              const template = checked ? '' : null;
              onChange('messageTemplate', template);
            }}
            id={`${id}-customizeMessageTemplate`}
          />
        }
        label="Customize message"
      />
      {messageTemplate !== null && (
        <>
          <TextField
            value={messageTemplate ?? ''}
            onChange={(event) => onChange('messageTemplate', event.target.value)}
            label="Message template"
            id={`${id}-messageTemplate`}
            autoComplete="off"
            name={`${id}-messageTemplate`}
            margin="none"
            multiline
            rows="3"
            rowsMax="6"
            fullWidth
            helperText="You can use $make, $color and $licensePlate variables inside the message"
          />
          <StaticTextField value={messagePreview} label="Message preview" />
        </>
      )}
    </Paper>
  );
}

export default memo(NotificationEditor);

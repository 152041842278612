import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import { getTitleForType } from 'components/common/entity';
import toJS from 'components/common/toJS';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { actions, getEntity, getEntityType, getIsFetching, isOpen } from 'reducers/entity/delete';

const styles = theme => ({
  deleteButton: {
    minWidth: 170,
  },
  deleteIcon: {
    marginRight: theme.spacing(1),
  },
});

function EntityDeleter({ classes, open, entityType, entity, isFetching, onDelete, onClose }) {
  const title = entityType && getTitleForType(entityType);
  const handleDelete = useCallback(() => {
    if (!isFetching) {
      onDelete(entityType, entity);
    }
  }, [entityType, entity, isFetching, onDelete]);
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="entity-delete-dialog-title"
        aria-describedby="entity-delete-dialog-description"
      >
        <DialogTitle id="entity-delete-dialog-title">Delete {title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="entity-delete-dialog-description">
            Permanently delete {title}? You can't undo this.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={isFetching} variant="text" color="default">
            Cancel
          </Button>
          <Button className={classes.deleteButton} onClick={handleDelete} variant="contained" color="secondary">
            {!isFetching && <DeleteIcon className={classes.deleteIcon} />}
            <AsyncActionLabel isFetching={isFetching} fetchingLabel="Deleting..." label="Delete" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    open: isOpen(state),
    isFetching: getIsFetching(state),
    entityType: getEntityType(state),
    entity: getEntity(state),
  };
}

export default connect(mapStateToProps, {
  onClose: actions.closeDialog,
  onDelete: actions.deleteRequest,
})(toJS(withStyles(styles)(EntityDeleter)));

import { Button, Grid, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import toJS from 'components/common/toJS';
import { TODAY_PERIOD, TOMORROW_PERIOD } from 'components/dashboard/common/periods';
import { PureLocationPicker } from 'components/pickers/LocationPicker';
import React from 'react';

const styles = () => ({
  buttonsContainer: {
    minHeight: 48,
    '& > button + button': {
      marginLeft: 2,
    },
  },
  button: {
    minWidth: 'unset',
    width: '50%',
    flexGrow: 1,
    backgroundColor: 'white',
    boxShadow: 'unset',
  },
  buttonSelected: {
    backgroundColor: '#1F2018',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1F2018',
    },
  },
  inputRoot: {
    minHeight: 40,
    backgroundColor: 'white',
    paddingLeft: 12,
    borderRadius: 4,
    borderBottom: 'unset !important',
    '&::before': {
      borderBottom: 'unset !important',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
});

function InsightsControls({
  classes,
  period,
  location,
  onPeriodChange,
  onLocationChange,
  availableLocations,
  availableIds,
}) {
  return (
    <Grid container spacing={1} justify="space-between" alignItems="flex-start">
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-end"
        wrap="nowrap"
      >
        <Grid item container alignItems="flex-end" className={classes.buttonsContainer} wrap="nowrap">
          {[TODAY_PERIOD, TOMORROW_PERIOD].map((value) => {
            const className = period === value ? classNames(classes.button, classes.buttonSelected) : classes.button;
            return (
              <Button
                onClick={() => onPeriodChange(value)}
                key={value}
                className={className}
                size="small"
                variant="contained"
              >
                {value}
              </Button>
            );
          })}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        lg={4}
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-end"
        wrap="nowrap"
      >
        <PureLocationPicker
          availableLocations={availableLocations}
          availableIds={availableIds}
          value={location}
          onChange={(locId) => onLocationChange(locId)}
          SelectProps={{ className: classes.inputRoot }}
          selectClasses={{ select: classes.select }}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(toJS(InsightsControls));

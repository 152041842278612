import { fromJS } from 'immutable';
import { hasErrorWithCode } from 'reducers/utils';

export const types = {
  CREATE_REQUEST: 'USER_EDIT/CREATE_REQUEST',
  LOAD_REQUEST: 'USER_EDIT/LOAD_REQUEST',
  LOAD_SUCCESS: 'USER_EDIT/LOAD_SUCCESS',
  LOAD_FAILURE: 'USER_EDIT/LOAD_FAILURE',
  UPDATE_REQUEST: 'USER_EDIT/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USER_EDIT/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USER_EDIT/UPDATE_FAILURE',
  RESET_LOAD_ERROR: 'USER_EDIT/RESET_LOAD_ERROR',
  RESET_UPDATE_ERROR: 'USER_EDIT/RESET_UPDATE_ERROR',
  CLOSE: 'USER_EDIT/CLOSE',
};

export const initialState = fromJS({
  open: false,
  isNew: false,
  loadIsFetching: false,
  loadError: false,
  updateIsFetching: false,
  updateError: false,
  entityId: null,
  entity: null,
});

export default function edit(state = initialState, action) {
  const { type, entityId, entity } = action;
  switch (type) {
    case types.CREATE_REQUEST:
      return state.withMutations(s => {
        s.set('open', true);
        s.set('isNew', true);
        s.set('loadIsFetching', false);
        s.set('updateIsFetching', false);
        s.set('entityId', null);
        s.set('entity', {});
        s.set('loadError', false);
        s.set('updateError', false);
      });
    case types.LOAD_REQUEST:
      return state.withMutations(s => {
        s.set('open', true);
        s.set('isNew', false);
        s.set('loadIsFetching', true);
        s.set('updateIsFetching', false);
        s.set('entityId', entityId);
        s.set('entity', null);
        s.set('loadError', false);
        s.set('updateError', false);
      });
    case types.LOAD_SUCCESS:
      return state.withMutations(s => {
        s.set('loadIsFetching', false);
        s.set('entity', fromJS(entity));
      });
    case types.LOAD_FAILURE:
      return state.withMutations(s => {
        s.set('open', false);
        s.set('loadIsFetching', false);
        s.set('loadError', true);
      });
    case types.UPDATE_REQUEST:
      return state.withMutations(s => {
        s.set('updateIsFetching', true);
        s.set('updateError', false);
      });
    case types.UPDATE_SUCCESS:
      return state.withMutations(s => {
        s.set('open', false);
        s.set('updateIsFetching', false);
        s.set('updateError', false);
      });
    case types.UPDATE_FAILURE:
      const emailAlreadyExists = hasErrorWithCode(action, 1006);
      return state.withMutations(s => {
        s.set('updateIsFetching', false);
        s.set(
          'updateError',
          emailAlreadyExists
            ? 'User with this email already exists. Please use another email.'
            : 'Failed to save user, please try again later'
        );
      });
    case types.RESET_LOAD_ERROR:
      return state.withMutations(s => s.set('loadError', false));
    case types.RESET_UPDATE_ERROR:
      return state.withMutations(s => s.set('updateError', false));
    case types.CLOSE:
      return state.withMutations(s => s.set('open', false));
    default:
      return state;
  }
}

export const actions = {
  createRequest: () => ({ type: types.CREATE_REQUEST }),
  loadRequest: entityId => ({ type: types.LOAD_REQUEST, entityId }),
  loadSuccess: (entityId, entity) => ({ type: types.LOAD_SUCCESS, entityId, entity }),
  loadFailure: error => ({ type: types.LOAD_FAILURE, error }),
  updateRequest: (entityId, entity, isNew) => ({
    type: types.UPDATE_REQUEST,
    entityId,
    entity,
    isNew,
  }),
  updateSuccess: (entityId, entity) => ({ type: types.UPDATE_SUCCESS, entityId, entity }),
  updateFailure: error => ({ type: types.UPDATE_FAILURE, error }),
  resetLoadError: () => ({ type: types.RESET_LOAD_ERROR }),
  resetUpdateError: () => ({ type: types.RESET_UPDATE_ERROR }),
  close: () => ({ type: types.CLOSE }),
};

export const getRoot = state => state.getIn(['user', 'edit']);
export const isOpen = state => getRoot(state).get('open');
export const isNew = state => getRoot(state).get('isNew');
export const isLoadFetching = state => getRoot(state).get('loadIsFetching');
export const isUpdateFetching = state => getRoot(state).get('updateIsFetching');
export const getLoadError = state => getRoot(state).get('loadError');
export const getUpdateError = state => getRoot(state).get('updateError');
export const getEntityId = state => getRoot(state).get('entityId');
export const getEntity = state => getRoot(state).get('entity');

import { Grid, Typography, withStyles } from '@material-ui/core';
import Logo from 'components/common/Logo';
import { brandedPageDetails, brandedPageSlogan } from 'components/common/whitelabel';
import React from 'react';
import { Link } from 'react-router-dom';

export const BLOCKS_SPACING = 5;
export const MOBILE_BREAKPOINT = 768;
export const LOGO_HEIGHT = 68;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    minHeight: '100%',
  },
  brandSidebar: {
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      display: 'none',
    },
    position: 'relative',
    color: 'white',
    padding: theme.spacing(4),
    minWidth: 400,
    maxWidth: 480,
    minHeight: '100%',
    background: '#0A0A32',
  },
  content: {
    overflowX: 'hidden',
    position: 'relative',
    flexGrow: 1,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 2),
    },
    minHeight: '100%',
  },
  logo: {
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
    display: 'inline-block',
    height: LOGO_HEIGHT,
  },
  slogan: {
    marginBottom: theme.spacing(5),
  },
  sloganDetails: {
    paddingLeft: 22,
    borderLeft: '4px solid',
    borderColor: theme.palette.primary.main,
  },
});

function BrandedFormPage({ classes, children, slogan = brandedPageSlogan, details = brandedPageDetails }) {
  return (
    <Grid component="main" container wrap="nowrap" className={classes.root}>
      <Grid component="section" item className={classes.brandSidebar} container direction="column" justify="center">
        <Link to="/" aria-label="Home page">
          <Logo white className={classes.logo} />
        </Link>
        <Grid item>
          <Typography variant="h3" color="inherit" className={classes.slogan}>
            {slogan}
          </Typography>
          <Typography component="p" variant="subtitle1" color="inherit" className={classes.sloganDetails}>
            {details}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        component="section"
        item
        className={classes.content}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        {children}
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(BrandedFormPage);

import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import React from 'react';

function MapContainer({ google, lat, long, children, ...rest }) {
  return (
    <Map
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
      containerStyle={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
      google={google}
      zoom={16}
      initialCenter={{
        lat,
        lng: long,
      }}
      {...rest}
    >
      <Marker name="Location" position={{ lat: lat, lng: long }} />
      {children}
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAgrrnbLQHDAbc1qAXNCXFYrobqpWKhNyg',
})(MapContainer);

import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    minHeight: 240,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  widget: {
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  widgetTitle: {
    textTransform: 'uppercase',
  },
  childrenContainer: {
    flexGrow: 1,
    position: 'static !important',
  },
});

function Widget({ classes, title, titleAction, titleActionContainerProps, PaperProps, children, ...rest }) {
  return (
    <div className={classes.root} {...rest}>
      <Grid container justify="space-between" wrap="nowrap">
        <Grid item container wrap="nowrap" alignItems="center">
          <Typography color="textSecondary" variant="subtitle1" className={classes.widgetTitle}>
            {title}
          </Typography>
        </Grid>
        <Grid item {...titleActionContainerProps}>
          {titleAction}
        </Grid>
      </Grid>
      <Grid
        component={Paper}
        {...PaperProps}
        container
        direction="column"
        wrap="nowrap"
        className={classNames(classes.widget, PaperProps?.className)}
      >
        <Grid container direction="column" spacing={1} wrap="nowrap" className={classes.childrenContainer}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Widget);

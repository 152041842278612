import { makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import { ERROR_COLOR } from 'components/style/colors';
import { cloneDeep, find } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getOpsDashboardConfig, getUserTimezone } from 'reducers/auth';
import {
  actions,
  daysOfWeek,
  getReport,
  getRequestDate,
  isReportFetching,
  modes,
} from 'reducers/dashboard/ops/loaderEfficiency';

function timeLabelFormatter(value) {
  return value.split(' ')[0];
}

function tooltipFormatter(mode) {
  return (params) => {
    const titleTail = mode === modes.BY_VOLUME ? ' cars' : '';
    return `${params[0].axisValue}${titleTail} <br /> ${params
      .map((p) => {
        const [, value, count] = p.data;
        const tail = count ? ` | <b>${pluralize('car', count, true)}</b>` : '';
        return `${p.marker} ${p.seriesName}: ${!value || value < 1 ? '\u2014' : value} seconds` + tail;
      })
      .join('<br />')}`;
  };
}

function optionsCustomizer(options, mode) {
  options.legend = {
    show: true,
    top: 20,
  };
  options.grid.top = 60;
  options.grid.left = 10;
  options.tooltip.formatter = tooltipFormatter(mode);
  options.yAxis[0].axisLabel.formatter = '{value} s';
  if (mode === modes.BY_DAY_OF_WEEK) {
    options.xAxis[0].axisLabel.formatter = (value) => value.substring(0, 2);
  }
}

function parseMode(value) {
  if (Object.values(modes).includes(value)) {
    return value;
  }
  return modes.BY_HOUR;
}

const useStyles = makeStyles(() => ({
  toggleGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    '& > button': {
      height: 30.5,
    },
  },
}));

function LoaderEfficiencyWidget({
  location,
  history,
  isFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
  defaultLocationId,
  opsDashboardConfig,
}) {
  const classes = useStyles();

  const [mode, setMode] = useQueryParam('leMode', (value) => parseMode(value), location, history);
  const [, , locationId] = useSettingsChangeActionDispatcher(
    location,
    history,
    userTimezone,
    reportRequest,
    defaultLocationId,
    mode
  );
  const customizerCallback = useCallback((options) => optionsCustomizer(options, mode), [mode]);

  const { openHour, closeHour } = find(opsDashboardConfig, ['locationId', locationId]);
  const openHourIndex = openHour - 1 > 0 ? openHour - 1 : 0;
  const closeHourIndex = closeHour - 1 < 23 ? closeHour - 1 : 23;

  let categories = [];
  if (mode === modes.BY_DAY_OF_WEEK) {
    categories = daysOfWeek;
  } else if (mode === modes.BY_HOUR) {
    for (let i = openHour; i < closeHour; i++) {
      const start = i < 10 ? `0${i}:00` : `${i}:00`;
      const endI = i + 1 === 24 ? 0 : i + 1;
      const end = endI < 10 ? `0${endI}:00` : `${endI}:00`;
      categories.push(`${start} - ${end}`);
    }
  }

  if (!requestDate) {
    return null;
  }

  let series = report && [report.queue, report.noQueue];
  if (series) {
    if (mode === modes.BY_VOLUME) {
      categories = series[0].data.map(([range]) => range);
    }
    series = cloneDeep(series);
    series.forEach((s) => {
      if (mode === modes.BY_HOUR) {
        s.data = s.data.filter((_, index) => index > openHourIndex && index <= closeHourIndex);
      }
      if (mode !== modes.BY_VOLUME) {
        s.data = s.data.map((d, index) => [index, ...d]);
      }
    });
    series[0].markLine = {
      silent: true,
      symbol: 'none',
      data: [
        {
          symbol: 'none',
          yAxis: 30,
          label: {
            show: false,
          },
          lineStyle: { color: ERROR_COLOR },
        },
      ],
    };
  }

  return (
    <Widget title="Loader efficiency">
      <>
        <ToggleButtonGroup
          className={classes.toggleGroup}
          size="small"
          value={mode}
          exclusive
          onChange={(_, value) => setMode(value)}
        >
          <ToggleButton value={modes.BY_HOUR}>hour</ToggleButton>
          <ToggleButton value={modes.BY_DAY_OF_WEEK}>day</ToggleButton>
          <ToggleButton value={modes.BY_VOLUME}>volume</ToggleButton>
        </ToggleButtonGroup>
        <ValueOverTimeChart
          isFetching={isFetching}
          type="bar"
          categories={categories}
          categoriesFormatter={timeLabelFormatter}
          series={series}
          additionalHeight={187}
          optionsCustomizer={customizerCallback}
        />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
    opsDashboardConfig: getOpsDashboardConfig(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(LoaderEfficiencyWidget))
);

import { fromJS } from 'immutable';

export const types = {
  DELETE_OPEN_DIALOG: 'ENTITY_DELETE/DELETE_OPEN_DIALOG',
  DELETE_CLOSE_DIALOG: 'ENTITY_DELETE/DELETE_CLOSE_DIALOG',
  DELETE_REQUEST: 'ENTITY_DELETE/DELETE_REQUEST',
  DELETE_SUCCESS: 'ENTITY_DELETE/DELETE_SUCCESS',
  DELETE_FAILURE: 'ENTITY_DELETE/DELETE_FAILURE',
};

export const initialState = fromJS({
  open: false,
  isFetching: false,
  entityType: null,
  entity: null,
});

export default function deleteReducer(state = initialState, action) {
  const { type, entityType, entity } = action;
  switch (type) {
    case types.DELETE_OPEN_DIALOG:
      return state.withMutations(s => {
        s.set('open', true);
        s.set('isFetching', false);
        s.set('entityType', entityType);
        s.set('entity', fromJS(entity));
      });
    case types.DELETE_CLOSE_DIALOG:
      return state.withMutations(s => {
        s.set('open', false);
      });
    case types.DELETE_REQUEST:
      return state.withMutations(s => {
        s.set('isFetching', true);
      });
    case types.DELETE_SUCCESS:
      return state.withMutations(s => {
        s.set('isFetching', false);
        s.set('open', false);
      });
    case types.DELETE_FAILURE:
      return state.withMutations(s => {
        s.set('isFetching', false);
        s.set('open', false);
      });
    default:
      return state;
  }
}

export const actions = {
  openDialog: (entityType, entity) => ({ type: types.DELETE_OPEN_DIALOG, entityType, entity }),
  closeDialog: () => ({ type: types.DELETE_CLOSE_DIALOG }),
  deleteRequest: (entityType, entity) => ({ type: types.DELETE_REQUEST, entityType, entity }),
  deleteSuccess: (entityType, entity) => ({ type: types.DELETE_SUCCESS, entityType, entity }),
  deleteFailure: error => ({ type: types.DELETE_FAILURE, error }),
};

export const getRoot = state => state.getIn(['entity', 'delete']);
export const isOpen = state => getRoot(state).get('open');
export const getIsFetching = state => getRoot(state).get('isFetching');
export const getEntityType = state => getRoot(state).get('entityType');
export const getEntity = state => getRoot(state).get('entity');

import { composeTimeCategories, getTimeLabelFormatter } from 'components/dashboard/common/periods';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import WidgetHeaderTrend from 'components/dashboard/common/WidgetHeaderTrend';
import COLORS from 'components/style/colors';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import { get } from 'lodash';
import React from 'react';

function prepareData(report) {
  if (get(report, 'empty', true)) {
    return null;
  }

  return [
    {
      name: 'Rewashes',
      color: COLORS.DARK_YELLOW,
      data: report.rows.map(({ row }) => row.totalRewashCount),
    },
  ];
}

export default function RewashesCountWidget({ requestDate, timezone, period, report }) {
  if (!requestDate) {
    return null;
  }

  const categories = composeTimeCategories(requestDate, timezone, period);
  const series = prepareData(report);
  const total = series[0].data.reduce((acc, obj) => acc + obj, 0);

  return (
    <Widget title="Rewashes">
      <>
        <WidgetHeaderTrend name="Rewashes" headerValue={total} period="last week" />
        <div style={{ minHeight: 200, flexGrow: 1 }}>
          <ValueOverTimeChart
            disableHeight={false}
            isFetching={false}
            type="bar"
            categories={categories}
            categoriesFormatter={getTimeLabelFormatter(requestDate, timezone, period)}
            series={series}
          />
        </div>
      </>
    </Widget>
  );
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Switch,
  Tab,
  Tabs,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DetailsIcon from '@material-ui/icons/List';
import NotificationsIcon from '@material-ui/icons/NotificationsActive';
import { TimePicker } from '@material-ui/pickers';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import StaticTextField from 'components/common/StaticTextField';
import toJS from 'components/common/toJS';
import EmailsEditor, { VALID_EMAIL_REGEX } from 'components/location/EmailsEditor';
import NotificationsEditor from 'components/location/NotificationsEditor';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getCompanies, getCompanyIds, isSuperAdmin } from 'reducers/auth';

export const locationTypes = {
  express_exterior: 'Express exterior',
  full_service: 'Full service',
  flex: 'Flex',
  inbay_automatic: 'In-bay automatic',
  self_serve: 'Self serve',
};

const operatingHoursFormat = 'HH:mm:00';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    marginBottom: theme.spacing(5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
  operatingHoursValues: {
    display: 'grid',
    gridTemplateColumns: '60px min-content 60px',
    gridGap: theme.spacing(2),
    alignItems: 'center',
  },
  primaryButton: {
    minWidth: 170,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function LocationEdit({
  onClose,
  superAdmin,
  initialEntity,
  availableCompanies,
  availableCompanyIds,
  isFetching,
  isNew,
  error,
  resetError,
  onSubmit,
}) {
  const classes = useStyles();
  const availableCompaniesPrepared = cloneDeep(availableCompanies);
  const availableCompanyIdsPrepared = cloneDeep(availableCompanyIds);
  const initialCompany = initialEntity?.company;
  if (initialCompany && !availableCompanyIdsPrepared.includes(initialCompany.id)) {
    availableCompanyIdsPrepared.push(initialCompany.id);
    availableCompaniesPrepared[initialCompany.id] = initialCompany;
  }

  const initialCompanyId = availableCompanyIdsPrepared.length === 1 ? availableCompanyIdsPrepared[0] : '';
  const [activeTab, setActiveTab] = useState(0);
  const [name, setName] = useState(get(initialEntity, 'name', ''));
  const [companyId, setCompanyId] = useState(get(initialEntity, 'company.id', initialCompanyId));
  const [type, setType] = useState(get(initialEntity, 'type', '') ?? '');
  const [wasSubmit, setWasSubmit] = useState(false);
  const [openTime, setOpenTime] = useState(get(initialEntity, 'openTime', null));
  const [closeTime, setCloseTime] = useState(get(initialEntity, 'closeTime', null));
  const [operating24h, setOperating24h] = useState(openTime === null || closeTime === null);
  const [insightsAccess, setInsightsAccess] = useState(get(initialEntity, 'insightsAccess', false));
  const [visitDurationAvailable, setVisitDurationAvailable] = useState(
    get(initialEntity, 'visitDurationAvailable', false)
  );
  const [activeVisitsAvailable, setActiveVisitsAvailable] = useState(
    get(initialEntity, 'activeVisitsAvailable', false)
  );
  const [alertEmails, setAlertEmails] = useState(get(initialEntity, 'alertEmails') || []);
  const [conversionSettings, setConversionSettings] = useState(get(initialEntity, 'conversionSettings') || []);
  const nameError = !name.trim() ? 'Name is required' : null;
  const companyError = !companyId ? 'Company is required' : null;
  const notificationsError = conversionSettings?.length
    ? conversionSettings.some((entry) => {
        return (
          !entry.name.trim() ||
          !entry.messageSubject?.trim() ||
          (entry.senderTypes.includes('email') && !entry.emails?.length) ||
          (entry.senderTypes.includes('telegram') && !entry.telegramSettings) ||
          (entry.senderTypes.includes('lightbox') && !entry.lightboxSettings) ||
          !entry.senderTypes?.length ||
          entry.range <= 0 ||
          parseInt(entry.thresholdMin ? entry.thresholdMin : 0) <= 0 ||
          parseInt(entry.thresholdMax ? entry.thresholdMax : 0) <= 1 ||
          parseInt(entry.thresholdMax ? entry.thresholdMax : 0) <= parseInt(entry.thresholdMin ? entry.thresholdMin : 0)
        );
      })
    : false;

  function handleSubmit(event) {
    event.preventDefault();
    setWasSubmit(true);
    if (!nameError && !companyError && !notificationsError) {
      doSubmit();
    } else {
      notificationsError ? setActiveTab(1) : setActiveTab(0);
    }
  }

  function doSubmit() {
    const entity = {
      ...initialEntity,
      name,
      companyId,
      type: type ? type : null,
      openTime: operating24h ? null : openTime,
      closeTime: operating24h ? null : closeTime,
      insightsAccess,
      visitDurationAvailable,
      activeVisitsAvailable,
      alertEmails: alertEmails.filter((email) => VALID_EMAIL_REGEX.test(email)),
      conversionSettings,
    };

    let entityId = initialEntity.id;
    onSubmit(entityId, entity, isNew);
  }

  return (
    <>
      <Dialog
        transitionDuration={0}
        open={!!error}
        onClose={resetError}
        aria-labelledby="location-edit-error-dialog-title"
        aria-describedby="location-edit-error-dialog-description"
      >
        <DialogTitle id="location-edit-wizard-error-dialog-title">Save failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="location-edit-error-dialog-description">{error}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetError} variant="contained" color="secondary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
      <DialogContent>
        {superAdmin && (
          <Tabs className={classes.tabPanel} value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
            <Tab icon={<DetailsIcon />} label="Details" {...a11yProps(0)} />
            <Tab icon={<NotificationsIcon />} label="Notifications" {...a11yProps(1)} />
          </Tabs>
        )}
        <TabPanel className={classes.content} value={activeTab} index={0}>
          <TextField
            value={name}
            onChange={(event) => setName(event.target.value)}
            id="name"
            label="Name"
            autoComplete="off"
            name="name"
            margin="none"
            error={wasSubmit && nameError != null}
            helperText={wasSubmit && nameError}
            inputProps={{ required: true }}
            fullWidth
          />
          <TextField
            select
            label="Type"
            id="type"
            name="type"
            margin="none"
            fullWidth
            value={type}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => setType(event.target.value)}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {Object.entries(locationTypes).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </TextField>
          {superAdmin && (
            <TextField
              select
              disabled={!isNew}
              label="Company"
              id="company"
              name="company"
              margin="none"
              error={wasSubmit && companyError != null}
              helperText={wasSubmit && companyError}
              fullWidth
              value={companyId}
              onChange={(event) => setCompanyId(event.target.value)}
            >
              {availableCompanyIdsPrepared.map((companyId) => (
                <MenuItem key={companyId} value={companyId}>
                  {availableCompaniesPrepared[companyId].name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <StaticTextField
            label="Operating hours"
            helperText="We don't connect to your cameras and analyze events outside the location’s operating hours"
            value={
              <>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={operating24h}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        if (!checked) {
                          !openTime && setOpenTime('07:40:00');
                          !closeTime && setCloseTime('20:20:00');
                        }
                        setOperating24h(checked);
                      }}
                      name="operating24h"
                    />
                  }
                  label="Operating 24h"
                />
                {!operating24h && (
                  <div className={classes.operatingHoursValues}>
                    <TimePicker
                      format="HH:mm"
                      ampm={false}
                      value={moment(openTime, operatingHoursFormat)}
                      onChange={(value) => setOpenTime(value.format(operatingHoursFormat))}
                    />
                    —
                    <TimePicker
                      format="HH:mm"
                      ampm={false}
                      value={moment(closeTime, operatingHoursFormat)}
                      onChange={(value) => setCloseTime(value.format(operatingHoursFormat))}
                    />
                  </div>
                )}
              </>
            }
          />
          <EmailsEditor
            value={alertEmails}
            label="Alert emails"
            helperText="We'll send notifications to these emails when any camera from the location goes up or down"
            onChange={(value) => setAlertEmails(value)}
          />
          {superAdmin && (
            <>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={insightsAccess}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setInsightsAccess(checked);
                      }}
                      name="insightsAccess"
                    />
                  }
                  label="Insights"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={visitDurationAvailable}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setVisitDurationAvailable(checked);
                      }}
                      name="visitDurationAvailable"
                    />
                  }
                  label="Visit duration"
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={activeVisitsAvailable}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        setActiveVisitsAvailable(checked);
                      }}
                      name="activeVisitsAvailable"
                    />
                  }
                  label="Active visits"
                />
              </div>
            </>
          )}
        </TabPanel>
        <TabPanel className={classes.content} value={activeTab} index={1}>
          <NotificationsEditor
            wasSubmit={wasSubmit}
            value={conversionSettings}
            onChange={(value) => setConversionSettings(value)}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isFetching} variant="text" color="default">
          Cancel
        </Button>
        <Button
          className={classes.primaryButton}
          onClick={handleSubmit}
          size="large"
          variant="contained"
          color="secondary"
        >
          <AsyncActionLabel label="Save" fetchingLabel="Saving..." isFetching={isFetching} />
        </Button>
      </DialogActions>
    </>
  );
}

function mapStateToProps(state) {
  return {
    availableCompanies: getCompanies(state),
    availableCompanyIds: getCompanyIds(state),
    superAdmin: isSuperAdmin(state),
  };
}

export default connect(mapStateToProps)(toJS(LocationEdit));

import { fromJS } from 'immutable';
import { get, round, sumBy } from 'lodash';

export const types = {
  REPORT_REQUEST: 'OPS/SUMMARY/REPORT_REQUEST',
  REPORT_SUCCESS: 'OPS/SUMMARY/REPORT_SUCCESS',
  REPORT_FAILURE: 'OPS/SUMMARY/REPORT_FAILURE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report) {
  const { total, rewashes } = report;
  if (get(total, 'empty', true)) {
    return null;
  }
  const totalCount = sumBy(total.rows, 'row.eventsCount');
  let rewashRatio = null;
  if (!get(rewashes, 'empty', true) && totalCount > 0) {
    const rewashCount = sumBy(rewashes.rows, 'row.totalRewashCount');
    if (rewashCount > totalCount) {
      rewashRatio = 100;
    } else {
      rewashRatio = round(rewashCount / (totalCount / 100), 1);
    }
  }
  const summary = {
    total: totalCount,
    rewashRatio,
  };
  return { summary };
}

export default function summary(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
  }),
  reportSuccess: (requestDate, timezone, period, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    report,
  }),
  reportFailure: (requestDate, timezone, period, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    error,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'ops', 'summary']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');

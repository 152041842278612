import { TOTAL_CUSTOMERS_COLORS } from 'components/style/colors';
import { fromJS } from 'immutable';
import { get, groupBy, reverse, sortBy, sum } from 'lodash';

export const types = {
  REPORT_REQUEST: 'LOCATION/TOTAL_CUSTOMERS/REPORT_REQUEST',
  REPORT_SUCCESS: 'LOCATION/TOTAL_CUSTOMERS/REPORT_SUCCESS',
  REPORT_FAILURE: 'LOCATION/TOTAL_CUSTOMERS/REPORT_FAILURE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report) {
  if (get(report, 'empty', true)) {
    return null;
  }

  const grouped = groupBy(report.rows, 'row.scaledDate');
  const transformed = Object.values(grouped).reduce((acc, rows) => {
    rows.forEach((entry, index) => {
      const { row } = entry;
      const rowValue = row.eventsCount;
      if (!acc[index]) {
        const data = [rowValue];
        acc.push({ id: row.locId, name: row.locName, data });
      } else {
        acc[index].data.push(rowValue);
      }
    });
    return acc;
  }, []);
  transformed.forEach((entry, index) => {
    entry.value = sum(entry.data);
    entry.color = TOTAL_CUSTOMERS_COLORS[index];
  });
  return reverse(sortBy(transformed, 'value'));
}

export default function totalCustomers(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations(s => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations(s => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations(s => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
  }),
  reportSuccess: (requestDate, timezone, period, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    report,
  }),
  reportFailure: (requestDate, timezone, period, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    error,
  }),
};

export const getRoot = state => state.getIn(['dashboard', 'location', 'totalCustomers']);
export const getRequestDate = state => getRoot(state).get('requestDate');
export const getReport = state => getRoot(state).get('report');
export const isReportFetching = state => getRoot(state).get('isFetching');

import { Button, Collapse, makeStyles, TextField, Typography } from '@material-ui/core';
import NegativeIcon from '@material-ui/icons/ThumbDown';
import PositiveIcon from '@material-ui/icons/ThumbUp';
import { ToggleButton } from '@material-ui/lab/';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions } from 'reducers/insights';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(3),
    },
  },
  titleButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: `${theme.spacing(1)}px !important`,
    },
  },
  collapse: {
    width: '100%',
  },
  details: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function InsightsFeedback({ timezone, period, locationId, onSubmit }) {
  const classes = useStyles();
  const submitRef = useRef(null);
  const [type, setType] = useState(null);
  const [details, setDetails] = useState('');

  function handleToggle(type) {
    setType(prevType => {
      if (prevType === type) {
        setType(null);
      } else {
        if (prevType === null) {
          setDetails('');
          submitRef.current.scrollIntoView();
        }
        setType(type);
      }
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit(timezone, period, locationId, type, details);
    setType(null);
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.title}>
          <Typography variant="body1">Rate our prediction</Typography>
          <div className={classes.titleButtons}>
            <ToggleButton
              selected={type === true}
              onChange={() => handleToggle(true)}
              size="small"
              value="true"
              aria-label="positive feedback"
            >
              <PositiveIcon />
            </ToggleButton>
            <ToggleButton
              selected={type === false}
              onChange={() => handleToggle(false)}
              size="small"
              value="false"
              aria-label="negative feedback"
            >
              <NegativeIcon />
            </ToggleButton>
          </div>
        </div>
        <Collapse in={type !== null} className={classes.collapse}>
          <form onSubmit={event => handleSubmit(event)} className={classes.details}>
            <TextField
              value={details}
              onChange={event => setDetails(event.target.value)}
              id="details"
              name="details"
              autoComplete="off"
              label="Details (optional)"
              multiline
              fullWidth
              margin="none"
            />
            <Button ref={submitRef} color="secondary" type="submit" variant="contained">
              Submit
            </Button>
          </form>
        </Collapse>
      </div>
    </div>
  );
}

export default withRouter(connect(null, { onSubmit: actions.submitFeedback })(InsightsFeedback));

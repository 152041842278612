import COLORS from 'components/style/colors';
import { fromJS } from 'immutable';

export const types = {
  REPORT_REQUEST: 'LOCATION/LAST_SEEN/REPORT_REQUEST',
  REPORT_SUCCESS: 'LOCATION/LAST_SEEN/REPORT_SUCCESS',
  REPORT_FAILURE: 'LOCATION/LAST_SEEN/REPORT_FAILURE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report) {
  const rows = report && report.rows;
  if (!rows || !rows.length) {
    return null;
  }

  const row = rows[0].row;
  return [
    { id: 'daily', color: COLORS.GREEN, name: 'Daily', value: row.daily },
    { id: 'weekly', color: COLORS.BLUE, name: 'Weekly', value: row.weekly },
    { id: 'monthly', color: COLORS.LIGHT_BLUE, name: 'Monthly', value: row.monthly },
    { id: 'qtrly', color: COLORS.DARK_GREY, name: '1M+', value: row.qtrly },
    {
      id: 'halfYearOrMore',
      color: COLORS.LIGHT_GREY,
      name: '6M+',
      value: row.halfYearOrMore,
    },
  ];
}

export default function lastSeen(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
  }),
  reportSuccess: (requestDate, timezone, period, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    report,
  }),
  reportFailure: (requestDate, timezone, period, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    error,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'location', 'lastSeen']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');

import { Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { useLayoutEffect, useRef, useState } from 'react';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  bar: {
    backgroundColor: grey[300],
    height: 4,
    marginRight: theme.spacing(1),
    minWidth: 1,
  },
});

function CountPercentBar({ classes, countWidth, parentWidth, value, percent, onCountWidthUpdate }) {
  const [width, setWidth] = useState(0);
  const countRef = useRef(null);
  const rootRef = useRef(null);
  useLayoutEffect(() => {
    if (countRef.current) {
      onCountWidthUpdate(countRef.current.offsetWidth);
    }
  }, [parentWidth, onCountWidthUpdate]);
  useLayoutEffect(() => {
    if (countRef.current && rootRef.current && countWidth) {
      const availableWidth = rootRef.current.offsetWidth - 8 - countWidth;
      setWidth((availableWidth / 100) * percent);
    } else {
      setWidth(0);
    }
  }, [countWidth, percent]);

  return (
    <span className={classes.root} ref={rootRef}>
      <span style={{ width }} className={classes.bar} />
      <span ref={countRef} className={classes.valueContainer}>
        <Typography display="inline" variant="body2" color="textSecondary" component="span" className={classes.value}>
          {value}
        </Typography>
      </span>
    </span>
  );
}

export default withStyles(styles)(CountPercentBar);

import useQueryParam from 'components/common/useQueryParam';
import { parsePeriod } from 'components/dashboard/common/periods';
import useTimezone from 'components/dashboard/common/useTimezone';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { getDefaultLocationId } from 'reducers/auth';
import store from 'reducers/index';

export function defaultLocationId() {
  const state = store.getState();
  return getDefaultLocationId(state);
}

const defaultTags = [];

export default function useSettingsChangeActionDispatcher(location, history, userTimezone, actionDispatcher) {
  let [timezone] = useTimezone(location, history, userTimezone);
  if (!timezone) {
    timezone = moment.tz.guess();
  }
  const [period] = useQueryParam('period', value => parsePeriod(value, timezone), location, history);
  let [locationId] = useQueryParam('location', v => v, location, history);
  const [tagIds] = useQueryParam('tag', v => v || defaultTags, location, history);
  const tagIdsJson = JSON.stringify(tagIds);
  if (!locationId) {
    locationId = defaultLocationId();
  }

  useEffect(() => {
    actionDispatcher(moment().format(), timezone, period, locationId, JSON.parse(tagIdsJson));
  }, [location, timezone, period, locationId, tagIdsJson, actionDispatcher]);
  useEffect(() => {
    localStorage.setItem('lastUsedLocationId', locationId);
  }, [locationId]);

  return [period, timezone, locationId, tagIds];
}

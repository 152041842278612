import { makeStyles, Typography } from '@material-ui/core';
import WhitelabelerIcon from '@material-ui/icons/SubdirectoryArrowRight';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  whitelabelerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    width: 16,
    height: 16,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
  },
}));

function CompanyAndWhitelabeler({ className, company, whitelabeler }) {
  const classes = useStyles();

  if (!company?.name) {
    return '—';
  }

  return (
    <div className={className}>
      {company.name}
      {whitelabeler && whitelabeler.id !== company.id && (
        <Typography className={classes.whitelabelerContainer} display="block" color="textSecondary" variant="caption">
          <WhitelabelerIcon className={classes.icon} />
          {whitelabeler.name}
        </Typography>
      )}
    </div>
  );
}

export default CompanyAndWhitelabeler;

import loaderEfficiency from 'reducers/dashboard/ops/loaderEfficiency';
import summary from 'reducers/dashboard/ops/summary';
import visitDurationBreakdown from 'reducers/dashboard/ops/visitDurationBreakdown';
import { combineReducers } from 'redux';

export default combineReducers({
  summary,
  visitDurationBreakdown,
  loaderEfficiency,
});

import { fromJS } from 'immutable';

export const types = {
  REPORT_REQUEST: 'CAMERA/VEHICLE_MAKES/REPORT_REQUEST',
  REPORT_SUCCESS: 'CAMERA/VEHICLE_MAKES/REPORT_SUCCESS',
  REPORT_FAILURE: 'CAMERA/VEHICLE_MAKES/REPORT_FAILURE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function convertReport(report) {
  if (!report.rows || report.rows.length === 0) {
    return null;
  }

  return report;
}

export default function vehicleMakes(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations(s => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations(s => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(convertReport(report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations(s => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, job) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    job,
  }),
  reportSuccess: (requestDate, timezone, period, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    report,
  }),
  reportFailure: (requestDate, timezone, period, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    error,
  }),
};

export const getRoot = state => state.getIn(['dashboard', 'camera', 'vehicleMakes']);
export const getRequestDate = state => getRoot(state).get('requestDate');
export const getReport = state => getRoot(state).get('report');
export const isReportFetching = state => getRoot(state).get('isFetching');

import { Grid, withStyles, withWidth } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import mixpanel from 'components/common/mixpanel';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import { resolveLocationNameById } from 'components/common/utils';
import DashboardControls, { driveoffDashboardMode } from 'components/dashboard/common/DashboardControls';
import MiniWidget from 'components/dashboard/common/MiniWidget';
import { composeTimeCategories, getTimeLabelFormatter, timeCategories24h } from 'components/dashboard/common/periods';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getDefaultLocationIdWithDriveoff,
  getLocationIdsWithDriveoff,
  getLocationsWithDriveoff,
  getUserTimezone,
} from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching, modes } from 'reducers/dashboard/driveoff/summary';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  miniWidgetsContainer: {
    marginBottom: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
    gridGap: theme.spacing(4),
  },
  toggleGroup: {
    maxWidth: 500,
    margin: '0 auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '& > button': {
      height: 30.5,
    },
  },
});

function parseMode(value) {
  if (Object.values(modes).includes(value)) {
    return value;
  }
  return modes.DEFAULT;
}

function DriveoffDashboard({
  classes,
  location,
  history,
  userTimezone,
  requestDate,
  reportRequest,
  isFetching,
  defaultLocationId,
  report,
}) {
  const onSettingsChange = useCallback(
    (requestDate, timezone, period, mode, locationId) => {
      mixpanel((mp) =>
        mp.track('Drive-off dashboard', {
          timezone,
          period,
          locationId,
          locationName: resolveLocationNameById(locationId),
        })
      );
      reportRequest(requestDate, timezone, period, mode, locationId);
    },
    [reportRequest]
  );
  const [mode, setMode] = useQueryParam('doMode', (value) => parseMode(value), location, history);
  const [period, timezone] = useSettingsChangeActionDispatcher(
    location,
    history,
    userTimezone,
    onSettingsChange,
    defaultLocationId,
    mode
  );

  if (!requestDate) {
    return null;
  }

  const categories = mode === modes.DEFAULT ? composeTimeCategories(requestDate, timezone, period) : timeCategories24h;
  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Drive-off dashboard" />
      <Grid item>
        <DashboardControls mode={driveoffDashboardMode} />
      </Grid>
      <Grid item className={classes.grow}>
        <div className={classes.miniWidgetsContainer}>
          <MiniWidget isFetching={isFetching} title="Bay count" value={report?.summary?.bayCount} />
          <MiniWidget isFetching={isFetching} title="Exit count" value={report?.summary?.exitCount} />
          <MiniWidget isFetching={isFetching} title="Drive-off count" value={report?.summary?.driveoffCount} />
          <MiniWidget
            isFetching={isFetching}
            title="Drive-off rate"
            value={report?.summary?.driveoffRate}
            valueHelperText="%"
          />
        </div>
        <Widget>
          <>
            <ToggleButtonGroup
              className={classes.toggleGroup}
              size="small"
              value={mode}
              exclusive
              onChange={(_, value) => setMode(value)}
            >
              <ToggleButton value={modes.DEFAULT}>date</ToggleButton>
              <ToggleButton value={modes.BY_HOUR}>hour</ToggleButton>
            </ToggleButtonGroup>
            <div style={{ minHeight: 200, flexGrow: 1 }}>
              <ValueOverTimeChart
                disableHeight={false}
                isFetching={isFetching}
                type="bar"
                categories={categories}
                categoriesFormatter={
                  mode === modes.DEFAULT ? getTimeLabelFormatter(requestDate, timezone, period) : null
                }
                series={report?.bayExitReport ? [report.bayExitReport.bay, report.bayExitReport.exit] : null}
                optionsCustomizer={(options) => {
                  options.legend = {
                    show: true,
                    top: 20,
                  };
                  options.grid.top = 60;
                  options.grid.left = 10;
                }}
              />
            </div>
          </>
        </Widget>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    report: getReport(state),
    requestDate: getRequestDate(state),
    locationIdsWithSales: getLocationIdsWithDriveoff(state),
    defaultLocationId: getDefaultLocationIdWithDriveoff(state),
    availableLocations: getLocationsWithDriveoff(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(
    withStyles(styles)(withWidth()(toJS(DriveoffDashboard)))
  )
);

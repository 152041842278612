import { CHARTS_PALETTE } from 'components/style/colors';
import { fromJS } from 'immutable';
import { find, last, sumBy } from 'lodash';

export const types = {
  REPORT_REQUEST: 'OPS/VISIT_DURATION_BREAKDOWN/REPORT_REQUEST',
  REPORT_SUCCESS: 'OPS/VISIT_DURATION_BREAKDOWN/REPORT_SUCCESS',
  REPORT_FAILURE: 'OPS/VISIT_DURATION_BREAKDOWN/REPORT_FAILURE',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(location, locationConfig, report) {
  const rows = report && report.rows;
  if (!rows || !rows.length) {
    return null;
  }

  const average = sumBy(report.rows, 'row.waitTime') / report.rows.length;
  const { visitDurationCategories } = locationConfig;
  const categories = visitDurationCategories.map((value, index) => {
    const min = index === 0 ? 0 : visitDurationCategories[index - 1];
    const max = index === visitDurationCategories.length - 1 ? last(visitDurationCategories) : value;
    return { value: 0, min, max };
  });
  categories.push({ value: 0, min: last(categories).max, max: Number.MAX_SAFE_INTEGER });
  categories.forEach((category, index) => {
    category.id = index;
    category.color = CHARTS_PALETTE[index];
    const { min, max } = category;
    let name;
    if (min === 0) {
      name = `up to ${max}m`;
    } else if (max === Number.MAX_SAFE_INTEGER) {
      name = `${min}+m`;
    } else {
      name = `${min}-${max}m`;
    }
    category.name = name;
  });
  report.rows.forEach(({ row }) => {
    const minutes = row.waitTime / 60;
    const category = find(categories, ({ min, max }) => minutes > min && minutes <= max);
    category.value = category.value + 1;
  });
  return { average, data: categories };
}

export default function visitDurationBreakdown(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, location, locationConfig, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(location, locationConfig, report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
      });
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
  }),
  reportSuccess: (requestDate, timezone, period, location, locationConfig, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    location,
    locationConfig,
    report,
  }),
  reportFailure: (requestDate, timezone, period, location, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    location,
    error,
  }),
};

export const getRoot = (state) => state.getIn(['dashboard', 'ops', 'visitDurationBreakdown']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');

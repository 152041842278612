import camera from 'reducers/dashboard/camera';
import company from 'reducers/dashboard/company';
import driveoff from 'reducers/dashboard/driveoff';
import location from 'reducers/dashboard/location';
import ops from 'reducers/dashboard/ops';
import sales from 'reducers/dashboard/sales';
import { combineReducers } from 'redux-immutable';

export default combineReducers({
  camera,
  location,
  sales,
  ops,
  company,
  driveoff,
});

import { Avatar, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import md5 from 'crypto-js/md5';
import React from 'react';
import { connect } from 'react-redux';
import { getUserEmail, getUserName } from 'reducers/auth';

const styles = theme => ({
  root: {
    textTransform: 'uppercase',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    position: 'relative',
    boxShadow: 'inset 0 0 0 2px rgba(0, 0, 0, 0.5)',
  },
});

function UserAvatar({ classes, className, name, email, size = 48 }) {
  const initials = name
    .trim()
    .split(' ')
    .map(s => s.charAt(0))
    .join('');
  return (
    <Avatar className={classNames(classes.root, className)} style={{ width: size, height: size }}>
      <img
        style={{ position: 'absolute', top: 0, left: 0 }}
        src={`https://gravatar.com/avatar/${md5(email)}}?s=${size}&d=blank`}
        alt=""
      />
      {initials}
    </Avatar>
  );
}

function mapStateToProps(state) {
  return {
    name: getUserName(state),
    email: getUserEmail(state),
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(UserAvatar));

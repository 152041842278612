import { createReportSettings, reports, widgetReport } from 'api/report';
import { find } from 'lodash';
import { DRIVE_OFF_CLASSIC_LAYOUT, getDriveoffDashboardConfig } from 'reducers/auth';
import { actions, modes, types } from 'reducers/dashboard/driveoff/summary';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { populateLocIdAndName } from 'sagas/dashboard/location/util';

function* fetchReports({ requestDate, timezone, period, mode, location }) {
  try {
    const config = yield select(getDriveoffDashboardConfig);
    const locationConfig = find(config.toJS(), ['locationId', location]);
    const { bayTags, exitTags, layout = DRIVE_OFF_CLASSIC_LAYOUT } = locationConfig;
    const reportId = mode === modes.BY_HOUR ? reports.VEHICLE_COUNT_SUM_BY_TAGS_BY_HOUR : reports.VEHICLE_COUNT_BY_TAGS;
    const [bay, exit] = yield all([
      call(widgetReport, reportId, createReportSettings(requestDate, timezone, period, null, location, bayTags)),
      call(widgetReport, reportId, createReportSettings(requestDate, timezone, period, null, location, exitTags)),
    ]);

    const report = {
      bay: yield populateLocIdAndName(bay.data, location),
      exit: yield populateLocIdAndName(exit.data, location),
      timezone,
    };

    yield put(actions.reportSuccess(requestDate, timezone, period, layout, mode, report));
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, period, mode, e));
  }
}

export default function* summary() {
  yield takeLatest(types.REPORT_REQUEST, fetchReports);
}

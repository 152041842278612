import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { useDispatch } from 'react-redux';

import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';

const CSVUploaderButton = ({ label = 'Upload file', onFileChange }) => {
  const dispatch = useDispatch();
  const handleFileChanged = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;

        onFileChange(content);
        dispatch(snackActions.set('CSV file uploaded successfully', snackVariants.success));
      };

      reader.readAsText(file);
    }

    event.target.value = null;
  };

  return (
    <Tooltip
      arrow
      interactive
      title="We support plain CSV without a header row and with only one column"
      placement="top"
      enterDelay={100}
    >
      <Button component="label" color="primary" startIcon={<BackupIcon />} variant="outlined">
        {label}
        <input hidden accept=".csv" type="file" onChange={handleFileChanged} />
      </Button>
    </Tooltip>
  );
};

export default CSVUploaderButton;

import { useTheme } from '@material-ui/core';
import { composeTimeCategories, getTimeLabelFormatter } from 'components/dashboard/common/periods';
import Widget from 'components/dashboard/common/Widget';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import WidgetHeaderTrend from 'components/dashboard/common/WidgetHeaderTrend';
import COLORS from 'components/style/colors';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import { get, round, sum as sumArray } from 'lodash';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

function pad(num) {
  return num < 10 ? `0${num}` : num;
}

function formatSeconds(seconds) {
  if (!seconds || seconds < 1) {
    return '\u2014';
  }
  const hours = Math.floor(seconds / 60 / 60);
  const minutes = Math.floor(seconds / 60);
  const secs = Math.ceil(seconds % 60);
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}

function prepareData(report) {
  if (get(report, 'empty', true)) {
    return [undefined, undefined, undefined, undefined];
  }

  const series = [
    {
      name: 'Average duration',
      color: COLORS.BLUE,
      data: report.rows.map(({ row }, dataIndex) => [dataIndex, 0, row.waitTime]),
    },
  ];

  const preparedSeries = series.map((s) => ({ ...s, type: 'scatter', itemStyle: { color: s.color } }));

  const parts = series[0].data.length;
  const averages = [];
  const nonZeroAverages = [];
  for (let i = 0; i < parts; i += 1) {
    let sum = 0;
    let nonZeroCount = 0;

    for (let j = 0; j < series.length; j += 1) {
      const value = series[j].data[i][2];
      if (value > 0) {
        sum += value;
        nonZeroCount += 1;
      }
    }
    if (nonZeroCount > 0) {
      const average = round(sum / nonZeroCount, 1);
      averages.push(average);
      nonZeroAverages.push(average);
    } else {
      averages.push(0);
    }
  }
  const totalAverages =
    nonZeroAverages.length > 0 ? round(sumArray(nonZeroAverages) / nonZeroAverages.length, 1) : undefined;

  const yAxisData = [];
  for (let i = 0; i < series.length; i += 1) {
    yAxisData.push(i);
  }

  let maxValue = 0;
  series.forEach((s) => {
    s.data.forEach((d) => {
      const value = d[2];
      if (value > maxValue) {
        maxValue = value;
      }
    });
  });

  return [preparedSeries, averages, totalAverages, yAxisData, maxValue];
}

export default function AverageVisitDurationWidget({ requestDate, timezone, period, report }) {
  const theme = useTheme();

  if (!requestDate) {
    return null;
  }

  const categories = composeTimeCategories(requestDate, timezone, period);
  const [series, averages, totalAverage, yAxisData, maxValue] = prepareData(report);

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params) =>
        `${params[0].axisValue} <br /> ${params
          .map((p) => `${p.marker} ${p.seriesName}: ${formatSeconds(p.data[2])}`)
          .join('<br />')}`,
      extraCssText: `z-index: ${theme.zIndex.drawer + 1}`,
    },
    grid: {
      left: 10,
      top: 20,
      right: 10,
      bottom: 0,
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        position: 'bottom',
        data: categories,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: getTimeLabelFormatter(requestDate, timezone, period),
          margin: 4,
        },
      },
      {
        type: 'category',
        position: 'top',
        data: averages,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value) => formatSeconds(value),
          margin: 4,
        },
      },
    ],
    yAxis: [
      {
        type: 'category',
        data: yAxisData,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
          margin: 4,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series,
  };

  return (
    <Widget title="Average duration">
      <>
        <WidgetHeaderTrend
          name="Avg. visit duration"
          headerValue={totalAverage && formatSeconds(totalAverage)}
          value={0}
          period="yesterday"
          aimDownward
        />
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = 200;

            let preparedOptions = options;
            if (series && series.length > 0) {
              const maxSize = Math.min(width / series[0].data.length - 1, height / series.length - 21, height / 5 - 10);
              const oneP = maxSize / 100;
              preparedOptions = {
                ...options,
                series: options.series.map((s) => ({ ...s, symbolSize: (val) => (val[2] / (maxValue / 100)) * oneP })),
              };
            }

            return <WidgetChart width={width} height={height} isFetching={false} options={preparedOptions} />;
          }}
        </AutoSizer>
      </>
    </Widget>
  );
}

import { downloadSnapshots } from 'api/job';
import { downloadData, resolveJobNameById } from 'components/common/utils';
import { actions, types } from 'reducers/dashboard/camera/snapshotsDownloader';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';
import { call, put, takeLatest } from 'redux-saga/effects';

function* downloadCsv({ startDate, endDate, jobId }) {
  try {
    const jobName = resolveJobNameById(jobId) || 'camera';
    const response = yield call(downloadSnapshots, 'enter', startDate, endDate, jobId);
    downloadData(response.data, 'application/zip', `${jobName}_snapshots.zip`);

    yield put(actions.downloadComplete(startDate, endDate, jobId));
  } catch (e) {
    yield put(snackActions.set('Failed to download snapshots, please try again later', snackVariants.error));
    yield put(actions.downloadComplete(startDate, endDate, jobId));
  }
}

export default function* snapshotsDownloader() {
  yield takeLatest(types.DOWNLOAD_REQUEST, downloadCsv);
}

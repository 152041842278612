import { observer } from 'mobx-react';
import Widget from 'components/dashboard/common/Widget';
import COLORS, { ERROR_COLOR } from 'components/style/colors';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import React, { useCallback, useState } from 'react';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import { makeAutoObservable } from 'mobx';
import { composeTimeCategories, getTimeLabelFormatter, TIME_SCALES } from 'components/dashboard/common/periods';
import {
  createAverageVisitsDurationReportSettings,
  createReportSettings,
  getReportTimeScale,
  reports,
  widgetReport,
} from 'api/report';
import { compact, find, get, mean, round } from 'lodash';
import { formatSeconds } from 'components/dashboard/camera/WaitTimeWidget';
import { fromPromise } from 'mobx-utils';
import ProductivityScoreGauge from 'components/dashboard/ops/ProductivityScoreGauge';

class State {
  requestDate = null;
  timezone = null;
  period = null;
  locationId = null;
  reportResult = null;
  opsDashboardConfig = null;

  constructor(opsDashboardConfig) {
    this.opsDashboardConfig = opsDashboardConfig;
    makeAutoObservable(this);
  }

  loadData(requestDate, timezone, period, locationId) {
    this.requestDate = requestDate;
    this.timezone = timezone;
    this.period = period;
    this.locationId = locationId;
    this.report = null;
    const { productivity = {} } = find(this.opsDashboardConfig, ['locationId', locationId]);
    const { propertyEntranceTags, tunnelEntranceTags } = productivity;
    if (!propertyEntranceTags?.length || !tunnelEntranceTags?.length) {
      this.reportResult = undefined;
      return;
    }

    const requests = [
      widgetReport(
        reports.VEHICLE_COUNT_BY_TAGS,
        createReportSettings(requestDate, timezone, period, null, locationId, tunnelEntranceTags)
      ),
      widgetReport(
        reports.VEHICLE_AVG_WAIT_TIME_BY_TAGS,
        createAverageVisitsDurationReportSettings(
          requestDate,
          timezone,
          period,
          locationId,
          propertyEntranceTags,
          tunnelEntranceTags
        )
      ),
    ];

    this.reportResult = fromPromise(Promise.all(requests));
  }

  get totalCustomersReport() {
    if (this.reportResult === null || this.reportResult.state !== 'fulfilled') {
      return null;
    }
    return this.reportResult.value[0].data;
  }

  get waitTimeReport() {
    if (this.reportResult === null || this.reportResult.state !== 'fulfilled') {
      return null;
    }
    return this.reportResult.value[1].data;
  }

  get loading() {
    return this.reportResult === null || this.reportResult.state === 'pending';
  }

  get series() {
    if (get(this.totalCustomersReport, 'empty', true) || get(this.waitTimeReport, 'empty', true)) {
      return null;
    }

    const totalCustomersData = this.totalCustomersReport.rows.map((row) => {
      return row.row.eventsCount;
    });
    const waitTimeData = this.waitTimeReport.rows.map((row) => {
      return row.row.waitTime;
    });
    const scorePerBucket = this.productivityScorePerBucket;
    const productivityScoreData = totalCustomersData.map((_, index) => round(scorePerBucket?.[index], 1) ?? null);
    return [
      { name: 'Customers', color: COLORS.LIGHT_BLUE, data: totalCustomersData },
      {
        name: 'Average wait time',
        color: COLORS.DARK_GREY,
        type: 'line',
        yAxisIndex: 1,
        data: waitTimeData,
        markLine: {
          silent: true,
          symbol: 'none',
          data: [
            {
              symbol: 'none',
              yAxis: 120,
              label: {
                show: false,
              },
              lineStyle: { color: ERROR_COLOR },
            },
          ],
        },
      },
      {
        name: 'Wash score',
        color: COLORS.GREEN,
        type: 'line',
        yAxisIndex: 2,
        data: productivityScoreData,
        smooth: true,
        showSymbol: false,
        lineStyle: { width: 0 },
      },
    ];
  }

  get productivityScorePerBucket() {
    if (get(this.totalCustomersReport, 'empty', true) || get(this.waitTimeReport, 'empty', true)) {
      return null;
    }

    const scorePerBucket = this.totalCustomersReport.rows.map((row, index) => {
      const customers = row.row.eventsCount;
      const waitTime = this.waitTimeReport.rows[index].row.waitTime;
      if (!customers || !waitTime) {
        return 0;
      }
      if (waitTime <= 120) {
        return 5;
      }

      const maxTunnelThroughput = 100;
      let tunnelUtilization = (customers / maxTunnelThroughput) * 1.5;
      if (tunnelUtilization === 0) {
        return null;
      }
      if (tunnelUtilization > 1) {
        tunnelUtilization = 1;
      }

      const medianWaitTimeGuess = waitTime / 2.5;
      let medianWaitTimeMinutes = medianWaitTimeGuess / 60;

      let productivityScore = tunnelUtilization / medianWaitTimeMinutes;
      if (productivityScore > 1) {
        productivityScore = 1;
      }
      if (productivityScore <= 0) {
        productivityScore = 0.02;
      }

      return 5 * productivityScore;
    });

    return scorePerBucket.length ? scorePerBucket : null;
  }

  get productivityScore() {
    const scorePerBucket = this.productivityScorePerBucket;
    if (!scorePerBucket) {
      return null;
    }

    const averageScore = mean(compact(scorePerBucket));
    return round(averageScore, 1);
  }

  get productivityScoreFormatted() {
    if (!this.productivityScore) {
      return null;
    }

    return `${this.productivityScore} of 5`;
  }

  get productivityReportAvailable() {
    const { productivity = {} } = find(this.opsDashboardConfig, ['locationId', this.locationId]);
    const { propertyEntranceTags, tunnelEntranceTags } = productivity;
    const timeScale = getReportTimeScale(this.requestDate, this.timezone, this.period);
    return propertyEntranceTags?.length > 0 && tunnelEntranceTags?.length > 0 && timeScale === TIME_SCALES.HOUR.name;
  }
}

function ProductivityWidget({ location, history, userTimezone, defaultLocationId, opsDashboardConfig }) {
  const [state] = useState(() => new State(opsDashboardConfig));
  const onSettingsChange = useCallback(
    (requestDate, timezone, period, locationId) => {
      state.loadData(requestDate, timezone, period, locationId);
    },
    [state]
  );
  useSettingsChangeActionDispatcher(location, history, userTimezone, onSettingsChange, defaultLocationId);

  if (!state.requestDate || !state.productivityReportAvailable) {
    return null;
  }

  const categories = composeTimeCategories(state.requestDate, state.timezone, state.period);

  return (
    <Widget title="Wash score">
      <ProductivityScoreGauge state={state} />
      <ValueOverTimeChart
        optionsCustomizer={(options) => {
          options.tooltip.formatter = (params) =>
            `${params[0].axisValue}<br /> <table>${params
              .map((p, index) => {
                const value = p.data;
                const formatterValue = index === 1 ? `${formatSeconds(value)}` : value?.toLocaleString();
                return `<tr><td>${p.marker} ${p.seriesName}</td><td style="text-align: right;padding-left: 8px"><b>${formatterValue}</b></td></tr>`;
              })
              .join('')}
                        </table>`;
          options.yAxis.push(
            {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              axisLabel: {
                formatter: (value) => (value === 0 ? 'mm:ss' : formatSeconds(value)),
                margin: 10,
              },
            },
            {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              axisLabel: {
                show: false,
                margin: 0,
              },
            }
          );
        }}
        additionalHeight={147}
        isFetching={state.loading}
        type="bar"
        categories={categories}
        categoriesFormatter={getTimeLabelFormatter(state.requestDate, state.timezone, state.period)}
        series={state.series}
      />
    </Widget>
  );
}

export default observer(ProductivityWidget);

import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import toJS from 'components/common/toJS';
import { getDescriptionByCode } from 'components/common/weather';
import MiniWidget from 'components/dashboard/common/MiniWidget';
import { resolveTimezone } from 'components/dashboard/common/periods';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import WeatherCodeIcon from 'components/icons/WeatherCodeIcon';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getLocations, getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/location/weatherMini';

const useStyles = makeStyles((theme) => ({
  value: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    maxHeight: 40,
    display: 'flex',
    alignItems: 'center',
    marginLeft: -8,
    marginRight: theme.spacing(1),
  },
}));

function WeatherMiniWidget({
  location,
  history,
  isFetching,
  requestDate,
  userTimezone,
  availableLocations,
  report,
  reportRequest,
  defaultLocationId,
}) {
  const classes = useStyles();
  const [, , locationId] = useSettingsChangeActionDispatcher(
    location,
    history,
    userTimezone,
    reportRequest,
    defaultLocationId
  );

  if (!requestDate) {
    return null;
  }

  const temp = report?.rows?.[0]?.row?.temp;
  const code = report?.rows?.[0]?.row?.code;
  const locationTimezone = resolveTimezone(locationId, availableLocations, userTimezone);
  const hour = moment.tz(requestDate, locationTimezone).hour();
  return (
    <MiniWidget
      isFetching={isFetching}
      title="Current weather"
      value={
        code === undefined ? null : (
          <>
            <Tooltip title={getDescriptionByCode(code)}>
              <span className={classes.iconContainer}>
                <WeatherCodeIcon nightMode={hour >= 20 || hour < 6} code={code} size={56} />
              </span>
            </Tooltip>
            {temp}
          </>
        )
      }
      valueClassName={classes.value}
      valueHelperText="℉"
    />
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
    availableLocations: getLocations(state),
  };
}

export default withRouter(connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(WeatherMiniWidget)));

import {
  Avatar,
  Grid,
  isWidthUp,
  Link as MuiLink,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles,
  withWidth,
} from '@material-ui/core';
import LocationIcon from '@material-ui/icons/LocationOn';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import MasonryLayout from 'components/common/MasonryLayout';
import mixpanel from 'components/common/mixpanel';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import { resolveLocationNameById } from 'components/common/utils';
import { formatSeconds } from 'components/dashboard/camera/WaitTimeWidget';
import Widget from 'components/dashboard/common/Widget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { COMPANY_DASHBOARD_CONFIG, getUserTimezone, SALE_TIME_COMPANY_DASHBOARD_GROUP_NAME } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/company/summary';
import StoreLeaderboard from 'components/dashboard/company/StoreLeaderboard';
import NonMembersLeaderboard from 'components/dashboard/company/NonMembersLeaderboard';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  avatar: {
    borderRadius: '15%',
  },
  nested: {
    paddingLeft: theme.spacing(9),
    paddingTop: 2,
    paddingBottom: 2,
  },
  listItemText: {
    paddingRight: theme.spacing(8),
  },
  sublistItemText: {
    marginTop: 0,
    marginBottom: 0,
    paddingRight: theme.spacing(8),
  },
});

function CompanyDashboard({
  classes,
  location,
  history,
  userTimezone,
  requestDate,
  reportRequest,
  width,
  isFetching,
  report,
}) {
  const onSettingsChange = useCallback(
    (requestDate, timezone, period) => {
      mixpanel((mp) =>
        mp.track('Site dashboard', {
          timezone,
          period,
        })
      );
      reportRequest(requestDate, timezone, period);
    },
    [reportRequest]
  );
  useSettingsChangeActionDispatcher(location, history, userTimezone, onSettingsChange);

  const defaultData = useMemo(() => {
    const result = cloneDeep(COMPANY_DASHBOARD_CONFIG);
    result.forEach(({ locations }) => {
      locations.forEach((location) => {
        location.name = resolveLocationNameById(location.id);
      });
    });
    return result;
  }, []);
  const data = useMemo(() => {
    return report ? report : defaultData;
  }, [report, defaultData]);

  let columns = 1;
  if (isWidthUp('md', width)) {
    columns = 2;
  }
  if (isWidthUp('lg', width)) {
    columns = 3;
  }

  if (!requestDate) {
    return null;
  }

  const widgets = data.map(({ name }, groupIndex) => {
    return (
      <Widget key={name} title={name}>
        <List>
          {data[groupIndex].locations.map((location) => {
            const timeField = name === SALE_TIME_COMPANY_DASHBOARD_GROUP_NAME ? 'averageTime' : 'medianTime';
            return (
              <React.Fragment key={`${name}_${location.id}`}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: location.color }}>
                      <LocationIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText className={classes.listItemText} primary={location.name} />
                  <ListItemSecondaryAction>
                    <Typography align="right">
                      <MuiLink
                        component={Link}
                        to={`/reports/visitsduration?location=${location.id}&enter[0]=${location.enterTags[0]}&exit[0]=${location.exitTags[0]}`}
                      >
                        {formatSeconds(location[timeField])}
                      </MuiLink>
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
                {location.topSlowest?.length ? (
                  <List component="div" disablePadding>
                    {location.topSlowest.map(({ row }, index) => {
                      const enterMoment = moment.tz(row.enterTime, 'UTC');

                      return (
                        <ListItem
                          key={`${name}_${location.id}_${index}`}
                          alignItems="flex-start"
                          className={classes.nested}
                        >
                          <ListItemText
                            className={classes.sublistItemText}
                            primaryTypographyProps={{ color: 'textSecondary' }}
                            primary={row.licensePlate}
                            secondary={enterMoment.format('MM/DD/YYYY HH:mm')}
                          />
                          <ListItemSecondaryAction>
                            <Typography color="textSecondary" variant="body2" align="right">
                              {formatSeconds(row.waitTime)}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : null}
              </React.Fragment>
            );
          })}
        </List>
      </Widget>
    );
  });
  widgets.push(<StoreLeaderboard key="storeLeaderboard" />);
  widgets.push(<NonMembersLeaderboard key="nonMembersLeaderboard" />);

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Portfolio leaderboards" />
      <Grid item className={classes.grow}>
        {isFetching && <BrandedLoadingIndicator />}
        {!isFetching && (
          <>
            <MasonryLayout columns={columns} gap={32}>
              {widgets}
            </MasonryLayout>
          </>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    report: getReport(state),
    requestDate: getRequestDate(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(
    withStyles(styles)(withWidth()(toJS(CompanyDashboard)))
  )
);

import {
  Avatar,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import DetailsIcon from '@material-ui/icons/KeyboardArrowRight';
import CountPercentBar from 'components/dashboard/common/CountPercentBar';
import React, { useCallback, useState } from 'react';
import { AutoSizer } from 'react-virtualized';

const styles = () => ({
  list: {
    backgroundColor: 'inherit',
  },
  avatar: {
    borderRadius: '15%',
  },
});

function WidgetLegend({ classes, icon, data, showAction = false }) {
  const [countWidth, setCountWidth] = useState(0);
  const onCountWidthUpdate = useCallback((width) => setCountWidth((state) => (width > state ? width : state)), []);

  if (!data) {
    return null;
  }

  return (
    <Grid item>
      <AutoSizer disableHeight>
        {({ width }) => {
          if (width === 0) {
            return null;
          }

          return (
            <List className={classes.list} style={{ width }}>
              {data.map((entry, index) => (
                <React.Fragment key={entry.id}>
                  <ListItem disableGutters>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar} style={{ backgroundColor: entry.color }}>
                        {entry.renderIcon ? entry.renderIcon() : icon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={entry.name}
                      secondary={
                        <CountPercentBar
                          countWidth={countWidth}
                          parentWidth={width}
                          value={entry.value}
                          percent={entry.percent}
                          onCountWidthUpdate={onCountWidthUpdate}
                        />
                      }
                    />
                    {showAction && (
                      <ListItemSecondaryAction>
                        <IconButton aria-label="Stream details">
                          <DetailsIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          );
        }}
      </AutoSizer>
    </Grid>
  );
}

export default withStyles(styles)(WidgetLegend);

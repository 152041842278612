import COLORS from 'components/style/colors';
import { fromJS } from 'immutable';
import { get } from 'lodash';

export const types = {
  REPORT_REQUEST: 'INSIGHTS/REPORT_REQUEST',
  REPORT_SUCCESS: 'INSIGHTS/REPORT_SUCCESS',
  REPORT_FAILURE: 'INSIGHTS/REPORT_FAILURE',
  SUBMIT_FEEDBACK: 'INSIGHTS/SUBMIT_FEEDBACK',
  RESET: 'INSIGHTS/RESET',
};

const initialState = fromJS({
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareWeatherReport(report) {
  if (get(report, 'empty', true)) {
    return null;
  }

  const hourlyTemp = {
    color: COLORS.GREEN,
    name: 'Temperature',
    type: 'line',
    lineStyle: { width: 3 },
    smooth: true,
    data: report.rows.map(({ row }) => row.temp),
    label: {
      show: true,
      color: 'black',
      formatter: ({ dataIndex, value }) => (dataIndex % 5 === 0 ? `${value}℉` : ''),
    },
  };
  const hourlyPrecip = {
    color: COLORS.BLUE,
    type: 'bar',
    name: 'Precipitation',
    barMinHeight: 1,
    yAxisIndex: 1,
    data: report.rows.map(({ row }) => row.chanceOfPrecip),
  };
  return {
    hourly: [hourlyTemp, hourlyPrecip],
    total: { ...report.total.row },
  };
}

function prepareEventsReport(report) {
  if (get(report, 'empty', true)) {
    return null;
  }

  return report.rows.slice(0, 5).map(({ row }) => ({
    date: row.scaledDate,
    name: row.eventName,
    type: row.eventType,
    location: row.eventVenue,
  }));
}

function prepareCustomersReport(report) {
  if (get(report, 'empty', true)) {
    return null;
  }

  const result = {
    color: COLORS.DARK_YELLOW,
    name: 'Customers',
    value: 0,
    data: [],
  };
  const rows = report && report.rows;
  rows.forEach((row) => {
    const value = row.row.prediction === null ? null : Math.round(row.row.prediction);
    result.data.push(value);
    if (value !== null) {
      result.value += value;
    }
  });
  return [result];
}

function prepareReport(report) {
  report.customers = prepareCustomersReport(report.customers);
  report.events = prepareEventsReport(report.events);
  report.weather = prepareWeatherReport(report.weather);
  return report;
}

export default function insights(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        const { timezone } = action;
        s.set('isFetching', false);
        s.set(
          'report',
          fromJS({
            customers: null,
            events: null,
            weather: null,
            timezone,
          })
        );
      });
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location) => ({
    type: types.REPORT_REQUEST,
    timezone,
    period,
    requestDate,
    location,
  }),
  reportSuccess: (requestDate, timezone, period, report) => ({
    type: types.REPORT_SUCCESS,
    timezone,
    period,
    requestDate,
    report,
  }),
  reportFailure: (requestDate, timezone, period, error) => ({
    type: types.REPORT_FAILURE,
    timezone,
    period,
    requestDate,
    error,
  }),
  submitFeedback: (timezone, period, locationId, positive, notes) => ({
    type: types.SUBMIT_FEEDBACK,
    timezone,
    period,
    locationId,
    positive,
    notes,
  }),
  reset: () => ({
    type: types.RESET,
  }),
};

export const getRoot = (state) => state.getIn(['insights']);
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const getReportTimezone = (state) => getRoot(state).getIn(['report', 'timezone']);
export const getCustomersReport = (state) => getRoot(state).getIn(['report', 'customers']);
export const getEventsReport = (state) => getRoot(state).getIn(['report', 'events']);
export const getWeatherReport = (state) => getRoot(state).getIn(['report', 'weather']);
export const isReportFetching = (state) => getRoot(state).get('isFetching');

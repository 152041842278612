import React from 'react';
import { FaCarSide as CarIcon, FaMotorcycle as MotorcycleIcon } from 'react-icons/fa';

const files = require.context('car-makes-icons/svgs/', true, /\.svg$/);
const paths = {};
export const icons = {};
files.keys().forEach(path => {
  paths[path] = true;
  icons[
    /\.\/(.+)\.svg/
      .exec(path)[1]
      .toLowerCase()
      .replace(/ /g, '')
  ] = files(path);
});

const motorcycles = {
  Aprilia: 1,
  Ducati: 1,
  'Harley-Davidson': 1,
  Indian: 1,
  Kawasaki: 1,
  KTM: 1,
  'MV Agusta': 1,
  Piaggio: 1,
  Triumph: 1,
  Victory: 1,
  Yamaha: 1,
};

function VehicleMakeIcon({ name, size }) {
  if (motorcycles[name]) {
    return <MotorcycleIcon style={{ width: size, height: size }} color="white" />;
  }

  const src = `./${name.toLowerCase().replace(/-/g, ' ')}.svg`;
  return paths[src] ? (
    <img style={{ width: size, height: size, filter: 'invert(1)' }} src={files(src)} alt={name} />
  ) : (
    <CarIcon style={{ width: size, height: size }} color="white" />
  );
}

export default VehicleMakeIcon;

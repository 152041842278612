import { apiPath as locationApiPath } from 'api/location';
import { apiPath as userApiPath } from 'api/user';

export const TYPE_USER = 'USER';
export const TYPE_LOCATION = 'LOCATION';

export function getApiPathForType(entityType) {
  switch (entityType) {
    case TYPE_USER:
      return userApiPath;
    case TYPE_LOCATION:
      return locationApiPath;
    default:
      throw new Error(`Unknown entity type: ${entityType}`);
  }
}

export function getTitleForType(entityType) {
  switch (entityType) {
    case TYPE_USER:
      return 'user';
    case TYPE_LOCATION:
      return 'location';
    default:
      throw new Error(`Unknown entity type: ${entityType}`);
  }
}

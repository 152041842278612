import toJS from 'components/common/toJS';
import useSettingsChangeActionDispatcher from 'components/dashboard/camera/useSettingsChangeActionDispatcher';
import { composeTimeCategories, getTimeLabelFormatter } from 'components/dashboard/common/periods';
import ThreeValuesWidgetHeader from 'components/dashboard/common/ThreeValuesWidgetHeader';
import { prepareLegendData } from 'components/dashboard/common/utils';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import WidgetLegend from 'components/dashboard/common/WidgetLegend';
import COLORS from 'components/style/colors';
import { get } from 'lodash';
import React from 'react';
import { FaCarSide as CarIcon } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/camera/returningCustomers';

const newColor = COLORS.LIGHT_BLUE;
const returningColor = COLORS.DARK_YELLOW;

function ReturningCustomersWidget({ location, history, isFetching, requestDate, userTimezone, report, reportRequest }) {
  const [period, timezone] = useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest);

  if (!requestDate) {
    return null;
  }

  const series = report && [report.new, report.returning];
  const categories = composeTimeCategories(requestDate, timezone, period);
  const legendData = report && prepareLegendData(series);

  return (
    <Widget title="New vs returning">
      <>
        <ThreeValuesWidgetHeader
          left={{ name: 'New', value: get(report, 'new.value'), color: newColor }}
          right={{ name: 'Returning', value: get(report, 'returning.value'), color: returningColor }}
        />
        <ValueOverTimeChart
          isFetching={isFetching}
          type="bar"
          stack="one"
          categories={categories}
          categoriesFormatter={getTimeLabelFormatter(requestDate, timezone, period)}
          series={series}
        />
        <WidgetLegend data={legendData} icon={<CarIcon />} />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(ReturningCustomersWidget))
);

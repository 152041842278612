import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import LocationIcon from '@material-ui/icons/LocationOn';
import Map from 'components/common/Map';
import StaticTextField from 'components/common/StaticTextField';
import toJS from 'components/common/toJS';
import LocationCameras from 'components/location/LocationCameras';
import LocationFeatures from 'components/location/LocationFeatures';
import LocationOpeningHours from 'components/location/LocationOpeningHours';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { actions, getDetailsEntity, isDetailsOpen } from 'reducers/location/list';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  metadata: {
    display: 'grid',
    gridGap: theme.spacing(5),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  map: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
  },
  titleIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function LocationDetails({ open, fullScreen, entity, onClose }) {
  const classes = useStyles();
  return (
    <>
      <Dialog
        onClose={onClose}
        disableBackdropClick
        scroll="body"
        maxWidth="md"
        fullWidth
        PaperProps={{
          id: 'location-details-dialog-paper',
          className: classes.paperRoot,
        }}
        open={open}
        fullScreen={fullScreen}
        aria-labelledby="location-details-dialog-title"
      >
        <DialogTitle id="location-details-dialog-title" className={classes.title} disableTypography>
          <div className={classes.titleContent}>
            <LocationIcon className={classes.titleIcon} />
            <div>
              <Typography variant="h6">{entity?.name}</Typography>
              <LocationFeatures value={entity} />
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {entity && (
          <DialogContent className={classes.content}>
            <div className={classes.metadata}>
              <div className={classes.content}>
                <StaticTextField label="Company" value={entity.company?.name} />
                {entity.timeZoneId && (
                  <StaticTextField
                    label="Timezone"
                    value={
                      <>
                        (GMT{moment.tz(entity.timeZoneId).format('Z')}) {entity.timeZoneId}
                        <Typography display="block" variant="body2">
                          Local time: {moment.tz(entity.timeZoneId).format('ddd HH:mm')}
                        </Typography>
                      </>
                    }
                  />
                )}
                <StaticTextField
                  label="Operating hours"
                  helperText="We don't connect to your cameras and analyze events outside the location’s operating hours"
                  value={<LocationOpeningHours value={entity} />}
                />
                {entity.alertEmails?.length > 0 && (
                  <StaticTextField
                    label="Alert emails"
                    helperText="We'll send notifications to these emails when any camera from the location goes up or down"
                    value={
                      <Grid container spacing={1}>
                        {entity.alertEmails.map((email) => (
                          <Grid key={email} item>
                            <Chip icon={<EmailIcon />} label={email} />
                          </Grid>
                        ))}
                      </Grid>
                    }
                  />
                )}
              </div>
              <div className={classes.map}>
                <Map latitude={entity.latitude} longitude={entity.longitude} />
              </div>
            </div>
            <LocationCameras value={entity} />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function mapStateToProps(state) {
  return {
    open: isDetailsOpen(state),
    entity: getDetailsEntity(state),
  };
}

export default connect(mapStateToProps, {
  onClose: actions.detailsClose,
})(withMobileDialog()(toJS(LocationDetails)));

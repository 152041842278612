import { makeStyles } from '@material-ui/core';
import VisitsDurationIcon from '@material-ui/icons/AccessTime';
import InsightsIcon from '@material-ui/icons/DonutLarge';
import RewashesIcon from '@material-ui/icons/LocalCarWash';
import ActiveVisitsIcon from '@material-ui/icons/Timer';
import LocationFeatureChip from 'components/location/LocationFeatureChip';
import COLORS from 'components/style/colors';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 220,
    flexWrap: 'wrap',
    margin: -3,
    '& > *': {
      margin: 3,
    },
  },
}));

function LocationFeatures({ value }) {
  const classes = useStyles();
  const { id, insightsAccess, visitDurationAvailable, activeVisitsAvailable, enterLaneJobIds } = value;
  const rewashesAvailable = enterLaneJobIds?.length > 0;
  if (!insightsAccess && !visitDurationAvailable && !activeVisitsAvailable && !rewashesAvailable) {
    return null;
  }

  return (
    <div className={classes.root}>
      {insightsAccess && (
        <div>
          <LocationFeatureChip
            href={`/insights?location=${id}`}
            title="Insights"
            icon={<InsightsIcon />}
            details="Insights are available for this location"
          />
        </div>
      )}
      {rewashesAvailable && (
        <div>
          <LocationFeatureChip
            href={`/reports/rewashes?location=${id}`}
            title="Rewashes"
            icon={<RewashesIcon />}
            backgroundColor={COLORS.BLUE}
            details="Rewashes analytics is available for this location"
          />
        </div>
      )}
      {visitDurationAvailable && (
        <div>
          <LocationFeatureChip
            href={`/reports/visitsduration?location=${id}`}
            title="Visit duration"
            icon={<VisitsDurationIcon />}
            backgroundColor={COLORS.DARK_GREY}
            details="Visit duration analytics is available for this location"
          />
        </div>
      )}
      {activeVisitsAvailable && (
        <div>
          <LocationFeatureChip
            href={`/reports/activevisits?location=${id}`}
            title="Active visits"
            icon={<ActiveVisitsIcon />}
            backgroundColor={COLORS.LIGHT_BLUE}
            details="Active visits analytics is available for this location"
          />
        </div>
      )}
    </div>
  );
}

export default LocationFeatures;

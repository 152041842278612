import { makeStyles, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.5),
  },
  paper: {
    padding: theme.spacing(1, 2),
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    lineHeight: 1,
  },
  textContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  helperText: {
    marginLeft: theme.spacing(0.5),
  },
}));

function MiniWidget({
  isFetching = false,
  title,
  textContainerAlignItems = 'center',
  value,
  valueClassName,
  valueHelperText,
  rightHelperText,
  action,
  ...rest
}) {
  const classes = useStyles();
  return (
    <div {...rest}>
      <Typography display="block" color="textSecondary" variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Paper className={classes.paper}>
        {isFetching ? (
          <Skeleton animation="wave" variant="rect" width="100%" height={41.5} />
        ) : (
          <>
            <div className={classes.textContainer} style={{ alignItems: textContainerAlignItems }}>
              <Typography variant="h4" component="span" className={valueClassName}>
                {value !== null && value !== undefined ? value : <>&mdash;</>}
              </Typography>
              {valueHelperText && (
                <Typography className={classes.helperText} variant="subtitle1" color="textSecondary" component="span">
                  {valueHelperText}
                </Typography>
              )}
              <span style={{ flexGrow: 1 }} />
              {rightHelperText && (
                <Typography variant="subtitle1" color="textSecondary" component="span">
                  {rightHelperText}
                </Typography>
              )}
            </div>
            {action}
          </>
        )}
      </Paper>
    </div>
  );
}

export default MiniWidget;

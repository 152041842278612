import { observer } from 'mobx-react';
import { isObject } from 'lodash';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { withTheme } from '@material-ui/core';
import { RACE_COLORS } from 'components/style/colors';

function RaceDistributionChart({ data, theme, ...rest }) {
  const raceData = data?.race_percentage;
  if (!isObject(raceData)) {
    return null;
  }

  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        if (width === 0) {
          return null;
        }

        const height = width / 1.78;

        const transformedData = [];
        let colorIndex = 0;
        for (let key in raceData) {
          if (raceData[key] !== 0) {
            let label = key.split('_')[0];
            label = label.charAt(0).toUpperCase() + label.slice(1);
            transformedData.push({
              name: label,
              value: raceData[key],
              itemStyle: { color: RACE_COLORS[colorIndex] },
            });
            colorIndex++;
          }
        }

        return (
          <WidgetChart
            width={width}
            height={height}
            options={{
              tooltip: {
                trigger: 'item',
                formatter: '<strong>{b}</strong>: {d}%',
                extraCssText: `z-index: ${theme.zIndex.drawer + 1}`,
              },
              series: [
                {
                  type: 'pie',
                  data: transformedData,
                  radius: ['40%', '80%'],
                  center: [width / 2, height / 2],
                  avoidLabelOverlap: true,
                  label: {
                    normal: {
                      show: true,
                      position: 'outside',
                      color: theme.palette.text.primary,
                      fontSize: 16,
                      formatter: '{b} ({d}%)',
                    },
                  },
                  labelLine: {
                    show: true,
                    length: 5,
                    length2: 15,
                  },
                  itemStyle: { borderWidth: 2, borderColor: 'white' },
                },
              ],
            }}
            {...rest}
          />
        );
      }}
    </AutoSizer>
  );
}

export default observer(withTheme(RaceDistributionChart));

import axios from 'axios';
import {
  getInsightsReportUtcTimeRange,
  PERIODS,
  periodToUtcTimeRange,
  TIME_SCALES,
} from 'components/dashboard/common/periods';
import moment from 'moment-timezone';

export const reports = {
  VEHICLE_TOTAL_CUSTOMERS: 'vehicleTotalCustomers',
  VEHICLE_NEW_RETURNING: 'vehicleNewVsReturning',
  VEHICLE_NEW_LOYAL: 'vehicleNewReturningVsLoyal',
  VEHICLE_WAIT_TIME: 'vehicleWaitTime',
  VEHICLE_TOP_MAKES: 'vehicleTopMakes',
  VEHICLE_TOTAL_CUSTOMERS_WIDGET: 'vehicleTotalCustomersWidget',
  VEHICLE_FIRST_TIME_WIDGET: 'vehicleFirstTimeWidget',
  VEHICLE_FIRST_TIME_VS_RETURN_WIDGET: 'vehicleFirstTimeVsReturnWidget',
  VEHICLE_REWASH_COUNT_WIDGET: 'vehicleRewashCountWidget',
  VEHICLE_CUSTOMERS_LAST_SEEN_WIDGET: 'vehicleCustomersLastSeenWidget',
  VEHICLE_NOTIFICATIONS_WIDGET: 'vehicleNotificationsWidget',
  CAMERA_STATUS_WIDGET: 'cameraStatusWidget',
  EMPLOYEE_SALES_LEADER_BOARD_WIDGET: 'employeeSalesLeaderBoardWidget',
  VEHICLE_TOTAL_CUSTOMERS_WITH_SESSIONS: 'vehicleTotalCustomersWithSessions',
  VEHICLE_NEW_RETURNING_WITH_SESSIONS: 'vehicleNewVsReturningWithSessions',
  VEHICLE_NEW_LOYAL_WITH_SESSIONS: 'vehicleNewReturningVsLoyalWithSessions',
  VEHICLE_WAIT_TIME_WITH_SESSIONS: 'vehicleWaitTimeWithSessions',
  VEHICLE_TOP_MAKES_WITH_SESSIONS: 'vehicleTopMakesWithSessions',
  VEHICLE_COUNT_BY_TAGS: 'vehicleCountByTags',
  VEHICLE_DIMENSIONS_BY_TAGS_CSV: 'vehicleDimensionsByTagsCsv',
  VEHICLE_DIMENSIONS_BY_LICENSE_PLATE: 'vehicleDimensionsByLicensePlate',
  VEHICLE_VISIT_DURATION_BY_TAGS: 'vehicleVisitDurationByTags',
  VEHICLE_WAIT_TIME_BY_TAGS: 'vehicleWaitTimeByTags',
  VEHICLE_AVG_WAIT_TIME_BY_TAGS: 'vehicleAvgWaitTimeByTags',
  VEHICLE_REWASH_COUNT_TOTAL_BY_DATE: 'vehicleRewashCountTotalByDate',
  VEHICLE_REWASH_COUNT_BY_LICENSE_PLATE: 'vehicleRewashCountByLicensePlate',
  VEHICLE_BODY_TYPES_WITH_SESSIONS: 'vehicleBodyTypesWithSessions',
  VEHICLE_LOADER_EFFICIENCY: 'vehicleLoaderEfficiencyWidget',
  VEHICLE_LOADER_EFFICIENCY_BY_DAY_OF_WEEK: 'vehicleLoaderEfficiencyByDayOfWeekWidget',
  VEHICLE_LOADER_EFFICIENCY_BY_RANGE: 'vehicleLoaderEfficiencyByRangeWidget',
  CAMERA_UPTIME: 'jobStatus',
  INSIGHTS_CUSTOMERS_PREDICTION: 'insightsCustomersPrediction',
  INSIGHTS_LOCAL_EVENTS: 'insightsLocalEvents',
  INSIGHTS_WEATHER_PREDICTION: 'insightsWeatherPrediction',
  CURRENT_WEATHER_WIDGET: 'currentWeatherWidget',
  DETAILED_FRAUD_TABLE: 'detailedFraudTableReport',
  MEMBER_VALIDATION_FRAUD_REPORT: 'memberValidationFraudReport',
  DATA_SYNC_FRAUD_REPORT: 'dataSyncFraudReport',
  TOTAL_VISITS_VS_MEMBER_VISITS_REPORT: 'totalVisitsVsMemberVisitsReport',
  MEMBER_VISITS_VS_MISMATCHES_REPORT: 'memberVisitsVsMismatchesReport',
  GREEN_FRAUD_REPORT: 'greenFraudReport',
  MEMBERSHIP_SOLD_TOTAL_WIDGET: 'membershipSoldTotalWidget',
  TOTAL_RETURNING_NON_MEMBERS_WIDGET: 'totalReturningNonMembersWidget',
  VEHICLE_RETURNING_NON_MEMBERS_WIDGET: 'vehicleReturningNonMembersWidget',
  VEHICLE_TOTAL_CUSTOMERS_SUM_BY_HOUR: 'vehicleTotalCustomersSumByHourWidget',
  VEHICLE_COUNT_SUM_BY_TAGS_BY_HOUR: 'vehicleCountSumByTagsByHour',
  MEMBERS_WITH_RETURNINGS_REPORT: 'membersWithReturningsReport',
  EMPLOYEE_SALES_LEADER_BOARD_BY_LOCATIONS: 'employeeSalesLeaderBoardByLocationsWidget',
  MEMBERSHIP_SOLD_TOTAL_BY_LOCATIONS: 'membershipSoldTotalByLocationsWidget',
  MEMBERSHIP_CONVERSION_RATE_BY_LOCATIONS: 'membershipConversionRateByLocationsWidget',
  AI_SUMMARY_REPORT: 'aiSummaryReport',
};

export const params = {
  START_DATE: 'sd',
  END_DATE: 'ed',
  TIMEZONE: 'tz',
  TIME_SCALE: 'timeScale',
  JOB_IDS: 'jobIds',
  LOCATION_ID: 'locId',
  LOCATION_IDS: 'locIds',
  TAGS: 'tags',
  LICENSE_PLATE: 'lp',
  ENTER_TAGS: 'enterTags',
  EXIT_TAGS: 'exitTags',
  MEMBER_ID: 'memId',
};

export function getReportTimeScale(requestDate, timezone, period) {
  const [start, end] = periodToUtcTimeRange(requestDate, timezone, period);
  const defaultScale = moment.duration(end.diff(start)).asDays() <= 1 ? TIME_SCALES.HOUR.name : TIME_SCALES.DAY.name;
  return (PERIODS[period] && PERIODS[period].scale.name) || defaultScale;
}

export function createReportSettings(requestDate, timezone, period, job, location = undefined, tags = undefined) {
  const [start, end] = periodToUtcTimeRange(requestDate, timezone, period);
  return {
    [params.START_DATE]: start.toISOString(),
    [params.END_DATE]: end.toISOString(),
    [params.TIMEZONE]: timezone || moment.tz.guess(),
    [params.TIME_SCALE]: getReportTimeScale(requestDate, timezone, period),
    [params.JOB_IDS]: job ? [job] : undefined,
    [params.LOCATION_ID]: location,
    [params.TAGS]: tags,
  };
}

export function createInsightsReportSettings(requestDate, timezone, period, location, dateRange = null) {
  const [start, end] = dateRange ? dateRange : getInsightsReportUtcTimeRange(requestDate, timezone, period);
  return {
    [params.START_DATE]: start.toISOString(),
    [params.END_DATE]: end.toISOString(),
    [params.TIMEZONE]: timezone,
    [params.TIME_SCALE]: TIME_SCALES.HOUR.name,
    [params.LOCATION_ID]: location,
  };
}

export function createLicensePlateSearchReportSettings(requestDate, timezone, location, query) {
  return {
    [params.START_DATE]: moment.tz('01/01/2019', 'MM/DD/YYYY', timezone).utc().toISOString(),
    [params.END_DATE]: moment.tz(requestDate, timezone).utc().toISOString(),
    [params.TIMEZONE]: timezone,
    [params.TIME_SCALE]: TIME_SCALES.HOUR.name,
    [params.LOCATION_ID]: location,
    [params.LICENSE_PLATE]: query,
  };
}

export function createVisitsDurationReportSettings(requestDate, timezone, location, enterTags, exitTags) {
  return {
    [params.START_DATE]: moment.tz(requestDate, timezone).subtract(2, 'hours').utc().toISOString(),
    [params.END_DATE]: moment.tz(requestDate, timezone).utc().toISOString(),
    [params.TIMEZONE]: timezone,
    [params.LOCATION_ID]: location,
    [params.ENTER_TAGS]: enterTags,
    [params.EXIT_TAGS]: exitTags,
  };
}

export function createAverageVisitsDurationReportSettings(
  requestDate,
  timezone,
  period,
  location,
  enterTags,
  exitTags
) {
  const [start, end] = periodToUtcTimeRange(requestDate, timezone, period);
  return {
    [params.START_DATE]: start.toISOString(),
    [params.END_DATE]: end.toISOString(),
    [params.TIMEZONE]: timezone,
    [params.TIME_SCALE]: getReportTimeScale(requestDate, timezone, period),
    [params.LOCATION_ID]: location,
    [params.ENTER_TAGS]: enterTags,
    [params.EXIT_TAGS]: exitTags,
  };
}

export function widgetReport(id, settings) {
  return axios.post(`/apigw/v1/report/${id}/widget`, { ...settings });
}

export function csvReport(id, settings) {
  return axios.post(`/apigw/v1/report/${id}/csv`, { ...settings });
}

import { Grid, withStyles, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import MasonryLayout from 'components/common/MasonryLayout';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import { parseInsightsPeriod, resolveTimezone } from 'components/dashboard/common/periods';
import InsightsControls from 'components/insights/InsightsControls';
import InsightsCustomersVolumeWidget from 'components/insights/InsightsCustomersVolumeWidget';
import InsightsEventsWidget from 'components/insights/InsightsEventsWidget';
import InsightsFeedback from 'components/insights/InsightsFeedback';
import InsightsOverviewWidget from 'components/insights/InsightsOverviewWidget';
import InsightsWeatherWidget from 'components/insights/InsightsWeatherWidget';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getDefaultLocationIdWithInsights,
  getLocationIdsWithInsights,
  getLocationsWithInsights,
  getUserTimezone,
} from 'reducers/auth';
import { actions, getReport, isReportFetching } from 'reducers/insights';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
});

function Insights({
  classes,
  width,
  location,
  history,
  userTimezone,
  defaultLocationId,
  availableLocations,
  availableIds,
  reportRequest,
  onUnmount,
  isFetching,
  report,
}) {
  const [locationId, setLocationId] = useQueryParam('location', (v) => v || defaultLocationId, location, history);
  const timezone = resolveTimezone(locationId, availableLocations, userTimezone);
  const [period, setPeriod] = useQueryParam(
    'period',
    (value) => parseInsightsPeriod(value, timezone),
    location,
    history
  );
  useWillUnmount(onUnmount);
  useEffect(() => {
    reportRequest(moment().format(), timezone, period, locationId);
  }, [location, locationId, timezone, period, reportRequest]);
  useEffect(() => {
    localStorage.setItem('lastUsedLocationId', locationId);
  }, [locationId]);

  let columns = 1;
  if (isWidthUp('md', width)) {
    columns = 2;
  }

  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Predictive insights" />
      <Grid item>
        <InsightsControls
          period={period}
          location={locationId}
          onPeriodChange={(value) => setPeriod(value)}
          onLocationChange={(value) => setLocationId(value)}
          availableLocations={availableLocations}
          availableIds={availableIds}
        />
      </Grid>
      <Grid item className={classes.content}>
        {isFetching && <BrandedLoadingIndicator />}
        {!isFetching && report && (
          <>
            <MasonryLayout columns={columns} gap={32}>
              <InsightsOverviewWidget period={period} locationId={locationId} />
              <InsightsCustomersVolumeWidget locationId={locationId} period={period} />
              <InsightsWeatherWidget period={period} />
              <InsightsEventsWidget />
            </MasonryLayout>
            <InsightsFeedback locationId={locationId} period={period} timezone={timezone} />
          </>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
    defaultLocationId: getDefaultLocationIdWithInsights(state),
    availableLocations: getLocationsWithInsights(state),
    availableIds: getLocationIdsWithInsights(state),
    isFetching: isReportFetching(state),
    report: getReport(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest, onUnmount: actions.reset })(
    withStyles(styles)(withWidth()(toJS(Insights)))
  )
);

import React, { useCallback, useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
  TextField,
  DialogActions,
  Button,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import CSVUploaderButton from 'components/common/CSVUploaderButton';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    width: 600,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tooltipHelpIcon: {
    marginBottom: -theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    pointerEvents: 'all',
  },
}));

const PlatesIgnoreEditDialog = ({ company, isOpen, isReadonly, onClose, onSave }) => {
  const classes = useStyles();

  const defaultPlatesValue = useMemo(() => company.platesIgnoreList.join('\n'), [company.platesIgnoreList]);
  const dialogTitle = useMemo(
    () =>
      isReadonly ? `Plates exclusions for ${company.name}` : `Modify license plates exclusion for ${company.name}`,
    [isReadonly, company.name]
  );

  const [platesValues, setPlatesValues] = useState(defaultPlatesValue);

  const handleFileUploadChange = useCallback(
    (fileContent) => {
      const platesList = platesValues ? `${platesValues}\n${fileContent}` : fileContent;

      setPlatesValues(platesList);
    },
    [platesValues]
  );

  const handleSave = useCallback(() => {
    if (platesValues === defaultPlatesValue) {
      onClose();
    } else {
      onSave(platesValues.split('\n'));
    }
  }, [platesValues, defaultPlatesValue, onClose, onSave]);

  const handlePlatesListChange = useCallback(({ target }) => setPlatesValues(target.value), []);

  return (
    <Dialog
      aria-labelledby="plates-ignore-edit-dialog"
      PaperProps={{
        className: classes.paperRoot,
      }}
      open={isOpen}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h6">{dialogTitle}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          multiline
          fullWidth
          disabled={isReadonly}
          value={platesValues}
          placeholder={'DX12345\nJUX580\n956VAH'}
          label={
            <div>
              {isReadonly ? 'Excluded license plates' : 'Enter license plates (one per line)'}
              <Tooltip
                aria-label="These license plates will be excluded from omniX Analytics"
                title="These license plates will be excluded from omniX Analytics"
              >
                <HelpOutlineIcon className={classes.tooltipHelpIcon} />
              </Tooltip>
            </div>
          }
          rows="10"
          variant="outlined"
          onChange={handlePlatesListChange}
        />
      </DialogContent>
      {!isReadonly && (
        <DialogActions>
          <CSVUploaderButton label="upload CSV" onFileChange={handleFileUploadChange} />
          <Button color="secondary" variant="contained" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PlatesIgnoreEditDialog;

import { fromJS } from 'immutable';
import { types as deleteTypes } from 'reducers/entity/delete';
import { types as editTypes } from 'reducers/user/edit';

export const types = {
  SEARCH_REQUEST: 'USER_LIST/SEARCH_REQUEST',
  SEARCH_SUCCESS: 'USER_LIST/SEARCH_SUCCESS',
  SEARCH_FAILURE: 'USER_LIST/SEARCH_FAILURE',
  RESET: 'USER_LIST/RESET',
};

export const initialListState = fromJS({
  items: null,
  paging: null,
  isFetching: false,
  isFetchingToAppend: false,
});

export default function list(state = initialListState, action) {
  switch (action.type) {
    case editTypes.UPDATE_SUCCESS: {
      const { entity } = action;
      const { id } = entity;
      const items = state.get('items');
      const itemIndex = items ? items.findIndex(item => item.get('id') === id) : -1;
      if (itemIndex !== -1) {
        return state.withMutations(s => {
          s.set('items', items.set(itemIndex, fromJS(entity)));
        });
      }
      return state;
    }
    case deleteTypes.DELETE_SUCCESS: {
      const { entity } = action;
      const { id } = entity;
      const items = state.get('items');
      const paging = state.get('paging');
      const count = paging ? paging.get('count') : 0;
      if (items && !items.isEmpty()) {
        return state.withMutations(s => {
          const newItems = items.filter(item => item.get('id') !== id);
          s.set('items', newItems);
          if (newItems.size !== items.size && count > 0) {
            s.setIn(['paging', 'count'], count - 1);
          }
        });
      }
      return state;
    }
    case types.SEARCH_REQUEST:
      return state.withMutations(s => {
        s.set('isFetching', true);
        s.set('isFetchingToAppend', action.append);
      });
    case types.SEARCH_SUCCESS:
      return state.withMutations(s => {
        const { items, paging } = action;
        const presentCount = s.get('items') ? s.get('items').size : 0;
        if (items.length === 0) {
          paging.count = presentCount;
        }
        s.set('isFetching', false).set('paging', fromJS(paging));
        if (action.append && s.get('items')) {
          s.set('items', s.get('items').concat(fromJS(items)));
        } else {
          s.set('items', fromJS(items));
        }
      });
    case types.SEARCH_FAILURE:
      return state.withMutations(s => {
        s.set('isFetching', false);
        if (!action.append) {
          s.set('items', null).set('paging', null);
        }
      });
    case types.RESET:
      return initialListState;
    default:
      return state;
  }
}

export const actions = {
  searchRequest: (query, append) => ({ type: types.SEARCH_REQUEST, query, append }),
  searchSuccess: (items, paging, append) => ({ type: types.SEARCH_SUCCESS, items, paging, append }),
  searchFailure: error => ({ type: types.SEARCH_FAILURE, error }),
  reset: () => ({ type: types.RESET }),
};

export const getList = state => state.get('user').get('list');
export const getListItems = state => getList(state).get('items');
export const getListPaging = state => getList(state).get('paging');
export const getTotalMatchedCount = state => getListPaging(state) && getListPaging(state).get('count');
export const isListFetching = state => getList(state).get('isFetching');
export const isListFetchingToAppend = state => getList(state).get('isFetchingToAppend');

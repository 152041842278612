import toJS from 'components/common/toJS';
import { composeTimeCategories, getTimeLabelFormatter } from 'components/dashboard/common/periods';
import { prepareLegendData } from 'components/dashboard/common/utils';
import ValueOverTimeChart from 'components/dashboard/common/ValueOverTimeChart';
import Widget from 'components/dashboard/common/Widget';
import WidgetHeaderTrend from 'components/dashboard/common/WidgetHeaderTrend';
import WidgetLegend from 'components/dashboard/common/WidgetLegend';
import ReportActionMenu from 'components/reports/ReportActionMenu';
import useSettingsChangeActionDispatcher from 'components/reports/useSettingsChangeActionDispatcher';
import React from 'react';
import { FaTag as TagsIcon } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserTimezone } from 'reducers/auth';
import {
  actions,
  getReport,
  getRequestDate,
  isDownloadFetching,
  isReportFetching,
} from 'reducers/reports/totalCustomers';
import { parseSets } from 'sagas/reports/totalCustomers';

function TotalCustomersByTagsWidget({
  location,
  history,
  isFetching,
  downloadIsFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
  downloadCsvRequest,
}) {
  const [period, timezone, locationId, tags] = useSettingsChangeActionDispatcher(
    location,
    history,
    userTimezone,
    reportRequest
  );
  const sets = parseSets(tags);

  if (!requestDate) {
    return null;
  }

  const categories = composeTimeCategories(requestDate, timezone, period);
  const total = report && report.reduce((acc, obj) => acc + obj.value, 0);
  const legendData = report && prepareLegendData(report);
  return (
    <Widget title="Total customers by tags">
      <>
        {Object.keys(sets).length === 1 && (
          <WidgetHeaderTrend
            name="Customers"
            headerValue={total}
            value={
              report && (
                <ReportActionMenu
                  isDownloadFetching={downloadIsFetching}
                  onDownload={() => downloadCsvRequest(requestDate, timezone, period, locationId, tags)}
                />
              )
            }
            period="last week"
          />
        )}
        <div style={{ minHeight: 200, flexGrow: 1 }}>
          <ValueOverTimeChart
            disableHeight={false}
            isFetching={isFetching}
            type="line"
            categories={categories}
            categoriesFormatter={getTimeLabelFormatter(requestDate, timezone, period)}
            series={report}
          />
        </div>
        {Object.keys(sets).length !== 1 && <WidgetLegend data={legendData} icon={<TagsIcon />} />}
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    downloadIsFetching: isDownloadFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { reportRequest: actions.reportRequest, downloadCsvRequest: actions.downloadCsvRequest }
  )(toJS(TotalCustomersByTagsWidget))
);

import auth from 'reducers/auth';
import configureAxiosInterceptors from 'reducers/configureAxiosInterceptors';
import dashboard from 'reducers/dashboard';
import entity from 'reducers/entity';
import insights from 'reducers/insights';
import location from 'reducers/location';
import map from 'reducers/map';
import network from 'reducers/network';
import profile from 'reducers/profile';
import reports from 'reducers/reports';
import snackbarNotification from 'reducers/snackbarNotification';
import user from 'reducers/user';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers } from 'redux-immutable';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas';

const reducer = combineReducers({
  auth,
  dashboard,
  reports,
  insights,
  profile,
  user,
  location,
  entity,
  snackbarNotification,
  map,
  network,
});

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
configureAxiosInterceptors(store);

sagaMiddleware.run(rootSaga);

export default store;

import { fromJS } from 'immutable';
import { get, reverse, sortBy } from 'lodash';
import { compareTwoStrings } from 'string-similarity';

export const types = {
  REPORT_REQUEST: 'REPORTS/FRAUD/REPORT_REQUEST',
  REPORT_SUCCESS: 'REPORTS/FRAUD/REPORT_SUCCESS',
  REPORT_FAILURE: 'REPORTS/FRAUD/REPORT_FAILURE',
  RESET: 'REPORTS/FRAUD/RESET',
};

const initialState = fromJS({
  isInitial: true,
  isFetching: false,
  requestDate: null,
  report: null,
});

function prepareReport(report) {
  if (get(report, 'empty', true)) {
    return null;
  }

  const mapped = report.rows.map(({ row }) => {
    return {
      memberId: row.memberId,
      date: row.date,
      mappedDate: row.mappedDate,
      licensePlate: row.licensePlate,
      mappedLicensePlate: row.mappedLicensePlate,
      jobName: row.jobName,
      similarMatch: row.similarMatch,
    };
  });
  return reverse(sortBy(mapped, ['memberId', 'date']));
}

export function calculateLpSimilarity(firstLp, secondLp) {
  return compareTwoStrings(firstLp?.replace(/\s/g, '').toUpperCase(), secondLp?.replace(/\s/g, '').toUpperCase());
}

export default function fraud(state = initialState, action) {
  switch (action.type) {
    case types.REPORT_REQUEST:
      return state.withMutations((s) => {
        const { requestDate } = action;
        s.set('report', null);
        s.set('requestDate', requestDate);
        s.set('isFetching', true);
        s.set('isInitial', false);
      });
    case types.REPORT_SUCCESS:
      return state.withMutations((s) => {
        const { requestDate, report } = action;
        s.set('isFetching', false);
        s.set('requestDate', requestDate);
        s.set('report', fromJS(prepareReport(report)));
        s.set('isInitial', false);
      });
    case types.REPORT_FAILURE:
      return state.withMutations((s) => {
        s.set('isFetching', false);
        s.set('report', null);
        s.set('isInitial', false);
      });
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}

export const actions = {
  reportRequest: (requestDate, timezone, period, location, query) => ({
    type: types.REPORT_REQUEST,
    requestDate,
    timezone,
    period,
    location,
    query,
  }),
  reportSuccess: (requestDate, timezone, period, location, query, report) => ({
    type: types.REPORT_SUCCESS,
    requestDate,
    timezone,
    period,
    location,
    query,
    report,
  }),
  reportFailure: (requestDate, timezone, period, location, query, error) => ({
    type: types.REPORT_FAILURE,
    requestDate,
    timezone,
    period,
    location,
    query,
    error,
  }),
  reset: () => ({ type: types.RESET }),
};

export const getRoot = (state) => state.get('reports').get('fraud');
export const getRequestDate = (state) => getRoot(state).get('requestDate');
export const getReport = (state) => getRoot(state).get('report');
export const isReportFetching = (state) => getRoot(state).get('isFetching');
export const isReportInitial = (state) => getRoot(state).get('isInitial');

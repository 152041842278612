import { withTheme } from '@material-ui/core';
import toJS from 'components/common/toJS';
import useSettingsChangeActionDispatcher from 'components/dashboard/camera/useSettingsChangeActionDispatcher';
import { prepareLegendData, setItemStyleColor } from 'components/dashboard/common/utils';
import Widget from 'components/dashboard/common/Widget';
import WidgetChart from 'components/dashboard/common/WidgetChart';
import WidgetLegend from 'components/dashboard/common/WidgetLegend';
import suvIconFile from 'components/icons/suv.png';
import colors from 'components/style/colors';
import { remove, reverse, sortBy } from 'lodash';
import React from 'react';
import {
  FaCar as SedanIcon,
  FaQuestion as UnknownIcon,
  FaShuttleVan as VanIcon,
  FaTruckPickup as TruckIcon,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AutoSizer } from 'react-virtualized';
import { getUserTimezone } from 'reducers/auth';
import { actions, getReport, getRequestDate, isReportFetching } from 'reducers/dashboard/camera/vehicleBodyTypes';

function prepareData(report) {
  if (!report || !report.rows || !report.rows.length) {
    return undefined;
  }

  const data = {
    sedan: {
      name: 'Sedan',
      value: 0,
      color: colors.GREEN,
      renderIcon: () => <SedanIcon />,
    },
    suv: {
      name: 'SUV',
      value: 0,
      color: colors.LIGHT_BLUE,
      renderIcon: () => <img src={suvIconFile} style={{ width: 20, height: 20, filter: 'invert(1)' }} alt="" />,
    },
    van: {
      name: 'Van',
      value: 0,
      color: colors.BLUE,
      renderIcon: () => <VanIcon />,
    },
    truck: {
      name: 'Truck',
      value: 0,
      color: colors.DARK_YELLOW,
      renderIcon: () => <TruckIcon />,
    },
    others: {
      name: 'Others',
      value: 0,
      color: colors.LIGHT_GREY,
      renderIcon: () => <UnknownIcon />,
    },
  };

  report.rows.forEach((row) => {
    data.sedan.value += row.row.sedan;
    data.suv.value += row.row.suv;
    data.van.value += row.row.van;
    data.truck.value += row.row.truck;
    data.others.value += row.row.antique + row.row.motorcycle + row.row.tractor + row.row.missing;
  });

  const values = Object.values(data).filter((entry) => entry.value > 0);
  return values.length ? values : undefined;
}

function VehicleBodyTypesWidget({
  location,
  history,
  theme,
  isFetching,
  requestDate,
  userTimezone,
  report,
  reportRequest,
}) {
  useSettingsChangeActionDispatcher(location, history, userTimezone, reportRequest);

  if (!requestDate) {
    return null;
  }

  let legendData;
  let data = report && prepareData(report);

  if (report && data) {
    data = setItemStyleColor(data);

    legendData = reverse(sortBy(data, 'value'));
    const others = remove(legendData, (element) => element.name === 'Others' || element.name === 'Unknown');
    legendData.push(...others);
    legendData = prepareLegendData(legendData);
  }

  return (
    <Widget title="Types">
      <>
        <AutoSizer disableHeight>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            const height = width / 1.78;
            const options = {
              tooltip: {
                trigger: 'item',
                formatter: '<strong>{b}</strong>: {c} ({d}%)',
                extraCssText: `z-index: ${theme.zIndex.drawer + 1}`,
              },
              series: report &&
                data && [
                  {
                    type: 'pie',
                    radius: ['40%', '80%'],
                    center: [width / 2, height / 2],
                    avoidLabelOverlap: true,
                    label: {
                      normal: {
                        show: true,
                        position: 'outside',
                        color: theme.palette.text.primary,
                        fontSize: 16,
                      },
                    },
                    labelLine: {
                      show: true,
                      length: 5,
                      length2: 15,
                    },
                    itemStyle: { borderWidth: 2, borderColor: 'white' },
                    data,
                  },
                ],
            };

            return <WidgetChart width={width} height={height} isFetching={isFetching} options={options} />;
          }}
        </AutoSizer>
        <WidgetLegend data={legendData} />
      </>
    </Widget>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    requestDate: getRequestDate(state),
    report: getReport(state),
    userTimezone: getUserTimezone(state),
  };
}

export default withRouter(
  connect(mapStateToProps, { reportRequest: actions.reportRequest })(toJS(withTheme(VehicleBodyTypesWidget)))
);

import MapContainer from 'components/dashboard/location-intelligence/MapContainer';
import { AutoSizer } from 'react-virtualized';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import COLORS, { CHARTS_PALETTE } from 'components/style/colors';
import { get, without } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Marker } from 'google-maps-react';
import humanizeDistance from 'humanize-distance';

require('intl');
require('intl/locale-data/jsonp/en.js');

const palette = without(CHARTS_PALETTE, COLORS.BLUE, COLORS.DARK_YELLOW, COLORS.LIGHT_GREY, COLORS.DARK_GREY);

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    '& > div': {
      width: '100%',
      height: '100%',
    },
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  },
  button: {
    paddingLeft: theme.spacing(2),
    justifyContent: 'left',
  },
  buttonLabel: {
    marginLeft: theme.spacing(1),
  },
}));

const defaultBlocks = [
  {
    name: 'Competitors',
    dataPath: 'competitors',
  },
  {
    name: 'Major competitors',
    dataPath: 'significant_competitors',
  },
  {
    name: 'Retailers',
    dataPath: 'attractions.retailers',
  },
  {
    name: 'Restaurants',
    dataPath: 'attractions.restaurants',
  },
  {
    name: 'Gyms',
    dataPath: 'attractions.gyms',
  },
];

class State {
  selected = {};

  constructor(blocks) {
    blocks.forEach(({ name }) => {
      this.selected[name] = true;
    });
    makeAutoObservable(this);
  }

  toggleSelected(name) {
    this.selected[name] = !this.selected[name];
  }
}

function NearbyMap({ lat, long, data, blocks = defaultBlocks, ...rest }) {
  const classes = useStyles();
  const [state] = useState(() => new State(blocks));

  return (
    <div {...rest}>
      <div className={classes.buttonsContainer}>
        {blocks.map(({ name, icon, dataPath }, index) => {
          const color = palette[index % CHARTS_PALETTE.length].replace('#', '');
          const dataBlock = get(data, dataPath) ?? [];
          const selected = dataBlock.length > 0 && state.selected[name];

          return (
            <ToggleButton
              key={name}
              className={classes.button}
              value="name"
              selected={selected}
              onChange={() => state.toggleSelected(name)}
              disabled={dataBlock.length === 0}
            >
              <img
                width={15}
                height={24}
                alt=""
                src={`https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${color}`}
              />
              <span className={classes.buttonLabel}>
                {name} ({dataBlock.length})
              </span>
            </ToggleButton>
          );
        })}
      </div>
      <AutoSizer disableHeight>
        {({ width }) => {
          if (width === 0) {
            return null;
          }

          const height = width / 1.78;

          return (
            <>
              <div
                style={{
                  width,
                  height,
                }}
                className={classes.mapContainer}
              >
                <MapContainer
                  zoom={11}
                  lat={lat}
                  long={long}
                  styles={[
                    {
                      featureType: 'poi',
                      elementType: 'labels',
                      stylers: [{ visibility: 'off' }],
                    },
                  ]}
                >
                  {blocks.map(({ name, dataPath }, index) => {
                    const color = palette[index % CHARTS_PALETTE.length].replace('#', '');
                    const dataBlock = get(data, dataPath) ?? [];
                    const selected = dataBlock.length > 0 && state.selected[name];
                    const pinImage = new window.google.maps.MarkerImage(
                      `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${color}`,
                      new window.google.maps.Size(21, 34),
                      new window.google.maps.Point(0, 0),
                      new window.google.maps.Point(10, 34)
                    );
                    const pinShadow = new window.google.maps.MarkerImage(
                      'http://chart.apis.google.com/chart?chst=d_map_pin_shadow',
                      new window.google.maps.Size(40, 37),
                      new window.google.maps.Point(0, 0),
                      new window.google.maps.Point(12, 35)
                    );

                    if (!selected) {
                      return null;
                    }

                    return dataBlock.map(({ name, rating, coordinates }) => {
                      const ratingStr = rating?.google_rating > 0 ? ` | ★${rating.google_rating}` : '';

                      return (
                        <Marker
                          name={name}
                          title={`${name} | ${humanizeDistance(
                            { latitude: lat, longitude: long },
                            { latitude: coordinates.la, longitude: coordinates.lo },
                            'en-US',
                            'us'
                          )}${ratingStr}`}
                          position={{ lat: coordinates.la, lng: coordinates.lo }}
                          icon={pinImage}
                          shadow={pinShadow}
                        />
                      );
                    });
                  })}
                </MapContainer>
              </div>
            </>
          );
        }}
      </AutoSizer>
    </div>
  );
}

export default observer(NearbyMap);

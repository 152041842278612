import { Grid, Typography, withStyles } from '@material-ui/core';
import { round } from 'lodash';
import React from 'react';
import { FaArrowCircleDown as DownwardIcon, FaArrowCircleUp as UpwardIcon } from 'react-icons/fa';

const styles = theme => ({
  trendIcon: {
    marginRight: theme.spacing(1),
    color: 'inherit',
    width: 24,
    height: 24,
  },
});

function WidgetHeaderTrend({ classes, name, headerValue, value, aimDownward = false, period }) {
  const roundedValue = round(value, 1);
  const positive = aimDownward ? roundedValue <= 0 : roundedValue > 0;
  const IconName = roundedValue > 0 ? UpwardIcon : DownwardIcon;

  return (
    <Grid item container alignItems="center">
      <Grid item xs={value === 0 ? 12 : 6}>
        <Typography display="inline" variant="h4" component="span">
          {headerValue || <>&mdash;</>}{' '}
        </Typography>
        <Typography noWrap display="inline" variant="subtitle1" color="textSecondary" component="span">
          {name}
        </Typography>
      </Grid>
      {value !== 0 && (
        <>
          <Grid item xs={1} />
          <Grid
            item
            xs={5}
            style={{ color: positive ? '#65c7f6' : '#fdb700' }}
            container
            wrap="nowrap"
            justify="flex-end"
            alignItems="center"
          >
            {typeof value === 'number' ? (
              <>
                <IconName className={classes.trendIcon} />
                <Typography display="inline" color="inherit" variant="subtitle2">
                  {(roundedValue > 0 && '+') + roundedValue}% from
                  <br />
                  {period}
                </Typography>
              </>
            ) : (
              value
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default withStyles(styles)(WidgetHeaderTrend);

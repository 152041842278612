import axios from 'axios';
import { BAD_GATEWAY, GATEWAY_TIMEOUT, SERVICE_UNAVAILABLE } from 'http-status-codes';
import { get } from 'lodash';
import { actions, getIsOffline } from 'reducers/network';
import { actions as snackActions, variants as snackVariants } from 'reducers/snackbarNotification';

function isOfflineOrUnavailableError(error) {
  const status = get(error, 'response.status', undefined);
  return (
    (error.request && !error.response) ||
    status === BAD_GATEWAY ||
    status === SERVICE_UNAVAILABLE ||
    status === GATEWAY_TIMEOUT
  );
}

export default function configureAxiosInterceptors(store) {
  axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      store.dispatch(actions.requestFailure(error));
      if (!getIsOffline(store.getState())) {
        store.dispatch(actions.setOffline());
      }
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (getIsOffline(store.getState())) {
        store.dispatch(actions.setOnline());
        store.dispatch(snackActions.set('You are back online', snackVariants.success));
      }
      return response;
    },
    (error) => {
      store.dispatch(actions.requestFailure(error));
      if (isOfflineOrUnavailableError(error) && !getIsOffline(store.getState())) {
        store.dispatch(actions.setOffline());
      }
      if (!isOfflineOrUnavailableError(error) && getIsOffline(store.getState())) {
        store.dispatch(actions.setOnline());
        store.dispatch(snackActions.set('You are back online', snackVariants.success));
      }
      return Promise.reject(error);
    }
  );
}

import { Button, Grid, Link as MuiLink, TextField, Typography, withStyles } from '@material-ui/core';
import * as api from 'api/user';
import AsyncActionLabel from 'components/common/AsyncActionLabel';
import SetTitle from 'components/common/SetTitle';
import useQueryParam from 'components/common/useQueryParam';
import gcpLogo from 'components/demo/Google-Cloud-Partnership.png';
import SubmitSuccessDialog from 'components/demo/SubmitSuccessDialog';
import BrandedFormPage from 'components/pages/BrandedFormPage';
import MobileLogo from 'components/pages/MobileLogo';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minWidth: 272,
    maxWidth: 480,
  },
  form: {
    marginTop: theme.spacing(2),
  },
  submit: {
    whiteSpace: 'nowrap',
  },
  formActions: {
    marginTop: theme.spacing(2),
    display: 'inline-flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  gcpLogo: {
    marginTop: theme.spacing(4),
  },
});

function RequestDemo({ classes, location, history }) {
  const [wasSubmit, setWasSubmit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [industry, setIndustry] = useState('');
  const [title, setTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [googleAccountId] = useQueryParam('id', v => v, location, history);

  async function signUp() {
    try {
      setIsFetching(true);

      const params = { name, email, company, industry, title, phone, message, googleAccountId };
      await api.signUp(params);

      setIsFetching(false);
      setSuccess(true);
    } catch (e) {
      setIsFetching(false);
    }
  }

  const nameError = !name.trim();
  const emailError = !/^\S+@\S+$/.test(email);
  const companyError = !company.trim();
  const messageError = !message.trim();

  function hasErrors() {
    return nameError || emailError || companyError || messageError;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setWasSubmit(true);
    if (!isFetching && !hasErrors()) {
      signUp();
    }
  }

  return (
    <BrandedFormPage
      slogan="Try the power of omniX for free"
      details="After creating your account simply upload some footage and discover how far omniX can take your business."
    >
      <div className={classes.root}>
        <SubmitSuccessDialog open={success} />
        <SetTitle title="Request a demo" />
        <MobileLogo />
        <Typography component="h1" variant="h6" gutterBottom>
          Request a demo | omniX
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Please enter your details below
        </Typography>
        <Grid
          component="form"
          container
          spacing={3}
          direction="column"
          wrap="nowrap"
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Grid item>
            <TextField
              label="Full name"
              required
              onChange={event => setName(event.target.value)}
              value={name}
              helperText={wasSubmit && nameError && 'Full name is required'}
              error={wasSubmit && nameError}
              autoComplete="name"
              name="name"
              id="name"
              margin="none"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Email"
              required
              onChange={event => setEmail(event.target.value)}
              value={email}
              helperText={wasSubmit && emailError && 'Incorrect email'}
              error={wasSubmit && emailError}
              autoComplete="email"
              name="email"
              type="email"
              id="email"
              margin="none"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Company name"
              required
              onChange={event => setCompany(event.target.value)}
              value={company}
              helperText={wasSubmit && companyError && 'Company name is required'}
              error={wasSubmit && companyError}
              autoComplete="organization"
              name="organization"
              id="organization"
              margin="none"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Industry"
              onChange={event => setIndustry(event.target.value)}
              value={industry}
              name="industry"
              id="industry"
              margin="none"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Title"
              onChange={event => setTitle(event.target.value)}
              value={title}
              autoComplete="organization-title"
              name="title"
              id="title"
              margin="none"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Phone no."
              onChange={event => setPhone(event.target.value)}
              value={phone}
              autoComplete="tel"
              type="tel"
              name="tel"
              id="tel"
              margin="none"
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Tell us a little about your project"
              required
              onChange={event => setMessage(event.target.value)}
              value={message}
              helperText={wasSubmit && messageError && 'Message is required'}
              error={wasSubmit && messageError}
              name="message"
              id="message"
              margin="none"
              multiline
              rows="4"
              fullWidth
            />
          </Grid>
          <Grid item container justify="center">
            <div className={classes.formActions}>
              <Button
                disabled={wasSubmit && hasErrors()}
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.submit}
              >
                <AsyncActionLabel label="Sign Up" fetchingLabel="Signing Up..." isFetching={isFetching} />
              </Button>
              <Typography align="center">
                Already have an account?{' '}
                <MuiLink component={Link} to="/login">
                  Sign in
                </MuiLink>
              </Typography>
              <div>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <img style={{ width }} className={classes.gcpLogo} src={gcpLogo} alt="Google Cloud Partner" />
                  )}
                </AutoSizer>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </BrandedFormPage>
  );
}

export default withRouter(withStyles(styles)(RequestDemo));

import { getJobName, getLocationIdByJobId, getLocationName, getTagName } from 'reducers/auth';
import store from 'reducers/index';
import moment from 'moment/moment';
import { AclOperationMap } from './constants';

export function resolveLocationIdByJobId(jobId) {
  return getLocationIdByJobId(store.getState(), jobId);
}

export function resolveLocationNameById(locationId) {
  return getLocationName(store.getState(), locationId);
}

export function resolveTagNameById(locationId, tagId) {
  return getTagName(store.getState(), locationId, tagId);
}

export function resolveJobNameById(jobId) {
  return getJobName(store.getState(), jobId);
}

export const MEMBER_VALIDATION_MODE_VALID = 'valid';
export const MEMBER_VALIDATION_MODE_INVALID = 'invalid';

export function parseMemberValidationMode(value) {
  return value === MEMBER_VALIDATION_MODE_VALID ? MEMBER_VALIDATION_MODE_VALID : MEMBER_VALIDATION_MODE_INVALID;
}

export function downloadAjaxResponse(response, format, filename = null) {
  const type = response.headers['content-type'];
  const name = filename ?? (response.headers['x-filename'] || `Customers report.${format.toLowerCase()}`);

  downloadData(response.data, type, name);
}

export function downloadData(data, contentType, filename) {
  let blob;
  try {
    blob = new File([data], filename, { type: contentType });
  } catch {
    blob = new Blob([data], { type: contentType });
  }

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const URL = window.URL || window.webkitURL;
    const downloadUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    // use HTML5 a[download] attribute to specify filename
    // safari doesn't support this yet
    if (typeof a.download === 'undefined') {
      const reader = new FileReader();
      reader.onload = () => {
        window.location.href = reader.result;
      };
      reader.readAsDataURL(blob);
    } else {
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);
    }, 100);
  }
}

export function median(array) {
  array = array.sort((a, b) => a - b);
  if (array.length % 2 === 0) {
    return (array[array.length / 2] + array[array.length / 2 - 1]) / 2;
  } else {
    return array[(array.length - 1) / 2];
  }
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getPastTimeFromNow = (date) => moment.min(moment(date), moment()).fromNow();

export const isEditAuthorized = ({ authorizedOperations }) => authorizedOperations.includes(AclOperationMap.WRITE);
export const isDeleteAuthorized = ({ authorizedOperations }) => authorizedOperations.includes(AclOperationMap.DELETE);

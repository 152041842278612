import { whitelabels } from 'components/common/whitelabel';
import { internalTrafficCompanies } from 'components/common/gtm';
import { getUserContext } from 'reducers/auth';
import store from 'reducers/index';

function isProdEnvironment() {
  const hostname = window.location.hostname.toLowerCase();
  return hostname === 'portal.omnixlabs.com' || whitelabels.some(({ host }) => hostname === `${host}.omnixlabs.com`);
}

export default function mixpanel(receiver) {
  const state = store.getState();
  const userContext = getUserContext(state)?.toJS();
  const context = userContext?.originalUser ?? userContext;
  const companyId = context?.company?.id;

  const instance = window.mixpanel;
  if (instance && isProdEnvironment() && context && !internalTrafficCompanies.includes(companyId)) {
    try {
      receiver(instance);
    } catch (e) {
      // ignore
    }
  }
}

import { createVisitsDurationReportSettings, reports, widgetReport } from 'api/report';
import mixpanel from 'components/common/mixpanel';
import { resolveLocationNameById } from 'components/common/utils';
import moment from 'moment-timezone';
import { actions, types } from 'reducers/reports/visitsDuration';
import { call, delay, put, race, spawn, take, takeLatest } from 'redux-saga/effects';

function* periodicAutoRefresh(timezone, location, enterTags, exitTags) {
  while (true) {
    yield delay(30000);

    try {
      const requestDate = moment.tz(timezone).format();
      const response = yield call(
        widgetReport,
        reports.VEHICLE_VISIT_DURATION_BY_TAGS,
        createVisitsDurationReportSettings(requestDate, timezone, location, enterTags, exitTags)
      );

      yield put(actions.reportSuccess(requestDate, timezone, location, enterTags, exitTags, response.data));
    } catch (e) {
      // ignore
    }
  }
}

function* autoRefreshSaga(timezone, location, enterTags, exitTags) {
  yield race({
    task: call(periodicAutoRefresh, timezone, location, enterTags, exitTags),
    cancel: take([types.REPORT_REQUEST, types.RESET]),
  });
}

function* fetchReport({ requestDate, timezone, location, enterTags, exitTags }) {
  try {
    const response = yield call(
      widgetReport,
      reports.VEHICLE_VISIT_DURATION_BY_TAGS,
      createVisitsDurationReportSettings(requestDate, timezone, location, enterTags, exitTags)
    );

    yield put(actions.reportSuccess(requestDate, timezone, location, enterTags, exitTags, response.data));
    yield spawn(autoRefreshSaga, timezone, location, enterTags, exitTags);

    mixpanel((mp) =>
      mp.track('Active visits', {
        timezone,
        locationId: location,
        locationName: resolveLocationNameById(location),
        enterTags,
        exitTags,
      })
    );
  } catch (e) {
    yield put(actions.reportFailure(requestDate, timezone, location, enterTags, exitTags, e));
  }
}

export default function* visitsDuration() {
  yield takeLatest(types.REPORT_REQUEST, fetchReport);
}

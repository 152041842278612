import React from 'react';

const files = require.context('components/media/weather/day', true, /\.svg$/);
const paths = {};
const icons = {};
files.keys().forEach((path) => {
  paths[path] = true;
  icons[parseInt(/\.\/(.+)\.svg/.exec(path)[1].toLowerCase())] = files(path);
});

const nightFiles = require.context('components/media/weather/night', true, /\.svg$/);
const nightPaths = {};
const nightIcons = {};
nightFiles.keys().forEach((path) => {
  nightPaths[path] = true;
  nightIcons[parseInt(/\.\/(.+)\.svg/.exec(path)[1].toLowerCase())] = nightFiles(path);
});

function WeatherCodeIcon({ code, nightMode = false, size, ...rest }) {
  const src = `./${parseInt(code)}.svg`;
  const currentPaths = nightMode ? nightPaths : paths;
  const currentFiles = nightMode ? nightFiles : files;
  return currentPaths[src] ? (
    <img style={{ width: size, height: size }} src={currentFiles(src)} alt="" {...rest} />
  ) : null;
}

export default WeatherCodeIcon;

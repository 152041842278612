import { LinearProgress, withStyles } from '@material-ui/core';
import Logo from 'components/common/Logo';
import React from 'react';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    width: 200,
  },
  progress: {
    marginTop: theme.spacing(6),
    width: 200,
  },
});

function BrandedLoadingIndicator({ classes, ...rest }) {
  return (
    <div className={classes.root} {...rest}>
      <Logo className={classes.logo} />
      <LinearProgress color="secondary" className={classes.progress} />
    </div>
  );
}

export default React.memo(withStyles(styles)(BrandedLoadingIndicator));

import { Button, Grid, withStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import {
  MEMBER_VALIDATION_MODE_INVALID,
  MEMBER_VALIDATION_MODE_VALID,
  parseMemberValidationMode,
} from 'components/common/utils';
import {
  dateFormat,
  isCustomPeriod,
  minDate,
  parseCustomPeriod,
  parsePeriod,
  PERIODS,
} from 'components/dashboard/common/periods';
import useTimezone from 'components/dashboard/common/useTimezone';
import DetectionJobPicker from 'components/pickers/DetectionJobPicker';
import LocationPicker, { PureLocationPicker } from 'components/pickers/LocationPicker';
import TagPicker from 'components/pickers/TagPicker';
import TagsPicker from 'components/pickers/TagsPicker';
import TimezonePicker from 'components/pickers/TimezonePicker';
import COLORS from 'components/style/colors';
import moment from 'moment-timezone';
import React from 'react';
import { useDidUpdate } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getDefaultJobId,
  getDefaultLocationId,
  getDefaultLocationIdWithDriveoff,
  getDefaultLocationIdWithFraud,
  getDefaultLocationIdWithOps,
  getDefaultLocationIdWithRewashes,
  getDefaultLocationIdWithSales,
  getDefaultLocationIdWithVisitDuration,
  getLocationIdsWithDriveoff,
  getLocationIdsWithFraud,
  getLocationIdsWithOps,
  getLocationIdsWithRewashes,
  getLocationIdsWithSales,
  getLocationIdsWithVisitDuration,
  getLocationsWithDriveoff,
  getLocationsWithFraud,
  getLocationsWithOps,
  getLocationsWithRewashes,
  getLocationsWithSales,
  getLocationsWithVisitDuration,
  getUserTimezone,
} from 'reducers/auth';

const styles = (theme) => ({
  buttonsContainer: {
    minHeight: 48,
    '& > button + button': {
      marginLeft: 2,
    },
  },
  button: {
    minWidth: 'unset',
    flexGrow: 1,
    backgroundColor: 'white',
    boxShadow: 'unset',
  },
  buttonSelected: {
    backgroundColor: '#1F2018',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1F2018',
    },
  },
  inputRoot: {
    minHeight: 40,
    backgroundColor: 'white',
    paddingLeft: 12,
    borderRadius: 4,
    borderBottom: 'unset !important',
    '&::before': {
      borderBottom: 'unset !important',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  rightPickersContainer: {
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  datePickers: {
    display: 'grid',
    marginTop: theme.spacing(1),
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(4),
    width: '100%',
  },
});

export const salesDashboardMode = 'salesDashboard';
export const opsDashboardMode = 'opsDashboard';
export const driveoffDashboardMode = 'driveoffDashboard';
export const locationDashboardMode = 'locationDashboard';
export const cameraDashboardMode = 'cameraDashboard';
export const companyDashboardMode = 'companyDashboardMode';
export const reportsMode = 'reports';
export const averageVisitsDurationMode = 'averageVisitsDuration';
export const rewashesMode = 'rewashesMode';
export const fraudMode = 'fraudMode';
export const memberValidationMode = 'memberValidationMode';
const defaultTags = [];

function DashboardControls({
  classes,
  location,
  history,
  userTimezone,
  defaultJobId,
  defaultLocationId,
  mode = cameraDashboardMode,
  visitDurationDefaultLocationId,
  visitDurationLocations,
  visitDurationLocationsIds,
  rewashesDefaultLocationId,
  rewashesLocations,
  rewashesLocationsIds,
  fraudDefaultLocationId,
  fraudLocations,
  fraudLocationsIds,
  salesDefaultLocationId,
  salesLocations,
  salesLocationsIds,
  opsDefaultLocationId,
  opsLocations,
  opsLocationsIds,
  driveoffDefaultLocationId,
  driveoffLocations,
  driveoffLocationsIds,
}) {
  const [timeZone, setTimezone] = useTimezone(location, history, userTimezone);
  const [period, setPeriod] = useQueryParam('period', (value) => parsePeriod(value, timeZone), location, history);
  const [jobId, setJobId] = useQueryParam('job', (v) => v || defaultJobId, location, history);
  let defaultId = defaultLocationId;
  if (mode === averageVisitsDurationMode) {
    defaultId = visitDurationDefaultLocationId;
  } else if (mode === rewashesMode) {
    defaultId = rewashesDefaultLocationId;
  } else if (mode === fraudMode || mode === memberValidationMode) {
    defaultId = fraudDefaultLocationId;
  } else if (mode === salesDashboardMode) {
    defaultId = salesDefaultLocationId;
  } else if (mode === opsDashboardMode) {
    defaultId = opsDefaultLocationId;
  } else if (mode === driveoffDashboardMode) {
    defaultId = driveoffDefaultLocationId;
  }

  const [locationId, setLocationId] = useQueryParam('location', (v) => v || defaultId, location, history);
  const [tagIds, setTagIds] = useQueryParam('tag', (v) => v || defaultTags, location, history);
  const [enterTags, setEnterTags] = useQueryParam('enter', (v) => v || defaultTags, location, history);
  const [exitTags, setExitTags] = useQueryParam('exit', (v) => v || defaultTags, location, history);
  const customPeriod = isCustomPeriod(period);
  const [startDate, endDate] = parseCustomPeriod(period, timeZone);
  const [mvMode, setMvMode] = useQueryParam('mvMode', (value) => parseMemberValidationMode(value), location, history);
  useDidUpdate(() => setTagIds([]), [locationId]);

  function handlePeriodChange(value) {
    const today = moment.tz(timeZone).format(dateFormat);
    const todayPeriod = `${today}:${today}`;
    if (todayPeriod === value) {
      localStorage.removeItem('lastUsedPeriod');
    } else {
      localStorage.setItem('lastUsedPeriod', value);
    }
    setPeriod(value);
  }

  return (
    <Grid container spacing={1} justify="space-between" alignItems="flex-start">
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-end"
        wrap="nowrap"
      >
        <Grid item container alignItems="flex-end" className={classes.buttonsContainer} wrap="nowrap">
          {Object.values(PERIODS)
            .filter(({ value }) => value !== PERIODS['1D'].value)
            .map(({ value }) => {
              const className = period === value ? classNames(classes.button, classes.buttonSelected) : classes.button;
              return (
                <Button
                  onClick={() => handlePeriodChange(value)}
                  key={value}
                  className={className}
                  size="small"
                  variant="contained"
                >
                  {value}
                </Button>
              );
            })}
          <Button
            onClick={() => handlePeriodChange(`${startDate}:${endDate}`)}
            className={customPeriod ? classNames(classes.button, classes.buttonSelected) : classes.button}
            size="small"
            variant="contained"
          >
            Any
          </Button>
        </Grid>
        {customPeriod && (
          <div className={classes.datePickers}>
            <DatePicker
              autoOk
              showTodayButton={moment.tz(endDate, dateFormat, timeZone).isSame(moment.tz(timeZone), 'day')}
              label="From"
              format={dateFormat}
              value={moment.tz(startDate, dateFormat, timeZone)}
              minDate={moment.tz(minDate, dateFormat, timeZone)}
              maxDate={moment.tz(endDate, dateFormat, timeZone)}
              InputProps={{ className: classes.inputRoot }}
              onChange={(value) => handlePeriodChange(`${value.format(dateFormat)}:${endDate}`)}
            />
            <DatePicker
              autoOk
              showTodayButton
              label="To"
              format={dateFormat}
              value={moment.tz(endDate, dateFormat, timeZone)}
              minDate={moment.tz(minDate, dateFormat, timeZone)}
              maxDate={moment.tz(timeZone)}
              InputProps={{ className: classes.inputRoot }}
              onChange={(value) => handlePeriodChange(`${startDate}:${value.format(dateFormat)}`)}
            />
          </div>
        )}
        {mode === memberValidationMode && (
          <Grid item container alignItems="flex-end" className={classes.buttonsContainer} wrap="nowrap">
            <Button
              onClick={() => setMvMode(MEMBER_VALIDATION_MODE_VALID)}
              className={
                mvMode === MEMBER_VALIDATION_MODE_VALID
                  ? classNames(classes.button, classes.buttonSelected)
                  : classes.button
              }
              size="small"
              variant="contained"
            >
              PLATE UPDATE
            </Button>
            <Button
              onClick={() => setMvMode(MEMBER_VALIDATION_MODE_INVALID)}
              className={
                mvMode === MEMBER_VALIDATION_MODE_INVALID
                  ? classNames(classes.button, classes.buttonSelected)
                  : classes.button
              }
              size="small"
              variant="contained"
            >
              PLATE MISMATCH
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        className={classes.rightPickersContainer}
        item
        xs={12}
        md={5}
        lg={4}
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-end"
        wrap="nowrap"
      >
        {mode !== averageVisitsDurationMode && mode !== rewashesMode && (
          <TimezonePicker
            compact
            value={timeZone}
            onChange={(event) => setTimezone(event.target.value)}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
        )}
        {mode === cameraDashboardMode && (
          <DetectionJobPicker
            value={jobId}
            onChange={(jobId) => setJobId(jobId)}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
        )}
        {(mode === reportsMode || mode === locationDashboardMode) && (
          <>
            <LocationPicker
              value={locationId}
              onChange={(locId) => setLocationId(locId)}
              SelectProps={{ className: classes.inputRoot }}
              selectClasses={{ select: classes.select }}
            />
            {mode === reportsMode && (
              <TagsPicker
                onChange={(ids) => setTagIds(ids)}
                value={tagIds}
                locationId={locationId}
                SelectProps={{ className: classes.inputRoot }}
                selectClasses={{ select: classes.select }}
              />
            )}
          </>
        )}
        {mode === averageVisitsDurationMode && (
          <>
            <PureLocationPicker
              availableLocations={visitDurationLocations}
              availableIds={visitDurationLocationsIds}
              value={locationId}
              onChange={(locId) => setLocationId(locId)}
              SelectProps={{ className: classes.inputRoot }}
              selectClasses={{ select: classes.select }}
            />
            <TagPicker
              label="Enter tags"
              onChange={(tags) => setEnterTags(tags)}
              value={enterTags}
              locationId={locationId}
              color={COLORS.DARK_YELLOW}
              SelectProps={{ className: classes.inputRoot }}
              selectClasses={{ select: classes.select }}
            />
            <TagPicker
              label="Exit tags"
              onChange={(tags) => setExitTags(tags)}
              value={exitTags}
              locationId={locationId}
              color={COLORS.GREEN}
              SelectProps={{ className: classes.inputRoot }}
              selectClasses={{ select: classes.select }}
            />
          </>
        )}
        {mode === rewashesMode && (
          <PureLocationPicker
            availableLocations={rewashesLocations}
            availableIds={rewashesLocationsIds}
            value={locationId}
            onChange={(locId) => setLocationId(locId)}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
        )}
        {(mode === fraudMode || mode === memberValidationMode) && (
          <PureLocationPicker
            availableLocations={fraudLocations}
            availableIds={fraudLocationsIds}
            value={locationId}
            onChange={(locId) => setLocationId(locId)}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
        )}
        {mode === salesDashboardMode && (
          <PureLocationPicker
            availableLocations={salesLocations}
            availableIds={salesLocationsIds}
            value={locationId}
            onChange={(locId) => setLocationId(locId)}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
        )}
        {mode === opsDashboardMode && (
          <PureLocationPicker
            availableLocations={opsLocations}
            availableIds={opsLocationsIds}
            value={locationId}
            onChange={(locId) => setLocationId(locId)}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
        )}
        {mode === driveoffDashboardMode && (
          <PureLocationPicker
            availableLocations={driveoffLocations}
            availableIds={driveoffLocationsIds}
            value={locationId}
            onChange={(locId) => setLocationId(locId)}
            SelectProps={{ className: classes.inputRoot }}
            selectClasses={{ select: classes.select }}
          />
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userTimezone: getUserTimezone(state),
    defaultJobId: getDefaultJobId(state),
    defaultLocationId: getDefaultLocationId(state),
    visitDurationDefaultLocationId: getDefaultLocationIdWithVisitDuration(state),
    visitDurationLocations: getLocationsWithVisitDuration(state),
    visitDurationLocationsIds: getLocationIdsWithVisitDuration(state),
    rewashesDefaultLocationId: getDefaultLocationIdWithRewashes(state),
    rewashesLocations: getLocationsWithRewashes(state),
    rewashesLocationsIds: getLocationIdsWithRewashes(state),
    fraudDefaultLocationId: getDefaultLocationIdWithFraud(state),
    fraudLocations: getLocationsWithFraud(state),
    fraudLocationsIds: getLocationIdsWithFraud(state),
    salesDefaultLocationId: getDefaultLocationIdWithSales(state),
    salesLocations: getLocationsWithSales(state),
    salesLocationsIds: getLocationIdsWithSales(state),
    opsDefaultLocationId: getDefaultLocationIdWithOps(state),
    opsLocations: getLocationsWithOps(state),
    opsLocationsIds: getLocationIdsWithOps(state),
    driveoffDefaultLocationId: getDefaultLocationIdWithDriveoff(state),
    driveoffLocations: getLocationsWithDriveoff(state),
    driveoffLocationsIds: getLocationIdsWithDriveoff(state),
  };
}

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(toJS(DashboardControls))));

import { Grid, withStyles, withWidth } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import MasonryLayout from 'components/common/MasonryLayout';
import mixpanel from 'components/common/mixpanel';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import { resolveLocationNameById } from 'components/common/utils';
import DashboardControls, { locationDashboardMode } from 'components/dashboard/common/DashboardControls';
import MiniWidget from 'components/dashboard/common/MiniWidget';
import LocationDashboard from 'components/dashboard/location/LocationDashboard';
import NotificationsSentWidget from 'components/dashboard/location/NotificationsSentWidget';
import useSettingsChangeActionDispatcher from 'components/dashboard/location/useSettingsChangeActionDispatcher';
import FirstTimeReturningMembersWidget from 'components/dashboard/sales/FirstTimeReturningMembersWidget';
import HighPotentialCustomers from 'components/dashboard/sales/HighPotentialCustomers';
import LastSeenWidget from 'components/dashboard/sales/LastSeenWidget';
import ReportActionMenu from 'components/reports/ReportActionMenu';
import { get, round } from 'lodash';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  EW_COMPANY_ID,
  getDefaultLocationId,
  getLocationIdsWithSales,
  getLocations,
  getUserTimezone,
} from 'reducers/auth';
import {
  actions,
  getReport,
  getRequestDate,
  isMembersDownloadFetching,
  isReportFetching,
} from 'reducers/dashboard/sales/summary';
import AiSummaryWidget from 'components/dashboard/location/AiSummaryWidget';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  miniWidgetsContainer: {
    marginBottom: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 1fr))',
    gridGap: theme.spacing(4),
  },
});

function percentageOfTotal(value, total) {
  if (typeof value !== 'number' || typeof total !== 'number' || !value || !total) {
    return null;
  }
  return `${round(value / (total / 100), 1)}%`;
}

function SalesDashboard({
  classes,
  width,
  location,
  history,
  userTimezone,
  requestDate,
  reportRequest,
  membersDownloadRequest,
  isFetching,
  locationIdsWithSales,
  defaultLocationId,
  availableLocations,
  report,
  membersDownloadIsFetching,
}) {
  const [locationId] = useQueryParam('location', (v) => v || defaultLocationId, location, history);
  const locationCompanyId = get(availableLocations, `${locationId}.company.id`);
  const locationWhitelabelerId = get(availableLocations, `${locationId}.whitelabeler.id`);
  const salesMode = locationIdsWithSales?.includes(locationId);
  const onSettingsChange = useCallback(
    (requestDate, timezone, period, locationId) => {
      if (salesMode) {
        mixpanel((mp) =>
          mp.track('Sales dashboard', {
            timezone,
            period,
            locationId,
            locationName: resolveLocationNameById(locationId),
          })
        );
        reportRequest(requestDate, timezone, period, locationId);
      }
    },
    [reportRequest, salesMode]
  );
  const [period, timezone] = useSettingsChangeActionDispatcher(
    location,
    history,
    userTimezone,
    onSettingsChange,
    defaultLocationId
  );
  const hasConversionSettings = get(availableLocations, `${locationId}.hasConversionSettings`, false);

  if (salesMode && !requestDate) {
    return null;
  }

  let columns = 1;
  if (isWidthUp('md', width)) {
    columns = 2;
  }
  if (isWidthUp('lg', width)) {
    columns = 3;
  }

  const membersAction =
    salesMode && report?.summary?.total ? (
      <ReportActionMenu
        isDownloadFetching={membersDownloadIsFetching}
        onDownload={() => membersDownloadRequest(requestDate, timezone, period, locationId)}
      />
    ) : null;
  return (
    <Grid container spacing={4} direction="column" className={classes.root} wrap="nowrap">
      <SetTitle title="Sales insights" />
      <Grid item>
        <DashboardControls mode={locationDashboardMode} />
      </Grid>
      {salesMode ? (
        <Grid item className={classes.grow}>
          <div className={classes.miniWidgetsContainer}>
            <MiniWidget isFetching={isFetching} title="Total" value={report?.summary?.total} action={membersAction} />
            <MiniWidget
              isFetching={isFetching}
              title="Members"
              value={report?.summary?.members}
              rightHelperText={percentageOfTotal(report?.summary?.members, report?.summary?.total)}
              textContainerAlignItems="baseline"
            />
            <MiniWidget
              isFetching={isFetching}
              title="First time"
              value={report?.summary?.firstTime}
              rightHelperText={percentageOfTotal(report?.summary?.firstTime, report?.summary?.total)}
              textContainerAlignItems="baseline"
            />
            <MiniWidget
              isFetching={isFetching}
              title="Returning non members"
              value={report?.summary?.returningNonMembers}
              rightHelperText={percentageOfTotal(report?.summary?.returningNonMembers, report?.summary?.total)}
              textContainerAlignItems="baseline"
            />
            <MiniWidget isFetching={isFetching} title="New memberships sold" value={report?.summary?.membershipSold} />
            <MiniWidget
              isFetching={isFetching}
              title="Conversion ratio"
              value={report?.summary?.conversionRate}
              valueHelperText="%"
            />
          </div>
          <MasonryLayout columns={columns} gap={32}>
            <FirstTimeReturningMembersWidget
              timezone={timezone}
              period={period}
              isFetching={isFetching}
              requestDate={requestDate}
              report={report}
            />
            <LastSeenWidget defaultLocationId={defaultLocationId} />
            {hasConversionSettings &&
            locationCompanyId !== EW_COMPANY_ID &&
            locationWhitelabelerId !== EW_COMPANY_ID ? (
              <NotificationsSentWidget defaultLocationId={defaultLocationId} />
            ) : (
              <HighPotentialCustomers
                timezone={timezone}
                period={period}
                isFetching={isFetching}
                requestDate={requestDate}
                report={report}
              />
            )}
            <AiSummaryWidget
              history={history}
              location={location}
              userTimezone={userTimezone}
              defaultLocationId={defaultLocationId}
            />
          </MasonryLayout>
        </Grid>
      ) : (
        <LocationDashboard />
      )}
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    isFetching: isReportFetching(state),
    report: getReport(state),
    requestDate: getRequestDate(state),
    locationIdsWithSales: getLocationIdsWithSales(state),
    defaultLocationId: getDefaultLocationId(state),
    availableLocations: getLocations(state),
    userTimezone: getUserTimezone(state),
    membersDownloadIsFetching: isMembersDownloadFetching(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    reportRequest: actions.reportRequest,
    membersDownloadRequest: actions.membersDownloadRequest,
  })(withStyles(styles)(withWidth()(toJS(SalesDashboard))))
);

import { get } from 'lodash';

export function getErrorResponseStatus(action) {
  return get(action, 'error.response.status', undefined);
}

export function hasErrorWithCode(action, code) {
  const errors = get(action, 'error.response.data.errors', []);
  return errors.some((error) => error.code === code);
}

import moment from 'moment-timezone';
import { getUserTimezone as getUserTimezoneSelector } from 'reducers/auth';
import store from 'reducers/index';

export function getUserTimezone() {
  const state = store.getState();
  return getUserTimezoneSelector(state) || moment.tz.guess();
}

export function momentUserTz(...args) {
  return moment.tz(...args, getUserTimezone());
}

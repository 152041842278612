import { dataLayerPush } from 'components/common/gtm';
import { types as authTypes } from 'reducers/auth';
import { all, takeEvery } from 'redux-saga/effects';

function onLogout() {
  try {
    dataLayerPush({ userId: undefined });
  } catch (e) {
    // ignore
  }
}

export default function* google() {
  yield all([takeEvery(authTypes.LOGOUT, onLogout)]);
}

import { get } from 'lodash';
import moment from 'moment-timezone';

export const TIME_SCALES = Object.freeze({
  HOUR: { name: 'HOUR' },
  DAY: { name: 'DAY' },
  MONTH: { name: 'MONTH' },
});

export const PERIODS = Object.freeze({
  TODAY: { value: 'TODAY', scale: TIME_SCALES.HOUR },
  '1D': { value: '1D', scale: TIME_SCALES.HOUR },
  '7D': { value: '7D', scale: TIME_SCALES.DAY },
  '1M': { value: '1M', scale: TIME_SCALES.DAY },
  MTD: { value: 'MTD', scale: TIME_SCALES.DAY },
  '6M': { value: '6M', scale: TIME_SCALES.MONTH },
  YTD: { value: 'YTD', scale: TIME_SCALES.MONTH },
  '1Y': { value: '1Y', scale: TIME_SCALES.MONTH },
});

const customPeriodRegex = /^\d\d\/\d\d\/\d\d\d\d:\d\d\/\d\d\/\d\d\d\d$/;

export function isCustomPeriod(period) {
  return period && customPeriodRegex.test(period.trim());
}

export const minDate = '01/01/2019';
export const dateFormat = 'MM/DD/YYYY';

export function parsePeriod(period, timezone) {
  const value = period || localStorage.getItem('lastUsedPeriod');
  if (Object.keys(PERIODS).includes(value)) {
    return value;
  }
  if (isCustomPeriod(value)) {
    return value;
  }
  const today = moment.tz(timezone).format(dateFormat);
  return `${today}:${today}`;
}

export function parseCustomPeriod(period, timezone) {
  const defaultSd = moment.tz(timezone).format(dateFormat);
  const defaultEd = moment.tz(timezone).format(dateFormat);
  if (!isCustomPeriod(period)) {
    return [defaultSd, defaultEd];
  }
  let [startDate, endDate] = period.split(':', 2);
  if (moment.tz(endDate, dateFormat, timezone).isBefore(moment.tz(minDate, dateFormat, timezone), 'day')) {
    endDate = minDate;
  }
  if (moment.tz(endDate, dateFormat, timezone).isAfter(moment.tz(defaultEd, dateFormat, timezone), 'day')) {
    endDate = defaultEd;
  }
  if (moment.tz(startDate, dateFormat, timezone).isBefore(moment.tz(minDate, dateFormat, timezone), 'day')) {
    startDate = minDate;
  }
  if (moment.tz(startDate, dateFormat, timezone).isAfter(moment.tz(endDate, dateFormat, timezone), 'day')) {
    startDate = endDate;
  }
  return [startDate, endDate];
}

export function endOfMinuteDateUtc(requestDate, timezone) {
  return moment.tz(requestDate, timezone).endOf('minute').utc();
}

function isDateRangeInCurrentDay(startDate, endDate, requestDate, timezone) {
  return (
    startDate === endDate && moment.tz(endDate, dateFormat, timezone).isSame(moment.tz(requestDate, timezone), 'day')
  );
}

export function periodToUtcTimeRange(requestDate, timezone = moment.tz.guess(), period) {
  switch (period) {
    case 'TODAY':
      return [moment.tz(requestDate, timezone).startOf('day').utc(), endOfMinuteDateUtc(requestDate, timezone)];
    case '1D':
      return [
        moment.tz(requestDate, timezone).subtract(23, 'hours').startOf('hour').utc(),
        endOfMinuteDateUtc(requestDate, timezone),
      ];
    case '7D':
      return [
        moment.tz(requestDate, timezone).subtract(6, 'days').startOf('day').utc(),
        endOfMinuteDateUtc(requestDate, timezone),
      ];
    case '1M':
      return [
        moment.tz(requestDate, timezone).subtract(29, 'days').startOf('day').utc(),
        endOfMinuteDateUtc(requestDate, timezone),
      ];
    case 'MTD':
      return [moment.tz(requestDate, timezone).startOf('month').utc(), endOfMinuteDateUtc(requestDate, timezone)];
    case '6M':
      return [
        moment.tz(requestDate, timezone).subtract(5, 'months').startOf('month').utc(),
        endOfMinuteDateUtc(requestDate, timezone),
      ];
    case 'YTD':
      return [moment.tz(requestDate, timezone).startOf('year').utc(), endOfMinuteDateUtc(requestDate, timezone)];
    case '1Y':
      return [
        moment.tz(requestDate, timezone).subtract(11, 'months').startOf('month').utc(),
        endOfMinuteDateUtc(requestDate, timezone),
      ];
    default: {
      const [startDate, endDate] = parseCustomPeriod(period, timezone);
      return [
        moment.tz(startDate, dateFormat, timezone).startOf('day').utc(),
        isDateRangeInCurrentDay(startDate, endDate, requestDate, timezone)
          ? endOfMinuteDateUtc(requestDate, timezone)
          : moment.tz(endDate, dateFormat, timezone).endOf('day').utc(),
      ];
    }
  }
}

function currentHourRangePrettyEndDate(requestDate, timezone) {
  return moment.tz(requestDate, timezone).format('mm') === '00'
    ? moment.tz(requestDate, timezone).add(1, 'minutes')
    : moment.tz(requestDate, timezone);
}

function numberOfMonthsYtd(requestDate, timezone) {
  return moment.tz(requestDate, timezone).diff(moment.tz(requestDate, timezone).startOf('year'), 'months', false);
}

function numberOfHoursInDateRange(startDate, endDate, requestDate, timezone) {
  if (isDateRangeInCurrentDay(startDate, endDate, requestDate, timezone)) {
    return moment
      .tz(requestDate, timezone)
      .endOf('hour')
      .diff(moment.tz(startDate, dateFormat, timezone).startOf('day'), 'hours', false);
  }
  return moment
    .tz(endDate, dateFormat, timezone)
    .endOf('day')
    .diff(moment.tz(startDate, dateFormat, timezone).startOf('day'), 'hours', false);
}

export function composeTimeCategories(requestDate, timezone, period) {
  switch (period) {
    case 'TODAY': {
      const result = [];
      const today = moment.tz(requestDate, timezone).format(dateFormat);
      const hours = numberOfHoursInDateRange(today, today, requestDate, timezone);
      for (let i = hours; i >= 0; i -= 1) {
        const start = moment.tz(requestDate, timezone).subtract(i, 'hours');
        const end =
          i === 0
            ? currentHourRangePrettyEndDate(requestDate, timezone)
            : moment.tz(requestDate, timezone).subtract(i - 1, 'hours');
        const endFormat = i === 0 ? 'HH:mm' : 'HH:00';
        const prefix = start.isSame(moment.tz(requestDate, timezone), 'day') ? '' : 'Yesterday, ';
        const rangeLabel = `${prefix}${start.format('HH:00')} - ${end.format(endFormat)}`;
        result.push(rangeLabel);
      }
      return result;
    }
    case '1D': {
      const result = [];
      for (let i = 23; i >= 0; i -= 1) {
        const start = moment.tz(requestDate, timezone).subtract(i, 'hours');
        const end =
          i === 0
            ? currentHourRangePrettyEndDate(requestDate, timezone)
            : moment.tz(requestDate, timezone).subtract(i - 1, 'hours');
        const endFormat = i === 0 ? 'HH:mm' : 'HH:00';
        const prefix = start.isSame(moment.tz(requestDate, timezone), 'day') ? '' : 'Yesterday, ';
        const rangeLabel = `${prefix}${start.format('HH:00')} - ${end.format(endFormat)}`;
        result.push(rangeLabel);
      }
      return result;
    }
    case '7D': {
      const result = [];
      for (let i = 6; i >= 0; i -= 1) {
        const day =
          i === 0
            ? currentHourRangePrettyEndDate(requestDate, timezone)
            : moment.tz(requestDate, timezone).subtract(i, 'days');
        const format = i === 0 ? '00:00 - HH:mm, dddd D, Y' : 'dddd D, Y';
        result.push(day.format(format));
      }
      return result;
    }
    case '1M': {
      const result = [];
      for (let i = 29; i >= 0; i -= 1) {
        const day =
          i === 0
            ? currentHourRangePrettyEndDate(requestDate, timezone)
            : moment.tz(requestDate, timezone).subtract(i, 'days');
        const format = i === 0 ? '00:00 - HH:mm, MMM D, Y' : 'MMM D, Y';
        result.push(day.format(format));
      }
      return result;
    }
    case '6M': {
      const result = [];
      for (let i = 5; i >= 0; i -= 1) {
        const month = moment.tz(requestDate, timezone).subtract(i, 'months');
        const format = 'MMM, Y';
        result.push(month.format(format));
      }
      return result;
    }
    case 'YTD': {
      const result = [];
      const monthsCount = numberOfMonthsYtd(requestDate);
      for (let i = monthsCount; i >= 0; i -= 1) {
        const month = moment.tz(requestDate, timezone).subtract(i, 'months');
        const format = 'MMM, Y';
        result.push(month.format(format));
      }
      return result;
    }
    case '1Y': {
      const result = [];
      for (let i = 11; i >= 0; i -= 1) {
        const month = moment.tz(requestDate, timezone).subtract(i, 'months');
        const format = 'MMM, Y';
        result.push(month.format(format));
      }
      return result;
    }
    default: {
      const [startDate, endDate] = parseCustomPeriod(period, timezone);
      const momentSd = moment.tz(startDate, dateFormat, timezone);
      const momentEd = moment.tz(endDate, dateFormat, timezone);
      const days = moment.duration(momentEd.diff(momentSd)).asDays();
      const result = [];
      if (startDate === endDate) {
        const hours = numberOfHoursInDateRange(startDate, endDate, requestDate, timezone);
        if (isDateRangeInCurrentDay(startDate, endDate, requestDate, timezone)) {
          for (let i = hours; i >= 0; i -= 1) {
            const start = moment.tz(requestDate, timezone).subtract(i, 'hours');
            const end =
              i === 0
                ? currentHourRangePrettyEndDate(requestDate, timezone)
                : moment.tz(requestDate, timezone).subtract(i - 1, 'hours');
            const endFormat = i === 0 ? 'HH:mm' : 'HH:00';
            const prefix = start.isSame(moment.tz(requestDate, timezone), 'day') ? '' : 'Yesterday, ';
            const rangeLabel = `${prefix}${start.format('HH:00')} - ${end.format(endFormat)}`;
            result.push(rangeLabel);
          }
        } else {
          for (let i = hours; i >= 0; i -= 1) {
            const start = moment.tz(endDate, dateFormat, timezone).endOf('day').subtract(i, 'hours');
            const end = moment
              .tz(endDate, dateFormat, timezone)
              .endOf('day')
              .subtract(i - 1, 'hours');
            const rangeLabel = `${start.format('HH:00')} - ${end.format('HH:00')}, ${start.format('MMM D, Y')}`;
            result.push(rangeLabel);
          }
        }
      } else {
        for (let i = days; i >= 0; i -= 1) {
          const day = moment.tz(endDate, dateFormat, timezone).subtract(i, 'days');
          result.push(day.format('dddd MMM D, Y'));
        }
      }
      return result;
    }
  }
}

export function getTimeLabelFormatter(requestDate, timezone, period) {
  switch (period) {
    case 'TODAY': {
      const today = moment.tz(requestDate, timezone).format(dateFormat);
      const hours = numberOfHoursInDateRange(today, today, requestDate, timezone);
      return (value, index) =>
        moment
          .tz(requestDate, timezone)
          .subtract(hours - index, 'hours')
          .format('HH:00');
    }
    case '1D':
      return (value, index) =>
        moment
          .tz(requestDate, timezone)
          .subtract(23 - index, 'hours')
          .format('HH:00');
    case '7D':
      return (value, index) =>
        moment
          .tz(requestDate, timezone)
          .subtract(6 - index, 'days')
          .format('dd');
    case '1M':
      return (value, index) =>
        moment
          .tz(requestDate, timezone)
          .subtract(29 - index, 'days')
          .format('MMM D');
    case '6M':
      return (value, index) =>
        moment
          .tz(requestDate, timezone)
          .subtract(5 - index, 'months')
          .format('MMM');
    case 'YTD':
      return (value, index) =>
        moment
          .tz(requestDate, timezone)
          .subtract(numberOfMonthsYtd(requestDate) - index, 'months')
          .format('MMM');
    case '1Y':
      return (value, index) =>
        moment
          .tz(requestDate, timezone)
          .subtract(11 - index, 'months')
          .format('MMM');
    default: {
      const [startDate, endDate] = parseCustomPeriod(period, timezone);
      const momentSd = moment.tz(startDate, dateFormat, timezone);
      const momentEd = moment.tz(endDate, dateFormat, timezone);
      const days = moment.duration(momentEd.diff(momentSd)).asDays();
      if (startDate === endDate) {
        const hours = numberOfHoursInDateRange(startDate, endDate, requestDate, timezone);
        if (isDateRangeInCurrentDay(startDate, endDate, requestDate, timezone)) {
          return (value, index) =>
            moment
              .tz(requestDate, timezone)
              .subtract(hours - index, 'hours')
              .format('HH:00');
        }
        return (value, index) =>
          moment
            .tz(endDate, dateFormat, timezone)
            .endOf('day')
            .subtract(hours - index, 'hours')
            .format('HH:00');
      }
      return (value, index) =>
        moment
          .tz(endDate, dateFormat, timezone)
          .subtract(days - index, 'days')
          .format('MMM D');
    }
  }
}

export function getInsightsDay(requestDate, timezone, period) {
  return period === TOMORROW_PERIOD
    ? moment.tz(requestDate, timezone).add(1, 'days')
    : moment.tz(requestDate, timezone);
}

export function getInsightsReportUtcTimeRange(requestDate, timezone, period) {
  const day = getInsightsDay(requestDate, timezone, period);
  return [moment.tz(day, timezone).startOf('day').utc(), moment.tz(day, timezone).endOf('day').utc()];
}

export function composeInsightsTimeCategories(requestDate, timezone, period) {
  const [startUtc] = getInsightsReportUtcTimeRange(requestDate, timezone, period);
  const result = [];
  for (let i = 0; i < 24; i += 1) {
    const start = moment.tz(startUtc, timezone).add(i, 'hours');
    const end = moment.tz(startUtc, timezone).add(i + 1, 'hours');
    const prefix = start.isSame(moment.tz(requestDate, timezone), 'day') ? 'Today, ' : 'Tomorrow, ';
    const rangeLabel = `${prefix}${start.format('HH:00')} - ${end.format('HH:00')}`;
    result.push(rangeLabel);
  }
  return result;
}

export const timeCategories24h = [];
for (let i = 0; i < 24; i += 1) {
  timeCategories24h.push(i < 10 ? `0${i}:00` : `${i}:00`);
}

export function getInsightsTimeLabelFormatter() {
  return (value, index) => `${index < 10 ? '0' : ''}${index}:00`;
}

export const TODAY_PERIOD = 'today';
export const TOMORROW_PERIOD = 'tomorrow';

export function parseInsightsPeriod(period, timezone) {
  if (period === TODAY_PERIOD) {
    return TODAY_PERIOD;
  } else if (period === TOMORROW_PERIOD) {
    return TOMORROW_PERIOD;
  } else {
    const localTime = moment.tz(timezone);
    const currentHour = parseFloat(localTime.format('HH'));
    return currentHour >= 12 ? TOMORROW_PERIOD : TODAY_PERIOD;
  }
}

export function resolveTimezone(locationId, availableLocations, userTimezone) {
  const defaultTimezone = userTimezone || moment.tz.guess();
  return get(availableLocations, [locationId, 'timezone'], defaultTimezone);
}

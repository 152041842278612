import { InputAdornment, ListSubheader, TextField, Typography, withStyles } from '@material-ui/core';
import LocationIcon from '@material-ui/icons/LocationOn';
import CameraIcon from '@material-ui/icons/Videocam';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import CompanyAndWhitelabeler from 'components/common/CompanyAndWhitelabeler';
import toJS from 'components/common/toJS';
import { find, get, map, sortBy, uniqBy } from 'lodash';
import matchSorter from 'match-sorter';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { getJobs, getJobsIds } from 'reducers/auth';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  menuList: {
    paddingRight: '0 !important',
    width: '100% !important',
    maxWidth: '600px',
  },
  selectRoot: {
    width: 'calc(100% - 32px)',
  },
  groupHeader: {
    backgroundColor: theme.palette.background.paper,
    top: -8,
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(1),
    },
    margin: theme.spacing(1.5, 0),
  },
  companyContainer: {
    lineHeight: 'normal',
  },
  groupUl: {
    padding: 0,
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const filterOptions = (options, { inputValue }) => {
  const matched = matchSorter(options, inputValue, { keys: [(item) => item.name] });
  return sortBy(matched, [
    (job) => get(job, 'location.company.name', '').toLowerCase(),
    (job) => get(job, 'location.name', '').toLowerCase(),
    (job) => job.name.toLowerCase(),
  ]);
};

function DetectionJobPicker({
  classes,
  className,
  SelectProps,
  selectClasses,
  value,
  onChange,
  availableIds = [],
  availableJobs = {},
  dispatch,
  ...rest
}) {
  const locations = useMemo(() => map(uniqBy(Object.values(availableJobs), 'location.id'), 'location'), [
    availableJobs,
  ]);
  const companies = useMemo(
    () => map(uniqBy(Object.values(availableJobs), 'location.company.id'), 'location.company'),
    [availableJobs]
  );
  const options = useMemo(() => {
    return sortBy(Object.values(availableJobs), [
      (job) => get(job, 'location.company.name', '').toLowerCase(),
      (job) => get(job, 'location.name', '').toLowerCase(),
      (job) => job.name.toLowerCase(),
    ]);
  }, [availableJobs]);
  const resolvedValue = find(options, { id: value });

  return (
    <Autocomplete
      filterOptions={filterOptions}
      className={classNames(classes.root, className)}
      id="detection-job-picker"
      options={options}
      groupBy={
        locations.length > 1
          ? (option) => `${get(option, 'location.company.id')}${get(option, 'location.id')}`
          : undefined
      }
      getOptionLabel={(option) => option.name}
      value={resolvedValue}
      onChange={(event, newValue) => {
        onChange(newValue.id);
      }}
      openOnFocus
      disableClearable
      noOptionsText="No cameras available"
      {...rest}
      renderGroup={({ key, group, children }) => {
        const locationId = group.substring(36);
        const location = locations.find((l) => l.id === locationId);

        return (
          <li key={key}>
            <ListSubheader className={classes.groupHeader} component="div">
              <LocationIcon className={classes.icon} />
              <div>
                {companies.length > 0 ? (
                  <CompanyAndWhitelabeler
                    className={classes.companyContainer}
                    company={location.company}
                    whitelabeler={location.whitelabeler}
                  />
                ) : null}
                <Typography component="div" display="block" variant="subtitle2">
                  {location.name}
                </Typography>
              </div>
            </ListSubheader>
            <ul className={classes.groupUl}>{children}</ul>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          label="Camera"
          margin="none"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            className: SelectProps.className,
            startAdornment: (
              <InputAdornment position="start">
                <CameraIcon className={classes.icon} color="inherit" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    availableJobs: getJobs(state),
    availableIds: getJobsIds(state),
  };
}

export default connect(mapStateToProps)(withStyles(styles)(toJS(DetectionJobPicker)));

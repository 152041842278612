import React, { useCallback, useEffect } from 'react';
import {
  CircularProgress,
  Fab,
  Grid,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/HelpOutline';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import moment from 'moment';
import { useWillUnmount } from 'react-hooks-lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import BrandedLoadingIndicator from 'components/common/BrandedLoadingIndicator';
import CompanyAndWhitelabeler from 'components/common/CompanyAndWhitelabeler';
import SearchBox from 'components/common/SearchBox';
import SetTitle from 'components/common/SetTitle';
import toJS from 'components/common/toJS';
import useQueryParam from 'components/common/useQueryParam';
import UserActionMenu from 'components/user/UserActionMenu';
import UserSearchHelperText from 'components/user/UserSearchHelperText';
import { actions as userEditActions } from 'reducers/user/edit';
import {
  actions,
  getListItems,
  getListPaging,
  getTotalMatchedCount,
  isListFetching,
  isListFetchingToAppend,
} from 'reducers/user/list';
import { getPastTimeFromNow, isDeleteAuthorized, isEditAuthorized } from '../common/utils';
import { isUsersCreateAuthorized } from '../../reducers/auth';
import { UserRolesMap } from '../common/constants';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  tablePaper: {
    width: '100%',
    overflowX: 'auto',
    minHeight: 200,
  },
  table: {
    minWidth: 700,
  },
  loadingContainer: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    position: 'sticky',
    left: 0,
  },
  loadingProgress: {
    marginRight: theme.spacing(4),
  },
  activationStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  grow: {
    flexGrow: 1,
    flexShrink: 0,
  },
  createFab: {
    marginRight: theme.spacing(1),
  },
  totalContainer: {
    marginBottom: theme.spacing(2),
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  actionMenu: {
    marginRight: theme.spacing(2),
  },
  nameCell: {
    paddingLeft: 80,
  },
});

function LoadingIndicator({ classes }) {
  return (
    <div className={classes.loadingContainer}>
      <CircularProgress variant="indeterminate" size={24} color="inherit" className={classes.loadingProgress} />
      <Typography variant="subtitle2" component="span" color="inherit">
        Loading...
      </Typography>
    </div>
  );
}

function getUserRoleName(user) {
  if (user.superAdmin) {
    return 'Administrator';
  }

  switch (user.role) {
    case UserRolesMap.LOCATION_MANAGER:
      return 'Location manager';
    case UserRolesMap.COMPANY_ADMIN:
      return 'Company administrator';
    case UserRolesMap.PORTFOLIO_SUPERVISOR:
      return 'Portfolio supervisor';
    default:
      return '';
  }
}

function UserList({
  classes,
  isFetching,
  isFetchingToAppend,
  items,
  totalMatched,
  isUsersCreateAuthorized,
  searchRequest,
  onCreateClick,
  onUnmount,
  location,
  history,
}) {
  useWillUnmount(onUnmount);

  const [query] = useQueryParam('query', (v) => v || '', location, history);
  const handleSearch = useCallback((value) => searchRequest(value, false), [searchRequest]);

  useEffect(() => {
    handleSearch(query);
  }, [handleSearch, query]);

  const displayTable = (isFetching && isFetchingToAppend) || !isFetching;

  return (
    <Grid container spacing={4} direction="column" className={classes.root}>
      <SetTitle title="Users" />
      <Grid item container justify="center">
        <SearchBox
          helperText={<UserSearchHelperText />}
          location={location}
          history={history}
          placeholder="Search by name, email, etc."
          onSearch={handleSearch}
        />
      </Grid>
      <Grid item container direction="column" className={classes.grow}>
        {isFetching && !isFetchingToAppend && <BrandedLoadingIndicator />}
        {displayTable && (
          <>
            <Grid className={classes.totalContainer} item container alignItems="flex-end">
              <Grid item xs={6}>
                {typeof totalMatched === 'number' && (
                  <Typography component="span" color="textSecondary" variant="subtitle1" align="left">
                    Total: {totalMatched}
                  </Typography>
                )}
              </Grid>
              {isUsersCreateAuthorized && (
                <Grid item xs={6} container justify="flex-end">
                  <Tooltip title="Create user">
                    <Fab
                      className={classes.createFab}
                      onClick={() => onCreateClick()}
                      size="small"
                      color="secondary"
                      aria-label="Create user"
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <Paper className={classNames(classes.tablePaper, classes.grow)}>
              {isFetching && !isFetchingToAppend && <LoadingIndicator classes={classes} />}
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.nameCell} align="left">
                      Name
                    </TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Company</TableCell>
                    <TableCell align="left">Role</TableCell>
                    <TableCell align="left">Locations</TableCell>
                    <TableCell align="left">Activation</TableCell>
                    <TableCell align="left" sortDirection="desc">
                      <TableSortLabel disabled active direction="desc">
                        Created
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items?.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        <div className={classes.actionContainer}>
                          {(isEditAuthorized(item) || isDeleteAuthorized(item)) && (
                            <UserActionMenu className={classes.actionMenu} entity={item} />
                          )}
                          {item.name}
                        </div>
                      </TableCell>
                      <TableCell align="left">{item.email}</TableCell>
                      <TableCell align="left">
                        <CompanyAndWhitelabeler company={item?.company} whitelabeler={item?.whitelabeler} />
                      </TableCell>
                      <TableCell align="left">{getUserRoleName(item)}</TableCell>
                      <TableCell align="left">
                        {item.locations && item.locations.length
                          ? sortBy(item.locations, 'name').map((location) => (
                              <div key={location.id}>{location.name}</div>
                            ))
                          : '—'}
                      </TableCell>
                      <TableCell align="left">
                        {item.active ? (
                          'Active'
                        ) : (
                          <Tooltip
                            interactive
                            title={
                              <>
                                To activate the account user needs to follow activation link sent to his email. The link
                                is valid 30 days from the creation date. To activate the account after this period user
                                needs to follow the{' '}
                                <MuiLink
                                  to={`/forgot-password`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  component={Link}
                                >
                                  Reset password
                                </MuiLink>{' '}
                                procedure.
                              </>
                            }
                          >
                            <span className={classes.activationStatus}>
                              Pending
                              <HelpIcon className={classes.helpIcon} />
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip
                          placement="bottom-start"
                          title={`Created at ${moment(item.createdAt).format('LL, LTS')}`}
                          enterDelay={300}
                        >
                          <span>{getPastTimeFromNow(item.createdAt)}</span>
                        </Tooltip>
                        {item.portalLastLoginAt && (
                          <>
                            <br />
                            <Tooltip
                              placement="bottom-start"
                              title={`Portal last login at ${moment(item.portalLastLoginAt).format('LL, LTS')}`}
                              enterDelay={300}
                            >
                              <Typography component="span" color="textSecondary" variant="caption">
                                Last login: {getPastTimeFromNow(item.portalLastLoginAt)}
                              </Typography>
                            </Tooltip>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {!isFetching && totalMatched > 0 && items && items.length < totalMatched && (
                <Waypoint
                  scrollableAncestor={window}
                  bottomOffset="-500px"
                  onEnter={() => searchRequest(query, true)}
                />
              )}
              {!isFetching && totalMatched === 0 && (
                <Typography
                  style={{ marginTop: 16 }}
                  className={classes.loadingContainer}
                  variant="h5"
                  component="span"
                  align="center"
                >
                  No users found
                </Typography>
              )}
              {isFetching && isFetchingToAppend && <LoadingIndicator classes={classes} />}
            </Paper>
          </>
        )}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    items: getListItems(state),
    paging: getListPaging(state),
    isFetching: isListFetching(state),
    isFetchingToAppend: isListFetchingToAppend(state),
    totalMatched: getTotalMatchedCount(state),
    isUsersCreateAuthorized: isUsersCreateAuthorized(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    searchRequest: actions.searchRequest,
    onUnmount: actions.reset,
    onCreateClick: userEditActions.createRequest,
  })(withStyles(styles)(toJS(UserList)))
);

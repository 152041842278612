import axios from 'axios';

export const apiPath = '/apigw/v1/company';

export const searchCompanies = async (searchParams) => {
  const { data } = await axios({
    method: 'POST',
    url: `${apiPath}/search`,
    data: searchParams,
  });

  return data;
};

export const updateCompany = async (id, entityData) => {
  const { data } = await axios({
    method: 'PUT',
    url: `${apiPath}/${id}`,
    data: entityData,
  });

  return data;
};

import 'echarts/lib/chart/scatter';
import 'echarts/lib/component/tooltip';
import { round } from 'lodash';
import React from 'react';
import { median } from 'components/common/utils';
import MiniWidget from 'components/dashboard/common/MiniWidget';

function pad(num) {
  return num < 10 ? `0${num}` : num;
}

function formatSeconds(seconds) {
  if (!seconds || seconds < 1) {
    return '\u2014';
  }
  const hours = Math.floor(seconds / 60 / 60);
  const minutes = Math.floor(seconds / 60);
  const secs = Math.ceil(seconds % 60);
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}

function prepareData(report) {
  if (report.length === 0) {
    return undefined;
  }
  const waitTimes = report.map(({ waitTime }) => waitTime);
  const medianTime = round(median(waitTimes));
  return medianTime > 0 ? formatSeconds(medianTime) : undefined;
}

export default function MedianVisitDurationWidget({ requestDate, report }) {
  if (!requestDate) {
    return null;
  }

  const medianWaitTime = prepareData(report);

  return (
    <MiniWidget
      isFetching={false}
      title="Median duration"
      value={medianWaitTime}
      valueHelperText="Median visit duration"
      textContainerAlignItems="baseline"
    />
  );
}

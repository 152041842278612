import { all } from 'redux-saga/effects';
import camera from 'sagas/dashboard/camera';
import company from 'sagas/dashboard/company';
import driveoff from 'sagas/dashboard/driveoff';
import location from 'sagas/dashboard/location';
import ops from 'sagas/dashboard/ops';
import sales from 'sagas/dashboard/sales';

export default function* dashboard() {
  yield all([location(), camera(), sales(), ops(), company(), driveoff()]);
}
